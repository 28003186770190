import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import config from "../../../config";
import { history } from "../../../routes";
import { ToastContainer, notify } from "../../../libraries/notifications";
import {
  capitalize,
  validateIsfilled,
  getOwner,
  selectGeneratorWObjChild,
  formatOnlyNumberInput,
  replaceNewLines,
  validateURL,
  parseUrl,
  composeValidators,
} from "../../../libraries/utils";
import FormLayout from "../../../components/forms/FormLayout";
import actionActions from "../../../context/actions/actions";
import filesActions from "../../../context/files/actions";
import usersActions from "../../../context/users/actions";
import categoriesActions from "../../../context/categories/actions";
import LayoutWithSidebar from "../../../components/layout/LayoutWithSidebar";
import PanelWindow from "../../../components/PanelWindow";
import TextInput from "../../../components/forms/TextInput";
import SelectInput from "../../../components/forms/SelectInput";
import ImageUploadFileInputEdit from "../../../components/forms/ImageUploadFileInputEdit";
import IntegerInput from "../../../components/forms/IntegerInput";
import TextareaInput from "../../../components/forms/TextareaInput";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { generateNewRank } from "../../../libraries/lexorank-helper";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";

class Action extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      action: {
        //"questions": [{ "respuesta": "Respuesta 1", "isCorrect": true }]
      },
      actionCategoryTypes: [],
      creatingNew: false,
      editing: false,
      files: {},
      imageUpdated: false,
      loading: false,
      remove: false,
      rewards: {},
      submitting: false,
      titles: "",
      user: {},
      view: false,
      page: 1,
      showQuestions: true,
      questions: [1, 2, 3, 4, 5, 6],
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t("Actions"), this.t("New")];
    this.titles = this.t("User details");
  }

  componentDidMount() {
    const {
      match: { params },
      location,
    } = this.props;
    const page = new URLSearchParams(location.search).get("page");
    if (page) this.setState({ page });

    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        titles: this.t("Edit Action"),
      });
      this.breadcrumbs = [this.t("Actions"), this.t("Edit")];
      this.getAction(params.id);
    } else {
      this.setState({ titles: this.t("New Action"), creatingNew: true });
    }
    this.getActionTypesArray();
  }

  getAction = async (id) => {
    this.setState({ loading: true });
    await this.props.onGetAction(id);
    const { action } = this.props;
    if (action.error) {
      notify(this.t(action.error.message));
    } else {
      this.setState({
        action: action.item,
        showQuestions: action.item?.json_data?.answers ? true : false,
        files: { img: action.item.json_data?.img },
        loading: action.loading,
      });
      if (this.state.action.owner && this.state.action.owner !== "system") {
        this.getUser(this.state.action.owner);
      }
    }
    this.setState({ loading: false });
  };

  getLastActionFromCategory = async () => {
    await this.props.onGetAction({});
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  goBack = () => {
    history.push(config.ROUTES.ACTIONS);
  };

  getUser = async (id) => {
    await this.props.onGetUser({ id });
    const { user } = this.props;
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      this.setState({
        user: user.item,
      });
      await this.props.onGetRewards(id);
      const { auth } = this.props;
      if (!auth.user.rewards.total) {
        notify(capitalize(this.t("error fetching rewards")));
      } else {
        this.setState({ rewards: auth.user.rewards });
      }
    }
  };

  getActionTypesArray = async () => {
    await this.props.onGetActionTypes({ type: "actions" });
    const { categories } = this.props;
    if (categories.error) {
      notify(this.t(categories.error.message));
    } else {
      this.setState({ actionCategoryTypes: categories.items });
    }
  };

  onFileChangeImageInput = async ({ file, data, name }) => {
    const { action, creatingNew } = this.state;
    this.setState({ submitting: true });
    if(creatingNew){
      const imageIdentifier = `${parseInt(Math.random() * 10 ** 9)}`;
      data.append('identifier',  `${imageIdentifier}_${name}`);
    }
    else{
      data.append("identifier", `${action.id}_${name}`);
    }
    await this.props.onPictureUpload(data);
    const { files } = this.props;
    if (files.error) {
      notify(this.t(files.error.message));
    } else {
      this.setState({ imageUpdated: true });
      this.setState({
        files: {
          ...this.state.files,
          [name]: files.files.fileInfo.location,
        },
      });
    }
    this.setState({ submitting: false });
    return files.files.fileInfo.location;
  };

  onClearImageField = ({ name }) => {
    if (name) this.setState({ files: { ...this.state.files, [name]: null } });
  };

  onSubmit = async (values) => {
    let arrayOds = [];
    // const odsKeys = this.state.ods.map( o => { const key = Object.keys(o); return key[0]; });
    // alert(JSON.stringify(values, null, 3))

    config.ODS.forEach((o, i) => {
      //console.log(o, values[o])
      if (values[`o${o.code}`] === true) {
        arrayOds.push(o.code);
      }
    });
    const stringOds = arrayOds.join(",");
    console.log({stringOds});
    const data = {
      type: values.type,
      name: values.name,
      details: replaceNewLines(values.details),
      order_index: "",
      json_data: {
        img: this.state.files && this.state.files.img,
        coins: +values.coins,
        number_attempts: values?.sub_type==='video' ? 1 : +values.number_attempts,
        points: +values.points,
        rule: values.rule,
        video: values.video,
        url: values.url,
        ods: stringOds,
        question: values?.question,
        answers: values?.answers ? values?.answers?.map(p => p.respuesta).join("-_-_") : null,
        answerCorrectOption: values?.answers ? values?.answers?.map(p => p.isCorrect ? 1 : 0).join(",") : null,
      },
    };
    if(values?.sub_type !== 'regular'){
      data.json_data.sub_type = values?.sub_type
    }

    if (values.id) data.id = values.id;
    await this.props.onSaveOrUpdate({
      ...data,
      owner: getOwner(this.props.auth.user),
    });
    const { action } = this.props;
    if (action.error) {
      notify(this.t(action.error.message));
    } else {
      this.goBack();
    }
  };

  disableEnableAction = async () => {
    this.setState({ loading: true });
    const { action } = this.state;
    const data = { id: action.id, enabled: action.enabled };
    await this.props.onSaveOrUpdate(data);
    const error = this.props.action.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    }
    this.setState({ loading: false });
  };

  render() {
    const {
      actionCategoryTypes,
      creatingNew,
      view,
      editing,
      submitting,
      action,
      loading,
      files,
      ods,
      page,
    } = this.state;
    const sub_type_action= config.ACTIONS_SUB_TYPES;
    const actionTypes = selectGeneratorWObjChild(
      actionCategoryTypes,
      "id",
      "name"
    );
    const possibleIntervals = selectGeneratorWObjChild(
      config.ACTIONS.INTERVALS,
      "value",
      (o) => capitalize(this.t(o.label))
    );

    // Layout actions
    const actions = {
      main: {
        onClick: (e) => this.submit(e),
        title: this.t("Save"),
        icon: "checkmark",
        disabled: submitting || (view && !editing),
        checkPermissions: "insert",
      },
      secondaries: [
        {
          onClick: (e) => history.push(`${config.ROUTES.ACTIONS}?page=${page}`),
          title: this.t("Go Back"),
          icon: "cheveron_left",
          disabled: submitting,
          visible: true,
          className: "btn-accent",
        },

        {
          onClick: (e) => this.setState({ editing: true }),
          title: this.t("Edit"),
          icon: "edit_pencil",
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: "update",
        },
        {
          onClick: (e) => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t("Cancel"),
          icon: "edit_pencil",
          disabled: submitting || !editing,
          visible: editing,
        },
        {
          onClick: (e) => {
            if (action.enabled === 1)
              this.setState({ action: { ...action, enabled: 0 } }, () =>
                this.disableEnableAction()
              );
            if (action.enabled === 0)
              this.setState({ action: { ...action, enabled: 1 } }, () =>
                this.disableEnableAction()
              );
          },
          title: this.t(action?.enabled ? "Disable" : "Enable"),
          icon: !action?.enabled ? "view_show" : "view_hide",
          disabled: submitting || this.props.action.loading,
          checkPermissions: "enable",
          visible: !creatingNew,
        },
      ],
    };

    // ** Form validation functions
    const required = (value) =>
      validateIsfilled(value) || (view && !editing)
        ? undefined
        : this.t("This field is required");
    const positive = (value) =>
     Number(value) >= 0 || (view && !editing)
        ? undefined
        : this.t("It must be positive");
        
    const validateForm = (values) => {
      const errors = {};
      errors.name = required(values.name);
      errors.details = required(values.details);
      errors.type = required(values.type);
      errors.sub_type = required(values.sub_type);
      errors.question = values?.sub_type ==='questions'? required(values.question) : undefined;
      errors.number_attempts = values?.sub_type ==='questions'? required(values.number_attempts) : undefined;
      errors.number_attempts = values?.sub_type ==='questions' ?(!errors?.number_attempts? (values?.sub_type !=='questions' ? positive(values.number_attempts) : undefined) : errors.number_attempts): undefined;
      // errors.answers =this.state.answers.length<1? required(values.question):undefined;
      console.log(errors)
      return errors;
    };
    const answersParse = action.json_data?.answers?.split("-_-_")
    const answerCorrectOption = action.json_data?.answerCorrectOption?.split(",")

    if (action.json_data) {
      action.coins = action.json_data.coins;
      action.points = action.json_data.points;
      action.rule = action.json_data.rule;
      action.question = action.json_data?.question;
      action.video = action.json_data?.video;
      action.url = action.json_data?.url;
      action.number_attempts = action.json_data?.number_attempts;
      action.sub_type = action.json_data?.sub_type || 'regular';
      action.answers = answersParse?.map((a, i) => ({ respuesta: a, isCorrect: Number(answerCorrectOption[i]) }));
      if (action.json_data.ods) {
        action.json_data.ods.split(",").forEach((o) => {
          action[`o${o}`] = true;
        });
      }
    }

    const isUrl = (value) =>
      validateURL(value) || (view && !editing) || !value
        ? undefined
        : this.t("Ingresar URL válido");

    return (
      <LayoutWithSidebar
        main={{ className: "text-content-400" }}
        header={{ breadcrumbs: this.breadcrumbs }}
        container={{ className: "px-8 mb-3 rounded-lg" }}
        actions={actions}
      >
        <PanelWindow outerTitle={this.titles} loading={loading}>
          
          <Form
            mutators={{ ...arrayMutators }}
            initialValues={{ ...action } || {}}
            onSubmit={this.onSubmit}
            validate={validateForm}
          >
            {({
              handleSubmit,
              form: {
                mutators: { push, pop }
              }, // injected from final-form-arrays above
              pristine,
              form,
              submitting,
              values
            }
            ) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              values.details = values.details || "";
              return (
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 grid-rows-3 sm:grid-cols-2 sm:grid-rows-2 items-center">
                    <div className="w-full px-3 md:mb-0">
                      <Field
                        name="name"
                        component={TextInput}
                        placeholder={this.t("First name")}
                        label={this.t("First name")}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="sm:row-span-2">
                      {!editing &&
                        !creatingNew &&
                        values.json_data &&
                        values.json_data.img ? (
                        <div className='flex justify-center items-center '>
                          <img src={files.img} alt="" className="h-48 sm:h-60 object-cover rounded-lg" />
                        </div>
                      ) : (
                        <Field
                          name="img"
                          img={files.img}
                          component={ImageUploadFileInputEdit}
                          className="h-full"
                          label={capitalize(this.t("image"))}
                          placeholder={capitalize(this.t("image URL"))}
                          inputOnChange={this.onFileChangeImageInput}
                          disabled={view && !editing}
                          clickButtonClear={this.onClearImageField}
                          inputClassName="input-bordered shadow-none h-full"
                          noError={true}
                        />
                      )}
                    </div>
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <Field
                        name="details"
                        component={TextareaInput}
                        placeholder={capitalize(this.t("details"))}
                        label={capitalize(this.t("details"))}
                        readOnly={view && !editing}
                        inputClassName="resize-none"
                      />
                    </div>
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                        <Field
                          name="sub_type"
                          component={SelectInput}
                          label={capitalize(this.t("Tipo"))}
                          placeholder={capitalize(this.t("Tipo"))}
                          options={sub_type_action}
                          empty={capitalize(this.t("Seleccionar..."))}
                          readOnly={view && !editing}
                        />
                      </div>
                  <div className="w-full mb-3 md:mb-0 flex flex-wrap lg:flex-nowrap">
                    <div className="flex">
                      <div className="w-full px-3 mb-3 md:mb-0">
                        <Field
                          name="type"
                          component={SelectInput}
                          label={capitalize(this.t("category"))}
                          placeholder={capitalize(this.t("category"))}
                          options={actionTypes}
                          empty={capitalize(this.t("select a category"))}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0">
                        <Field
                          name="rule"
                          component={SelectInput}
                          placeholder={capitalize(this.t("interval"))}
                          label={capitalize(this.t("interval between uses"))}
                          options={possibleIntervals}
                          empty={capitalize(this.t("select interval"))}
                          readOnly={view && !editing}
                          disabled={values?.sub_type !=='regular' ? true : false}
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-full px-3 mb-3 md:mb-0">
                        <Field
                          name="coins"
                          component={IntegerInput}
                          placeholder="0"
                          label={capitalize(this.t("sharycoins"))}
                          parse={(v) => formatOnlyNumberInput(v)}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0">
                        <Field
                          name="points"
                          component={IntegerInput}
                          placeholder="0"
                          label={capitalize(this.t("sharypoints"))}
                          parse={(v) => formatOnlyNumberInput(v)}
                          readOnly={view && !editing}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap md:flex-nowrap">
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <Field
                        name="url"
                        component={TextInput}
                        placeholder={capitalize(this.t("url"))}
                        label={capitalize(this.t("url"))}
                        readOnly={view && !editing}
                        parse={parseUrl}
                        validate={isUrl}
                      />
                    </div>
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <Field
                        name="video"
                        component={TextInput}
                        placeholder={capitalize(this.t("video"))}
                        label={capitalize(this.t("video"))}
                        readOnly={view && !editing}
                        parse={parseUrl}
                        validate={isUrl}
                      />
                    </div>
                  </div>

                  <p className="px-3">ODS</p>
                  <div className="flex flex-wrap w-full px-3">
                    {config.ODS.map((o, i) => {
                      // const key = Object.keys(o);
                      return (
                        <label
                          key={i}
                          className={
                            "cursor-pointer" +
                            (view && !editing ? " pointer-events-none" : "")
                          }
                        >
                          <Field
                            name={`o${o.code}`}
                            component="input"
                            type="checkbox"
                            className="hidden"
                          />
                          <img
                            src={o.icon}
                            className={
                              "inline-block relative w-24 h-24 transition-all" +
                              (view && !editing ? " opacity-40" : "") +
                              (values[`o${o.code}`] === true
                                ? " border-4 border-primary"
                                : " p-2 opacity-40")
                            }
                          />
                        </label>
                      );
                    })}
                  </div>
                  <div>
                     {values?.sub_type === "questions" && (
                      <div className="mt-4">
                        <p className="px-3 mb-4">Pregunta</p>
                        <Field
                          name="question"
                          type="text"
                          component={TextInput}
                          placeholder="Escribe tu pregunta"
                        // className="bg-white input flex-1 w-full"
                        />
                        <div className="max-w-sm">
                        <p className="px-3 mb-4">Cantidad de intentos</p>
                        <Field
                          name="number_attempts"
                          type="number"
                          component={TextInput}
                          placeholder="Ingrese la cantidad de intentos "
                          min={1} 
                          data={10}

                          // className="bg-white input flex-1 w-full"
                          />
                          </div>
                        <div className="flex  my-4 gap-8">
                          <button
                           disabled={view && !editing}
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                              push("answers", {
                                respuesta: "",
                                isCorrect:
                                  values?.answers?.length > 0 ? false : true,
                              })
                            }
                          >
                            Agregar respuesta
                          </button>
                        </div>
                        <FieldArray name="answers">
                          {({ fields }) => {
                            this.fields = fields

                            return fields.map((name, index) => (
                              <div
                                className="flex flex-row mb-1 gap-4 py-4 px-2 rounded-md border border-gray-200"
                                key={name}
                              >
                                <div className="w-96 flex">
                                  <label className="self-center h-min font-bold mr-2">
                                    #{index + 1}
                                  </label>
                                  <Field
                                    name={`${name}.respuesta`}
                                    component="input"
                                    placeholder="Resuesta"
                                    className="bg-white input flex-1"
                                    readOnly={view && !editing}
                                  />
                                </div>
                                <div className="self-center">
                                  <Field
                                    name={`${name}.isCorrect`}
                                    component="input"
                                    type="checkbox"
                                    readOnly={view && !editing}
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;
                                      let hasCorrect = false;
                                      // Si quiero desactivar una correcta, debo asegurarme que exista el menos una correcta
                                      if (isChecked == false) {
                                        fields.forEach((fieldName, idx) => {
                                          if (idx !== index) {
                                            if (fields.value[idx].isCorrect) hasCorrect = true
                                          }
                                        });
                                      }
                                      if (isChecked || (hasCorrect && !isChecked)) {
                                        fields.update(index, {
                                          ...fields.value[index],
                                          isCorrect: isChecked,
                                        });
                                      }
                                    }}
                                  />
                                  <label className="ml-4">Es correcta?</label>
                                </div>
                                <button
                                  className="self-center"
                                  onClick={() => fields.pop(index)}
                                  style={{ cursor: "pointer" }}
                                  disabled={view && !editing}
                                >
                                  ❌
                                </button>
                              </div>
                            ))
                          }
                          }
                        </FieldArray>
                      </div>
                    )}
                  </div>
                  <pre>
                    {/* {JSON.stringify(values,null,3)} */}
                  </pre>
                </form>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    action: state.actions.current,
    categories: state.categories.list,
    category: state.categories.current,
    files: state.files,
    user: state.users.current,
    // rewards: state.users.auth.user.rewards
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveOrUpdate: (params) => dispatch(actionActions.saveOrUpdate(params)),
    onGetAction: (id) => dispatch(actionActions.get(id)),
    onGetRewards: (id) => dispatch(usersActions.getRewards(id)),
    onGetActionTypes: (id) => dispatch(categoriesActions.getAll(id)),
    onGetTypeName: (id) => dispatch(categoriesActions.get(id)),
    onRemove: (id) => dispatch(actionActions.del(id)),
    onGetUser: (params) => dispatch(usersActions.get(params)),
    onClearCurrent: () => dispatch(actionActions.clearCurrent()),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Action))
);
