import React from 'react';
import Icon from '../../libraries/icons';
// import ButtonIcon from '../commons/ButtonIcon';
import readXlsxFile from 'read-excel-file'


const ExcelUploadFileInput = ({ input, meta, label, inputOnChange, ...rest }) => {
  const [fileName, setFileName] = React.useState(null)
  const inputProps = {
    ...input,
    onChange: async e => {
      const file = e.target.files[0];
      const formData = new FormData();
      setFileName(file.name)
      formData.append("myFile",file,file.name)

      console.log("schema", rest.schema)

      if(rest.checkRows){
        readXlsxFile(file).then((rows) => {
          rest.checkRows(rows)
        })
      }

      if(rest.schema){
        readXlsxFile(file, { schema: rest.schema }).then(({ rows, errors }) => {
          console.log("errors", errors)
          rest.getRows(rows, errors)
        })
      } else {
        readXlsxFile(file).then((rows) => {
          rest.getRows(rows)
        })
      }
      await inputOnChange && inputOnChange({ file: file.name, data: formData, name: input.name });
    }
  };

  return (
    <div className={"form-control " + (rest.className ? rest.className : "")}>
      {label && (
        <label htmlFor={input.name} className={"label " + (rest.labelClassName ? rest.labelClassName : "")}>
          <span className="label-text">{label}</span>
        </label>
      )}
      <div className={"relative input py-10 flex justify-center items-center " + (rest.disabled ? "bg-gray-400 " : "") + (rest.inputClassName ? rest.inputClassName : "")}>
        <Icon className="h-8 w-8 absolute" name="excel_logo" />
        {<input {...inputProps}
          type="file" 
          // accept="accept"
          className={"opacity-0 " 
            + (meta && meta.error && meta.touched ? "input-error invalid-shadow " : "") 
          }
          placeholder={rest.placeholder}
          onClick={rest.onClick}
          disabled={rest.disabled}
          readOnly={rest.readOnly}
          value={undefined}
          accept=".xlsx"
        />}
      </div>
      {fileName ? fileName : "Seleccionar un archivo .xlsx"}
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">{meta && meta.error && meta.touched}</span>
        </label>
      )}
    </div>
  )
}

export default ExcelUploadFileInput;