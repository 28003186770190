import { combineReducers } from 'redux';
import accounts from './accounts';
import actions from './actions';
import categories from './categories';
import companies from './companies';
import files from './files';
import initiatives from './initiatives';
import initiative_assistants from './initiative_assistants';
import initiative_assistances from './initiative_assistances';
import internals from './internals';
import localization from './localization'; //TODO: REMOVE
import locations from './locations';
import messages from './messages';
import notifications from './notifications';
import oauth from './oauth';
import permissions from './permissions'
import products from './products';
import product_variants from './product_variants';
import roles from './roles'
import roles_users from './roles_users'
import services from './services';
import settings from './settings';
import transactions from './transactions'
import users from './users';
import benefits from './benefits';
import businesses from './businesses';
import assistants from './assistants';
import challenges from './challenges';
import payments from './payments';

export default combineReducers({
  accounts: accounts.reducers,
  actions: actions.reducers,
  assistants: assistants.reducers,
  benefits: benefits.reducers,
  businesses: businesses.reducers,
  companies: companies.reducers,
  categories: categories.reducers,
  files: files.reducers,
  initiatives: initiatives.reducers,
  initiative_assistants: initiative_assistants.reducers,
  initiative_assistances: initiative_assistances.reducers,
  internals: internals.reducers,
  locale: localization.reducers, //TODO: REMOVE
  locations: locations.reducers,
  messages: messages.reducers,
  notifications: notifications.reducers,
  oauth: oauth.reducers,
  permissions: permissions.reducers,
  products: products.reducers,
  product_variants: product_variants.reducers,
  roles: roles.reducers,
  roles_users: roles_users.reducers,
  settings: settings.reducers,
  services: services.reducers,
  transactions: transactions.reducers,
  users: users.reducers,
  challenges: challenges.reducers,
  payments: payments.reducers,
});
