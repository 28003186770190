

import Loader from '../../components/commons/Loader';
import { getAwardingAndRemainingCredits } from '../../libraries/utils';

const WinnersList = ({ winners, awards, reservedCredits, condition_type }) => {
  const remainingCredits = winners && awards && reservedCredits ? getAwardingAndRemainingCredits(
    awards, winners, reservedCredits
  ).remainingCredits : 0;

  return (
    <>
      <h3 className="font-bold text-lg text-center mb-8">Ganadores</h3>
      
      {!winners && <Loader />}
      {winners && !winners.length && <p className="text-center">Este desafío no tuvo ganadores</p>}
      {winners && !!winners.length && winners.map((w, i) => {
        let prizeName = ""
        if(awards[i].benefit === "coins"){
          prizeName = "Puntos Shary"
        } else if(awards[i].benefit === 'credits'){
          prizeName = "Créditos"
        } else {
          prizeName = awards[i].benefit
          awards[i].quantity = 1
        }

        return(
          <div key={w.user.id} className="w-full px-3 flex gap-2 items-center border rounded-lg bg-white">
            <div className="w-8 p-3 font-bold text-black flex-shrink-0">
              {i + 1}
            </div>
            <div className="w-full flex items-center justify-between flex-shrink">
              <div className="py-4 flex gap-2 items-center">
                <div className="avatar border-0">
                  <div className="w-5">
                    <img
                      src={w.user?.profile_image}
                      alt={`Avatar ${w.user?.name}`}
                    />
                  </div>
                </div>
                <p>{w.user?.name}</p>
              </div>
              <div className="flex gap-3">
                <span className="text-[#FFC93F] font-bold">
                  {
                    w?.points} {condition_type === 'actions' ?
                      w?.points > 1 ? 'acciones' : 'acción' :
                        w?.points > 1 ? 'puntos' : 'punto'
                  }
                </span>
              </div>
            </div>
            <div className='w-36'>
              {awards && <p className='rounded-box px-2 py-1 capitalize text-right bg-green-100'>{awards[i].quantity} {prizeName}</p>}
            </div>
          </div>
        )
      })}
      {!!reservedCredits && reservedCredits > 0 && <p className='mt-4 text-center text-sm'>De la reserva créditos de este desafío, los {remainingCredits} restantes regresarán a los créditos disponibles de la empresa.</p>}
    </>
  )
}

export default WinnersList;