import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { capitalize, getOwner } from '../../../libraries/utils';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import businessActions from '../../../context/businesses/actions';
import companiesActions from '../../../context/companies/actions';
import Swal from 'sweetalert2';
import PrivateButton from '../../../components/commons/PrivateButton';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Businesess extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      businesses: [],
      loading: true,
      windowWidth: window.innerWidth,
      location: '',
      roles: []
    };
    this.breadcrumbs = [capitalize(this.t('brands'))];
  }

  componentDidMount() {
    this.setBreadcrumbsAndGetBusinesses();
    window.addEventListener('resize', this.handleResize);
  }

  setBreadcrumbsAndGetBusinesses = async (page_num = 1) => {
    const location = this.props.location.pathname.slice(1);
    const { roles } = this.props.auth.user;
    const params = { page_num };

    switch (location) {
      case 'businesses':
        this.breadcrumbs = [capitalize(this.t('brands'))];
        params.type = 'benefits';
        break;
      case 'entities':
        this.breadcrumbs = [capitalize(this.t('entities'))];
        params.type = 'initiatives';
        break;
      case 'subsidiaries':
        this.breadcrumbs = [capitalize(this.t('subsidiaries'))];
        params.type = 'subsidiaries';
        break;
      default:
        break;
    }

    if (this.state.search && this.state.search !== '') {
      params.where = { json_data: [] };
      if (this.state.search && this.state.search !== '') {
        params.where = { custom: { name: `%${this.state.search.toLowerCase()}` } };
      }
    }
    if (this.state.sort) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }

    if (roles.includes(config.ROLES.COMPANIES)) {
      const owner = await this.getCompanies();
      params.owner = getOwner(this.props.auth.user)
      //const owner = await this.getCompanies();
      //params.owner= owner[0].id
    } 
    
    await this.props.onGetAll({ ...params });
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      this.setState({ businesses: businesses.items, loading: businesses.loading, location, roles });
    }
  };

  getCompanies = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });
    if (this.props.companies.error) {
      notify(this.t(this.props.companies.error.message));
    } else {
      return this.props.companies.items;
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname.slice(1) !== prevProps.location.pathname.slice(1)) {
      this.setState({ loading: true }, () => {
        this.setBreadcrumbsAndGetBusinesses();
      });
    }
  }
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () =>
      this.setBreadcrumbsAndGetBusinesses(currentPage)
    );
  };

  onRemove = (id, resetRows) => {
    const { businesses } = this.props;
    customSwal
      .fire({
        title: this.t(resetRows ? 'Multiple remove' : '¿Are you sure?'),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          businesses.loading = true;
          if (resetRows) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (businesses.error) {
              notify(this.t(businesses.error.message));
            } else {
              this.setBreadcrumbsAndGetBusinesses(this.state.currentPage);
            }
          } else {
            await this.props.onRemove(id);
            if (businesses.error) {
              notify(this.t(businesses.error.message));
            } else {
              this.setBreadcrumbsAndGetBusinesses(this.state.currentPage);
            }
          }
        }
      });
  };
  onEdit = id => {
    history.push(`${this.state.location}/${id}`)
  };

  onNew = () => {
    history.push(`${this.state.location}/new`);
  };

  onSearch = data => {
    if (this.state.search !== data.search)
      this.setState({ search: data.search || '' }, () => this.setBreadcrumbsAndGetBusinesses());
  };

  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  render() {
    const { windowWidth, businesses, loading, roles } = this.state;

    const columns = [
      {
        name: capitalize(this.t('name')),
        sortable: true,
        selector: row => row['name'],
        minWidth: '150px',
        sortSelector: 'name'
      },
      {
        name: capitalize(this.t('type')),
        selector: row => capitalize(row['type']),
        minWidth: '220px',
        hide: 'md'
      },
      {
        name: this.t('Enabled'),
        hide: 'sm',
        selector: row => (
          <p className={`${row.enabled ? 'bg-green-100' : 'bg-red-100'} rounded-box px-2 py-1`}>
            {row.enabled ? this.t('Active') : this.t('Inactive')}
          </p>
        )
      },
      {
        name: capitalize(this.t('UI actions')),
        allowOverflow: true,
        center: true,
        width: '150px',
        hide: this.hide(370),
        cell: row => {
          return (
           <div className="flex justify-center align-center rounded-full gap-2">
              <ButtonIcon onClick={() => this.onEdit(row.id)} icon="view_show" buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm" className="w-5" />
              <PrivateButton control="delete">
                <ButtonIcon onClick={() => this.onRemove(row.id)} icon="trash" buttonClassName="btn-link text-error bg-gray-100 btn-sm" />
              </PrivateButton>
            </div>
          );
        }
      }
    ];

    const actions = {
      main: {
        onClick: this.onNew,
        title: this.t('New'),
        checkPermissions: 'insert'
      },
      secondaries: [],
      search: { onSearch: this.onSearch, title: this.t('Search') }
    };

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={businesses}
            columns={columns}
            selectableRows={roles.includes(config.ROLES.BENEFITS) ? windowWidth > 600 : null}
            query={this.props.businesses.query}
            getDataFunction={this.setBreadcrumbsAndGetBusinesses}
            multipleDeleteFunction={roles.includes(config.ROLES.BENEFITS) ? this.onRemove : null}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    businesses: state.businesses.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAll: params => dispatch(businessActions.getAll(params)),
    onGetCompanies: params => dispatch(companiesActions.getAll(params)),
    onRemove: id => dispatch(businessActions.del(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Businesess));
