import React from 'react';

import { ReactComponent as arrow_left } from '../assets/icons/arrow-left.svg';
import { ReactComponent as arrow_right } from '../assets/icons/arrow-right.svg';
import { ReactComponent as bell } from '../assets/icons/outline/bell.svg';
import { ReactComponent as briefcase } from '../assets/icons/outline/briefcase.svg';
import { ReactComponent as calendar } from '../assets/icons/calendar.svg';
import { ReactComponent as chat_alt } from '../assets/icons/chat-alt.svg';
import { ReactComponent as menu } from '../assets/icons/outline/menu.svg';
import { ReactComponent as home } from '../assets/icons/outline/home.svg';
import { ReactComponent as user_group } from '../assets/icons/outline/user-group.svg';
import { ReactComponent as logout } from '../assets/icons/outline/logout.svg';
import { ReactComponent as color_swatch } from '../assets/icons/outline/color-swatch.svg';
import { ReactComponent as globe } from '../assets/icons/outline/globe.svg';
import { ReactComponent as tag } from '../assets/icons/outline/tag.svg';
import { ReactComponent as cog } from '../assets/icons/outline/cog.svg';
import { ReactComponent as user } from '../assets/icons/outline/user.svg';
import { ReactComponent as qrcode } from '../assets/icons/outline/qrcode.svg';
import { ReactComponent as receipt_tax } from '../assets/icons/outline/receipt-tax.svg';
import { ReactComponent as gift } from '../assets/icons/outline/gift.svg';
import { ReactComponent as coin24 } from '../assets/icons/custom/2-4coin.svg';
import { ReactComponent as maslow } from '../assets/icons/maslow.svg';
import { ReactComponent as close } from '../assets/icons/close.svg';
import { ReactComponent as cheveron_right } from '../assets/icons/cheveron-right.svg';
import { ReactComponent as cheveron_left } from '../assets/icons/cheveron-left.svg';
import { ReactComponent as cheveron_down } from '../assets/icons/cheveron-down.svg';
import { ReactComponent as checkmark } from '../assets/icons/checkmark.svg';
import { ReactComponent as clean_inside } from '../assets/icons/car-clean-inside.svg';
import { ReactComponent as clipboard_list } from '../assets/icons/outline/clipboard-list.svg';
import { ReactComponent as currency_dollar } from '../assets/icons/outline/currency-dollar.svg';
import { ReactComponent as edit_pencil } from '../assets/icons/edit-pencil.svg';
import { ReactComponent as flag } from '../assets/icons/outline/flag.svg';
import { ReactComponent as gout } from '../assets/icons/gout.svg';
import { ReactComponent as help } from '../assets/icons/help.svg';
import { ReactComponent as impact1 } from '../assets/icons/custom/1impact.svg';
import { ReactComponent as impact3 } from '../assets/icons/custom/3impact.svg';
import { ReactComponent as impact5 } from '../assets/icons/custom/5impact.svg';
import { ReactComponent as hand_world } from '../assets/icons/custom/hand_world.svg';
import { ReactComponent as loans } from '../assets/icons/custom/loans.svg';
import { ReactComponent as user_group_2 } from '../assets/icons/user-group.svg';
import { ReactComponent as location } from '../assets/icons/location.svg';
import { ReactComponent as notification } from '../assets/icons/notification.svg';
import { ReactComponent as minus_no_outline } from '../assets/icons/solid/minus.svg';
import { ReactComponent as office_building } from '../assets/icons/outline/office-building.svg';
import { ReactComponent as phone } from '../assets/icons/phone.svg';
import { ReactComponent as photograph } from '../assets/icons/outline/photograph.svg';
import { ReactComponent as pin } from '../assets/icons/pin.svg';
import { ReactComponent as plus } from '../assets/icons/plus.svg';
import { ReactComponent as plus_circle } from '../assets/icons/outline/plus-circle.svg';
import { ReactComponent as search } from '../assets/icons/search.svg';
import { ReactComponent as sharypoints_main } from '../assets/icons/custom/sharypoints_main.svg';
import { ReactComponent as switch_horizontal } from '../assets/icons/outline/switch-horizontal.svg';
import { ReactComponent as time } from '../assets/icons/time-circle.svg';
import { ReactComponent as trash } from '../assets/icons/trash.svg';
import { ReactComponent as user_info } from '../assets/icons/user-info.svg';
import { ReactComponent as view_hide } from '../assets/icons/view-hide.svg';
import { ReactComponent as view_show } from '../assets/icons/view-show.svg';
import { ReactComponent as whatsapp } from '../assets/icons/whatsapp.svg';
import { ReactComponent as x_circle } from '../assets/icons/outline/x-circle.svg';
import { ReactComponent as upload } from '../assets/icons/upload.svg';
import { ReactComponent as excel_logo } from '../assets/icons/excel-logo.svg';

import { ReactComponent as pharmacy } from '../assets/icons/custom/pharmacy.svg';
import { ReactComponent as stethoscope } from '../assets/icons/custom/stethoscope.svg';
import { ReactComponent as community_service } from '../assets/icons/custom/community-service.svg';
import { ReactComponent as challenges } from '../assets/icons/challenges.svg';
import { ReactComponent as send_mail } from '../assets/icons/send-mail.svg';
import { ReactComponent as information_solid } from '../assets/icons/information-solid.svg';
import { ReactComponent as trophy } from '../assets/icons/trophy.svg';
import { ReactComponent as drag } from '../assets/icons/custom/drag.svg';


// stroke="none" fill="currentColor"


/* 
  To find out if an icon is being used somewhere 
  in the app use this regex in the search menu:

  (?:'|")text to find(?:'|")

  It will search for single and double quotes
  Don't use icon:{'something'} or name="something"
  It won't find all of the places where it's being used
  since those are the two ways to use an icon

  TODO: work out a regex to find both instances
*/
const iconMap =  {
  maslow,
  trophy,
  arrow_left,
  arrow_right,
  bell,
  briefcase,
  chat_alt,
  checkmark,
  cheveron_down,
  cheveron_left,
  cheveron_right,
  calendar,
  clean_inside,
  clipboard_list,
  close,
  cog,
  currency_dollar,
  edit_pencil,
  flag,
  globe,
  gout,
  help,
  home,
  impact1,
  impact3,
  impact5,
  loans,
  hand_world,
  location,
  logout,
  menu,
  minus_no_outline,
  notification,
  office_building,
  phone,
  photograph,
  pin,
  plus,
  plus_circle,
  search,
  sharypoints_main,
  switch_horizontal,
  tag,
  time,
  trash,
  user,
  user_group,
  user_info,
  view_show,
  view_hide,
  whatsapp,
  x_circle,
  upload,
  color_swatch,
  receipt_tax,
  gift,

  stethoscope,
  pharmacy,
  community_service,
  qrcode,

  excel_logo,
  challenges,
  send_mail,
  information_solid,
  user_group_2,
  coin24,
  drag,
};

const Icon = ({ name, size, color, ...rest }) => {
  const Icon = iconMap[name];
  if (typeof Icon === 'undefined') return null;
  return <Icon color={color} style={{ width: size, height: size }} {...rest} />;
};

export default Icon;

