import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import challengesActions from "../../../context/challenges/actions"

import Loader from "../../../components/commons/Loader"

import { ReactComponent as Trofeo } from "../../../assets/icons/custom/trofeo.svg"
import { ReactComponent as TrofeoUno } from "../../../assets/icons/custom/trofeo_uno.svg"

class Leaderboard extends React.Component {
  constructor(props) {
    super(props)
    this.t = this.props.t
    this.state = {
      loading: true,
      leaderboard: [],
    }
  }
  async componentDidMount() {
    const { id } = this.props.match.params
    await this.props.getLeaderboard(id)

    const conditionType = this.props.challenge.item?.condition_type;
    const leaderboard = this.props.leaderboard

    this.setState({
      leaderboard,
      loading: false,
      conditionType
    })
  }

  

  render() {
    const { loading, leaderboard, conditionType } = this.state

    return (
      <div className="w-full h-full rounded-lg">
        {loading && <Loader />}
        {!loading && leaderboard && !leaderboard.length && (
          <p>Este desafío no tiene actividad todavía.</p>
        )}
        {!loading && leaderboard.length > 0 && (
          <div className="flex flex-col gap-1">
            {/* <div className="w-full px-3 flex gap-2 items-center rounded-lg bg-[#297F87]"> */}
            <div className="w-full px-3 flex gap-2 items-center rounded-lg bg-green-600">
              <div className="w-8 self-start flex-shrink-0">
                <TrofeoUno />
              </div>
              <div className="w-full flex items-center justify-between flex-shrink">
                <div className="py-4 flex gap-2 items-center">
                  {/* <div className="w-6 h-6 rounded-full bg-white"></div> */}
                  <div className="avatar border-0">
                    <div className="w-5">
                      <img
                        src={leaderboard[0].user?.profile_image}
                        alt={`Avatar ${leaderboard[0].user?.name}`}
                      />
                    </div>
                  </div>
                  <p className="text-white">{leaderboard[0].user?.name}</p>
                </div>
                <div className="flex gap-3">
                  <Trofeo className="w-5" />
                  <span className="text-white font-bold">
                    {conditionType !== "actions" &&  `${leaderboard[0].points}pts`}
                    {conditionType === "actions" &&  `${leaderboard[0].number_of_actions} ${leaderboard[0].number_of_actions > 1 ? 'acciones' : 'acción'}`}
                  </span>
                </div>
              </div>
            </div>

            {leaderboard.map((u, i) => {
              if (i === 0) return null
              return (
                <div
                  key={u.user.id}
                  className="w-full px-3 flex gap-2 items-center border rounded-lg bg-white"
                >
                  <div className="w-8 p-3 font-bold text-black flex-shrink-0">
                    {i + 1}
                  </div>
                  <div className="w-full flex items-center justify-between flex-shrink">
                    <div className="py-4 flex gap-2 items-center">
                      <div className="avatar border-0">
                        <div className="w-5">
                          <img
                            src={u.user?.profile_image}
                            alt={`Avatar ${u.user?.name}`}
                          />
                        </div>
                      </div>
                      <p>{u.user?.name}</p>
                    </div>
                    <div className="flex gap-3">
                      <span className="text-[#FFC93F] font-bold">
                        {conditionType !== "actions" &&  `${u.points}pts`}
                        {conditionType === "actions" &&  `${u.number_of_actions} ${u.number_of_actions > 1 ? 'acciones' : 'acción'}`}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.auth.user,
    challenge: state.challenges?.current,
    leaderboard: state.challenges.current?.leaderboard?.leaderboard

  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLeaderboard: id => dispatch(challengesActions.getLeaderboard(id)),
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Leaderboard))
)
