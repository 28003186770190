import React from 'react';
import Icon from '../../libraries/icons';

function SelectInput({ input, meta, label, options, empty, ...rest }) {
  return (
    <div className={"form-control w-full max-w-xs " + rest.className ? rest.className : ""}>
      {label && (
        <label htmlFor={input.name} className={"relative label " + (rest.labelClassName ? rest.labelClassName : "")}>
          <span className="label-text flex gap-2">{label}
          {rest.info &&
            <span
              className="ml-1 font-normal pop-up  after:content-[attr(after)]"
              after={rest.info}
            >
              <Icon name="information_solid" className="h-4 w-4" />
            </span>
          }
          </span>


        </label>
      )}
      <select
        {...input}
        onChange={(v) => {
          input.onChange(v)
          if(rest.onChangeSelect){
            rest.onChangeSelect(v.target.value)
          }
        }}
        className={"select w-full "
          + (rest.selectClassName ? rest.selectClassName : "")
          + (meta && meta.error && meta.touched ? " input-error invalid-shadow" : "")
          + (rest.narrow ? " px-1" : "")
        }
        disabled={rest.readOnly || rest.disabled}
      >
        {empty && <option key={input.name + '0'} value="">{empty}</option>}
        {options && options.map(option =>
          <option key={option.value} value={option.value} disabled={option.disabled} selected={option.selected}>{option.label}</option>
        )}
      </select>
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">{meta?.error && meta?.touched && meta?.error}</span>
        </label>
      )}
    </div>
  )
}

export default SelectInput;