import React from 'react';
import PanelLayout from './PanelLayout';
import Loader from './commons/Loader';

export default function PanelWindow({ innerTitle, outerTitle, loading, children }) {

  return (
    <PanelLayout title={outerTitle} >
      <div className="p-4">
        {innerTitle && <h3 className="card-title">{innerTitle}</h3>}
        <div className="w-full">
          {loading ? (
            <Loader />
          ) : (
            children
          )}
        </div>
      </div>
    </PanelLayout>
    )
  }