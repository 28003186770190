import React from 'react';
import Icon from '../../libraries/icons';
import { useTranslation } from 'react-i18next';

export default function OfferOptions({ data, ...rest }) {
  const { t } = useTranslation();
  return(
    <div className="flex px-3 py-4 border-b" onClick={() => rest.onClick && rest.onClick(data)}>
      <span className="px-3">{t(data.name)}</span> 
      {rest.noChevron ? '' : <Icon className="h-6 w-6 ml-auto text-base-300" name="cheveron_right" />}
      {/* <Icon className="h-6 w-6 ml-auto text-base-300" name="cheveron_right" /> */}
    </div>
  )
}

/*
    <div  className="flex px-3 py-2 border-b">
      <Link
        to={route}>
        <span className="px-3 py-2">{t(data.name)}</span> 
      </Link>
      <Icon className="h-6 w-6 ml-auto text-base-300" name="cheveron_right" />
    </div>

*/