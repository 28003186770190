import config from '../../config';
import { request } from '../../libraries/request'


const onCoin = (data, token) => {
  // console.log('SERVICES::onCoin::save', data, token);
  console.log('SERVICES::onCoin::get');
  return request({
    // url: config.BASE_API_URL + config.API.PAYMENTS_BRICK,
    url: config.API.PAYMENTS_USD,
    // accessToken: token,
    method: 'GET',
    // data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

export default {
  onCoin,
};