const routes = {
  ROUTES: {
    HOME: '/',
    LOGIN: '/login',
    SIGNUP: '/signup',
    SIGNUP_FINALIZE: '/signup/:hash',
    SIGNUP_FULL: '/social/signup',
    FORGOT: '/forgot',
    PASSWORD: '/password/:hash',
    VERIFY: '/verify/:hash',

    USER: {
      PASSWORD_CHANGE: '/user/change',
      PROFILE: '/user/profile',
      INFO: '/user/info'
    },

    HELP: '/help',
    TERMS: '/terms',
    PRIVACY: '/privacy',
    CONTACT_US: '/contact_us',

    ACCOUNTS: '/accounts',
    ACCOUNTS_NEW: '/accounts/new',
    ACCOUNTS_EDIT: '/accounts/:id',

    ACTIONS: '/actions',
    ACTIONS_NEW: '/actions/new',
    ACTIONS_EDIT: '/actions/:id',
    ACTIONS_ORDER: '/actions/order',

    BUSINESSES: '/businesses',
    BUSINESSES_NEW: '/businesses/new',
    BUSINESSES_EDIT: '/businesses/:id',

    ENTITIES: '/entities',
    ENTITIES_NEW: '/entities/new',
    ENTITIES_EDIT: '/entities/:id',

    SUBSIDIARIES: '/subsidiaries',
    SUBSIDIARIES_NEW: '/subsidiaries/new',
    SUBSIDIARIES_EDIT: '/subsidiaries/:id',

    PRIZES: '/prizes',
    PRIZES_NEW: '/prizes/new',
    PRIZES_EDIT: '/prizes/:id',

    MY_COMPANY: '/mycompany',
    MY_BUSINESS: '/mybusiness',
    MY_ORGANIZATION: '/myorganization',
    
    CATEGORIES: '/categories',
    CATEGORIES_NEW: '/categories/new',
    CATEGORIES_EDIT: '/categories/:id',

    COMPANIES: '/companies',
    COMPANIES_NEW: '/companies/new',
    COMPANIES_EDIT: '/companies/:id',

    // INITIATIVES: '/initiatives',
    // INITIATIVES_NEW: '/initiatives/new',
    // INITIATIVES_EDIT: '/initiatives/:id',

    LOCATIONS: '/locations',
    LOCATIONS_NEW: '/locations/new',
    LOCATIONS_EDIT: '/locations/:id',

    MESSAGES: '/messages',
    MESSAGES_NEW: '/messages/new',
    MESSAGES_EDIT: '/messages/:id',

    PERMISSIONS: '/permissions',
    PERMISSIONS_NEW: '/permissions/new',
    PERMISSIONS_EDIT: '/permissions/:id',

    PRODUCTS: '/products',
    PRODUCTS_NEW: '/products/new',
    PRODUCTS_EDIT: '/products/:id',

    DEPARTMENTS: '/departments',
    DEPARTMENTS_NEW: '/departments/new',
    DEPARTMENTS_EDIT: '/departments/:id',

    MY_SETTINGS: '/mysettings',
    MY_SETTINGS_NEW: '/mysettings/new',
    MY_SETTINGS_EDIT: '/mysettings/:id',

    TRANSACTIONS: '/transactions',
    TRANSACTIONS_NEW: '/transactions/new',
    TRANSACTIONS_EDIT: '/transactions/:id',

    CONSUMPTIONS: '/consumptions',
    CONSUMPTIONS_NEW: '/consumptions/new',
    CONSUMPTIONS_EDIT: '/consumptions/:id',

    ASSISTANCES: '/assistances',
    ASSISTANCES_NEW: '/assistances/new',
    ASSISTANCES_EDIT: '/assistances/:id',

    COLLABORATORS: '/collaborators',
    COLLABORATORS_NEW: '/collaborators/new',
    COLLABORATORS_EDIT: '/collaborators/:id',

    ADMINISTRATORS: '/administrators',
    ADMINISTRATORS_NEW: '/administrators/new',
    ADMINISTRATORS_EDIT: '/administrators/:id',

    BENEFITS: '/benefits',
    BENEFITS_COLABORADORES: '/benefits/collaborators',
    BENEFITS_CONSUMPTIONS: '/benefits/consumptions',
    BENEFIT_CONSUMPTION: '/benefits/consumptions/:id',
    BENEFITS_NEW: '/benefits/new',
    BENEFITS_EDIT: '/benefits/:id',

    SCANQR: '/scanqr',
    // INITIATIVES_ATTENDANCE: '/attendance',
    // INITIATIVES_ATTENDANCE_CONFIRMATION: '/attendance/:volunteering_id',
    // INITIATIVES_ATTENDANCE_CONFIRMATION_SUCCESS: '/attendance/:code/success',

    RANKING: '/ranking',
    CREDITS_COMPANY: '/credits/company',
    CREDITS_COLLABORATORS: '/credits/collaborators',
    CREDITS_ONE_COLLABORATOR: '/credits/collaborators/:id',

    BENEFITS_SCANQR: '/scanqr',
    BENEFITS_CONSUME: '/consume',
    BENEFITS_CONSUME_CONFIRMATION: '/consume/:benefit_id',

    // BENEFITS_LIST: '/companybenefits',
    // BENEFIT_SINGLE: '/companybenefits/:companybenefit_id',

    CHALLENGES: '/challenges',
    CHALLENGES_NEW: '/challenges/new',
    CHALLENGES_EDIT: '/challenges/:id',

    EXCEL: '/excel',

    REFERENTS: '/referents',
    REFERENTS_NEW: '/referents/new',
    REFERENTS_EDIT: '/referents/:id',
    REFERENTS_EXCEL: '/referents/:id/excel',
    
    CHAT: '/chat/:root_id',
    MODERADOR_ACCIONES: '/actions/h/moderador',
  }
};
export default routes;
