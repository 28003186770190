import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../../libraries/notifications';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import transactionActions from '../../../context/transactions/actions';
import categoriesActions from '../../../context/categories/actions';
import usersActions from '../../../context/users/actions';
import config from '../../../config';
import { isEmptyObject, capitalizePhrase, capitalize, getOwner } from '../../../libraries/utils';
import businessesActions from '../../../context/businesses/actions';

class Assistances extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      actionCategoryTypes: [],
      currentType: 'initiative',
      sort: {},
      transactions: [],
      loading: true,
      users: [],
      windowWidth: window.innerWidth
    };
    this.breadcrumbs = [capitalize(this.t('Participantes'))];
  }

  componentDidMount() {
    // used to display user data in some columns
    this.getUsers();
    this.getTransactions();
    // used for the select
    this.getActionTypesArray();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getActionTypesArray = async () => {
    await this.props.onGetActionTypes({ type: 'actions' });
    const { categories } = this.props;
    if (categories.error) {
      notify(this.t(categories.error.message));
    } else {
      this.setState({ actionCategoryTypes: categories.items });
    }
  };

  getUsers = async () => {
    await this.props.onGetAllUsers();
    const { users } = this.props;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      this.setState({ users: users.items });
    }
  };

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getBusinessId = async () => {
    await this.props.onGetAllBusinesses({ owner: getOwner(this.props.auth.user) })
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message))
      return null
    } else {
      if(businesses.items.length){
        return businesses.items[0].id;
      }
      return null
    }
  }

  getTransactions = async (page_num = 1, type = this.state.currentType) => {
    this.setState({loading: true})
    const params = { page_num, type };
    const { search } = this.state;

    if (search && search !== '') {
      params.where = {
        custom: {
          sub_type: `%${search.toLowerCase()}`,
          comparison: 'OR'
        }
      };
    }

    if (!isEmptyObject(this.state.sort)) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }

    params.type = config.TYPES.TRANSACTIONS.INITIATIVE;
    params.source = await this.getBusinessId()//this.props.auth.user.unique_id;

    await this.props.onGetAllTransactions(params);
    const { transactions } = this.props;
    if (transactions.error) {
      notify(this.t(transactions.error.message));
    } else {
      this.setState({ transactions: transactions.items, loading: transactions.loading });
    }
  };

  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getTransactions(currentPage));
  };
  onSearch = data => {
    if (this.state.search !== data.search) this.setState({ search: data.search || '' }, () => this.getTransactions());
  };

  searchByType = async type => {
    this.setState({ currentType: type }, () => this.getTransactions());
  };

  // Function for hiding columns when the windowWidth goes below the number specified
  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  searchClear = form => {
    form.change('search', undefined);
    this.onSearch({ search: '' });
  };

  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  render() {
    const { windowWidth, transactions, actionCategoryTypes, currentType, users, loading } = this.state;

    const actionNameDisplay = row => {
      /* 
        Find in array where item.id is equal to
        the row.sub_type property
        Return the item's name
        (the find returns an object 
        since this is an array of objects)
      */
      /* 
        actions sub_type property is the only property which is not a string
        but a category id, hence the find needed to translate it into
        a human-readable string
       */
      if (currentType === 'action') {
        const foundItem = actionCategoryTypes.find(item => item.id === row['sub_type']);
        return foundItem?.name;
      }
      return capitalizePhrase(row['sub_type']);
    };

    const displaySourceName = row => {
      /* 
        Actions and initiatives do not have the source data saved in json_data
        So the user data must be found in order to display the name correctly
      */
      if (row['source'].length === 36) {
        const foundUser = users.find(user => user.id === row['source']);
        return capitalizePhrase(foundUser?.name);
      }
      return capitalizePhrase(row['source']);
    };

    // FIX: REDEFINIR
    const columns = [
      {
        name: capitalize(this.t('type')),
        sortable: true,
        selector: actionNameDisplay,
        sortSelector: 'sub_type'
      },
      {
        name: this.t('Source'),
        sortable: true,
        selector:
          currentType === 'borrow'
            ? row => `${row.json_data?.owner?.first_name} ${row.json_data?.owner?.last_name}`
            : currentType === 'action' || currentType === 'initiative'
            ? displaySourceName
            : '',
        sortSelector: 'source',
        hide: 'md'
      },
      {
        name: this.t('Nombre'),
        sortable: true,
        selector:
          currentType === 'borrow'
            ? row => capitalizePhrase(row.json_data?.product?.name) || capitalizePhrase(row.json_data?.offer?.name)
            : currentType === 'action'
            ? row => capitalizePhrase(row.json_data?.action?.name)
            : currentType === 'initiative'
            ? row => capitalizePhrase(row.json_data?.initiative?.name)
            : '',
        sortSelector: 'target'
      },
      {
        name: capitalize(this.t('sharycoins')),
        sortable: true,
        selector: row => row.json_data.coins,
        hide: this.hide(1280)
      },
      {
        name: capitalize(this.t('sharypoints')),
        sortable: true,
        selector: row => row.json_data.points,
        hide: this.hide(1280)
      },
      {
        name: capitalize(this.t('creation date')),
        sortable: true,
        selector: row => row.created_at.toLocaleDateString(),
        sortSelector: 'transactions__created_at',
        hide: this.hide(1280)
      },
      {
        name: this.t('Status'),
        hide: this.hide(1100),
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '10.48vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '11.89vw'
            : // 4k
            windowWidth >= 3840
            ? '13.56vw'
            : '',
        selector: row => <p className="rounded-box px-2 py-1 bg-gray-100">{capitalizePhrase(this.t(row.status))}</p>
      }
    ];

    // Layout actions
    const _actions = {
      // not needed
      main: null,
      secondaries: [],
      form: {},
      search: {
        onSearch: this.onSearch,
        onClearClick: this.searchClear,
        title: this.t('Search')
      }
    };

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={_actions}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={transactions}
            columns={columns}
            query={this.props.transactions.query}
            getDataFunction={this.getTransactions}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            onRowExpand={() => {}}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    transactions: state.transactions.list,
    categories: state.categories.list,
    users: state.users.list,
    businesses: state.businesses.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllTransactions: params => dispatch(transactionActions.getAll(params)),
    onGetActionTypes: id => dispatch(categoriesActions.getAll(id)),
    onGetAllUsers: params => dispatch(usersActions.getAll(params)),
    onGetAllBusinesses: params => dispatch(businessesActions.getAll(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Assistances));
