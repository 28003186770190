import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import { history } from '../../../routes';
import { calculateBalanceFromTrx, capitalize, capitalizePhrase, getCreditsFromMoney, getMoneyFromCredits } from '../../../libraries/utils';

import companyActions from '../../../context/companies/actions';
import trxActions from "../../../context/transactions/actions"
import settingActions from "../../../context/settings/actions"
import paymentsActions from "../../../context/payments/actions"

import PanelLayout from '../../../components/PanelLayout';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import Icon from '../../../libraries/icons';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import PrivateButton from '../../../components/commons/PrivateButton';
import clsx from 'clsx';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      companies: [],
      windowWidth: window.innerWidth,
      loading: true,
      setting: [],
      maslow_factor: null,
      dolarCCL: null,
    };
    this.breadcrumbs = [capitalize(this.t('companies'))];
  }

  componentDidMount() {
    this.getSettings();
    this.getUSD();
    this.getCompanies();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getCompanies = async (page_num = 1) => {
    // const { search } = this.state;
    // const params = { 
    //   unique_id: "companies",
    //   output: "companies_balances"
    //  };
// 
    // await this.props.onGetAllCompaniesBalances(params);
    // const { companies } = this.props;
    // if (companies.error) {
    //   notify(this.t(companies.error.message));
    // } else {
    //   let result = companies.items
    //   if (search && search !== '') {
    //     const columns = ["name", "email"]
    //     result = result.filter(comp => columns.some(col => comp[col]?.toLowerCase().includes(search.toLowerCase())))
    //   }
    //   // Arreglo temporal hasta permitir busqueda y sort
    //   this.setState({ companies: result, loading: companies.loading });
    // }
    await this.props.onGetAllCompanies()
    const { companies } = this.props;
    const params = {
      owner : companies.items.map(c => c.id),
      type: "credits",
      status: "paid" 
    }
    await this.props.onGetAllTrx(params)
    const { transactions } = this.props;

    const companieswithbalances = companies.items.map(c => {
      // use calculateBalanceFromTrx, it takes the transaction and the id of the company
      let balance = calculateBalanceFromTrx(transactions.items, c.id)
      return {...c, credits: balance}
    })

    this.setState({ companies: companieswithbalances, loading: companies.loading });

  };

  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getCompanies(currentPage));
  };

  getSettings = async () => {
    const params = {
      type: config.TYPES.SETTINGS.SHARYCO_ADMIN_SETTINGS,
      owner: config.OWNER.SYSTEM
    };
    await this.props.onGetSettings(params);
    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      const maslow = settings.items.find(s => s.code === 'maslow_factor');
      this.setState({ settings: settings.items, maslow_factor: +maslow.value });
    }
  };

  getUSD = async () => {
    await this.props.onCoin();
    const { payments } = this.props.payments
    if (payments.error) {
      notify(this.t(payments.error.message));
    } else {
      this.setState({ dolarCCL: payments.data.venta})
    }
  }

  onAddCredits = async company => {
    const { dolarCCL, maslow_factor } = this.state
    const { value: credits } = await customSwal.fire({
      title: "¿Cuantos créditos quieres agregar?",
      // text: "En pesos",
      input: 'number',
      showCancelButton: true,
      cancelButtonText: this.t('Cancel')
    })
    if(!credits || credits === "") return
    const money = await getMoneyFromCredits(credits, maslow_factor, dolarCCL);
    const {value: accepted} = await customSwal
      .fire({
        title: this.t(`¿Queres agregar ${credits} créditos a esta empresa?`),
        text: `El equivalente en USD es ${money}`,
        footer: this.t("No puedes deshacer esta operación."),
        icon: 'warning',
        // input: 'number',
        
        showCancelButton: true,

        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
    if(!accepted) return

    let data = {
      type: "credits",
      sub_type: "credits",
      owner: company.id,
      source: "SYSTEM",
      target: company.id,
      total: credits,
      currency: "ARS",
      // json_data: company,
      status: "paid",
      json_data: {
        company,
        reason: "Recarga de crédito desde el admin"
      }
    }
    this.props.onSaveTrx(data)
    // rreload the companies

    await this.getCompanies()
  }

  onEnabled = async company => {
    await this.props.onSaveOrUpdateCompany({
      id: company.id,
      enabled: company.enabled === 1 ? 0 : 1
    });
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      await this.getCompanies();
    }
  }

  onEdit = id => {
    history.push(config.ROUTES.COMPANIES_EDIT.replace(':id', id));
  };

  onNew = () => {
    history.push(config.ROUTES.COMPANIES_NEW);
  };

  onSearch = data => {
    if (this.state.search !== data.search) this.setState({ search: data.search || '' }, () => this.getCompanies());
  };

  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  render() {
    const { companies, windowWidth, loading } = this.state;

    const expandableComponent = ({ data }) => (
      <Link to={`${config.ROUTES.COMPANIES}/${data.id}`} className="flex items-center py-3 px-5">
        <Icon className="h-12 w-12 mr-3 text-primary" name="calculator" />
        <div className="mr-auto">
          <p className="text-gray-500">{capitalize(data.type)}</p>
          <small className="text-gray-500">
            {capitalize(data.state)}, {data.country}
          </small>
        </div>
      </Link>
    );

    const columns = [
      {
        name: capitalize(this.t('name of company')),
        sortable: true,
        selector: row => row['name'],
        maxWidth: '500px',
        sortSelector: 'name'
      },
      {
        name: this.t('email'),
        sortable: true,
        selector: row => row['email'],
        maxWidth: '500px',
        sortSelector: 'name'
      },
      {
        name: 'Créditos',
        sortable: true,
        selector: row => row['credits']['balance'].toFixed(2),
        hide: 'md',
        sortSelector: 'credits'
      },
      {
        name: this.t('Status'),
        hide: this.hide(1100),
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '10.48vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '11.89vw'
            : // 4k
            windowWidth >= 3840
            ? '13.56vw'
            : '',
        selector: row => <p className={
          clsx("rounded-box px-2 py-1 capitalize",{
            "bg-red-100": row.enabled === 0,
            "bg-green-100": row.enabled === 1
          })}
        >
          {row.enabled === 1 ? this.t('Active') : this.t('Inactive')}
        </p>
      },
      {
        name: capitalize(this.t('UI actions')),
        allowOverflow: true,
        center: true,
        width: '150px',
        hide: this.hide(370),
        cell: row => {
          return (
           <div className="flex justify-center items-center rounded-full gap-2">
              <div className="tooltip" data-tip="Ver">
                <ButtonIcon onClick={() => this.onEdit(row.id)} icon="view_show" buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm" className="w-5" />
              </div>
              <div className="tooltip" data-tip="Agregar Créditos">
                <ButtonIcon onClick={() => this.onAddCredits(row)} icon="coin24" buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm" className="w-5" />
              </div>
              <div className="tooltip" data-tip={ row.enabled === 1 ? "Deshabilitar" : "Habilitar"}>
                <input type="checkbox" onClick={() => this.onEnabled(row)} onChange={() => {}} className="toggle toggle-sm toggle-success" checked={ row.enabled === 1 } />
              </div>
            </div>
          );
        }
      }
    ];

    const actions = {
      main: {
        onClick: this.onNew,
        title: this.t('New'),
        checkPermissions: 'insert'
      },
      secondaries: [],
      search: { onSearch: this.onSearch, title: this.t('Search') } // Deshabilitado hasta nuevo aviso
    };

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={companies}
            noHeadCells
            columns={columns}
            query={this.props.companies.query}
            getDataFunction={this.getCompanies}
            // pagination// Deshabilitado hasta nuevo aviso
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            expandableRows={windowWidth < 450}
            expandableRowsComponent={expandableComponent}
            onRowExpand={() => {}}
          />
          
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    transaction: state.transactions.current,
    transactions: state.transactions.list,
    settings: state.settings.list,
    payments: state.payments
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllCompaniesBalances: params => dispatch(companyActions.getBalance(params)),
    onSaveTrx: params => dispatch(trxActions.saveOrUpdate(params)),
    onGetAllTrx: params => dispatch(trxActions.getAll(params)),//TODO: remove later
    onGetAllCompanies : params => dispatch(companyActions.getAll(params)),
    onSaveOrUpdateCompany: (params) => dispatch(companyActions.saveOrUpdate(params)),
    onGetSettings: params => dispatch(settingActions.getAll(params)),
    onCoin: (params) => dispatch(paymentsActions.onCoin(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Companies));
