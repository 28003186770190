const types = {
  CLEAR_CURRENT: 'SETTINGS_CLEAR_CURRENT',
  GET_REQUEST: 'SETTINGS_GET_REQUEST',
  GET_SUCCESS: 'SETTINGS_GET_SUCCESS',
  GET_FAILURE: 'SETTINGS_GET_FAILURE',

  SAVE_REQUEST: 'SETTINGS_SAVE_REQUEST',
  SAVE_SUCCESS: 'SETTINGS_SAVE_SUCCESS',
  SAVE_FAILURE: 'SETTINGS_SAVE_FAILURE',

  GETALL_REQUEST: 'SETTINGS_GETALL_REQUEST',
  GETALL_SUCCESS: 'SETTINGS_GETALL_SUCCESS',
  GETALL_FAILURE: 'SETTINGS_GETALL_FAILURE',

  DELETE_REQUEST: 'SETTINGS_DELETE_REQUEST',
  DELETE_SUCCESS: 'SETTINGS_DELETE_SUCCESS',
  DELETE_FAILURE: 'SETTINGS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'SETTINGS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SETTINGS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SETTINGS_UPDATE_FAILURE'    

};

export default types;