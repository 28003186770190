import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import config from '../../config';

import { useSelector } from 'react-redux';
import { permissionsCheck } from '../../libraries/utils';

const PrivateMenuItem = ({ children, ...rest}) => {
  const logged = useSelector(state => state.users.auth.logged);
  const userType = useSelector(state => state.users.auth.user?.type[0]);
  const permissions = useSelector(state => state.users.auth.user?.permissions);
  const location = useLocation();

  if (!rest.location) return null

  const access = () => {
    return permissionsCheck(rest.location, userType, permissions);
  }

  return (
    logged ? ( 
      access() ? (
        children
      ) : (
        null
      )
    ) : (
      <Redirect to={{ pathname: config.ROUTES.LOGIN, state: { from: location.pathname } }} />
    )
  )
}

export default PrivateMenuItem;