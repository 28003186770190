import React from 'react';
import Icon from '../../libraries//icons';
import { selectIconSize } from '../../libraries/utils';

export default function Button({ ...rest }) {
  return (
    <button
      type="button"
      className={"btn " + (rest.className ? rest.className : "btn-primary") }
      onClick={() => rest.onClick(rest.value)}
      disabled={rest.disabled}
    >
      {rest.icon && <Icon className={selectIconSize(rest.className) + (rest.title ? " mr-2" : "")} name={rest.icon} size={rest.iconSize || ''} />}
      {rest.title}
    </button>
  )
}