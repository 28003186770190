import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { validateIsfilled, capitalizePhrase, selectGeneratorWObjChild, capitalize, getOwner } from '../../../libraries/utils';

import FormLayout from '../../../components/forms/FormLayout';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import SelectInput from '../../../components/forms/SelectInput';
import CompanyHistory from "../../../components/customs/CompanyHistory"
import UsersHistory from "../../../components/customs/UsersHistory"

import companyActions from '../../../context/companies/actions';
import userActions from '../../../context/users/actions';
import settingsActions from '../../../context/settings/actions';

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      company: {},
      companyOwners: [],
      creatingNew: false,
      submitting: false,
      loading: true,
      view: false,
      editing: false,
      titles: '',
      users: [],
      remove: false
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Companies'), this.t('New')];
    this.titles = this.t('User details');
  }

  // ** Life cycle
  async componentDidMount() {
    const params = this.props.match.params;
    await this.getUsers();
    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        companyID: params.id,
        titles: this.t('Edit Company')
      });
      this.breadcrumbs = [this.t('Companies'), this.t('Edit')];
      await this.getCompany(params.id);
    } else {
      this.setState({ titles: this.t('New Company'), creatingNew: true, loading: false });
    }
  }

  getUsers = async () => {
    await this.props.onGetUsers({ user_type: config.ROLES.COMPANIES });
    const { users } = this.props;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      const userIds = users.items?.map(user => user.id);
      await this.props.onGetCompanies({ owner: [...userIds] });
      const { companies } = this.props;
      if (companies.error) {
        notify(this.t(companies.error.message));
      } else {
        const companyOwners = companies.items?.map(company => company.owner);
        this.setState({ users: users.items, companyOwners: companyOwners });
      }
    }
  };

  getCompany = async id => {
    await this.props.onGet(id);
    const company = this.props.company;
    if (company.error) {
      notify(this.t(company.error.message));
    } else {
      if (!company.item) {
        history.push(config.ROUTES.COMPANIES);
      }
      this.setState({ company: company.item, loading: company.loading });
    }
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  goBack = () => {
    history.push(config.ROUTES.COMPANIES);
  };

  getUser = async (id) => {
    await this.props.onGetUser({ id });
    let { user } = this.props;
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      user = user.item
      //let account = user.accounts.filter(acc => acc.ownership === config.USER_ACCOUNTS.TYPES.OWNER);
      //let owner = account.length ? account[0].id : user.id;
      //console.log(user, account, owner)
      let owner = getOwner(user)
      this.setState({userOwnerAccountId: owner}) 
    }
  };

  onSubmit = async values => {
    let data = {...values, json_data: {}, type: 'company'}
    await this.getUser(values.owner)
    let owner = this.state.userOwnerAccountId
    data.owner = owner
    await this.props.onSaveOrUpdate(data);
    const company = this.props.company;
    if (company.error) {
      notify(this.t(company.error.message));
    } else {
      if (this.state.creatingNew) {
        let promises = [];
        for (let i = 1; i < config.COMPANY_SETTINGS.length; i++) {
          let data = {
            ...config.COMPANY_SETTINGS[i],
            type: config.TYPES.SETTINGS.SHARYCO_SETTINGS,
            owner: company?.item.id,
            data_type: null,
          }
          promises.push(this.props.onSaveSetting(data))
        }
        await Promise.all(promises)
      }
      this.goBack();
    }
  };

  disableEnableCompany = async () => {
    this.setState({loading: true})
    const { id, enabled } = this.state.company;
    await this.props.onSaveOrUpdate({id, enabled });
    const error = this.props.company.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    }
    this.setState({loading: false})
  };

  render() {
    // **Destructuring objects for direct use of values
    const { view, editing, submitting, company, users, creatingNew, companyOwners, loading, companyID } = this.state;

    let userOptions = [];
    if (creatingNew) {
      userOptions = selectGeneratorWObjChild(
        users?.filter(user => !companyOwners.includes(user.id)),
        'id',
        o => capitalizePhrase(o.name)
      );
    } else {
      userOptions = selectGeneratorWObjChild(users, 'id', o => capitalizePhrase(o.name));
    }
      const countries = selectGeneratorWObjChild(config.OPTIONS.COUNTRIES, '', o => capitalizePhrase(o));
    const argentinaStates = selectGeneratorWObjChild(config.OPTIONS.STATES.ARGENTINA, '', o => capitalizePhrase(o));
    // Layout actions
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert',
        visible: creatingNew
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.COMPANIES),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing
        },
        {
          onClick: e => {
            if (company.enabled === 1)
              this.setState({ company: { ...company, enabled: 0 } }, () => this.disableEnableCompany());
            if (company.enabled === 0)
              this.setState({ company: { ...company, enabled: 1 } }, () => this.disableEnableCompany());
          },
          title: this.t(company.enabled ? 'Disable' : 'Enable'),
          icon: !company.enabled ? 'view_show' : 'view_hide',
          disabled: submitting || this.props.company.loading,
          checkPermissions: 'insert',
          visible: !creatingNew
        }
      ]
    };

    // ** Form validation functions
    const required = value => (validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required'));
    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      errors.country = required(values.country);
      errors.state = required(values.state);
      return errors;
    };
    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outterTitle={this.titles} loading={loading}>
          <Form initialValues={company || {}} onSubmit={this.onSubmit} validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-full px-2">
                      <Field
                        name="name"
                        component={TextInput}
                        placeholder={capitalize(this.t('name of company'))}
                        label={capitalize(this.t('name of company'))}
                        readOnly={view && !editing}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-full sm:w-2/4 px-2">
                      <Field
                        name="owner"
                        component={SelectInput}
                        placeholder={capitalize(this.t('company owner'))}
                        options={userOptions}
                        empty={capitalize(this.t('select owner'))}
                        label={capitalize(this.t('company owner'))}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="w-full sm:w-2/4 px-2">
                      <Field
                        name="email"
                        component={TextInput}
                        placeholder={capitalize(this.t('Email de la empresa'))}
                        // empty={capitalize(this.t('select owner'))}
                        label={capitalize(this.t('Email'))}
                        readOnly={view && !editing}
                        parse={ v => v.trim() }
                      />
                    </div>
                    <div className="w-full sm:w-2/4 px-2">
                      <Field
                        name="country"
                        component={SelectInput}
                        placeholder={this.t('Country')}
                        options={countries}
                        empty={this.t('Select a country')}
                        label={this.t('Country')}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="w-full sm:w-2/4 px-2">
                      <Field
                        name="state"
                        component={SelectInput}
                        placeholder={this.t('State')}
                        options={argentinaStates}
                        empty={this.t('Seleccione una provincia')}
                        label={this.t('State')}
                        readOnly={view && !editing}
                      />
                    </div>
                  </div>
                </FormLayout>
              );
            }}
          </Form>
          {companyID && 
            <>
              <div className='mt-10 mb-3'>
                <CompanyHistory companyID={companyID} />
              </div>
              <div className='mt-10 mb-3'>
                <UsersHistory companyID={companyID} />
              </div>
            </>
          }
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    company: state.companies.current,
    companies: state.companies.list,
    users: state.users.list,
    user: state.users.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(companyActions.saveOrUpdate(params)),
    onGet: id => dispatch(companyActions.get(id)),
    onGetCompanies: id => dispatch(companyActions.getAll(id)),
    onGetUsers: params => dispatch(userActions.getAll(params)),
    onGetUser: id => dispatch(userActions.get(id)),
    onRemove: id => dispatch(companyActions.del(id)),
    onClearCurrent: () => dispatch(companyActions.clearCurrent()),
    onSaveSetting: params => dispatch(settingsActions.saveOrUpdate(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Company));
