import types from './types';
import companies from './services';

const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_CURRENT });
  }
}

const getAll = (params) => {

  function request() { return { type: types.GETALL_REQUEST } }
  function success(payload) { return { type: types.GETALL_SUCCESS, payload } }
  function failure(error) { return { type: types.GETALL_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::COMPANIES::getAll', params);
    dispatch(request());
    const response = await companies.getAll(params, getState().users.auth.token)
    console.log('ACTIONS::COMPANIES::getAll::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };  

}

const get = (id) => {
  console.log('ACTIONS::COMPANIES::get', id);

  const request = (id) => { return { type: types.GET_REQUEST, id } };
  const success = (payload) => { return { type: types.GET_SUCCESS, payload } }
  const failure = (error) => { return { type: types.GET_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await companies.get(id, getState().users.auth.token)
    console.log('ACTIONS::COMPANIES::get::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };

}

const getByOwner = (owner) => {
  console.log('ACTIONS::COMPANIES::getByOwner', owner);

  const request = (owner) => { return { type: types.GET_REQUEST, owner } };
  const success = (payload) => { return { type: types.GET_SUCCESS, payload } }
  const failure = (error) => { return { type: types.GET_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(owner));
    let response = await companies.getByOwner(owner, getState().users.auth.token)
    console.log('ACTIONS::COMPANIES::get::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };
}

const saveOrUpdate = (company) => {
  function request(payload) { return { type: types.SAVE_REQUEST, payload } }
  function success(payload) { return { type: types.SAVE_SUCCESS, payload } }
  function failure(error) { return { type: types.SAVE_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::COMPANIES::saveOrUpdate', company);
    dispatch(request(company));

    let response;
    if (company.id) {  // editing a existing record
      response = await companies.update(company, getState().users.auth.token)
    } else {
      response = await companies.save(company, getState().users.auth.token)
    }
    console.log('ACTIONS::COMPANIES::saveOrUpdate::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}

const del = (id) => {
  console.log('ACTIONS::COMPANIES::del', id);
 
  function request(id) { return { type: types.DELETE_REQUEST, id } }
  function success(id) { return { type: types.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: types.DELETE_FAILURE, id, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await companies.del(id, getState().users.auth.token)
    console.log('ACTIONS::COMPANIES::del::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(id));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}

const getBalance = (params) => {

  function request() { return { type: types.GETBALANCE_REQUEST } }
  function success(payload) { return { type: types.GETBALANCE_SUCCESS, payload } }
  function failure(error) { return { type: types.GETBALANCE_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::COMPANIES::getCompaniesBallance', params);
    dispatch(request());
    const response = await companies.getBalance(params, getState().users.auth.token)
    console.log('ACTIONS::COMPANIES::getCompaniesBallance::RESPONSE FROM SERVIVE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
        dispatch(failure(response.error));
    }

  };  

}

const actions = {
  clearCurrent,
  get,
  getAll,
  saveOrUpdate,
  del,
  getByOwner,
  getBalance,
};

export default actions;