import config from '../../config';
import { request } from '../../libraries/request'
import { isJSON } from '../../libraries/utils';

const getAll = (params = {}, token) => {
  console.log('SERVICES::PRODUCTS::get', params, token);
  if (params?.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }
  if (!params.order_by) { 
    params.order_by = 'products__name';
    params.order_direction = 'ASC';
  }

  return request({
    url: config.BASE_API_URL + config.API.PRODUCTS,
    accessToken: token,
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
    }
    return response;
  })
}

const save = (data, token) => {
  console.log('SERVICES::PRODUCTS::save', data, token);
  return request({
    url: config.BASE_API_URL + config.API.PRODUCTS,
    accessToken: token,
    method: 'POST',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const update = (data, token) => {
  console.log('SERVICES::PRODUCTS::update', data, token);
  return request({
    url: config.BASE_API_URL + config.API.PRODUCT.replace('{id}', data.id),
    accessToken: token,
    method: 'PATCH',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const get = (id, token) => {
  console.log('SERVICES::PRODUCTS::get', id, token);
  return request({
    url: config.BASE_API_URL + config.API.PRODUCT.replace('{id}', id),
    accessToken: token,
    // data: {},
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      const {data} = response.data
      if (isJSON(data.json_data.category)){
        console.log('CATEGORY IS JSON')
        data.json_data.category = JSON.parse(data.json_data.category)
      }
    }
    return response;
  })

}

const del = (id, token) => {
  console.log('SERVICES::PRODUCTS::remove', id, token);
  return request({
    url: config.BASE_API_URL + config.API.PRODUCT.replace('{id}', id),
    accessToken: token,
    method: 'DELETE',
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const services = {
  getAll,
  save,
  get,
  del,
  update,
};

export default services;