import React from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'react-final-form'
import { WhenFieldChangesDo } from '../../libraries//forms';
import Icon from '../../libraries//icons';

export default function MenuItemAdvance({ item, onSubmit, ...rest }) {
  if (!item) return null;
  const { icon, label, action } = item;
  const children = (
    <>
      <div className="text-lg p-0 text-gray-400 mr-2">
        {icon && icon.left && <Icon className="h-6 w-6" name={icon.left} />}
      </div>
      {label && (<div className={"text-gray-500 " + (label.className ? label.className : "")}>{label.text}</div>)}
      <div className="ml-auto text-sm text-gray-400">
        {icon && icon.right && <Icon className="h-5 w-5" name={icon.right} />}
        {/* {(action.field && action.field.component) && action.field.component} */}
        {(action.field && action.field.component) &&
          <Field name={action.field.name} component={action.field.component} placeholder={action.field.placeholder} className={action.field.className} />
        }
        {(action.field && action.field.html) && action.field.html}
      </div>
    </>
  );
  if (action.route) { 
    return (
      <Link
        className="flex items-center cursor-pointer border-b border-gray-100 h-12 px-3"
        to={action.route}
      >
        { children }
      </Link>
    )
  } else {
    return (
      <div
        className="flex items-center cursor-pointer border-b border-gray-100 h-12 px-3"
        onClick={action.fn ? action.fn : ()=>{}}
      >
        {children}
        {(action.field && action.field.name) && (
          <WhenFieldChangesDo
            field={action.field.name}
            action={({ field, value, values }) =>  {
              onSubmit(field, value);
            }}
          />            
        )}
      </div>
    );
  }
}