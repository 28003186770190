import { combineReducers } from 'redux';
import types from './types';


export const payments = (state = {
  loading: false,
  data: {},
  error: null,
}, action) => {
  // console.log('::::::REDUCER::VEHICLES::vehicles::', action);
  switch (action.type) {
    case types.PAYMENTS_USD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.PAYMENTS_USD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.PAYMENTS_USD_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state
  }
}


export default combineReducers({
  payments
});

