import { Component } from 'react'
import {  withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Loader from '../../../components/commons/Loader';


import { notify } from '../../../libraries/notifications';


import challengesActions from "../../../context/challenges/actions";
import {  withRouter } from 'react-router-dom';


import clsx from 'clsx';

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activity: [],
      loading: true,
      participants: 0,
      numberParticipants:0
    }
  }

  getActivities = async () => {
    const { id } = this.props.match.params
    await this.props.getActivity(id);
     
    if (!this.props.activity || this.props.activity.error) {
      if(this.props.activity?.error?.message) notify(this.props.activity.error.message)
      return;
    }
    const cantidadParticipntes= new Set(this.props.activity.map((trx) => trx.created_by)).size
    this.setState({ activity: this.props.activity, numberParticipants:cantidadParticipntes })
    this.setState({ loading: false })
  }
  // onGetParticipants = async () => {
  //   const { id } = this.props.match.params
  //   await this.props.getParticipants(id)
  //   const {participants} = this.props

  //   this.setState({participants: participants.participants.length || 0})
  // }

  componentDidMount() {
    this.getActivities();
    // this.onGetParticipants()
  }

  render() {
    const { activity, loading, } = this.state
    return (
      <>
        <h4 className="mb-1 text-center text-lg">Actividad del Desafío</h4>
        {
          loading && <Loader/>
        }
        {
          !loading &&
          <>
            <p className="text-xs opacity-70 text-center mb-4">En este desafio hubo {this.state.numberParticipants} participantes</p>
            <div className="w-full h-[600px] overflow-y-scroll px-8">
              <div className='flex flex-col gap-2'>
                {activity && !activity.length && <p className="text-center">Este desafío no tuvo ninguna actividad.</p>}
                {
                  activity && activity.map(trx => {
                    if (trx.type === "action") return <ActionActivityCard key={trx.id} trx={trx} />
                    if (trx.type === "borrow") return <BorrowActivityCard key={trx.id} trx={trx} />
                    return null
                  }
                  )
                }
              </div>
            </div>
          </>
        }
      </>
    )
  }
}


const ActionActivityCard = ({trx}) => {
  return (
    <div
      className={clsx('w-full p-3 flex gap-2 items-center border rounded-lg bg-white',
        trx.archived === 1 && "grayscale"
      )}
    >
      <div className='w-14 h-12 self-start rounded-lg'> 
        <img src={trx.json_data.action.img} alt="" className="w-full h-full object-cover" />
      </div>
      <div className='flex-1'>
        <h6 className='capitalize'>{`${trx.json_data.user.first_name} ${trx.json_data.user.last_name}`}</h6>
        <div className='flex w-full justify-between'>
          <p className='max-w-[60ch] flex-shrink'>realizó la acción: {trx.json_data.action.name}</p>
        </div>
        <span className='text-sm font-bold text-yellow-400'>+{trx.json_data.action?.points}pts</span>
      </div>
    </div>
  )
}

const BorrowActivityCard = ({trx}) => {
  let type = (trx.sub_type === "product") ? "product" : "offer"

  return (
    <div
      className={clsx('w-full p-3 flex gap-2 items-center border rounded-lg bg-white',
        trx.archived === 1 && "grayscale"
      )}
    >
      <div className='w-14 h-12 self-start rounded-lg'>
        <img src={trx.json_data[type].img} alt="" className='w-full h-full object-cover' />
      </div>
      <div className='flex-1'>
        <h6 className='capitalize'>{`${trx.json_data.borrower.first_name} ${trx.json_data.borrower.last_name}`}</h6>
        <div className='flex w-full justify-between'>
          <p className='max-w-[60ch] flex-shrink'>Prestó: {trx.json_data[type].name}</p>
        </div>
        <span className='text-sm font-bold text-yellow-400'>+{1}pts</span>
      </div>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    user: state.users.auth.user,
    // activities: state.activities.list,
    // activities:state.challenges.activities.list,
    activity: state.challenges.current.activity,
    challenge: state.challenges.current,
    participants: state.challenges.current.participants,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    getActivity: (id) => dispatch(challengesActions.getActivity(id)),
    getParticipants: (id) => dispatch(challengesActions.getParticipants(id))
  };
};

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Activity)))