import React from 'react';

export default function PanelLayout({ title, children }) {

  return (
    <div className="panel">
      <div className="panel-header">
        {/* {title && (
          <h6 className="panel-title">
            {title}
          </h6>
        )} */}
        <div className="card white shadow-md w-full"> 
          {children}
        </div>
      </div>
    </div>
  )
}