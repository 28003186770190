import React from 'react';
import Icon from '../../libraries/icons';
import { selectIconSize } from '../../libraries//utils';
const TextWithIconButtonInput = ({ input, meta, label, ...rest }) => {
  return (
    <div className={'form-control w-full ' + (rest.className ? rest.className : '')}>
      {label && (
        <label
          htmlFor={input.name}
          className={
            'label ' + (rest.labelClassName ? rest.labelClassName : '')
          }
        >
          <span className="label-text">{label}</span>
        </label>
      )}
      <div className={
         "relative flex items-center "
         + (meta?.error && meta?.touched ? 'input-error invalid-shadow ' : '')
         + (rest.inputClassName ? rest.inputClassName : ' input')}>
        <input
          {...input}
          type="text "
          className={
            'w-full border-none outline-none bg-white'
          }
          placeholder={rest.placeholder}
          disabled={rest.disabled}
          readOnly={rest.readOnly}
        />
        <button
          type="submit"
          className={'relative right-0  ' + (rest.className ? rest.className : 'px-2  border-l')}
          onClick={() => rest.onClick ? rest.onClick(input) : null}
          disabled={rest.disabled}
        >
          {rest.invert ? rest.title : ''}
          {rest.icon && (
            <Icon
              className={
                selectIconSize(rest.className) + (rest.title ? (rest.invert ? ' ml-2' : ' mr-2') : '')
              }
              name={rest.icon}
            />
          )}
          {rest.invert ? '' : rest.title}
        </button>
      </div>
    </div>
  );
};

export default TextWithIconButtonInput;
