import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { validateIsfilled, selectGeneratorWObjChild, capitalizePhrase, isEmptyObject, capitalize, getObjectWithJsonDataToFormValues, getOwner, validateURL, parseUrl,} from '../../../libraries/utils';
import FormLayout from '../../../components/forms/FormLayout';
import filesActions from '../../../context/files/actions';
import businessActions from '../../../context/businesses/actions';
import usersActions from '../../../context/users/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import SelectInput from '../../../components/forms/SelectInput';
import ImageUploadFileInput from '../../../components/forms/ImageUploadFileInput';

class MyBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      disableEverything: false,
      business: {},
      loading: false,
      files: {},
      submitting: false,
      view: false,
      editing: false,
      titles: '',
      remove: false
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [capitalize(this.t('Mi negocio'))];
    this.titles = this.t('User details');
  }
  // ** Life cycle
  componentDidMount() {
    const { user } = this.props.auth;
    const params = this.props.match.params;
    this.setState({
      users: [],
      remove: true,
      view: true,
      titles: this.t('Edit company')
    });
    this.breadcrumbs[0] = user.roles.includes(config.ROLES.BENEFITS)
      ? capitalize(this.t('Mi negocio'))
      : capitalize(this.t('entities'));
    //params.owner = this.props.auth.user.id;
    params.owner = getOwner(this.props.auth.user)
    this.getBusinesses(params);
    this.getUsers();
  }
  getBusinesses = async params => {
    await this.props.onGetAll(params);
    const businesses = this.props.businesses;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      if (isEmptyObject(businesses.items[0])) {
        notify(capitalize(this.t("user doesn't own any business")));
        this.setState({ disableEverything: true });
        return;
      }
      this.setState({
        business: businesses.items[0],
        files: { image: businesses.items[0].json_data?.image },
        loading: businesses.loading
      });
    }
  };

  getUsers = async () => {
    await this.props.onGetUsers();
    const users = this.props.users;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      this.setState({ users: users.items });
    }
  };

  onClearImageField = ({ name }) => {
    if (name) this.setState({ files: { ...this.state.files, [name]: null } });
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  gotoBack = business => {
    if (!this.props.location.state) {
      history.push(config.ROUTES.MY_COMPANY);
    } else {
      const { location, from } = this.props.location.state;
      if (from === config.ROUTES.BOOK.COMPANY) {
        history.push({
          pathname: business ? config.ROUTES.BOOK.SERVICE : config.ROUTES.MY_COMPANY,
          state: {
            location,
            business: business || undefined
          }
        });
      } else {
        history.push(config.ROUTES.MY_COMPANY);
      }
    }
  };

  onFileChangeImageInput = async ({ file, data, name }) => {
    const { business } = this.state;
    this.setState({ submitting: true });
    data.append('identifier', `${business.id}_${name}`);
    await this.props.onPictureUpload(data);
    const { files } = this.props;
    if (files.error) {
      notify(this.t(files.error.message));
    } else {
      this.setState({ imageUpdated: true });
      this.setState({
        files: {
          ...this.state.files,
          [name]: files.files.fileInfo.location
        }
      });
    }
    this.setState({ submitting: false });
    return files.files.fileInfo.location;
  };

  onSubmit = async values => {
    this.setState({ submitting: true, loading: true });
    const { files } = this.state;

    const data = {
      name: values.name,
      country: values.country,
      state: values.state,
      type: this.state.business.type,
      owner: this.state.business.owner,
      json_data: {
        url: values.url,
        image: files ? files.image : '',
        address: values.address
      }
    };

    if (this.state.business.id) data.id = this.state.business.id

    await this.props.onSaveOrUpdate({ ...data });
    const businesses = this.props.businesses;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      this.setState({ editing: false, submitting: false });
      this.getBusinesses({ owner: getOwner(this.props.auth.user) });
    }
  };


  render() {
    const { business, view, editing, files, submitting, users, loading, disableEverything } = this.state;
    const countries = selectGeneratorWObjChild(config.OPTIONS.COUNTRIES, '', o => capitalizePhrase(o));
    const argentinaStates = selectGeneratorWObjChild(config.OPTIONS.STATES.ARGENTINA, '', o => capitalizePhrase(o));
    const userOptions = selectGeneratorWObjChild(users, 'id', o => capitalizePhrase(o.name));

    let _business;
    if (!isEmptyObject(business) && business !== undefined && business !== null) {
      _business = getObjectWithJsonDataToFormValues(business, ['image', 'name', 'url', 'country', 'state', 'address', 'owner'])
    }

    // Layout actions
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing) || disableEverything,
        checkPermissions: 'update'
      },
      secondaries: [
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting || disableEverything,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing || disableEverything,
          visible: editing
        }
      ]
    };

    if (this.props.auth.user.roles.includes(config.ROLES.ADMIN))
      actions.secondaries.push({
        onClick: e => {
          if (business.enabled === 1) this.setState({ business: { ...business, enabled: 0 } });
          if (business.enabled === 0) this.setState({ business: { ...business, enabled: 1 } });
        },
        title: this.t(business?.enabled ? 'Disable' : 'Enable'),
        icon: !business?.enabled ? 'view_show' : 'view_hide',
        disabled: submitting || (view && !editing) || loading,
        checkPermissions: 'insert'
      });

    // ** Form validation functions
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');
    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      errors.country = required(values.country);
      return errors;
    };
    
    const isUrl = value => validateURL(value) || (view && !editing) || !value ? undefined : this.t('Ingresar URL válido');
    
    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outterTitle={this.titles} loading={loading}>
          <Form initialValues={_business || {}} onSubmit={this.onSubmit} validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="flex justify-center mb-3 md:mb-6">
                    {!editing && values.image ? (
                      <img src={files.image} alt="" className="w-64 h-64" />
                    ) : (
                      <Field
                        name="image"
                        img={files.image}
                        component={ImageUploadFileInput}
                        label={capitalize(this.t('image'))}
                        placeholder={this.t('URL de imagen')}
                        inputOnChange={this.onFileChangeImageInput}
                        disabled={view && !editing}
                        clickButtonClear={this.onClearImageField}
                        inputClassName="input-bordered shadow-none"
                        labelClassName="justify-center"
                      />
                    )}
                  </div>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-full md:w-2/4 px-2">
                      <Field
                        name="name"
                        component={TextInput}
                        placeholder={capitalize(this.t('name of brand'))}
                        label={capitalize(this.t('name of brand'))}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                      <Field
                        name="url"
                        component={TextInput}
                        placeholder={this.t('Página web')}
                        label={this.t('Página web')}
                        readOnly={view && !editing}
                        parse={parseUrl} validate={isUrl}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                      <Field
                        name="country"
                        component={SelectInput}
                        placeholder={this.t('Country')}
                        options={countries}
                        empty={this.t('Select a country')}
                        label={this.t('Country')}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                      <Field
                        name="state"
                        component={SelectInput}
                        placeholder={this.t('State')}
                        options={argentinaStates}
                        empty={this.t('Select a state')}
                        label={this.t('State')}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                      <Field
                        name="address"
                        component={TextInput}
                        placeholder={this.t('Address')}
                        label={this.t('Address')}
                        readOnly={view && !editing}
                      />
                    </div>
                  </div>
                  <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                    {this.props.auth.user.roles.includes(config.ROLES.ADMIN) ? (
                      <Field
                        name="owner"
                        component={SelectInput}
                        placeholder={this.t('Propietario')}
                        options={userOptions}
                        empty={this.t('Seleccionar el propietario')}
                        label={this.t('Propietario')}
                        readOnly={view && !editing}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    files: state.files,
    businesses: state.businesses.list,
    users: state.users.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(businessActions.saveOrUpdate(params)),
    onGetAll: params => dispatch(businessActions.getAll(params)),
    onGetUsers: params => dispatch(usersActions.getAll(params)),
    onRemove: id => dispatch(businessActions.del(id)),
    onClearCurrent: () => dispatch(businessActions.clearCurrent()),
    onPictureUpload: params => dispatch(filesActions.upload(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyBusiness));
