import React from "react"
import { Field, Form } from "react-final-form"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import PanelWindow from "../../../components/PanelWindow"
import FormLayout from "../../../components/forms/FormLayout"
import TextInput from "../../../components/forms/TextInput"
import LayoutWithSidebar from "../../../components/layout/LayoutWithSidebar"
import config from "../../../config"
import businessesActions from "../../../context/businesses/actions"
import companiesActions from "../../../context/companies/actions"
import filesActions from "../../../context/files/actions"
import rolesActions from "../../../context/roles/actions"
import usersActions from "../../../context/users/actions"
import { ToastContainer, notify } from "../../../libraries/notifications"
import {
  composeValidators,
  getOwner,
  isObject,
  validateEmail,
  validateIsfilled,
} from "../../../libraries/utils"
import { history } from "../../../routes"

import ReferentUsers from "./ReferentUsers"

class Referent extends React.Component {
  constructor(props) {
    super(props)
    this.t = this.props.t
    this.state = {
      creatingNew: false,
      submitting: false,
      view: false,
      editing: true,
      user: {},
      roles: [],
      company_id: "",
      showPassword: false,
      showPassword2: false,
      companyOptionsInState: [],
      loading: true,
    }
    this.submit = null
    this.reset = null
    this.breadcrumbs = [this.t("Referentes"), this.t("Agregar/Editar")]
    this.titles = this.t("Referentes")
    this.id=this.props.match.params.id
  }

  componentDidMount() {
    const params = this.props.match.params
    

    this.getDataUser(params)
  }

  getDataUser = async params => {
    await this.getData()
    await this.getCompaniesAreasSucursales()

    if (!params.id) {
      this.setState({ creatingNew: true })
    }

    if (params.id) {
      this.setState({ view: true })
      await this.getUser(params.id)
    }    
    this.setState({ loading: false })
  }

  getCompaniesAreasSucursales = async () => {
    await this.getCompany()
    await this.getAreas()
    await this.getSucursales()
  }

  getCompany = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) })
    const { companies } = this.props
    if (companies.error) {
      notify(this.t(companies.error.message))
    } else {
      if (this.props.companies.items.length) {
        this.setState({ company_id: this.props.companies.items[0].id })
      }
    }
  }

  getAreas = async () => {
    await this.props.onGetRoles({ type: config.TYPES.GROUP, owner: this.state.company_id })
    const { roles } = this.props
    if (roles.error) {
      notify(this.t(roles.error.message))
    } else {
      this.setState({ areas: roles.items })
    }
  }

  getSucursales = async () => {
    await this.props.onGetBusinesses({
      type:config.TYPES.SUBSIDIARIES,
      owner: getOwner(this.props.auth.user),
    })
    const { businesses } = this.props
    if (businesses.error) {
      notify(this.t(businesses.error.message))
    } else {
      this.setState({ sucursales: businesses.items })
    }
  }

  getData = async () => {
    const { user } = this.props.auth
    await this.props.onGetRoles()
    const { roles } = this.props
    if (roles.error) {
      notify(this.t(roles.error.message))

    } else {
      this.setState({ roles: [...roles.items] })
      if (user.type.includes(config.ROLES.COMPANIES)) {
        //await this.props.onGetCompanies({ owner: user.id });
        await this.props.onGetCompanies({ owner: getOwner(user) })
        const { companies } = this.props
        if (companies.error) {
          notify(this.t(companies.error.message))
        } else {
          this.setState({
            company_id: this.props.companies.items[0].id,
          })
        }
      }
    }
  }

  getUser = async id => {
    await this.props.onGetUser({ id })
    const error = this.props.user.error
    if (error) {
      notify(this.t(error.message))
    } else {
      const roles = this.props.user.item?.role
      let role
      if (roles && roles.id && roles.name) {
        role = roles.id.split("|")[0]
      }

      this.setState({
        user: {
          ...this.props.user.item,
          role,
          area: this.props.user.item.meta_description,
          sucursal: this.props.user.item.meta_title,
        },
      })
    }
  }

  componentWillUnmount() {
    this.props.onClearCurrentUser()
  }

  onDelete = id => {
    this.props.onDelUser(id)
  }

  gotoBack = user => {
    if (!this.props.location.state) {
      history.push(config.ROUTES.COLLABORATORS)
    } else {
      const { location, from } = this.props.location.state
      if (from === config.ROUTES.BOOK.USER) {
        history.push({
          pathname: user ? config.ROUTES.BOOK.SERVICE : config.ROUTES.BOOK.USER,
          state: {
            location,
            user: user || undefined,
          },
        })
      } else {
        history.push(config.ROUTES.COLLABORATORS)
      }
    }
  }

  onSubmit = async values => {
    // const { user } = this.props.auth
    // const { creatingNew } = this.state
    // this.setState({ submitting: true })

    // let data = {
    //   phone: values.phone,
    //   email: values.email,
    //   user_type: values.user_type,
    //   password: values.password,
    //   profile_image: values.profile_image,
    //   cover_image: values.cover_image,
    //   meta_title: values.sucursal,
    //   meta_description: values.area,
    // }
    // if (this.state.user?.id) {
    //   data.id = this.state.user.id
    // } else {
    //   data.role = ""
    // }
    // if (creatingNew) {
    //   data.first_name = values.first_name
    //   data.last_name = values.last_name
    // } else {
    //   data.json_data = {
    //     first_name: values.first_name,
    //     last_name: values.last_name,
    //   }
    // }
    // if (!data.password) data.password = "12345"
    // if (values.profile_image !== "") {
    //   //fixme: que es esto???
    //   let profile_image =
    //     this.state.files &&
    //     this.state.files[
    //       values.profile_image.split("\\")[
    //         values.profile_image.split("\\").length - 1
    //       ]
    //     ]
    //   if (profile_image) {
    //     profile_image.append("identifier", data.id)
    //     profile_image.append("prefix", "profile")
    //     await this.props.onPictureUpload(profile_image)
    //     const error = this.props.files.error
    //     data.profile_image = this.props.files?.files?.fileInfo?.location
    //     if (error) {
    //       notify(this.t(error?.message))
    //     }
    //   }
    // }
    // if (values.cover_image !== "") {
    //   let cover_image =
    //     this.state.files &&
    //     this.state.files[
    //       values.cover_image.split("\\")[
    //         values.cover_image.split("\\").length - 1
    //       ]
    //     ]
    //   if (cover_image) {
    //     cover_image.append("identifier", data.id)
    //     cover_image.append("prefix", "cover")
    //     await this.props.onPictureUpload(cover_image)
    //     const error = this.props.files.error
    //     data.cover_image = this.props.files?.files?.fileInfo?.location
    //     if (error) {
    //       notify(this.t(error?.message))
    //     }
    //   }
    // }

    // let tempRole
    // if (!user.roles.includes(config.ROLES.ADMIN)) {
    //   data.unique_id = this.state.company_id
    //   data.user_type = config.USER_TYPE.REGULAR
    // } else {
    //   tempRole = this.state.roles.find(role => role.code === values.user_type)
    //   data.role = tempRole.id
    // }

    // await this.props.onSaveUser(data)
    // const error = this.props.user.error
    // if (error) {
    //   notify(this.t(error.message))
    //   this.setState({ submitting: false })
    // } else {
    //   this.gotoBack(this.props.user.item)
    // }
  }

  onFileChange = async ({ file, data }) => {
    this.setState({ files: { ...this.state.files, [file]: data } })
  }

  disableEnableUser = async () => {
    const { user } = this.state
    const data = {
      id: user.id,
      enabled: user.enabled,
      role: isObject(user.role) ? user.role.id : user.role,
      user_type: config.USER_TYPE.REGULAR,
    }
    await this.props.onSaveUser(data)
    const error = this.props.user.error
    if (error) {
      notify(this.t(error.message))
      this.setState({ submitting: false })
    }
  }

  onPasswordClick = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  onPasswordClick2 = () => {
    this.setState({ showPassword2: !this.state.showPassword2 })
  }

  render() {
    const { view, creatingNew, editing, submitting, user, loading } = this.state

    const actions = {
      //main: {
      //  onClick: e => this.submit(e),
      //  title: this.t('Save'),
      //  icon: 'checkmark',
      //  disabled: submitting || (view && !editing),
      //  checkPermissions: 'insert',
      //},
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.REFERENTS),
          title: this.t("Go Back"),
          icon: "cheveron_left",
          disabled: submitting,
          visible: true,
          className: "btn-accent",
        },
        {
          onClick: e => history.push(config.ROUTES.REFERENTS_EXCEL.replace(":id", this.id)),
          title: this.t("Importar Excel"),
          // icon: "cheveron_left",
          disabled: submitting,
          visible: true,
          className: "btn-accent",
        },
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t("Edit"),
          icon: "edit_pencil",
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: "update",
        },
        {
          onClick: e => {
            if (user.enabled === 1)
              this.setState({ user: { ...user, enabled: 0 } }, () =>
                this.disableEnableUser()
              )
            if (user.enabled === 0)
              this.setState({ user: { ...user, enabled: 1 } }, () =>
                this.disableEnableUser()
              )
          },
          title: this.t(user.enabled ? "Disable" : "Enable"),
          icon: !user.enabled ? "view_show" : "view_hide",
          disabled: submitting || this.props.user.loading,
          checkPermissions: "insert",
          visible: !creatingNew,
        },
      ],
    }

    // const userTypeOptions = optionSelectGenerator(config.OPTIONS.SHARYCO_ROLES)
    // const sucursalesOptions = this.state.sucursales
    //   ? this.state.sucursales.map(s => {
    //       return { label: s.name, value: s.id }
    //     })
    //   : []
    // const areasOptions = this.state.areas
    //   ? this.state.areas.map(s => {
    //       return { label: s.name, value: s.id }
    //     })
    //   : []

    const required = value =>
      validateIsfilled(value) || (view && !editing)
        ? undefined
        : this.t("This field is required")
    // CHECK EMAIL
    const checkEmail = value =>
      validateEmail(value) || (view && !editing)
        ? undefined
        : this.t("Not a valid email")

    const validateForm = values => {
      const errors = {}
      errors.first_name = required(values.first_name)
      errors.last_name = required(values.last_name)
      errors.email = checkEmail(values.email)
      //errors.phone = required(values.phone);
      if (values.password !== values.password2)
        errors.password2 = this.t("Passwords must match")
      // errors.user_type = required(values.user_type);
      return errors
    }

    if (user.json_data) {
      user.first_name = user.json_data.first_name
      user.last_name = user.json_data.last_name
    }

    return (
      <LayoutWithSidebar
        main={{ className: "text-content-400" }}
        header={{
          breadcrumbs: this.breadcrumbs,
        }}
        container={{ className: "px-8" }}
        actions={actions}
      >
        <ToastContainer />
        <PanelWindow
          outterTitle={this.titles}
          loading={loading}
        >
          <Form
            initialValues={Object.assign({ ...user } || {}, {
              profile_image: "",
              cover_image: "",
            })}
            onSubmit={this.onSubmit}
            validate={validateForm}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit
              this.reset = form.reset
              return (
                <FormLayout form={form} onSubmit={handleSubmit} values={values}>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-1/2 sm:w-3/3 px-2">
                      <Field
                        name="first_name"
                        component={TextInput}
                        label={this.t("First name")}
                        readOnly={view}
                      />
                    </div>
                    <div className="w-1/2 sm:w-3/3 px-2">
                      <Field
                        name="last_name"
                        component={TextInput}
                        label={this.t("Last name")}
                        readOnly={view}
                      />
                    </div>
                  </div>
                  <div className="flex -mx-2">
                    <div className="w-full sm:w-2/4 px-2">
                      <Field
                        name="email"
                        component={TextInput}
                        label={this.t("Email")}
                        validate={composeValidators(required, checkEmail)}
                        parse={ v => v.trim() }
                        readOnly={view}
                      />
                    </div>
                  </div>
                </FormLayout>
              )
            }}
          </Form>

          {user?.id && editing && (
            <>
              <div>
                <ReferentUsers referentID={user.id} editing={editing} />
              </div>
            </>
          )}
        </PanelWindow>
      </LayoutWithSidebar>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    user: state.users.current,
    roles: state.roles.list,
    files: state.files,
    businesses: state.businesses.list,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetUser: id => dispatch(usersActions.get(id)),
    onSaveUser: params => dispatch(usersActions.saveOrUpdate(params)),
    onDelUser: id => dispatch(usersActions.del(id)),
    onClearCurrentUser: () => dispatch(usersActions.clearCurrent()),
    onGetRoles: params => dispatch(rolesActions.getAll(params)),
    onGetCompanies: params => dispatch(companiesActions.getAll(params)),
    onGetBusinesses: params => dispatch(businessesActions.getAll(params)),
    onPictureUpload: params => dispatch(filesActions.upload(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Referent))
