import React from 'react';

import WinnersList from '../../../components/customs/WinnersList';

import WebappActivity from "./Activity";
import Impact from '../webapp/Impact';

function FinishedChallengeBottom(props) {
  return (
    <section className="flex flex-col xl:flex-row gap-3">
      <div className="card white flex flex-col xl:w-1/2 border p-3 ">
        <WebappActivity />
      </div>
      <div className="card white flex flex-col xl:w-1/2 border p-3 ">
        <Impact />
      </div>
      <div className="card white flex flex-col xl:w-1/2 border p-3 ">
        <WinnersList {...props} />
      </div>
    </section>
  );
}

export default FinishedChallengeBottom;