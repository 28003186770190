import React from 'react';
import Icon from '../../libraries/icons';

const TextInput = ({ input, meta, label, ...rest }) => {
  return (
    <div className={"form-control " + (rest.className ? rest.className : "")}>
      {label && (
        <label htmlFor={input.name} className={"relative label " + (rest.labelClassName ? rest.labelClassName : "")}>
          <span className="label-text flex gap-2">{label}
          {rest.info &&
            <span
              className="ml-1 font-normal pop-up  after:content-[attr(after)]"
              after={rest.info}
            >
              <Icon name="information_solid" className="h-4 w-4" />
            </span>
          }
          </span>


        </label>
      )}
      <input {...input}
        // type="text"
        className={"input w-full " 
          + (meta?.error && meta?.touched ? "input-error invalid-shadow " : "") 
          + (rest.inputClassName ? rest.inputClassName : "")
          // + (rest.inputClassName ? rest.inputClassName : "input-bordered")
        }
        placeholder={rest.placeholder}
        disabled={rest.disabled}
        readOnly={rest.readOnly}
      />
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">{meta?.error && meta?.touched && meta?.error}</span>
        </label>
      )}
    </div>
  )
}

export default TextInput;