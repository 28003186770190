import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { capitalize, validateIsfilled, getOwner } from '../../../libraries/utils';
import FormLayout from '../../../components/forms/FormLayout';
import roleActions from '../../../context/roles/actions';
import companiesActions from '../../../context/companies/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import TextareaInput from '../../../components/forms/TextareaInput';
import RoleUsers from './RoleUsers';

class Role extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      editing: false,
      edit_page: false,
      loading: true,
      remove: false,
      role: {},
      submitting: false,
      view: false,
      titles: '',
    };
    this.submit = null;
    this.reset = null;
    // this.breadcrumbs = [capitalize(this.t('departments')), this.t('New')];
    this.breadcrumbs = [capitalize(this.t('Áreas')), this.t('New')];
    this.titles = this.t('User details');
  }

  // ** Life cycle
  componentDidMount() {
    const params = this.props.match.params;
    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        edit_page: true,
        titles: this.t('Edit Role'),
      });
      this.breadcrumbs = [capitalize(this.t('departments')), this.t('Edit')];
      this.getRole(params.id);
    } else {
      this.setState({ titles: this.t('New Department'), loading: false });
    }
  }

  getRole = async (id) => {
    await this.props.onGetRole(id);
    const error = this.props.role.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({
        role: this.props.role.item,
        loading: this.props.role.loading,
      });
    }
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  setParentEditing = () => {
    const { editing } = this.state;
    editing ? this.setState({ editing: false }) : this.setState({ editing: true })
  }

  gotoBack = () => {
        history.push(config.ROUTES.DEPARTMENTS);
  };

  getCompanies = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });
    if (this.props.companies.error) {
      notify(this.t(this.props.companies.error.message))
    } else {
      return this.props.companies.items
    }
  }

  onSubmit = async (values) => {
    const owner = await this.getCompanies();
    const data = {
      name: values.name,
      code: values.name.replaceAll(" ", "_").toLowerCase(),
      description: values.description,
      owner: owner[0].id,
      type: 'group'
    }

    console.log("_______________data", data)
    if (this.state.role.id) data.id = this.state.role.id
    await this.props.onSaveOrUpdate({
      ...data,
    });
    const role = this.props.role;
    if (role.error) {
      notify(this.t(role.error.message));
    } else {
      this.gotoBack(role.item);
    }
  };

  render() {
    const { view, editing, edit_page, submitting, role, loading } = this.state;
    const required = (value) =>
      validateIsfilled(value) || (view && !editing)
        ? undefined
        : this.t('This field is required');

    const validateForm = (values) => {
      const errors = {};
      errors.name = required(values.name);
      return errors;
    };

    // Layout actions
    const actions = {
      main: {
        onClick: (e) => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert',
      },
      secondaries: [
        {
          onClick: (e) => history.push(config.ROUTES.DEPARTMENTS),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent',
        },
        {
          onClick: (e) => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update',
        },
        {
          onClick: (e) => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing,
        },
      ],
    };

    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs,
        }}
        container={{ className: 'px-8' }}
        actions={actions}
      >
        <ToastContainer />
        <PanelWindow outterTitle={this.titles} loading={loading}>
          <Form
            initialValues={role || {}}
            onSubmit={this.onSubmit}
            validate={validateForm}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="w-full xl:w-2/6 px-3 mb-3 md:mb-0">
                    <Field
                      name="name"
                      component={TextInput}
                      placeholder={this.t('Name')}
                      label={this.t('Name')}
                      readOnly={view && !editing}
                    />
                  </div>
                  <div className="w-full xl:w-4/6 px-2">
                    <Field
                      name="description"
                      component={TextareaInput}
                      placeholder={this.t('Description')}
                      label={this.t('Description')}
                      readOnly={view && !editing}
                    />
                  </div>
                </FormLayout>
              );
            }}
          </Form>
          {/*edit_page && role.id && editing ? (
            <React.Fragment>
              <div>
                <RoleUsers roleID={role.id} editing={editing} />
              </div>
            </React.Fragment>
          ) : (
            ''
          )*/}
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    role: state.roles.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveOrUpdate: (params) => dispatch(roleActions.saveOrUpdate(params)),
    onGetRole: (id) => dispatch(roleActions.get(id)),
    onGetCompanies: (params) => dispatch(companiesActions.getAll(params)),
    onRemove: (id) => dispatch(roleActions.del(id)),
    onClearCurrent: () => dispatch(roleActions.clearCurrent()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Role));
