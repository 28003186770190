import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import FormLayout from '../../../components/forms/FormLayout';
import settingActions from '../../../context/settings/actions';
import companyActions from '../../../context/companies/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import SwitchboxInput from '../../../components/forms/SwitchboxInput';
import TextInput from '../../../components/forms/TextInput';
import { composeValidators, getOwner, validateIsfilled, validateOnlyNumber } from '../../../libraries/utils';
import prizesActions from '../../../context/benefits/actions';
import actions from '../../../context/transactions/actions';
import SelectInput from '../../../components/forms/SelectInput';
import IntegerInput from '../../../components/forms/IntegerInput';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      defaultSettings: [],
      loading: false,
      submitting: false,
      view: false,
      editing: false,
      settings: [],
      titles: '',
      type: '',
      owner: '',
      remove: false,
      optionPrizes: []
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Settings'), this.t('New')];
    this.titles = this.t('User details');
  }

  // ** Life cycle
  componentDidMount() {
    this.setState({
      remove: true,
      view: true,
      titles: this.t('Edit Settings')
    });
    this.breadcrumbs = [this.t('Settings'), this.t('Edit')];
    this.getDefaultSettings();
     if(this.props.auth.user.type.includes('companies')) this.getPrizes();
   }

  getDefaultSettings = async () => {
    const { user } = this.props.auth;
    const type = user.roles.includes(config.ROLES.COMPANIES)
      ? config.TYPES.SETTINGS.SHARYCO_SETTINGS
      : user.roles.includes(config.ROLES.ADMIN)
      ? config.TYPES.SETTINGS.SHARYCO_ADMIN_SETTINGS
        : null;
    this.setState({ type: type });
    const params = {
      type: type,
      owner: config.OWNER.SYSTEM
    };
    this.setState({ loading: true });
    await this.props.onGetSettings(params);
    const { settings } = this.props;

    if (settings.error) {
      notify(this.t(settings.error.message));
    } else if (user.roles.includes(config.ROLES.COMPANIES)) {
      const companyParams = {
        owner: getOwner(this.props.auth.user)
      };
      await this.props.onGetCompanies(companyParams);
      const { companies } = this.props;
      if (companies.error) {
        notify(this.t(companies.error.message));
      } else {
        this.setState({ defaultSettings: settings.items, owner: companies.items[0].id }, () =>
          this.getSettings(params)
        );
      }
    } else if (user.roles.includes(config.ROLES.ADMIN)) {
      this.setState({ defaultSettings: settings.items, owner: config.OWNER.SYSTEM }, () => this.getSettings(params));
    }
  };

  getSettings = async params => {
    const { defaultSettings, owner } = this.state;
    params.owner = owner;
    await this.props.onGetSettings(params);

    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      console.log("settings", settings.items)
      this.setState({ settings: settings.items, loading: false }, () => {
        if (this.state.settings.length < this.state.defaultSettings.length) {
          let finalArr = this.state.settings;

          for (let i = 0; i < defaultSettings.length; i++) {
          const indx= this.state.settings.findIndex(s=>s.name===defaultSettings[i].name&&s.code===defaultSettings[i].code)
          if(indx<0){
            finalArr.push({
              name: defaultSettings[i].name,
              details: defaultSettings[i].details,
              value: defaultSettings[i].value,
              code: defaultSettings[i].code,
              type: defaultSettings[i].type
            })
          }
            if (i === defaultSettings.length - 1) {
              this.setState({
                settings: [...finalArr],
                loading: false
              });
            }
          }
        }
      });
    }
  };
  getCompanies = async () => {
    //await this.props.onGetCompanies({ owner: this.props.auth.user.id });
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });

    if (this.props.companies.error) {
      notify(this.t(this.props.companies.error.messsage));
    } else {
      return this.props.companies.items
    }
  };

  getPrizeUses = async id => {
    this.setState({ loading: true });
    let params = {
      target: id,
      type: config.TRANSACTIONS.BENEFIT.TYPE,
      owner: this.props.auth.user.unique_id,
    };
    await this.props.onGetTransactions(params);
    const { items, error } = this.props.transactions;
    if (error) return null
    return items
  }

  getPrizes = async () => { 
    const owner = await this.getCompanies();
    const params = { owner: owner[0].id, type: config.TYPES.BENEFITS.PRIZE };
 
    await this.props.onGetAllPrizes(params);
    const { prizes } = this.props;
    if (prizes.error) {
      notify(this.t(prizes.error.message));
    } else {
      const transactionPrizez = await Promise.all(prizes.items.map(async (p) => (await this.getPrizeUses(p.id))))
      const prizesWithCountTransaction = prizes?.items.map((p,i) =>({...p,transactionCount:transactionPrizez[i]?.filter(t => t.status === 'consumed').length||0}) )
      const premiosDisponibles=prizesWithCountTransaction.filter(p=>p?.json_data?.rules?.group_uses-p.transactionCount>0)
      const optionPrizes = premiosDisponibles?.map(p=>({ value: p.id, label: this.props.t(p.name)}))
      optionPrizes.unshift({ value:0, label: this.props.t("Seleccionar...")});
      this.setState({ prizes:prizesWithCountTransaction, optionPrizes, loading: false });}

  };


  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  gotoBack = action => {
    if (!this.props.location.state) {
      history.push(config.ROUTES.SETTINGS);
    } else {
      const { location, from } = this.props.location.state;
      if (from === config.ROUTES.BOOK.SETTING) {
        history.push({
          pathname: action ? config.ROUTES.BOOK.SERVICE : config.ROUTES.BOOK.SETTING,
          state: {
            location,
            action: action || undefined
          }
        });
      } else {
        history.push(config.ROUTES.SETTINGS);
      }
    }
  };

  onSubmit = async values => {
    this.setState({loading: true})
    const localSettings = this.state.settings;
    const { owner } = this.state;
    // por un lado el config.OWNER.SYSTEM para buscar por primera vez
    // para buscar el valor si esta seteado con el owner
    // obj.code
    // combinar el default settings y el owner settings
    const contienePrizeCompanySetting=localSettings.findIndex(setting=>setting.code === 'prize_by_company')  
    if(contienePrizeCompanySetting < 0 && owner!== config.OWNER.SYSTEM) localSettings.push({
      "name":"Premio para el oboarding",
      "details":"Esta opcion permite elegir cual es el premio que se entregara una vez que finalicen con el onboarding",
      "value":null,//id Prize
      "data_type":null,
      "code":"prize_by_company",
      "type": "sharyco_settings",
      "owner":null//ID company
  }) 
    const contieneOnboardingSetting=localSettings.findIndex(setting=>setting.code === 'show_onboarding')  
    if(contieneOnboardingSetting < 0 && owner !== config.OWNER.SYSTEM) localSettings.push({
      "name":"Habilitar/deshabilitar onboarding",
      "details":"Esta opción permitirá habilitar/deshabilitar el módulo Onboarding de la app",
      "value":null,//id Prize
      "data_type":null,
      "code":"show_onboarding",
      "type": "sharyco_settings",
      "owner":null//ID company
  }) 
    const contienetiempoEntreFlowsSetting=localSettings.findIndex(setting=>setting.code === 'tiempo_flows')  
    if(contienetiempoEntreFlowsSetting < 0 && owner === config.OWNER.SYSTEM) localSettings.push({
    "name": "Tiempo entre flows (dias)",
    "details": "Es el tiempo en dias que se debe esperar para realizar e siguiente flow del onboarding",
    "value": null,
    "data_type": null,
    "code": "tiempo_flows",
    "type": "sharyco_admin_settings", 
    "owner": null,
  }) 
    for (let obj of localSettings) {
      obj.value = values[obj.code];
    }
    for (let i = 0; i < localSettings.length; i++) {
      const element = { ...localSettings[i], owner: owner };
      await this.props.onSaveOrUpdate({
        ...element,
        data_type: null,
      });
    }
    const settings = this.props.settings;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      this.setState({ editing: false });
      const params = {type: this.state.type, owner: this.state.owner}
      this.getSettings(params);
    }
  };

  render() {
    // **Destructuring objects for direct use of values
    const { view, editing, submitting, settings, loading } = this.state;
    const { user } = this.props.auth;

    // Layout settings
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert'
      },
      secondaries: [
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing
        }
      ]
    };

    const customFormObject = {};
    const detailsDisplayObject = {};

    // {details: setting.details, label: setting.name}
    // customFormObject[setting.code]

    if (user.roles.includes(config.ROLES.COMPANIES) && settings.length > 0) {
      settings?.forEach(setting => {
        if(setting.code == 'prize_by_company'){
          customFormObject[setting.code] = setting.value;
          detailsDisplayObject[setting.code] = setting.details || '';
          return;
        }else{
          customFormObject[setting.code] = setting.value === 'true' ? true : setting.value === 'false' ? false : true;
          detailsDisplayObject[setting.code] = setting.details || '';
          return;
        }
      });
    }

    if (user.roles.includes(config.ROLES.ADMIN) && settings.length > 0) {
      settings?.forEach(setting => {
        customFormObject[setting.code] = setting.value;
        detailsDisplayObject[setting.code] = setting.details || '';
        return;
      });
    }

    const onlynumber = (value) => validateOnlyNumber(value) ? undefined : 'El campo debe tener solo números';
    const required = (value) => validateIsfilled(value) ? undefined : 'Este campo es requerido';
    const numberlength = (value) => value.length <= 3 && value <= 100 ? undefined : 'El maximo permitido es 100';
    const prizeRequired = (value) => value && value != 0 ? undefined : 'Debe seleccionar un premio'
    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outterTitle={this.titles} loading={loading}>
          <Form  initialValues={customFormObject || {}} onSubmit={this.onSubmit}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  {user.roles.includes(config.ROLES.COMPANIES) && (
                    <>
                      {settings
                        .filter(
                          (setting) => setting.type === "sharyco_settings"
                        )
                        .map((setting,i) => {
                         return setting.code!=="prize_by_company"?(
                            <div key={setting.code + i} className="w-full px-3 md:mb-0 font-bold">
                              <Field
                                labelClassName="mt-3"
                                name={setting.code}
                                component={SwitchboxInput}
                                label={setting.name}
                                readOnly={view && !editing}
                              />
                              <span className="italic font-normal">
                                {setting.details}
                              </span>
                            </div>
                         ):
                         (
                          <div className="w-full px-3 md:mb-0 font-bold">
                            <Field
                              labelClassName="mt-3"
                              name={setting.code}
                              component={SelectInput}
                              options={this.state.optionPrizes}
                              label={setting.name}
                              readOnly={view && !editing} 
                              validate={composeValidators(required,prizeRequired)}
                            />
                            <span className="italic font-normal">
                              {setting.details}
                            </span>
                          </div>
                         )                       
                        }
                        )}
                        {settings?.filter(s=>s.code==="show_onboarding").length == 0 && <div className="w-full px-3 md:mb-0 font-bold">
                            <Field
                              labelClassName="mt-3"
                              name={'show_onboarding'}
                              component={SwitchboxInput}
                              label={"Habilitar/deshabilitar onboarding"}
                              readOnly={view && !editing}
                            />
                            <span className="italic font-normal">
                              {"Esta opción permitirá habilitar/deshabilitar el módulo Onboarding de la app"}
                            </span>
                          </div>
                        }
                        {settings?.filter(s=>s.code==="prize_by_company").length == 0 && <div className="w-full px-3 md:mb-0 font-bold">
                            <Field
                              labelClassName="mt-3"
                              name={'prize_by_company'}
                              component={SelectInput}
                              options={this.state.optionPrizes}
                              label={"Debe configurar el premio para el onboarding"}
                              readOnly={view && !editing}
                              validate={composeValidators(required,prizeRequired)}
                            />
                            <span className="italic font-normal">
                              {"Esta opcion permite elegir cual es el premio que se ganara una vez que cumplan con el onboarding"}
                            </span>
                          </div>
                        }
                    </>
                  )}
                  {user.roles.includes(config.ROLES.ADMIN) && (
                    <>
                      <div className="w-full md:w-fit px-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="email_admin_sharyco"
                          component={TextInput}
                          label={this.t('Email admin')}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.email_admin_sharyco}`}</span>
                      </div>
                      <div className="w-full md:w-fit px-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="sharyco_levels"
                          component={TextInput}
                          label={this.t('Niveles Sharyco')}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.sharyco_levels}`}</span>
                      </div>
                      <div className="w-full md:w-fit px-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="donations_points"
                          component={TextInput}
                          label={this.t('Puntos de impacto para donaciones')}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.donations_points}`}</span>
                      </div>
                      <div className="w-full md:w-fit px-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="donations_coins"
                          component={TextInput}
                          label={this.t('Puntos Shary para donaciones')}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.donations_coins}`}</span>
                      </div>
                      <div className="w-full md:w-fit px-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="volunteering_points"
                          component={TextInput}
                          label={this.t('Puntos de impacto para voluntariado')}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.volunteering_points}`}</span>
                      </div>
                      <div className="w-full md:w-fit px-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="volunteering_coins"
                          component={TextInput}
                          label={this.t('Puntos Shary para voluntariado')}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.volunteering_coins}`}</span>
                      </div>
                      <div className="w-full md:w-fit px-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="recycling_points"
                          component={TextInput}
                          label={this.t('Puntos de impacto para reciclado')}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.recycling_points}`}</span>
                      </div>
                      <div className="w-full md:w-fit px-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="recycling_coins"
                          component={TextInput}
                          label={this.t('Puntos Shary para reciclado')}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.recycling_coins}`}</span>
                      </div>
                      <div className="w-full md:w-fit px-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="maslow_factor"
                          component={TextInput}
                          label={this.t('Factor de Maslow')}
                          readOnly={view && !editing}
                          validate={composeValidators(required, onlynumber, numberlength)}
                        />
                        
                      </div>
                     {detailsDisplayObject.tiempo_flows&&
                        <>
                          <div className="w-full md:w-fit px-2 md:mb-0 font-bold">
                            <Field
                              labelClassName="mt-3"
                              name="tiempo_flows"
                              component={IntegerInput}
                              label={this.t('Tiempo en dias por cada flow')}
                              readOnly={view && !editing}
                              validate={composeValidators(required, onlynumber, numberlength)}
                              defaultValue={1}
                            />
                          </div>
                          <div className="w-full px-3 mb-3 md:mb-0 italic">
                            <span>{`${detailsDisplayObject.tiempo_flows}`}</span>
                          </div>
                        </>
                      }
                     {!detailsDisplayObject.tiempo_flows&&
                        <>
                          <div className="w-full md:w-fit px-3 md:mb-0 font-bold">
                            <Field
                              labelClassName="mt-3"
                              name="tiempo_flows"
                              component={IntegerInput}
                              label={this.t('Tiempo en dias por cada flow')}
                              readOnly={view && !editing}
                              validate={composeValidators(required, onlynumber, numberlength)}
                              defaultValue={1}
                            />
                          </div>
                          <div className="w-full px-3 mb-3 md:mb-0 italic">
                            <span>{`${'Es el tiempo en dias que se debe esperar para realizar e siguiente flow del onboarding'}`}</span>
                          </div>
                        </>
                      }
                    </>
                  )}
                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    settings: state.settings.list,
    prizes: state.benefits.list,
    transactions: state.transactions.list

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(settingActions.saveOrUpdate(params)),
    onGetSettings: params => dispatch(settingActions.getAll(params)),
    onGetCompanies: params => dispatch(companyActions.getAll(params)),
    onRemove: id => dispatch(settingActions.del(id)),
    onClearCurrent: () => dispatch(settingActions.clearCurrent()),
    onGetAllPrizes: params => dispatch(prizesActions.getAll(params)),
    onGetTransactions: params => dispatch(actions.getAll(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Settings));
