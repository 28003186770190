import React from "react";
import NavbarSmall from './NavbarSmall'

export default function Navbar(props) {
  return (
    <>
      {/* Navbar */}
      <nav className="top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-no-wrap md:justify-start flex items-center pt-4 pb-4">
        <div className="w-full mx-auto items-center flex justify-between md:flex-no-wrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          {/* <a
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="#"
            onClick={e => e.preventDefault()}
          >
            Dashboard
          </a> */}
          
          <NavbarSmall/>
          {props && props.children}
          {/* Form */}
          {/* <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
            <div className="flex w-full flex-wrap items-stretch">
              <span className="text-gray-600 text-base z-10 h-full leading-snug font-normal text-center absolute bg-transparent rounded items-center justify-center w-12 pl-3 py-3">
                <FontAwesomeIcon icon={faSearch}/>
              </span>
              <input type="text" placeholder="Search here..." className="bg-white text-sm placeholder-gray-600 text-gray-700 px-3 py-3 relative shadow outline-none focus:outline-none focus:shadow-outline w-full pl-12"/>
            </div>
          </form> */}
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
