export default {
  CLEAR_CURRENT: 'ASSISTANTS_CLEAR_CURRENT',
  GET_REQUEST: 'ASSISTANTS_GET_REQUEST',
  GET_SUCCESS: 'ASSISTANTS_GET_SUCCESS',
  GET_FAILURE: 'ASSISTANTS_GET_FAILURE',

  SAVE_REQUEST: 'ASSISTANTS_SAVE_REQUEST',
  SAVE_SUCCESS: 'ASSISTANTS_SAVE_SUCCESS',
  SAVE_FAILURE: 'ASSISTANTS_SAVE_FAILURE',

  GETALL_REQUEST: 'ASSISTANTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'ASSISTANTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'ASSISTANTS_GETALL_FAILURE',

  DELETE_REQUEST: 'ASSISTANTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'ASSISTANTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'ASSISTANTS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'ASSISTANTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ASSISTANTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ASSISTANTS_UPDATE_FAILURE'    

};