import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import CodeInput from '../../components/forms/CodeInput';

import {capitalize} from '../../libraries/utils'

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      code: null,
      loading: true,
    }
  }
  
  componentDidMount() {
    ;
    const params = this.props.match.params;
    if (params.hash) {
      this.checkHash(params)
    } else {
      history.push(config.ROUTES.LOGIN);
    }
  }

  checkHash = async (params) => {
    await this.props.onGetFromHash(params)
    const user = this.props.user;
    if (user.error) {
      history.push(config.ROUTES.LOGIN);
    } else {
      this.setState({ user: user.item, loading: false });
    }
  }
  
  onSubmit = async () => {
    const data = { id:this.state.user.id, code:this.state.code }
    await this.props.onVerifyPIN(data)
    const auth = this.props.auth;
    if (auth.error) {
      notify(this.t(auth.error.message));
    } else {
      const user = this.state.user
      await this.props.onUpdate({ status: "active", id: user.id, user_type: config.USER_TYPE.REGULAR })
      if (this.props.user.error) {
        notify(this.t(this.props.user.error.message));
      } else {
        history.push(config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash));
      }
    }
  }

  onSendAgain = async () => {
    console.log('SEND AGAIN');
    const data = {
      email: this.state.user.email,
      redirect_uri: config.DOMAIN_NAME + config.ROUTES.VERIFY.replace(':hash', this.state.user.hash)
    }

    await this.props.onVerifyPINretry(data)
    const auth = this.props.auth;
    if (auth.error) { 
      notify(this.t(auth.error.message));
    } else {
      notify(this.t("Mail enviado"), "SUCCESS");
    }
  }

  onChange = (value) => {
    ;
    this.setState({ code: value });
  }

  render() {
    return (
      <LayoutSmall
        main={{ className: "bg-base-200 text-primary bg-pattern"}}
        header={{ 
          logo: true,
          // title: this.t("Verification email"),
          className: "bg-transparent text-primary text-center mt-10",
        }}
        container= {{
          className: "mt-10 p-4",
        }}
        loading={this.state.loading}
      >
        <ToastContainer/>
        <div className='w-full flex flex-col items-center'>
          <div className="text-center mb-10">
            <h1 className="text-primary">{this.t("Enter code")}</h1>
          </div>       
          <form className="w-full max-w-lg">
                <CodeInput
                  name="code"
                  type="number"
                  fields={6}
                  onChange={this.onChange}
                  autoFocus={true}
                />             
            <div className="w-full px-3 text-center">
              <Button
                className="btn-link mb-10 text-primary-100 text-sm"
                title={capitalize(this.t("send again"))}
                onClick={this.onSendAgain}
              />
              <Button
                className="btn-primary btn-block"
                title={this.t("Log in")}
                onClick={this.onSubmit}
              />
            </div>
          </form>
        </div>
      </LayoutSmall>        
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    user: state.users.current,    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onVerifyPIN: (params) => dispatch(userActions.verifyPIN(params)),
    onVerifyPINretry: (params) => dispatch(userActions.verifyPINretry(params)),
    onGetFromHash: (params) => dispatch(userActions.getFromHash(params)),
    onUpdate: (values) => dispatch(userActions.saveOrUpdate(values, 'register')),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Verify));
