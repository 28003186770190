import React  from 'react';
import FooterSmall from './FooterSmall';
import HeaderSmall from './HeaderSmall';

export default function LayoutHalfScreen({ side, header, children }) {
  // side: { image: <image> } or content: <content>
  return (
    <main className="w-full flex flex-wrap h-screen">
      <div className="w-full md:w-1/2 flex flex-col">
        <HeaderSmall header={header}/>
        <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
          {children}
        </div>
        <FooterSmall/>
      </div>
      {side && (
        <div className={"w-1/2 shadow-2xl hidden md:flex " + (side.className ? side.className : "")}>
          {side && side.image && (
            <img alt="" className={side.imageClassName ? side.imageClassName : "object-cover w-full hidden md:block"} src={side.image}/>
          )}
        </div>
      )}
    </main>
  )
}
