import React from 'react';
// libraries
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { notify } from '../../../libraries/notifications';
import {
  capitalize,
  selectGeneratorWObjChild,
  validateIsfilled,
  capitalizePhrase,
  hideColumns,
  getOwner
} from '../../../libraries/utils';
import Icon from '../../../libraries/icons';
// components
import FormLayout from '../../../components/forms/FormLayout';
import Button from '../../../components/commons/Button';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import Swal from 'sweetalert2';
// context
import usersActions from '../../../context/users/actions';
import companyActions from '../../../context/companies/actions';
import ComboboxInput from '../../../components/forms/ComboboxInput';

// custom swal
const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class ReferentUsers extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      creatingNew: null,
      currSelectedUser: {},
      showEditForm: false,
      loading: true,
      users: [],
      windowWidth: window.innerWidth
    };
    this.submit = null;
    this.breadcrumbs = [this.t('Referente')];
  }

  componentDidMount() {
    if (this.props.referentID) {
      this.setState({referentID: this.props.referentID})
      this.getData();
    }
    //window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  getCompany = async () => {
    const { user } = this.props.auth
    await this.props.onGetCompanies({ owner: getOwner(user) });
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message));
      return null
    } else {
      return companies.items[0]?.id
    }
  }

  getData = async () => {
    let company = await this.getCompany()
    //fixme: todos tienen por defecto null y no system, debería traer los null o system o deberiamos patchear todo como system
    await this.props.onGetUsers({unique_id: company}); //{invited_by: "system"}
    await this.props.onGetReferentInvitesPoints(this.state.referentID)
    this.setState({
      //fixme: sacar el primer filter cuando esté solucionado lo de arriba 
      users: [...this.props.users.items.filter(u => ((u.invited_by === "system" || u.invited_by === null) && u.invited_by !== this.props.auth.user.id) )],
      invites: this.props.invites.invites,
      invites_total_points: this.props.invites.invites.filter(i=>i.user.enabled===1).reduce((acc, i) => acc + parseInt(i.points), 0),
    });
    await this.getReferentUsers(this.state.referentID)
  };
  
  getReferentUsers = async (referentID) => {
    this.setState({ loading: true });
    await this.props.onGetUsers({invited_by: referentID,enabled: 1,});
    this.setState({
      referentUsers: [...this.props.users.items],
      referentUsersQuery: this.props.users.query,
      loading: false
    });
  }

  onSubmit = async values => {
    try {
      const { referentID } = this.state;
      await this.props.onSaveOrUpdateUser( { id: values.user_id, invited_by: referentID } );
      this.setState({ showEditForm: false, creatingNew: false });
      this.getData();
    } catch (e) {
      console.error(e);
    }
  };

  onRemove = async (referentId) => {
    const {value: swalResponse} = await customSwal.fire({
      title: this.t('¿Are you sure?'),
      text: this.t("¿Estás seguro que quieres remover a este usuario del referente?"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.t('Yes'),
      cancelButtonText: this.t('Cancel')
    })

    if(swalResponse){
      const { referentID } = this.state;
      await this.props.onSaveOrUpdateUser( { id: referentId, invited_by: "system" } );
      this.setState({ showEditForm: false, creatingNew: false });
      this.getData();          
    }
  }  
  // Other methods
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  render() {
    const { referentUsers, currSelectedUser, loading, users, windowWidth, invites, invites_total_points } = this.state;

    const userOptions = selectGeneratorWObjChild(
      users,
      'id',
      o => `${capitalizePhrase(o.name)} - ${o.email}`
    )
    
    const columns = [
      {
        name: this.t('Email'),
        sortable: true,
        selector: row => capitalizePhrase(row['email']),
        // hide: hideColumns(455, windowWidth),
        sortSelector: 'user'
      },
      {
        name: this.t('User'),
        sortable: true,
        selector: row => capitalizePhrase(row['name']),
        // hide: hideColumns(455, windowWidth),
        sortSelector: 'user'
      },
      {
        name: 'Puntos',
        sortable: true,
        selector: row => {
          let u = invites.find(i => i.user?.id == row['id'])
          if(u?.points) return u?.points
          return 0
        },
        // hide: hideColumns(455, windowWidth),
        sortSelector: 'user'
      },
      {
        name: capitalize(this.t('UI actions')),
        allowOverflow: true,
        center: true,
        width: '150px',
        hide: hideColumns(370, windowWidth),
        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full">
              <span
                onClick={() => this.onRemove(row.id)} className="p-3 rounded-full cursor-pointer bg-gray-700 hover:bg-gray-800">
                <Icon className="h-3 w-3 text-white" name="trash" />
              </span>
            </div>
          );
        }
      }
    ];
    const required = value => validateIsfilled(value) ? undefined : this.t('This field is required');
    // ** Render
    return (
      <div className="">
        <div className="">
          <Form
            initialValues={currSelectedUser || {}}
            onSubmit={this.onSubmit}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <>
                    <div className="w-full">
                      <div className="w-full px-3 md:mb-0">
                        <Field component={ComboboxInput} empty={this.t('Seleccionar un usuario')} label={this.t('Agregar un referido')} 
                          name="user_id" options={userOptions} placeholder={this.t('Seleccionar un usuario')} validate={required}/>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <Button onClick={e => this.submit(e)} title={this.t('Agregar usuario')} />
                    </div>
                  </>
                </FormLayout>
              );
            }}
          </Form>
        </div>
        <h1>Puntos totales del referente: {invites_total_points ? invites_total_points : 0}</h1>

        {/*<div className="">
          <Form
            onSubmit={this.onSubmit}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <FormLayout form={form} onSubmit={(values) => {}} values={values}>
                  <div className='flex flex-col sm:flex-row gap-3 justify-start'>
                    <Field
                      name="start_date"
                      component={DatepickerInput}
                      placeholder={this.t('dd/mm/yyyy')}
                      label={this.t('Desde')}
                      className='w-40'
                    />
                    <Field
                      name="end_date"
                      component={DatepickerInput}
                      placeholder={this.t('dd/mm/yyyy')}
                      label={this.t('Hasta')}
                      className='w-40'
                    />
                  </div>
                </FormLayout>
                );
              }}
          </Form>
        </div>*/}

        <div className="">
          <StyledDataTable
            data={referentUsers}
            noHeadCells
            columns={columns}
            selectableRows={windowWidth > 450}
            query={this.state.referentUsersQuery}
            getDataFunction={this.getReferentUsers}
            multipleDeleteFunction={this.onRemove}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
          />
          <div className="flex justify-evenly my-5">
            {/*<Button
              onClick={() => {
                this.setState({ showEditForm: true, creatingNew: true });
                this.getData();
              }}
              disabled={this.state.showEditForm}
              title={this.t('Add user')}
            />
            <Button
              onClick={() => {
                this.setState({
                  showEditForm: false,
                  currSelectedUser: ''
                });
              }}
              className="danger"
              disabled={!this.state.showEditForm}
              title={capitalize(this.t('close'))}
            />*/}
          </div>
        </div>



       
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    users: state.users.list,
    user: state.users.current,
    invites: state.users.invites,
    companies: state.companies.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdateUser: (params) => dispatch(usersActions.saveOrUpdate(params)),
    onGetUsers: params => dispatch(usersActions.getAll(params)),
    onGetUser: id => dispatch(usersActions.get(id)),
    onGetReferentInvitesPoints: id => dispatch(usersActions.getInvites(id)),
    onGetCompanies: params => dispatch(companyActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ReferentUsers));
