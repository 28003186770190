import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../../libraries//icons';
import { capitalize } from '../../libraries/utils';


export default function MenuItem({ item, location, ...rest }) {
  const { t } = useTranslation();
  if (!item) return null;
  const { icon, label, route, action } = item;
  const isSelected = (route, location)  => {
    return location.pathname === route;    
  }
  return (
    route ? (
      <li className="rounded-sm">
        <Link
          className={"" + (isSelected(route, location) ? " bg-base-300" : "")}
          to={route}
        >
          {icon && <Icon className="h-7 w-7 mr-3 text-primary" name={icon} />}
          {capitalize(t(label))}
        </Link>
      </li>
    ) : (
      action ? (
        <li>
          <span
            className={""}
            onClick={action ? action : ()=>{}}
          >
            {icon && <Icon className="h-7 w-7 mr-3" name={icon} />}
            {capitalize(t(label))}
          </span>
        </li>
      ) : (
        // Only a title
        <li>
          <p className="text-xs text-gray-500 uppercase px-3">{capitalize(t(label))}</p>
        </li>
      )
    )
  )
}
