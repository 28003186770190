import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { validateIsfilled, selectGeneratorWObjChild, capitalizePhrase, isEmptyObject, capitalize, getObjectWithJsonDataToFormValues, getOwner, validateURL, parseUrl, } from '../../../libraries/utils';
import FormLayout from '../../../components/forms/FormLayout';
import filesActions from '../../../context/files/actions';
import companyActions from '../../../context/companies/actions';
import usersActions from '../../../context/users/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import SelectInput from '../../../components/forms/SelectInput';
import ImageUploadFileInput from '../../../components/forms/ImageUploadFileInput';

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      disableEverything: false,
      company: {},
      loading: false,
      files: {},
      submitting: false,
      view: false,
      editing: false,
      titles: '',
      remove: false
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Companies'), this.t('New')];
    this.titles = this.t('User details');
  }
  // ** Life cycle
  componentDidMount() {
    const params = this.props.match.params;
    this.setState({
      users: [],
      remove: true,
      view: true,
      loading: true,
      titles: this.t('Edit ompany')
    });
    this.breadcrumbs = [capitalize(this.t('company')), this.t('Edit')];
    //params.owner = this.props.auth.user.id;
    params.owner = getOwner(this.props.auth.user)

    this.getCompanies(params);

  }

  getCompanies = async params => {
    await this.props.onGetAll(params);
    const companies = this.props.companies;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      if (isEmptyObject(companies.items[0])) {
        notify(capitalize(this.t("user doesn't own any company")));
        this.setState({ disableEverything: true });
        return;
      }
      this.setState({
        company: companies.items[0],
        files: { image: companies.items[0].json_data?.image },
        loading: companies.loading
      });
    }
  };

  onClearImageField = ({ name }) => {
    if (name) this.setState({ files: { ...this.state.files, [name]: null } });
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  onFileChangeImageInput = async ({ file, data, name }) => {
    const { company } = this.state;
    this.setState({ submitting: true });
    data.append('identifier', `${company.id}_${name}`);
    await this.props.onPictureUpload(data);
    const { files } = this.props;
    if (files.error) {
      notify(this.t(files.error.message));
    } else {
      this.setState({ imageUpdated: true });
      this.setState({
        files: {
          ...this.state.files,
          [name]: files.files.fileInfo.location
        }
      });
    }
    this.setState({ submitting: false });
    return files.files.fileInfo.location;
  };

  onSubmit = async values => {
    this.setState({ submitting: true, loading: true });
    const { files } = this.state;
    const data = {
      name: values.name,
      country: values.country,
      state: values.state,
      type: this.state.company.type,
      json_data: {
        address: values.address,
        url: values.url,
        image: files ? files.image : ''
      }
    };

    if (this.state.company.id) data.id = this.state.company.id

    await this.props.onSaveOrUpdate({ ...data });
    const companies = this.props.companies;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      // history.push(config.ROUTES.MY_COMPANY);
      this.setState({ editing: false, submitting: false });
      this.getCompanies({ owner: getOwner(this.props.auth.user) });
    }
  };

  render() {
    // **Destructuring objects for direct use of values
    const { company, view, editing, files, submitting, users, loading, disableEverything } = this.state;
    // ** Options for selects
    const countries = selectGeneratorWObjChild(config.OPTIONS.COUNTRIES, '', o => capitalizePhrase(o));
    const argentinaStates = selectGeneratorWObjChild(config.OPTIONS.STATES.ARGENTINA, '', o => capitalizePhrase(o));
    const userOptions = selectGeneratorWObjChild(users, 'id', o => capitalizePhrase(o.name));

    let _company;
    if (!isEmptyObject(company) && company !== undefined && company !== null) {
      _company = getObjectWithJsonDataToFormValues(company, ['image', 'name', 'url', 'country', 'state', 'owner', 'address']);
    }

    // Layout actions
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing) || disableEverything,
        checkPermissions: 'update'
      },
      secondaries: [
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting || disableEverything,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing || disableEverything,
          visible: editing
        }
      ]
    };

    if (this.props.auth.user.roles.includes(config.ROLES.ADMIN))
      actions.secondaries.push({
        onClick: e => {
          if (company.enabled === 1) this.setState({ company: { ...company, enabled: 0 } });
          if (company.enabled === 0) this.setState({ company: { ...company, enabled: 1 } });
        },
        title: this.t(company?.enabled ? 'Disable' : 'Enable'),
        icon: !company?.enabled ? 'view_show' : 'view_hide',
        disabled: submitting || (view && !editing) || loading,
        checkPermissions: 'insert'
      });

    // ** Form validation functions
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');
    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      errors.country = required(values.country);
      return errors;
    };

    const isUrl = value => validateURL(value) || (view && !editing) || !value ? undefined : this.t('Ingresar URL válido');

    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outterTitle={this.titles} loading={loading}>
          <Form initialValues={_company || {}} onSubmit={this.onSubmit} validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="flex justify-center mb-3 md:mb-6 h-64">
                    {!editing && values.image ? (
                      <img src={files.image} alt="" className="aspect-square object-contain" />
                    ) : (
                      <Field
                        name="image"
                        img={files.image}
                        component={ImageUploadFileInput}
                        label={capitalize(this.t('image'))}
                        placeholder={this.t('URL de imagen')}
                        inputOnChange={this.onFileChangeImageInput}
                        disabled={view && !editing}
                        clickButtonClear={this.onClearImageField}
                        inputClassName="input-bordered"
                        labelClassName="justify-center"
                      />
                    )}
                  </div>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-full md:w-2/4 px-2">
                      <Field
                        name="name"
                        inputClassName="input-bordered"
                        component={TextInput}
                        placeholder={this.t('Nombre')}
                        label={this.t('Nombre')}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="w-full md:w-2/4 px-2">
                      <Field
                        name="url"
                        inputClassName="input-bordered"
                        component={TextInput}
                        placeholder={this.t('Página web')}
                        label={this.t('Página web')}
                        readOnly={view && !editing}
                        parse={parseUrl} validate={isUrl}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                      <Field
                        name="country"
                        component={SelectInput}
                        selectClassName="select-bordered w-full"
                        placeholder={this.t('Country')}
                        options={countries}
                        empty={this.t('Select a country')}
                        label={this.t('Country')}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                      <Field
                        name="state"
                        component={SelectInput}
                        selectClassName="select-bordered w-full"
                        placeholder={this.t('State')}
                        options={argentinaStates}
                        empty={this.t('Select a state')}
                        label={this.t('State')}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                      <Field
                        name="address"
                        inputClassName="input-bordered"
                        component={TextInput}
                        placeholder={capitalize(this.t('address'))}
                        label={capitalize(this.t('address'))}
                        readOnly={view && !editing}
                      />
                    </div>
                  </div>
                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    files: state.files,
    companies: state.companies.list,
    users: state.users.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(companyActions.saveOrUpdate(params)),
    onGetAll: params => dispatch(companyActions.getAll(params)),
    onGetUsers: params => dispatch(usersActions.getAll(params)),
    onRemove: id => dispatch(companyActions.del(id)),
    onClearCurrent: () => dispatch(companyActions.clearCurrent()),
    onPictureUpload: params => dispatch(filesActions.upload(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Company));
