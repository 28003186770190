import React from 'react';
import Icon from '../../libraries/icons';
import config from '../../config';

export default function IconPoints({ points, ...rest })  {
  const leyend = rest.leyend ? rest.leyend : '';
  
  const icon = (value) => {
    const cfg = config.VALUE_RANGE_ICONS.POINTS;
    if (value <= cfg.LOW.value) {
      return cfg.LOW.icon;
    } else if (value > cfg.LOW.value && value <= cfg.MEDIUM.value) {
      return cfg.MEDIUM.icon;
    } else {
      return cfg.HIGH.icon;
    }
  }

  return (
    <div className="flex items-center">
      <div className={
        "inline-block "
        + (rest.className ? rest.className : "mx-auto")
      }>
        <h3 className="leading-none font-bold"><Icon className={"inline w-7 mr-2 -mt-1 " + (rest.iconClassName ? rest.iconClassName : "")} name={icon(points)} />{points} {leyend}</h3>
      </div>
    </div>
  );
}


