import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import { Link } from 'react-router-dom';
import { capitalize, capitalizePhrase, getOwner } from '../../../libraries/utils';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import companyActions from '../../../context/companies/actions';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import userActions from '../../../context/users/actions';
import clsx from 'clsx';

// import users from '../../../context/users';
import Swal from 'sweetalert2';
const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    denyButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Referents extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      disableEverything: false,
      users: [],
      companyID: '',
      windowWidth: window.innerWidth,
      loading: true,
    };
    this.breadcrumbs = [this.t('Referentes')];
  }

  // ** Life cycle
  async componentDidMount() {
    await this.props.onGetAll()
    const { users } = this.props;
    if(users.error){
      notify(this.t(users.error.message));
    }
    this.setState({ allUsers: users.items }, () => { this.getData() });
    
    // ** Get window width
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    // ** Get window width on resize
    window.removeEventListener('resize', this.handleResize);
  }
  // ** Function to handle resize
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getData = async (page_num = 1) => {
    this.setState({ loading: true });
    await this.getUsers(page_num);
    await this.getReferentUsers()
    this.setState({ loading: false });
  }

  getUsers = async (page_num = 1) => {
    const { user } = this.props.auth;
    const params = { page_num };
    const { search } = this.state;
    // ** If search exist, filter the data
    if (search && search !== '') {
      params.where = {
        custom: {
          // keys can be in any order
          name: `%${search.toLowerCase()}`,
          email: `%${search.toLowerCase()}`,
          comparison: 'OR'
        }
      };
    }
    // ** If sort exist, sort the data
    if (this.state.sort) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }

    params.invited_by = "referent"

    // ** Execute get request with params
    if (user.roles.includes(config.ROLES.COMPANIES)) {
      //await this.props.onGetCompanies({ owner: user.id });
      await this.props.onGetCompanies({ owner: getOwner(user) });
      const { companies } = this.props;
      if (companies.error) {
        notify(this.t(companies.error.message));
      } else {
        params.unique_id = companies.items[0]?.id;
        if (!params.unique_id) {
          notify(capitalize(this.t("user doesn't own any company")));
          this.setState({disableEverything: true}) 
          return;
        }
        await this.props.onGetAll(params);
        const { users } = this.props;
        if (users.error) {
          notify(this.t(users.error.message));
        } else {
          // ** Set state based on response data
          this.setState({ companyID: companies.items[0].id, users: users.items,querys:users?.query, });
        }
      }
    }

    if (!user.roles.includes(config.ROLES.COMPANIES)) {
      //params.user_type = [config.ROLES.COMPANIES, config.ROLES.BENEFITS,config.ROLES.INITIATIVES]
      await this.props.onGetAll(params);
      const { users } = this.props;
      if (users.error) {
        notify(this.t(users.error.message));
      } else {
        // ** Set state based on response data
        this.setState({ users: users.items, querys:users?.query  });
      }
    }
  };


  getReferentUsers = async () => {
    const { allUsers, users } = this.state
    const data = []
    for (const user of users) {
      // await this.props.onGetAll({ invited_by: user.id })
      await this.props.onGetReferentInvitesPoints(user.id)
      let invites_total_points = this.props.invites.invites.filter(i=>i.user.enabled===1).reduce((acc, i) => acc + parseInt(i.points), 0)
      const refuser = allUsers.filter(u => u.invited_by === user.id &&  u.enabled === 1)
      data.push({
        ...user,
        referents: refuser,
        invites_total_points
      });
    }

    this.setState({
      users: data,
    });
  }

  // ** Custom sort function
  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: `users__${field}`, order_direction: order } }, () => this.getUsers(currentPage));
  };
  //  ** Delete function
  onRemove = (id, resetRows) => {
    const { users } = this.props;
    customSwal
      .fire({
        title: this.t(resetRows ? capitalize('delete multiple elements') : '¿Are you sure?'),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          // ** Loading
          users.loading = true;
          // ** If the parameter recieved is an array, execute multiple delete function
          if (resetRows) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              // ** Wait until the api removes selected items
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (users.error) {
              notify(this.t(users.error.message));
            } else {
              // ** Get users
              this.getUsers(this.state.currentPage);
            }
            // ** If the parameter is a number execute unique delete
          } else {
            // ** Wait until the api removes selected items
            await this.props.onRemove(id);
            if (users.error) {
              notify(this.t(users.error.message));
            } else {
              // ** Get users
              this.getUsers(this.state.currentPage);
            }
          }
        }
      });
  };
  //  ** Actions function
  multipleAction = (usersArray, resetRows) => {
    const { users } = this.props;
    customSwal
      .fire({
        title: capitalize(this.t('multiple action')),
        text: capitalize(this.t('do you want to enable or disable selected elements?')),
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        denyButtonText: this.t('Disable'),
        confirmButtonText: this.t('Enable'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        let changed = false;
        // ** Loading
        if (!result.isDismissed) {
          users.loading = true;
          for (let index = 0; index < usersArray.length; index++) {
            const element = usersArray[index];

            let data = { ...element, enabled: !result.isDenied };
            if (!data.password) data.password = '@';
            // if (!data.user_type) data.user_type = 'regular';

            if ((element.enabled === 1 && result.isDenied) || (element.enabled === 0 && result.isConfirmed)) {
              this.setState({ loading: true });
              await this.props.onSaveUser(data);
              changed = true;
            }
          }
          resetRows();
          changed && this.setState({ loading: false });
          if (users.error) {
            notify(this.t(users.error.message));
            users.loading = false;
          } else {
            // ** Get users
            changed && (await this.getUsers(this.state.currentPage));
            users.loading = false;
          }
        }
      });
  };
  // ** Redirection to edit user route
  onEdit = id => {
    history.push(`${config.ROUTES.REFERENTS_EDIT.replaceAll(":id", id)}`);
  };
  // ** Redirection to new user route
  onNew = () => {
    history.push(config.ROUTES.REFERENTS_NEW);
  };

  onGotoExcel= () => {
    history.push(config.ROUTES.EXCEL);
  };

  // ** Function to look for specific data in the table
  onSearch = data => {
    if (this.state.search !== data.search) this.setState({ search: data.search || '' }, () => this.getUsers());
  };

  searchClear = form => {
    form.change('search', undefined);
    this.onSearch({ search: '' });
  };
  // ** Use this function when configuring columns, its for hiding columns when the window width goes below the number specified
  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  emailSingleOrMultiple = async (usersArray, resetRows = () => {}) => {
    console.log("usersArray", usersArray)
    const {value: swalResponse} = await customSwal.fire({
        title: this.t("Mandar mail de invitación?"),
        text: usersArray.length > 1 ? this.t("Se enviará un mail de invitación a los usuarios seleccionados (solo los usuarios con status nuevo)") : this.t("Se enviará un mail de invitación al usuario seleccionado (solo los usuarios con status nuevo)"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.t("Yes"),
        cancelButtonText: this.t("Cancel"),
      })

    if(swalResponse){
      //fixme: error handling si vuelve un error del back (ahora asume que salio todo bien)
      this.sendEmail(usersArray)
      await customSwal.fire({
        title: this.t("Correos enviados."),
        icon: "success",
        showCancelButton: false,
        confirmButtonText: this.t("Aceptar"),
      })
      resetRows()
    }

  }
  
  sendEmail = async (users) => {
    this.setState({ loading: true })
    
    let payload = {
      ids: users.map(u => u.id).join(","),
      referents: true,
    }
    this.props.onSendReferentsMail(payload)
    this.setState({ loading: false })
  }

  render() {
    // **Destructuring objects for direct use of values
    const { windowWidth, users, disableEverything, loading } = this.state;

    // ** This is the component that will show when expanding the table
    const expandableComponent = ({ data }) => (
      <Link to={`${config.ROUTES.REFERENTS_EDIT.replaceAll(":id", data.id)}`}>
        <div className="flex flex-wrap py-3 px-5 align-center justify-around bg-gray-50">
          <div className="py-2">
            <h4>{capitalize(this.t("user name"))}</h4>
            <p className="text-gray-500">{this.t(data.username).toUpperCase()}</p>
          </div>
          <div className="py-2">
            <h4>{this.t('Email')}</h4>
            <p className="text-gray-500">{data.email}</p>
          </div>
          <div className="py-2">
            <p className={`${data.enabled ? 'bg-green-100' : 'bg-red-100'} rounded-box px-2 py-1`}>
              {data.enabled ? this.t('Active') : this.t('Inactive')}
            </p>
          </div>
        </div>
      </Link>
    );

    const columns = [
      {
        name: this.t('First name'),
        sortable: true,
        maxWidth:
          // non-desktop
          windowWidth < 959
            ? // windowWidth - (table padding + cell padding) - (actions cell width + actions cell padding)
              `${windowWidth - 64 - 162}px`
            : // 720p
            windowWidth > 959 && windowWidth < 1920
            ? '19.41vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '23.68vw'
            : // 4k
            windowWidth >= 3840
            ? '28.695vw'
            : '',
        selector: row => capitalizePhrase(row['name']?.toUpperCase()),
        sortSelector: 'name'
      },
      {
        name: this.t('Username'),
        sortable: true,
        selector: row => row['username'],
        sortSelector: 'username',
        // hide: 'md',
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '19.41vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '23.68vw'
            : // 4k
            windowWidth >= 3840
            ? '28.69vw'
            : ''
      },
      {
        name: this.t('Email'),
        sortable: true,
        // hide: 'md',
        selector: row => row['email'],
        sortSelector: 'email',
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '19.41vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '23.68vw'
            : // 4k
            windowWidth >= 3840
            ? '28.69vw'
            : ''
      },
      {
        name: this.t('Activo'),
        // hide: 'md',
        selector: row => (
          <p className={`${row.enabled ? 'bg-green-100' : 'bg-red-100'} rounded-box px-2 py-1`}>
            {row.enabled ? 'Active' : 'Inactive'}
          </p>
        ),
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '6.48vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '7.89vw'
            : // 4k
            windowWidth >= 3840
            ? '9.56vw'
            : ''
      },
      {
        name: this.t("Status"),
        // hide: "md",
        selector: row => (
          <p className={clsx("rounded-box px-2 py-1", row.status ==="new" ? "bg-yellow-100": "bg-green-100")}>
            {this.t(capitalize(row.status))}
          </p>
        ),
        maxWidth:
          // 720p
          windowWidth < 1920
            ? "6.48vw"
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? "7.89vw"
            : // 4k
            windowWidth >= 3840
            ? "9.56vw"
            : "",
      },
      {
        name: this.t("Referidos"),
        // hide: "md",
        selector: row => (
          <p className={`bg-slate-100 rounded-box px-2 py-1`}>{row.referents?.length || "0"}</p>
        ),
        width: "130px",
      },
      {
        name: this.t("Puntos totales"),
        // hide: "md",
        selector: row => (
          <p className={`bg-slate-100 rounded-box px-2 py-1`}>{row.invites_total_points || "0"}</p>
        ),
        width: "130px",
      },
      {
        name: capitalize(this.t('UI actions')),
        allowOverflow: true,
        center: true,
        width: '130px',

        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full">
              <ButtonIcon onClick={() => this.onEdit(row.id)} icon="view_show" buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm" className="w-5" />
            </div>
          );
        }
      }
    ];
    // ** Main and secondary actions of out layout
    const actions = {
      //main: { onClick: this.onNew, title: this.t('New'), checkPermissions: 'insert', disabled: disableEverything },
      //secondaries: [
      //  { onClick: this.onGotoExcel, title: this.t('Excel'), checkPermissions: 'insert', disabled: disableEverything }
      //],
      search: { onSearch: this.onSearch, onClearClick: this.searchClear, title: this.t('Search') }
    };
    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        // loading={loading || this.state.loading}
      >
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={users}
            columns={columns}
            selectableRows
            query={this.state.querys}
            getDataFunction={this.getData}
            multipleFunction={this.multipleAction}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            // expandableRows={windowWidth < 600}
            // expandableRowsComponent={expandableComponent}
            multipleFunctionLabel={this.t('Acción múltiple')}
            multipleFunctionIcon={'view_show'}
            onRowExpand={() => {}}

            extraTableFunctions={[{
              customFunction: this.emailSingleOrMultiple,
              label: this.t("Enviar email a seleccionados"),
              icon: "send_mail",
            }]}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    users: state.users.list,
    invites: state.users.invites,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAll: params => dispatch(userActions.getAll(params)),
    onSaveUser: params => dispatch(userActions.saveOrUpdate(params)),
    onGetCompanies: params => dispatch(companyActions.getAll(params)),
    onRemove: id => dispatch(userActions.del(id)),
    onSendReferentsMail: ids => dispatch(userActions.sendInvatationMails(ids)),

    onGetReferentInvitesPoints: id => dispatch(userActions.getInvites(id)),
    

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Referents));
