import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import { validateBenefitsTransactionsArray, validateBenefitRules, getDateFirstPart, daysOfWeekText, daysOfWeekToString, getRewardFromLevel, getOwner } from '../../libraries/utils';

import LayoutSmall from '../../components/layout/LayoutSmall';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import Button from '../../components/commons/Button';

import transactionsAction from "../../context/transactions/actions";

import UserProfileImage from '../../components/commons/UserProfileImage';
import internalsActions from '../../context/internals/actions';
import businessesActions from '../../context/businesses/actions';
import userActions from '../../context/users/actions';
import benefitsActions from '../../context/benefits/actions';

import MultiLineTextDisplay from '../../components/commons/MultiLineTextDisplay';
import companiesActions from "../../context/companies/actions";

class BenefitConsumeConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = { 
      assistant: null,
      postulated_initiative:"",
      loading: false,
      businesses: [],
      historialTransactions: [],
      showMoreInfo: true,
      userLevel: null,
      benefit: null,
    }
  }
  
  componentDidMount() {
    
    if (this.props?.location?.state?.transactionCode){
      const benefitTransactionCode = this.props.location.state.transactionCode.replace("/", "")
      const params = {"json_data":[{"field":"code","value":benefitTransactionCode}]}
      console.log("____CODE", benefitTransactionCode)
      this.setState({transactionCode:benefitTransactionCode})
      this.getData(params)
    } else {
      this.goBack();
    }
    
    //const params = this.props.match.params;
    //
    //if (params.id){
    //  this.getVolunteering(params);
    //} else {
    //  this.goBack();
    //}
    //const state = this.props.location.state;
    //this.setState({ route: {
    //    pathname: !isEmptyObject(state) ? state.from : config.ROUTES.HOME,
    //    state: { from: config.ROUTES.VOLUNTEERING.DETAIL }
    //  }
    //});
  }
  componentWillUnmount() {}

  gotoBack = () => {
    history.push(config.ROUTES.BENEFIT_CONSUME);
  }

  gotoError = (reason=null) => {
    this.setState({transactionFailure: true})
    history.push({
      pathname: config.ROUTES.BENEFITS_CONSUME,
      state: {error: true,reason}
    });
  }

  getBusinesses = async (params) => {
    await this.props.onGetAllBusinesses(params);
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      console.log("___________busi test",businesses.items)
      this.setState({ businesses: businesses.items });
    }    
  }  

  getData = async (params) => {
    await this.getBusinesses({owner: getOwner(this.props.auth.user)})
    await this.getTransaction(params);
    await this.getHistorialTransactions();
    await this.getUserLevel();
    this.setState({ route: {
      pathname: config.ROUTES.VOLUNTEERING_ATTENDANCE,
      state: { from: config.ROUTES.VOLUNTEERING_ATTENDANCE }
      }
    })
  }

  getUserLevel = async () => {
    if(this.state.benefitUser){
      let params = {id: this.state.benefitUser.id}
      await this.props.onGetRewards(this.state.benefitUser.id);
      const { rewards } = this.props
      if (rewards.error) {
        notify(this.t("Error obteniendo nivel"));
      } else {
        console.log("levle",rewards.level)
        //FIXME this.state.userLevel a get level
        const user_current_level_benefit = getRewardFromLevel(rewards.level.toString(), this.state.benefit.json_data)
        this.setState({ userLevel: rewards.level.toString(), user_current_level_benefit })
      }
    }
  }

  getTransactionPre = async (params) => {
    await this.props.onGetTransaction(params);
    let {transaction} = this.props;
    if (transaction.error){
      notify(this.t(transaction.error.message));
      this.gotoError(`Error al chequear código: ${transaction.error.message}`)
    } else {
      this.setState({preTransaction:transaction})
    }
  }


  getComapany = async (id) => {
    await this.props.onGetCompany(id);
    let {company} = this.props;
    if (company.error){
      notify(this.t(company.error.message));
      this.gotoError(`Error al chequear código: ${company.error.message}`)
    } else {
      return company.item
    }
  }

  getTransaction = async (params) => {
    //FIXME: esto es un fix rápido para que el componente no se ejecute con una transaccion undefined, despues corregir
    await this.getTransactionPre(params);
    if(this.state.preTransaction){
      let transaction = {...this.state.preTransaction};
      if (transaction.error){
        notify(this.t(transaction.error.message));
        this.gotoError(`Error al chequear código: ${transaction.error.message}`)
      } else {
        console.log("____transaction.items.length", transaction.items.length)

        if(transaction.items.length === 0){
          this.gotoError("Código inexistente")
          return
        }
        if(transaction.items.length !== 1){
          this.gotoError("Multiples transacciones con el mismo código")
          return
        }
        

        transaction = {...transaction.items[0]}

        if(transaction === undefined) return
        
        //if(transaction.json_data?.business?.owner != this.props.auth.user.id){
        //  this.gotoError("No corresponde a esta business")
        //  return
        //}

        //FIXME: arreglar tema de owners
        //FIXME: traer todos los businesses que tengan de owner this.props.auth.user.id 
        //de ahi comparar si alguno es el owner de este benefit
        //if(transaction.json_data?.benefit?.owner != this.props.auth.user.id){
        //  if(transaction.json_data?.benefit?.owner != "system"){
        //    this.gotoError("No corresponde a este negocio")
        //    return
        //  }
        //}
        console.log("____transaction.items.length", transaction.json_data?.benefit?.owner)
        if(!transaction.json_data?.benefit?.owner){
            this.gotoError("No corresponde a este negocio 1")
            return
        }

        console.log("____busines", this.state.businesses.map(b => b.id), transaction.json_data?.benefit?.owner)
        
        console.log("_________check 2", getOwner(this.props.auth.user), this.state.businesses)
        if(transaction.json_data?.benefit.sub_type === "user" || transaction.json_data?.benefit.sub_type === "both"){
          if(!(this.state.businesses.map(b => b.id).includes(transaction.json_data?.benefit?.owner))){
            if(transaction.json_data?.benefit?.owner != "system"){//&& transaction.json_data?.benefit?.type != "prize" FIXME
              console.log("ERROR","No corresponde a este negocio")
              this.gotoError("No corresponde a este negocio")
              return
            }
          }          
        }

        if(transaction.sub_type === "prize"){
          let company = await this.getComapany(transaction.json_data?.benefit?.owner)
          console.log("_________check", company.owner, getOwner(this.props.auth.user))
          if(company.owner !== getOwner(this.props.auth.user)){
            console.log("ERROR","No corresponde a este negocio")
            this.gotoError("No corresponde a esta empresa")
            return            
          }
        }

        if(transaction.status === config.TRANSACTIONS.INITIATIVE.STATUS.CONSUMED){
          this.gotoError("El beneficio ya fue usado")
          return
        }

        this.setState({ 
          benefitTransaction: transaction,
          benefitUser: transaction.json_data?.user,
        });

        await this.getBenefit(transaction.json_data?.benefit?.id);
      } 
    }
  }

  getBenefit = async id => {
    this.setState({ loading: true });
    await this.props.onGetBenefit(id);
    const error = this.props.benefit.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({
        benefit: this.props.benefit.item,
      });
    }
    this.setState({ loading: false });
  };


  getHistorialTransactions = async () => {
    if(this.state.benefit){
      let params = {status:config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED, target:this.state.benefit?.id, type:config.TRANSACTIONS.BENEFIT.TYPE}

      await this.props.onGetTransaction(params)
      let {transaction} = this.props
      if (transaction.error){
        notify(this.t(transaction.error.message));
        return false
      } else {
        if(transaction.items.length){
          let all_transactions = [...transaction.items]
          let my_transactions = transaction.items.filter(t => t.created_by === this.state.benefitUser.id)
          this.setState({historialTransactions: my_transactions})
        }
      }
    }

  }

  checkTransactions = async (rules) => {
    let params = {status:config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED, target:this.state.benefit?.id, type:config.TRANSACTIONS.BENEFIT.TYPE}
    console.log("getTransaction::", params)
    await this.props.onGetTransaction(params)
    let {transactions} = this.props
    console.log("ERROR::", transactions.error)
    if (transactions.error){
      notify(this.t(transactions.error.message));
      return [transactions.error.message]
    } else {
      console.log("TRX::", transactions)
      if(transactions.items.length){
        console.log("TRX::", "OK")
        let all_transactions = [...transactions.items]
        let my_transactions = transactions.items.filter(t => t.created_by === this.state.benefit?.json_data?.user?.id)
        console.log("TRX::", "OK", my_transactions)
        return validateBenefitsTransactionsArray(all_transactions, my_transactions, rules, )
      }

      return []
    }

    return []
  }



  sendMailUser = async () => {
    let subject = this.state.benefit?.type === "prize" ? "Utilizaste un premio" : "Utilizaste un descuento"
    let mailData = {
      template: this.state.benefit?.type === "prize" ? "benefit_prize_user" : "benefit_user",
      locale: "es",
      to:this.state.benefitUser.email,
      first_name: this.state.benefitUser.first_name,
      last_name:  this.state.benefitUser.last_name,
      params: {
          subject,
          benefit_name: this.state.benefit.name,
          user_name: `${this.state.benefitUser.first_name} ${this.state.benefitUser.last_name}`,
      }
    }
    
    await this.props.onSendMail(mailData);
    const internals = this.props.internals;
    if (internals.error) {
      notify(this.t(internals.error.message));
    }
  }

  sendMailOwner = async () => {
    let subject = "Confirmación de descuento"
    let mailData = {
      template: "benefit_owner",
      locale: "es",
      to:this.props.auth.user.email,
      first_name: this.props.auth.user.first_name,
      last_name:  this.props.auth.user.last_name,
      params: {
          subject,
          benefit_name: this.state.benefit.name,
          business_owner_name: `${this.props.auth.user.first_name}`,
          benefit_user_name: `${this.state.benefitUser.first_name} ${this.state.benefitUser.last_name}`,
      }
    }
    
    await this.props.onSendMail(mailData);
    const internals = this.props.internals;
    if (internals.error) {
      notify(this.t(internals.error.message));
    }
  }

  confirmAttendance = async () => {
    this.setState({loading:true})
    //FIXME: activar
    //if(config.VALIDATE_BENEFTIS){
    //if(false){
      if (this.state.benefitTransaction.status === config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED) {
        history.push({
          pathname: config.ROUTES.BENEFITS_CONSUME,
          state: {success: false}
        });
      }
    let { benefit, benefitTransaction} = this.state
    let transactionCheckArray = await this.checkTransactions(benefit.json_data.rules)
    let rulesCheckArray = validateBenefitRules(benefit)
    
    let unavBenefitReasons = [...transactionCheckArray, ...rulesCheckArray]

    //otros check
    if(benefit.type === "prize"){
      if (!benefitTransaction.json_data?.isChallengePrize && parseInt(benefit.json_data?.required_level) > parseInt(this.state.userLevel)) {
        unavBenefitReasons = [...unavBenefitReasons, `No tiene nivel suficiente. Nivel minimo requerido: ${benefit.json_data?.required_level}`];
      }
    } else {
      if(!benefitTransaction.json_data?.isChallengePrize && parseInt(this.state.userLevel) === 0){
        unavBenefitReasons = [...unavBenefitReasons,`No tiene nivel suficiente. Nivel minimo requerido: 1`]
      }
    }

    if(!benefitTransaction.json_data?.isChallengePrize && !benefit.enabled){
      unavBenefitReasons = [...unavBenefitReasons, `beneficio inactivo`]
    }

    if(unavBenefitReasons.length){
      notify(`Beneficio no válido: ${unavBenefitReasons.join("/")}`);
      this.setState({loading:false})
      return
    }
    //}

    let today = new Date()
    let data = {
      id: this.state.benefitTransaction.id,
      status:config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED,
      date: today.toISOString(),
      json_data:{
        consumed_level: this.state.user_current_level_benefit
      }
    }
    
    await this.props.onSaveTransaction(data);
    console.log("TRX::", data)
    const {transaction} = this.props
    if (transaction.error) {
      notify(this.t(transaction.error.message));
      this.setState({loading:false})
    } else {
      console.log("TRX::", "OK")
      await this.sendMailUser()
      //await this.sendMailOwner()
      const state = {
        title: "Beneficio consumido",
        headline: "",
        button:{
          text: "Continuar",
          route: config.ROUTES.BENEFITS_CONSUME,
        },
      }

      this.gotoSuccess()
    }
  }

  gotoSuccess = () => {
    history.push({
      pathname: config.ROUTES.BENEFITS_CONSUME,
      state: {success: true}
    });
  }

  goBack = () => {
    history.push(config.ROUTES.BENEFITS_CONSUME)
  }
  
  getBenefitValuesArray = (benefit) => {
    if(!benefit?.json_data?.values) return []

    const percentage = benefit.json_data?.percentage
    return Object.keys(benefit.json_data?.values).map(k => {
      return {level: parseInt(k)+1, value: `${benefit.json_data?.values[k].value}${percentage ? "%" : ""}`}  
    }) 
  }

  showMoreInfo = () => {
    this.setState({showInfo: !this.state.showInfo})
  }

  render() {
    const { benefit, benefitUser } = this.state;
    const benefitValues = this.getBenefitValuesArray(benefit)
    
    return (
     <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: null
        }}
        container={{ className: 'px-8' }}
        actions={null}
        loading={null}>
      <LayoutSmall
        main={{ className: (!benefit ? "bg-primary" : "") }}
        header={{ 
          className: `bg-transparent fixed text-primary`,
          left: { icon: 'arrow_left', action: this.goBack },
          /* right: { icon: 'heart'} */
        }}
        container={{ className: "px-0"}}
        loading={this.props.benefit.loading}
      > 
        <ToastContainer/>
        {this.state.transactionFailure && 
          <div className="bg-white shadow-lg">
            <div className="max-w-md mx-auto py-24">
              <p>Error al confirmar asistencia, revise que el código ingresado sea correcto</p>
              <button className="btn-secondary btn-block" onClick={this.gotoBack}>Atrás</button>
            </div>
          </div>}
        {benefit && (
          <div className='max-w-md'>
            <div className="bg-white shadow-lg">
                <div className="p-3">
                  <img src={benefit && benefit.json_data.picture} className="max-h-26 mx-auto" alt="" />
                </div>
                <div className="container p-4">  
                  <h1 className="">{benefit.name}</h1>
                    <div className="flex items-center gap-3 pb-4 mt-2">        
                      {this.state.user_current_level_benefit && <div className="badge badge-primary inline-block">{this.state.user_current_level_benefit}%</div>}
                    </div>
                </div>
              </div>
              <div className="collapse collapse-arrow items-center" onClick={this.showMoreInfo}>
                <div class="collapse-title text-xl font-medium">
                  {this.state.showInfo ? "Ocultar" : "Ver info extra"}
                </div>
            </div>

            {this.state.showInfo &&
            <section className="p-4 max-w-md mx-auto">

              <div>
                <h4 className="mb-1" >{this.t("Descripción")}</h4>
                <MultiLineTextDisplay className="mb-5" text={benefit.detail}/>
                <h4 className="mb-1" >{this.t("Dinámica para usar descuento")}</h4>
                <MultiLineTextDisplay className="mb-5" text={benefit.json_data.instructions}/>
                <h4 className="mb-1">{this.t("Aclaraciones")}</h4>
                <MultiLineTextDisplay className="mb-5" text={benefit.json_data.extra_info}/>

                <h4>{this.t("Descuentos por nivel")}</h4>
                <div className="grid gap-2 grid-cols-3 mt-2 mb-4">
                  {benefitValues.map((b, index) => {
                    return (
                      <div className={"card white p-2 text-center " + (this.state.userLevel === (index + 1).toString() ? "border border-primary" : "")}>
                        <p className="mb-1">{this.t("Nivel")} {b.level}</p> 
                        <span className="badge badge-primary">{b.value}</span>
                      </div>
                    )
                  })}
                </div>
                <h4 className="mb-1">{this.t("Período de validez")}</h4>
                {(benefit.json_data?.rules?.start_date && benefit.json_data?.rules?.end_date) && <p>Desde el {getDateFirstPart(benefit.json_data.rules.start_date)} hasta el {getDateFirstPart(benefit.json_data.rules.end_date)}</p>}
                {(benefit.json_data?.rules?.start_date && !benefit.json_data?.rules?.end_date) && <p>Desde el {getDateFirstPart(benefit.json_data.rules.start_date)}</p>}
                {(!benefit.json_data?.rules?.start_date && benefit.json_data?.rules?.end_date) && <p>Hasta el {getDateFirstPart(benefit.json_data.rules.end_date)}</p>}
                {(!benefit.json_data?.rules?.start_date && !benefit.json_data?.rules?.end_date) && <p>Disponible cualquier fecha</p>}
                {benefit.json_data?.rules?.days_of_week && false && <p>Los días {daysOfWeekText(benefit.json_data.rules.days_of_week).join(" ")} o hasta agotar stock</p>}
                {benefit.json_data?.rules?.days_of_week ? <p>{daysOfWeekToString(benefit.json_data.rules.days_of_week)}</p> : <p>Disponible todos los días de la semana</p>}
              </div>
            </section>}
              
            {benefitUser && (
              <section className="container-fluid p-4 text-center">
                <div className="p-4 flex flex-col items-center">
                  <UserProfileImage data={benefitUser} picture={{ onClick: () => {} }}/>
                  <p className="">Nivel: {this.state.userLevel}</p>
                </div>
              </section>
            )}
            <h3>Historial de usos:</h3>
            {this.state.historialTransactions.map(h => <div>{(new Date(h.date)).toLocaleString()} - Valor: {h.json_data.consumed_level}%</div>)}
            <div className="mt-4 p-4">
              <Button
                disabled = {this.state.loading || (parseInt(this.state.userLevel) === 0)}
                className="btn btn-secondary btn-block"
                title={this.t("Confirmar consumición")}
                onClick = {this.confirmAttendance}
              />
            </div>
            {(parseInt(this.state.userLevel) === 0) && <p>El usuario no tiene nivel para usar el beneficio.</p>}
          </div>
        )}
      </LayoutSmall>
      </LayoutWithSidebar>
    ) 
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth, 
    benefit: state.benefits.current,
    assistant: state.assistants.current,
    assistants: state.assistants.list,
    transaction: state.transactions.list,
    transactions: state.transactions.list,
    internals: state.internals,
    businesses: state.businesses.list,
    rewards: state.users.auth.user.rewards,
    company: state.companies.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetTransaction: (params) => dispatch(transactionsAction.getAll(params)),
    onSaveTransaction: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    //onGetTransaction: (params) => dispatch(transactionsAction.get(params)),
    onChangeTransactionState: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
    onGetAllBusinesses: (params) => dispatch(businessesActions.getAll(params)),
    onGetRewards: (params) => dispatch(userActions.getRewards(params)),
    onGetCompany: (id) => dispatch(companiesActions.get(id)),
    onGetBenefit: id => dispatch(benefitsActions.get(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(BenefitConsumeConfirmation));
