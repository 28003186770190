import types from './types'; 

const reducer = (state = {
  lang: '',
  strings: {},
  loading: false,
}, action) => {
  // console.log('::::::REDUCER::LOCALIZATION::', action);
  switch(action.type) {
    case types.LOADING_LANGUAGE:
      return { ...state,
        loading: true,
      };
    case types.SET_STRINGS:
      return { ...state,
        strings: action.strings,
        loading: false,
      };
    case types.SET_LANGUAGE: 
      return { ...state,
        lang: action.langCode,
      };
    default:
      return state;
  }
};

export default reducer;