import React, { useCallback, useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import companiesActions from "../../context/companies/actions"
import trxActions from "../../context/transactions/actions"
import StyledDataTable from "../commons/StyledDataTable"
import userActions from "../../context/users/actions"
import paymentsActions from "../../context/payments/actions"
import settingActions from "../../context/settings/actions"

import config from '../../config';

import { calculateBalanceFromTrx, getCreditType, getCreditsFromMoney, getMoneyFromCredits } from "../../libraries/utils"
import Loader from "../commons/Loader"

function CompanyHistory({
  onGetAllTrx,
  onGetCompaniesByOwner,
  auth,
  companies,
  trx,
  companyID,
  onGetSettings,
  onCoin,
  payments,
  settings
}) {
  const [allTrx, setAllTrx] = useState()
  const [sort, setSort] = useState({})
  const [loading, setLoading] = useState(true)
  const [balance, setBalance] = useState(undefined)
  const [reservedBalance, setReservedBalance] = useState(undefined)

  const requestedAll = useRef(false)

  const [maslow_factor, setMaslowFactor] = useState(null)
  const [balancePesos, setBalancePesos] = useState(null)
  const [dolarCCL, setDolarCCL] = useState(null)
  const [cotizacion, setCotizacion] = useState(null)

  let companyId = companyID
  if (!companyID) {
    companyId = companies.item ? companies.item[0].id : null
  }

  const getSettings = useCallback(() => {
    const params = {
      type: config.TYPES.SETTINGS.SHARYCO_ADMIN_SETTINGS,
      owner: config.OWNER.SYSTEM,
    };
    onGetSettings(params);
  }, [onGetSettings])

  const getUSD = useCallback(() => {
    onCoin();
  }, [onCoin])

  useEffect(() => {
    if (settings) {
      if (settings.error) {
        // notify(this.t(settings.error.message));
      } else {
        const maslow = settings.items.find(s => s.code === 'maslow_factor');
        if (maslow) {
          setMaslowFactor(+maslow.value)
        }
      }
    }
  }, [settings])

  useEffect(() => {
    if (payments) {
      if (payments.error) {
        // notify(this.t(payments.error.message));
      } else {
        setDolarCCL(payments.payments.data.venta)
      }
    }
  }, [payments])

  const pageTrx = allTrx ? trx.items.filter((t) => getCreditType(t, companyId) !== "other") : null

  const requestCompanies = useCallback(() => {
    onGetCompaniesByOwner(auth.user.accounts[0].id)
  }, [onGetCompaniesByOwner, auth])

  const requestPageTrx = useCallback(
    async currentPage => {
      let params = {
        type: "credits",
        status: ["paid", "archived"],
        page_num: currentPage,
        page_size: trx.query?.page_size,
        owner: companyId,
      }
      if (sort) {
        params.order_by = sort.order_by
        params.order_direction = sort.order_direction
      }

      await onGetAllTrx(params)
    },
    [onGetAllTrx, sort, trx.query?.page_size, companyId]
  )

  const requestAllTrx = useCallback(() => {
    onGetAllTrx({
      type: "credits",
      status: "paid",
      owner: companyId,
    })
    requestedAll.current = true
  }, [onGetAllTrx, companyId])

  //First, request company id.
  useEffect(() => {
    if (!companyId) {
      requestCompanies()
    }
    getUSD()
    getSettings()
  }, [companyId, requestCompanies])

  //Second, request all trx.
  useEffect(() => {
    if (companyId) {
      if (!requestedAll.current)
        requestAllTrx()
      else if (!allTrx) {

        const trxWithnNoOther = trx.items.filter((t) => getCreditType(t, companyId) !== "other")
        setAllTrx(trxWithnNoOther)
      }
    }
  }, [trx.items, allTrx, setAllTrx, requestAllTrx, companyId])

  //Third, calculate balance over all trx.
  useEffect(() => {
    if (allTrx) {
      const { balance, reservedBalance } = calculateBalanceFromTrx(allTrx, companyId)
      setBalance(balance === 0 ? "empty" : balance)
      setReservedBalance(reservedBalance === 0 ? "empty" : reservedBalance)
      setLoading(false)
    }
  }, [allTrx])

  //Fourth, request first page.
  // useEffect(() => {
  //   if (allTrx && balance){
  //     requestPageTrx(1).then(() => {
  //       setLoading(false)
  //     })
  //   }
  // }, [allTrx, balance, requestPageTrx])

  useEffect(() => {
    let balancePesos = 0;
    if(maslow_factor && dolarCCL && !cotizacion) {
      setCotizacion(getMoneyFromCredits(1, +maslow_factor, +dolarCCL));
    }
    if ((balance && maslow_factor && dolarCCL) && balance !== 'empty') {
      balancePesos = getMoneyFromCredits(+balance, +maslow_factor, +dolarCCL);
    }
    setBalancePesos(balancePesos === 0 ? "empty" : balancePesos)
  }, [balance, dolarCCL, maslow_factor])

  async function customSort(field, order, currentPage) {
    setSort({ order_by: `transactions__${field}`, order_direction: order })
    requestPageTrx(currentPage)
  }

  // ** Columns configuration for our data table
  const columns = [
    {
      name: "Fecha",
      sortable: true,
      maxWidth: "12vw",
      selector: row => row.created_at.toLocaleString("es-AR"),
      sortSelector: "created_at",
    },
    {
      name: "Tipo",
      sortable: true,
      selector: row => {
        const type = getCreditType(row, companyId)
        const getClassNames = () => {
          if (type === "income") return "text-green-700 bg-green-100"
          if (type === "reserved") return "text-yellow-700 bg-yellow-100"
          if (type === "archived") return "text-gray-400 bg-gray-100"
          if (type === "egress") return "text-red-700 bg-red-100"
          if (type === "other") return "text-gray-700 bg-gray-100"
          if (type === "award") return "text-blue-700 bg-blue-100"
          return "text-gray-700 bg-gray-100"
        }
        const getName = () => {
          if (type === "income") return "Ingreso"
          if (type === "reserved") return "Reservado"
          if (type === "archived") return "Archivado"
          if (type === "egress") return "Egreso"
          if (type === "award") return "Premio"
          if (type === "other") return "Otro"
          return "Desconocido"
        }
        return (
          <p className={getClassNames() + " text-center py-1 px-2 w-full rounded-md"}>
            {getName()}
          </p>
        )
      },
      sortSelector: "target",
      maxWidth: "8vw",
    },
    {
      name: "Razón",
      sortable: true,
      selector: row => row.json_data?.reason || "Sin razón",
      sortSelector: "reason",
      maxWidth: "18vw",
    },
    {
      name: "Destino",
      selector: row => (
        <span className="capitalize">
          {row.json_data?.winner?.name
            ? `Colaborador: ${row.json_data?.winner?.name}`
            : row.json_data?.challenge?.name
              ? `Desafío: ${row.json_data?.challenge?.name}`
              : row.json_data?.user?.first_name
                ? `Colaborador: ${row.json_data?.user?.first_name} ${row.json_data?.user?.last_name}`
                : "————"
          }
        </span>
      ),
      sortSelector: "winner",
      maxWidth: "25vw",
    },
    {
      name: "Cantidad",
      sortable: true,
      selector: row => {
        const getClassNames = () => {
          const type = getCreditType(row, companyId)
          if (type === "income") return "text-green-700"
          if (type === "reserved") return "text-yellow-700"
          if (type === "archived") return "text-gray-400"
          if (type === "egress") return "text-red-700"
          if (type === "other") return "text-gray-700"
          return "text-gray-700"
        }
        return (
          <p className={getClassNames()}>
            {row.total}
          </p>
        )
      },
      sortSelector: "total",
      maxWidth: "10vw",
    },
  ]
  return (
    <>
      <div className="w-full md:w-1/2 mt-8 mx-auto py-6 bg-gray-200 text-center rounded-lg">
        {
          loading ? <Loader /> :
            <>
              <p className="text-xl font-bold">
                {balance ? "Balance actual de la empresa" : "Cargando..."}
              </p>
              <p className="mt-4 text-5xl font-bold text-green-700">
                {balance
                  ? isNaN(balance)
                    ? 0
                    : `${(balance + (reservedBalance !== 'empty' ? reservedBalance : 0)).toLocaleString("es-AR")} `
                  : "..."}
                <small className="mt-2 text-3xl text-green-700">
                  {balance
                    ? isNaN(balance)
                      ? ""
                      : `créditos`
                    : "..."}
                </small>
              </p>
              <p className="mt-2 text-lg text-yellow-700">
                {reservedBalance
                  ? isNaN(reservedBalance)
                    ? ""
                    : `Reserva: ${reservedBalance.toLocaleString("es-AR")} créditos`
                  : "..."}
              </p>
              <p className="text-lg font-bold mt-5">
                {balancePesos ? "El valor en dolares disponible es:" : "Cargando..."}
              </p>
              <p className="mt-2 text-3xl font-bold text-green-700">
                {balancePesos
                  ? isNaN(balancePesos)
                    ? 0
                    : `${config.MONEY_CODE} ${balancePesos.toLocaleString("es-AR")}`
                  : "..."}
              </p>
              <p className="text-md font-bold mt-5">
                {dolarCCL && maslow_factor ? `1 crédito = ${config.MONEY_CODE} ${cotizacion}` : ''}
              </p>
            </>
        }
      </div>
      <p className="mt-12 font-bold text-lg self-start">
        Transacciones de la empresa
      </p>
      <div className="w-full mt-4">
        <StyledDataTable
          data={pageTrx}
          columns={columns}
          selectableRows={window.innerWidth > 600}
          getDataFunction={requestPageTrx}
          query={trx.query}
          pagination
          loading={loading}
          screenWidth={window.innerWidth}
          customSort={customSort}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    trx: state.transactions.list,
    companies: state.companies.current,
    settings: state.settings.list,
    payments: state.payments
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCoin: (params) => dispatch(paymentsActions.onCoin(params)),
    onGetAllTrx: params => dispatch(trxActions.getAll(params)),
    onGetCompaniesByOwner: params =>
      dispatch(companiesActions.getByOwner(params)),
    onGetBalance: params => dispatch(userActions.getBalance(params)),
    onGetSettings: (params) => dispatch(settingActions.getAll(params)),
    onCoin: (params) => dispatch(paymentsActions.onCoin(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CompanyHistory))
