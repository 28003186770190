import React from 'react';
import Icon from '../../libraries/icons';

const TextareaInput = ({ input, meta, label, ...rest }) => {
  return (
    <div className={"form-control " + (rest.className ? rest.className : "")}>
      {label && (
        <label htmlFor={input.name} className={"relative label " + (rest.labelClassName ? rest.labelClassName : "")}>
          <span className="label-text flex gap-2">{label}
          {rest.info &&
            <span
              className="ml-1 font-normal pop-up  after:content-[attr(after)]"
              after={rest.info}
            >
              <Icon name="information_solid" className="h-4 w-4" />
            </span>
          }
          </span>


        </label>
      )}
      <textarea
        {...input}
        value={(input.value && rest.readOnly && typeof(input.value) == 'string') ? input.value.replaceAll("<br>", "\n") : (typeof(input.value) == 'string' && (meta.active ? input.value.replaceAll("<br>", "\n") : input.value)) }
        className={"textarea  "
          + (meta.error && meta.touched ? "is-invalid invalid-shadow" : "")
          + (rest.inputClassName ? rest.inputClassName : "h-24 input-bordered")
        }
        placeholder={rest.placeholder}
        disabled={rest.disabled}
        readOnly={rest.readOnly}
        data-gramm="false"
      ></textarea>
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">{meta?.error && meta?.touched && meta?.error}</span>
        </label>
      )}
    </div>
  )
}

export default TextareaInput;