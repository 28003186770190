import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { fromISO } from '../../libraries/utils';

import "react-datepicker/dist/react-datepicker.css";
import Icon from '../../libraries/icons';

registerLocale('es', es)

const DatepickerInput = ({ input, meta, label, locale, isClearable,showYear, ...rest }) => {
  let date = Date.parse(input.value);
  const value = typeof input.value === 'string' && input.value.includes('T') ? fromISO(input.value) : input.value;
  return (
    <div className={"form-group " + rest.className ? rest.className : ""}>
      {label && (
        <label htmlFor={input.name} className={"relative label " + (rest.labelClassName ? rest.labelClassName : "")}>
          <span className="label-text flex gap-2">{label}
          {rest.info &&
            <span
              className="ml-1 font-normal pop-up  after:content-[attr(after)]"
              after={rest.info}
            >
              <Icon name="information_solid" className="h-4 w-4" />
            </span>
          }
          </span>


        </label>
      )}
    
      <div
        className={"form-control relative" + ((meta.touched || meta.data.touched) && meta.error ?
          " is-invalid invalid-shadow focus-within:invalid-shadow-focus" :
          " focus-within:outline-none focus-within:shadow-outline focus-within:bg-white")
        }
      >
        <DatePicker
          {...input}
          value = {value}
          dateFormat="dd/MM/yyyy"
          selected={date}
          showPopperArrow={false}
          locale={locale}
          // customInput={<CustomInput />}
          className="pl-12 outline-none input w-full"
          isClearable={isClearable && !rest.disabled}
          onBlur={(event) => input.onBlur(event)}
          disabled= {rest.disabled}
          readOnly= {rest.readOnly}
          placeholderText={rest.placeholder}

          showYearDropdown={showYear}
          maxDate={showYear && new Date()}
          dateFormatCalendar={"MMMM"}
          yearDropdownItemNumber={100}
          scrollableYearDropdown
        />
        <div className="absolute top-1 left-0 px-3 py-2">
          <svg className="h-6 w-6 text-gray-400"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
          </svg>
        </div>      
      </div>
      <p className="invalid-feedback">{(meta.touched || meta.data.touched) && meta.error && meta.error}&nbsp;</p>
    </div>    
  )
}

export default DatepickerInput;

