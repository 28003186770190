import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import LayoutHalfScreen from '../../components/layout/LayoutHalfScreen';
import logo from '../../assets/img/logo-negative.png';



class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      password: false,
    }
  }
  
  componentDidMount() {
  }
  
  onSubmit = async values => {
    const data = { ...values };
    await this.props.onForgot(data);
    const error = this.props.auth.error;
    if (error) { 
      notify(this.t(error.message));
    } else {
      history.push(config.ROUTES.LOGIN);        
    }
  }


  render() {

    const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"))
    const email = value => (validateEmail(value) ? undefined : this.t("Not a valid email"))

    return (
      <LayoutHalfScreen
        header={{ 
          className: 'text-primary',
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.LOGIN)}
        }}
        side={{ 
          className: "bg-primary flex items-center h-full justify-center", 
          imageClassName: " ", 
          image: logo /*images[`slide${this.slide}`]*/ ,
          
        }}
        // header={{ logo: true }}
      >
        <ToastContainer/>
        <h2 className="h2 text-primary-100 text-center mb-20">{this.t("Reset password")}</h2>
        <Form onSubmit={this.onSubmit}>
          {({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto px-4">
              <div className="w-full mb-3 md:mb-0">
                <Field name="email" component={TextInput} placeholder={this.t("Email")} label={this.t("Ingresé su correo")} validate={composeValidators(required, email)} parse={ v => v.trim() }/>
              </div>
              <Button
                className="btn-primary btn-block mt-5"
                title={this.t("Send")}
                onClick={handleSubmit}
              />
            </form>
          )}
        </Form>
      </LayoutHalfScreen>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onForgot: (values) => dispatch(userActions.forgot(values)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Forgot));

