import clsx from "clsx"
import React, { useCallback, useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import Icon from "../../libraries/icons"
import {
  assignRanksToItems,
  getSurroundingItems,
  getSurroundingRankAsc,
  sortByLexoRankAsc,
} from "../../libraries/lexorank-helper"
import Button from "../commons/Button"


// TODO: Agregar memo así no se envien tantos requests al mover cada elemento
function OrderComponent({
  dataList = [],
  onSubmit = async () => {},
  loading = true,
  saveOnMove = false,
  resetData = false,
}) {
  const [itemsList, setItemsList] = useState(assignRanksToItems(dataList))
  const [prevItemsList, setPrevItemsList] = useState(assignRanksToItems(dataList))
  const [updatedItemsId, setUpdatedItemsId] = useState([])
  const [isSaving, setIsSaving] = useState(false)

  const [triggerSave, setTriggerSave] = useState(false)

  useEffect(() => {
    setItemsList(() => assignRanksToItems(dataList))
    setPrevItemsList(() => assignRanksToItems(dataList))
    setUpdatedItemsId([])
    setTriggerSave(false)
  }, [dataList])

  const onDragComplete = result => {
    if (!result.destination || result.destination.index === result.source.index) return

    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index

    setUpdatedItemsId(old => [...old, itemsList[sourceIndex].id])
    setItemsList(oldItems => {
      const surroundingData = getSurroundingItems(
        oldItems,
        sourceIndex,
        destinationIndex
      )

      const newRank = getSurroundingRankAsc(surroundingData)

      const newItems = oldItems.map(item => {
        if (item.id === surroundingData.entity.id) {
          return { ...item, order_index: newRank.toString() }
        }
        return item
      })
      if (saveOnMove) setTriggerSave(true)

      return newItems.sort(sortByLexoRankAsc)
    })
  }
  const removeDuplicateId = arr => {
    const uniqueIds = []
    const unique = arr.filter(element => {
      const isDuplicate = uniqueIds.includes(element?.id)
      if (!isDuplicate) {
        uniqueIds.push(element?.id)
        return true
      }
      return false
    })
    return unique
  }

  const hasTheSameValueAndIndexValidation = useCallback(
    arr => {
      return arr.filter(item => {
        const prevItemIndex = prevItemsList.findIndex(i => i?.id === item?.id)
        const prevItem = prevItemsList[prevItemIndex]
        const currentItemIndex = itemsList.findIndex(i => i?.id === item?.id)
        return (
          item?.order_index !== prevItem?.order_index &&
          prevItemIndex !== currentItemIndex
        )
      })
    },
    [itemsList, prevItemsList]
  )

  const saveItem = useCallback(async () => {
    setIsSaving(true)
    let requestItem = []

    updatedItemsId.forEach(id => {
      const item = itemsList.find(item => item.id === id)
      requestItem.push(item)
    })
    requestItem = removeDuplicateId(requestItem)
    requestItem = hasTheSameValueAndIndexValidation(requestItem)

    if (requestItem.length > 0) {
      await onSubmit(requestItem)
      // await sleep(3000)
      setUpdatedItemsId([])
    }
    setIsSaving(false)
  }, [hasTheSameValueAndIndexValidation, itemsList, onSubmit, updatedItemsId])

  useEffect(() => {
    if(!saveOnMove) return
    if(!triggerSave) return
    saveItem()
    setTriggerSave(false)

  }, [saveOnMove, saveItem, triggerSave])

  // solo usar si hay un error
  const onReset = async () => {
    const resetedData = assignRanksToItems(dataList)
    await onSubmit(resetedData)
  }

  const skeletonItems = Array.from({ length: 5 }, (_, index) => ({
    id: `skeleton-${index}`,
    name: "",
    // order_index: "",
  }))

  const hasUpdatedItems = updatedItemsId.length > 0

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div className="flex justify-between items-center pb-4">
        <h2 className="text-xl font-bold ">Puedes arrastrar las acciones </h2>
        <div className="flex gap-2">
          {!saveOnMove && (
            <Button
              onClick={saveItem}
              className="btn-ghost btn-outline"
              icon="loading"
              title={isSaving ? "Guardando" : "Guardar"}
              disabled={loading || !hasUpdatedItems || isSaving}
            />
          )}
          {resetData && (
            <Button
              onClick={onReset}
              className="btn-ghost btn-outline"
              icon="loading"
              title={isSaving ? "Resetenado" : "Resetear Datos"}
              // disabled={loading || !hasUpdatedItems || isSaving}
            />
          )}
        </div>
      </div>

      <DragDropContext onDragEnd={onDragComplete}>
        <Droppable droppableId="drag-drop-list">
          {(provided, snapshot) => {
            return (
              <div
                className="bg-gray-200 p-4"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {(loading ? skeletonItems : itemsList).map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={isSaving}
                  >
                    {provided => (
                      <div
                        className={clsx(
                          "flex items-center justify-between bg-white p-4 rounded shadow-md mb-2 max-w-full w-full",
                          loading && "animate-pulse",
                          isSaving && "animate-pulse text-slate-300"
                        )}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="flex items-center gap-2 max-w-[80%]">
                          <Icon name="drag" size="22px" />
                          {!loading && (
                            <p className="text-sm truncate ... ">{item.name}</p>
                          )}
                          {loading && (
                            <div className="w-[60ch]  h-5  bg-slate-200 rounded-md" />
                          )}
                        </div>
                        <code className="text-sm">{item.order_index}</code>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default OrderComponent
