const types = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  FORGOT: "USERS_LOGIN_FORGOT",
  LOGOUT: "USERS_LOGOUT",
  LOGGED: "USERS_LOGGED",

  VERIFY_REQUEST: "USERS_VERIFY_REQUEST",
  VERIFY_SUCCESS: "USERS_VERIFY_SUCCESS",
  VERIFY_FAILURE: "USERS_VERIFY_FAILURE",

  VERIFY_RETRY_REQUEST: 'USERS_VERIFY_RETRY_REQUEST',
  VERIFY_RETRY_SUCCESS: 'USERS_VERIFY_RETRY_SUCCESS',
  VERIFY_RETRY_FAILURE: 'USERS_VERIFY_RETRY_FAILURE',

  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  CLEAR_CURRENT: "USERS_CLEAR_CURRENT",
  CLEAR_CURRENT_LIST: 'TRANSACTIONS_CLEAR_CURRENT_LIST',

  GET_REQUEST: "USERS_GET_REQUEST",
  GET_SUCCESS: "USERS_GET_SUCCESS",
  GET_FAILURE: "USERS_GET_FAILURE",

  SAVE_REQUEST: "USERS_SAVE_REQUEST",
  SAVE_SUCCESS: "USERS_SAVE_SUCCESS",
  SAVE_FAILURE: "USERS_SAVE_FAILURE",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  UPDATE_REQUEST: "USERS_UPDATE_REQUEST",
  UPDATE_SUCCESS: "USERS_UPDATE_SUCCESS",
  UPDATE_FAILURE: "USERS_UPDATE_FAILURE",

  GETPOINTS_REQUEST: "USERS_GETPOINTS_REQUEST",
  GETPOINTS_SUCCESS: "USERS_GETPOINTS_SUCCESS",
  GETPOINTS_FAILURE: "USERS_GETPOINTS_FAILURE",

  GETREWARDS_REQUEST: "USERS_GETREWARDS_REQUEST",
  GETREWARDS_SUCCESS: "USERS_GETREWARDS_SUCCESS",
  GETREWARDS_FAILURE: "USERS_GETREWARDS_FAILURE",

  GETINVITES_REQUEST: "USERS_GETINVITES_REQUEST",
  GETINVITES_SUCCESS: "USERS_GETINVITES_SUCCESS",
  GETINVITES_FAILURE: "USERS_GETINVITES_FAILURE",

  SEND_BATCH_INVITATION_REQUEST: "USERS_SEND_BATCH_INVITATION_REQUEST",
  SEND_BATCH_INVITATION_SUCCESS: "USERS_SEND_BATCH_INVITATION_SUCCESS",
  SEND_BATCH_INVITATION_FAILURE: "USERS_SEND_BATCH_INVITATION_FAILURE",

  GETRANKING_REQUEST: "USERS_GETRANKING_REQUEST",
  GETRANKING_SUCCESS: "USERS_GETRANKING_SUCCESS",
  GETRANKING_FAILURE: "USERS_GETRANKING_FAILURE",

  GETBALANCE_REQUEST: "USERS_GETBALANCE_REQUEST",
  GETBALANCE_SUCCESS: "USERS_GETBALANCE_SUCCESS",
  GETBALANCE_FAILURE: "USERS_GETBALANCE_FAILURE",
};

export default types;
