import React from "react";
import config from "../../config";

export default function FooterSmall(props) {
  return (
    <footer className="w-full pb-2 pt-8">
      <div className="text-sm text-center justify-center">
        Copyright © {new Date().getFullYear()}{" "}
        <span
          className="font-semibold py-1"
        >
          {config.COMPANY.LONG_NAME}
        </span>
      </div>
    </footer>
  );
}
