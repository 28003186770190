import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import messageActions from '../../../context/messages/actions';
import transactionsActions from '../../../context/transactions/actions';
import companiesActions from '../../../context/companies/actions';

import Swal from 'sweetalert2';
import Composer from './Composer';

import { getOwner,capitalizePhrase, capitalize } from '../../../libraries/utils';
import Chat from './Chat';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    denyButton: 'btn btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text',
  },
  buttonsStyling: false,
  background: '#fff',
});

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      messages: [],
      inbox:{},
      sent:{},
      chat:{},
      chatWith: '',
      chatOpen: false,
      composing: {},
      tab: 0,
      owner: '',
      files: {},
      windowWidth: window.innerWidth,
    };
    this.breadcrumbs = [this.t('Reclamos')];
  }

  closeChat = async () => {
    await this.getMessages();
    this.setState({chatOpen: false})
  }
  componentDidMount() {
    this.getMessages();
    this.getCompanies();
    // ** Get window width
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    // ** Get window width on resize
    window.removeEventListener('resize', this.handleResize);
  }

  // ** Function to handle resize
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getTransactions = async (ids) => {
    await this.props.onGetAllTransactions({id: ids});
    const { transactions } = this.props;
    if (transactions.error) {
      notify(this.t(transactions.error.message));
    } else {
      this.setState({ transactions: transactions.items });
    }
  }

  getCompanies = async () => {
    await this.props.onGetAllCompanies({});
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      this.setState({ companies: companies.items });
    }
  }

  getMessages = async (page_num = 1, parent, chatOpening) => {
    // ** By default, page_num is equal to 1, it will change if you send it as a parameter
    await  this.setState({owner: getOwner(this.props.auth.user), chatOpen: false})
    const params = { page_num };
    const { owner, sort } = this.state
    // const { search } = this.state;
    // // ** If search exists, filter the data
    // if (search && search !== '') {
    //   params.where = { json_data: [] };
    //   if (search && search !== '') {
    //     params.where = { custom: { name: `%${search.toLowerCase()}` } };
    //   }
    // }
    // ** If sort exists, sort the data
    
    params.order_by = 'JSON_EXTRACT(messages__json_data, "$.last_message_at")'
    if (sort) {
      params.order_by = sort.order_by;
      params.order_direction = sort.order_direction;
    }
    // If parent exist its determines the user we are having a chat with
    if (parent?.source?.id && parent?.target?.id) {
      const chatWith = parent.source.id !== owner ? parent.source : parent.target
      await this.setState({chatWith, chatOpen: true, composing: {}})
    }

    // If parent does not exist, set the order direction to DESC
    if (!parent) {
      params.order_direction = 'DESC'
    }
    // Depending if there is a parent or not, it filters the messages, and set the states from each part of our system using the name of the object
    /*const filters = parent ? 
    [
      {
        name: 'chat',
        custom: {
          id: parent.id,
          comparison: 'OR',
          parent:parent.id,
        },
      }
    ] :
    [
      {
        name : 'inbox',
        custom: {
          target: owner,
          comparison: 'AND',
          parent: { 
            comparison: '=',
            value: 'root', 
          },
        }
          
      },
      {
      name : 'sent',
        custom: {
          source: owner,
          comparison: 'AND',
          parent: { 
            comparison: '=',
            value: 'root', 
          },
        }
      }
    ]*/

    if(parent){
      console.log("__parent", parent.id)

      params.where = {
        custom: {
          id: parent.id,
          comparison: 'OR',
          parent: parent.id
        }
      }

      //params.parent = "f715137a-16fe-46c4-a7bd-d72971233a40"
    } else {
      params.target = this.props.auth.user.id
      params.parent = "root"
    }
    params.type = "message"
    this.setState({ 'inbox': {...this.state['inbox'], items: [], loading: chatOpening ? true : !this.state.chatOpen && !this.state.composing.open}});
    await this.props.onGetAll(params);
    const { messages } = this.props;
    if (messages.error) {
      notify(this.t(messages.error.message));
    } else {
      // ** Set state based on response data
      console.log("relatd_to", messages.items.map(m => m.related_to))
      await this.getTransactions(messages.items.map(m => m.related_to))
      await this.setState({ 'inbox': {items: messages.items, query: messages.query, loading:false }});
    }

    let filters = []
    filters.forEach(async ({custom, name, json_data}) => {
      // ** Execute get request with params
      params.where = {custom}
      this.setState({ [name]: {...this.state[name], items: [], loading: chatOpening ? true : !this.state.chatOpen && !this.state.composing.open}});
      await this.props.onGetAll(params);
      const { messages } = this.props;
      if (messages.error) {
        notify(this.t(messages.error.message));
      } else {
        // ** Set state based on response data
        await this.setState({ [name]: {items: messages.items, query: messages.query, loading:false }});
      }
    })
  };
  // This function handles what happens after a message compose
  afterComposeMessage =  () => {
    const {message} = this.props;
    if (message.error) {
      notify(this.t(message.error.message));
    } else {
      this.setState({tab: 1, composing: { open: false}})
      this.getMessages(this.state.currentPage)
    }
  };

  handleSaveModified = async (modifiedMessage) => {
    await this.props.onSaveOrUpdate(modifiedMessage)
    const {message} = this.props
    if (message.error) {
      notify(this.t(message.error.message));
    } else {
        this.setState({chat : {...this.state.chat, item: message.item, loading: false}})
    }
  }
  // ** Custom sort function
  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () =>
      this.getMessages(currentPage)
    );
  };
  // ** Function to delete a whole conversation
  deleteConversation = async (id) => {
    const params = {
      page_num: 0,
      where :{
        custom:  {
          id: id,
          comparison: 'OR',
          parent:id
        }
      }
    }
    await this.props.onGetAll(params);
    const {messages} = this.props
    await messages.items.forEach(async ({id}) => {
      await this.props.onRemove(id);
    })
  }
  selectChat = (row) => {
    console.log("ROW CLICKED", row)
    this.getMessages(0, row, true)
    const tabs = ['inbox', 'sent']
    row.json_data = {...row.json_data, [this.state.tab ? 'unread_messages_for_source' : 'unread_messages_for_target'] : 0}
    //this.setState({ [tabs[this.state.tab]] : this.state[tabs[this.state.tab]].map(message => message.id === row.id ? row : message)})
    //this.setState({ inbox: this.state.inbox.map(message => message.id === row.id ? row : message)})
  }
  //  ** Delete function
  onRemove = (data, resetRows) => {
    const { messages } = this.props;
    customSwal
      .fire({
        title: this.t(resetRows ? 'Eliminar multiple' : '¿Estas seguro?'),
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: this.state.tab,
        confirmButtonText: this.t('Para mi'),
        denyButtonText: this.t('Opciones'),
        cancelButtonText: this.t('Cancelar'),
      })
      .then(async (result) => {
        // Delete for me
        if (result.isConfirmed) {
        
           // ** If the parameter received is an array, execute multiple delete functions
           if (resetRows) {
            for (let index = 0; index < data.length; index++) {
              const element = data[index];
              const obj = {id: element.id ,json_data: {...element.json_data, [`deleted_chat_for_${this.state.tab ? 'source' : 'target'}`]: 1}}
              // ** Wait until the api removes selected items
              await this.handleSaveModified(obj);
            }
            resetRows();
            if (messages.error) {
              notify(this.t(messages.error.message));
            } else {
              // ** Get messages
              this.getMessages(this.state.currentPage);
            }
            // ** If the parameter is a number execute unique delete
          } else {
            // ** Wait until the api removes selected items
            const obj = {id: data.id ,json_data: {...data.json_data, [`deleted_chat_for_${this.state.tab ? 'source' : 'target'}`]: 1}}
            await this.handleSaveModified(obj);
            if (messages.error) {
              notify(this.t(messages.error.message));
            } else {
              // ** Get messages
              this.getMessages(this.state.currentPage);
            }
          }

        } else if (result.isDenied){
          // Options
          customSwal
          .fire({
            title: this.t('Opciones'),
            icon: 'warning',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: this.t('Pausar conversación'),
            denyButtonText: this.t('Permanente'),
            cancelButtonText: this.t('Cancelar'),
          }).then(async result => {
            // Pause conversation
            if(result.isConfirmed) {
              // ** If the parameter received is an array, execute multiple delete functions
              if (resetRows) {
                for (let index = 0; index < data.length; index++) {
                  const element = data[index];
                  const obj = {id: element.id ,json_data: {...element.json_data, paused: 1}}
                  // ** Wait until the api removes selected items
                  await this.handleSaveModified(obj);
                }
                resetRows();
                if (messages.error) {
                  notify(this.t(messages.error.message));
                } else {
                  // ** Get messages
                  this.getMessages(this.state.currentPage);
                }
                // ** If the parameter is a number execute unique delete
              } else {
                // ** Wait until the api removes selected items
                const obj = {id: data.id ,json_data: {...data.json_data, paused: 1}}
                await this.handleSaveModified(obj);
                if (messages.error) {
                  notify(this.t(messages.error.message));
                } else {
                  // ** Get messages
                  this.getMessages(this.state.currentPage);
                }
              }

            }
            // Delete permanently
            else if (result.isDenied) {
              // ** If the parameter received is an array, execute multiple delete functions
              if (resetRows) {
                for (let index = 0; index < data.length; index++) {
                  const element = data[index];
                  // ** Wait until the api removes selected items
                  await this.deleteConversation(element.id);
                }
                resetRows();
                if (messages.error) {
                  notify(this.t(messages.error.message));
                } else {
                  // ** Get messages
                  this.getMessages(this.state.currentPage);
                }
                // ** If the parameter is a number execute unique delete
              } else {
                // ** Wait until the api removes selected items
                await this.deleteConversation(data.id);
                if (messages.error) {
                  notify(this.t(messages.error.message));
                } else {
                  // ** Get messages
                  this.getMessages(this.state.currentPage);
                }
              }

            }
          })
        }
      });
  };
  // ** Redirection to edit message route
  onEdit = (id) => {
    history.push(config.ROUTES.MESSAGES_EDIT.replace(':id', id));
  };
  // ** Redirection to new message route
  onNew = () => {
    this.setState({composing: {open: !this.state.composing.open}});
  };
  // ** Function to look for specific data in the table
  onSearch = (data) => {
    if (this.state.search !== data.search)
      this.setState({ search: data.search || '' }, () => this.getMessages());
  };

  changeTab = (tab) => {
    this.setState({tab})
  }

  render() {
    // **Destructuring objects for direct use of values
    const { props, state, getMessages, customSort, onRemove, changeTab, afterComposeMessage, selectChat } =
      this;
    const { windowWidth , tab, composing, chatOpen } = state;

    const {
      messages: { loading },
      companies: { items: companies },
    } = props;

 
    
    const tabs = ['inbox', 'sent' ]//'sent'
    // ** Columns configuration for our data table
    const columns = [
      {
        name:'Nombre',
        selector: (row) => {
          const readed = !row.json_data[`unread_messages_for_${tab ? 'source': 'target'}`]
          // const targetCompany = this.props.companies.items.find(c => c.id === row.source['unique_id'])
          // const sent = <span className={`${readed ? 'font-normal text-gray-700' : 'font-semibold'}`}>{this.t('Para')} {capitalizePhrase(targetCompany?.name)}</span>   // comentado porque parece innecesario en esta columna
          const inbox = <span className={`${readed ? 'font-normal text-gray-700' :'font-semibold'}`}>{this.t('De')} {capitalizePhrase(row.source['name'])}</span>
          // const element = { inbox, sent }
          // return element[tabs[tab]]
          return inbox
        },
        width: '170px'
      },
      {
        name:'Empresa',
        selector: (row) => {
          const readed = !row.json_data[`unread_messages_for_${tab ? 'source' : 'target'}`]
          const targetCompany = companies.find(c => c.id === row.source['unique_id'])
          const sent = <span className={`${readed ? 'font-normal text-gray-700' : 'font-semibold'}`}>{capitalizePhrase(targetCompany?.name)}</span>
          // const inbox = <span className={`${readed ? 'font-normal text-gray-700' : 'font-semibold'}`}>{this.t('De')} {capitalizePhrase(row.source['name'])}</span>
          // const element = { inbox, sent }
          // return element[tabs[tab]] // comentado porque parece innecesario en esta columna
          return sent
        },
        width: '170px'
      },
      {
        name:"Reclamo",
        selector: (row) => {
          const readed = !row.json_data[`unread_messages_for_${tab ? 'source': 'target'}`]
          return (
            <div>
              <span className={`${readed ? 'font-normal text-gray-700' :'font-semibold'}`}>{row['subject']}</span>
              <span className={`${readed ? 'font-thin text-gray-500' : 'font-thin'}`}> - {row['body']}</span>
            </div>
          )
        },
        maxWidth: composing.open || chatOpen ? (windowWidth> 1376 ? '700px' :'400px') : 'auto'
      },
      {
        name:"Fecha",
        selector: (row) => {
          const readed = !row.json_data[`unread_messages_for_${tab ? 'source': 'target'}`]
          const date = new Date (row.json_data.last_message_at) || new Date(row['created_at'])
          const month = capitalize(date.toLocaleDateString(undefined, {month: 'long'}).substring(0,3))
          const day = `${date.toLocaleDateString(undefined, {day: 'numeric'})}`
          return (
            <div className='mr-2'>
              {!readed && <span className='font-semibold mr-2 rounded-full bg-primary text-white p-1'>{row.json_data[`unread_messages_for_${tab ? 'source': 'target'}`]}</span>}
              <span className={`${readed ? 'font-normal text-gray-700' :'font-semibold'}`}>{`${month} ${day}`}</span>
            </div>
          )
        },
        right: true,
        width: '130px'
      },
    ];
    // const conditionalRowStyles = [
    //   {
    //     when: row => !row.json_data[`unread_messages_for_${tab ? 'source': 'target'}`],
    //     style: {
    //       backgroundColor: '#f9fafb',
    //       '&:hover': {
    //         cursor: 'pointer',
    //       },
    //     },
    //   },
    // ]

    const conditionalRowStyles = {
      transition: 'all 0.2s ease-in-out', 
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#f1f1f1', 
      },
}

    // ** Main and secondary actions of out layout
    const actions = {
      main: {
        //onClick: this.onNew,
        //title: this.t(composing.open ? 'Cancelar' : 'Redactar'),
        //checkPermissions: 'insert',
      },
      secondaries: [],
      // search: { onSearch: this.onSearch, title: this.t('Search') },
    };
    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs,
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={loading}
      >
        <ToastContainer />
          <PanelLayout>
            <div className='flex h-full'>
              {((windowWidth < 1023 && !composing.open && !chatOpen) ? true : windowWidth > 1023) && !chatOpen && <div className='w-full border-r pt-2'>

                {/*<div className="tabs tabs-boxed p-3 mb-1">
                  {tabs.map((e, i) => (
                    <button key={e} onClick={()=> changeTab(i)} className={`tab ${tab === i &&  'tab-active'}`}>{e}</button> 
                  ))}
                </div>*/}
                <StyledDataTable
                  noHead
                  className='rounded-none'
                  tab={tab}
                  data={this.state[tabs[tab]].items} 
                  columns={columns}
                  //selectableRows
                  query={this.state[tabs[tab]].query}
                  getDataFunction={getMessages}
                  //multipleDeleteFunction={onRemove}
                  pagination
                  loading={this.state[tabs[tab]].loading}
                  customSort={customSort}
                  screenWidth={windowWidth}
                  height={'400px'}
                  conditionalRowStyles={conditionalRowStyles}
                  onRowClicked={(row) => selectChat(row)}
                />
              </div>}
              {composing.open && <div className='w-full pt-2 flex flex-col items-stretch'>
              <Composer t={this.t} afterComposeMessage={afterComposeMessage}/>
              </div>}
              {!composing.open && chatOpen && <div className='w-full pt-2'>
                  <Chat t={this.t} closeChat={this.closeChat} companies={companies} />
              </div>}
              
            </div>
          </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    messages: state.messages.list,
    message: state.messages.current,
    query: state.messages.list.query,
    transactions: state.transactions.list,
    companies: state.companies.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAll: (params) => dispatch(messageActions.getAll(params)),
    onRemove: (id) => dispatch(messageActions.del(id)),
    onSaveOrUpdate: (params) => dispatch(messageActions.saveOrUpdate(params)),
    onGetAllTransactions: (params) => dispatch(transactionsActions.getAll(params)),
    onGetAllCompanies: (params) => dispatch(companiesActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Messages));

