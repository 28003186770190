import { LexoRank } from "lexorank"

let newCurrentRank = LexoRank.middle()

export const generateNewRank = prevRank => {
  if (prevRank) {
    return prevRank.genNext().toString()
  }
  newCurrentRank = newCurrentRank.genPrev()
  return newCurrentRank.toString()
}

export const generateRankItems = items => {
  let currentRank = LexoRank.middle()
  const data = []
  items.forEach(item => {
    data.push({
      ...item,
      order_index: currentRank.toString(),
    })
    currentRank = currentRank.genNext()
  })

  return data.sort(sortByLexoRankAsc)
}

export function sortByLexoRankAsc(a, b) {
  if (!a.order_index && b.order_index) return -1
  if (a.order_index && !b.order_index) return 1
  if (!a.order_index || !b.order_index) return 0

  return a.order_index.localeCompare(b.order_index)
}

export const getSurroundingItems = (items, oldIndex, newIndex) => {
  let input
  const entity = items[oldIndex]
  if (newIndex === 0) {
    const nextEntity = items[newIndex]
    input = { prevEntity: undefined, entity: entity, nextEntity: nextEntity }
  } else if (newIndex === items.length - 1) {
    const prevEntity = items[newIndex]
    input = { prevEntity: prevEntity, entity: entity, nextEntity: undefined }
  } else {
    const prevEntity = items[newIndex]
    const offset = oldIndex > newIndex ? -1 : 1
    const nextEntity = items[newIndex + offset]
    input = { prevEntity: prevEntity, entity: entity, nextEntity: nextEntity }
  }

  return input
}

export const getSurroundingRankAsc = payload => {
  const { prevEntity, entity, nextEntity } = payload
  let newLexoRank
  if (!prevEntity && !!nextEntity) {
    newLexoRank = LexoRank.parse(nextEntity.order_index).genPrev()
  } else if (!nextEntity && !!prevEntity) {
    newLexoRank = LexoRank.parse(prevEntity.order_index).genNext()
  } else if (!!prevEntity && !!nextEntity) {
    newLexoRank = LexoRank.parse(nextEntity.order_index).between(
      LexoRank.parse(prevEntity.order_index)
    )
  } else {
    newLexoRank = LexoRank.parse(entity.order_index).genNext()
  }

  return newLexoRank
}

export const assignRanksToItems = items => {
  const existingRanks = items
    .filter(item => item.order_index)
    .map(item => LexoRank.parse(item.order_index))
    .sort((a, b) => {
      return a.compareTo(b.order_index)
  })

  // Genera Lexoranks a items que no lo tengan
  let currentRank = LexoRank.middle()
  const itemsToAddRank = items.filter(item => !item.order_index)
  itemsToAddRank.forEach(item => {
    const compare = rank => rank.compareTo(currentRank) === 0
    while (existingRanks.some(compare)) {
      currentRank = currentRank.genNext()
    }
    item.order_index = currentRank.toString()
    currentRank = currentRank.genNext()
  })

  const orderedAndSavedItems = [
    ...items.filter(item => (item.order_index || item.order_index.length > 0)),
    ...itemsToAddRank,
  ]

  orderedAndSavedItems.sort(sortByLexoRankAsc)

  return orderedAndSavedItems
}
