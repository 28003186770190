import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { capitalize, getOwner } from '../../../libraries/utils';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import { Link } from 'react-router-dom';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import initiativeActions from '../../../context/initiatives/actions';
import businessesActions from '../../../context/businesses/actions';
import Swal from 'sweetalert2';
import PrivateButton from '../../../components/commons/PrivateButton';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Initiatives extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      initiatives: [],
      loading: true,
      windowWidth: window.innerWidth
    };
    this.breadcrumbs = [this.t('Iniciativas de Servicio Comunitario')];
  }

  componentDidMount() {
    this.getInitiatives();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getInitiatives = async (page_num = 1) => {
    // ** By default, page_num is equal to 1, it will change if you send it as a parameter
    this.state.loading = true;
    const params = { page_num };
    const { search } = this.state;
    const { user } = this.props.auth;
    // ** If search exists, filter the data
    if (search && search !== '') {
      params.where = {
        custom: {
          name: `%${search.toLowerCase()}`,
          details: `%${search.toLowerCase()}`,
          type: `%${search.toLowerCase()}`,
          comparison: 'OR'
        }
      };
    }
    // ** If sort exists, sort the data
    if (this.state.sort) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }

    if (user.roles.includes(config.ROLES.INITIATIVES)) {
      //await this.props.onGetBusinesses({ owner: user.id });
      await this.props.onGetBusinesses({ owner: getOwner(user) });
      const { businesses } = this.props;
      if (businesses.error) {
        notify(this.t(businesses.error.message));
      } else {
        params.owner = [];
        for (let business of businesses.items) {
          params.owner.push(business.id);
        }
      }
    }

    // ** Execute get request with params
    await this.props.onGetAll(params);
    const { initiatives } = this.props;
    if (initiatives.error) {
      notify(this.t(initiatives.error.message));
    } else {
      // ** Set state based on response data
      this.setState({ initiatives: initiatives.items, loading: initiatives.loading });
    }
  };
  // ** Custom sort function
  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getInitiatives(currentPage));
  };
  //  ** Delete function
  onRemove = (id, resetRows) => {
    const { initiatives } = this.props;
    customSwal
      .fire({
        title: this.t(resetRows ? capitalize('delete multiple elements') : '¿Are you sure?'),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          // ** Loading
          initiatives.loading = true;
          // ** If the parameter received is an array, execute multiple delete functions
          if (resetRows) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              // ** Wait until the api removes selected items
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (initiatives.error) {
              notify(this.t(initiatives.error.message));
            } else {
              // ** Get initiatives
              this.getInitiatives(this.state.currentPage);
            }
            // ** If the parameter is a number execute unique delete
          } else {
            // ** Wait until the api removes selected items
            await this.props.onRemove(id);
            if (initiatives.error) {
              notify(this.t(initiatives.error.message));
            } else {
              // ** Get initiatives
              this.getInitiatives(this.state.currentPage);
            }
          }
        }
      });
  };
  // ** Redirection to edit initiative route
  onEdit = id => {
    history.push(`${config.ROUTES.INITIATIVES}/${id}`);
  };
  // ** Redirection to new initiative route
  onNew = () => {
    history.push(config.ROUTES.INITIATIVES_NEW);
  };
  // ** Function to look for specific data in the table
  onSearch = data => {
    if (this.state.search !== data.search) this.setState({ search: data.search || '' }, () => this.getInitiatives());
  };

  searchClear = form => {
    form.change('search', undefined);
    this.onSearch({ search: '' });
  };

  // ** Use this function when configuring columns, its for hiding columns when the window width goes below the number specified
  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  render() {
    const { windowWidth, initiatives, loading } = this.state;

    const expandableComponent = ({ data }) => (
      <Link to={`${config.ROUTES.INITIATIVES}/${data.id}`}>
        <div className="flex flex-wrap py-3 px-5 align-center justify-around bg-gray-50">
          <div className="py-2">
            <h4>{this.t('First name')}</h4>
            <p className="text-gray-500">{data.name}</p>
          </div>
          <div className="py-2">
            <p className={`${data.enabled ? 'bg-green-100' : 'bg-red-100'} rounded-box px-2 py-1`}>
              {data.enabled ? this.t('Active') : this.t('Inactive')}
            </p>
          </div>
        </div>
      </Link>
    );
    // ** Columns configuration for our data table
    const columns = [
      {
        name: capitalize(this.t('type')),
        sortable: true,
        maxWidth:
          // non-desktop
          windowWidth < 959
            ? // windowWidth - (table padding + cell padding) - (actions cell width + actions cell padding)
              `${windowWidth - 64 - 162}px`
            : // 720p
            windowWidth > 959 && windowWidth < 1920
            ? '19.41vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '23.68vw'
            : // 4k
            windowWidth >= 3840
            ? '28.695vw'
            : '',
        selector: row => capitalize(this.t(row['type'])),
        sortSelector: 'type'
      },
      {
        name: this.t('Nombre'),
        sortable: true,
        selector: row => row['name'],
        sortSelector: 'name',
        hide: 'md',
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '19.41vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '23.68vw'
            : // 4k
            windowWidth >= 3840
            ? '28.69vw'
            : ''
      },
      {
        name: capitalize(this.t('details')),
        sortable: true,
        hide: this.hide(1100),
        selector: row => row['details'],
        sortSelector: 'details',
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '19.41vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '23.68vw'
            : // 4k
            windowWidth >= 3840
            ? '28.69vw'
            : ''
      },
      {
        name: this.t('Activo'),
        hide: 'md',
        selector: row => (
          <p className={`${row.enabled ? 'bg-green-100' : 'bg-red-100'} rounded-box px-2 py-1`}>
            {row.enabled ? 'Activo' : 'Inactivo'}
          </p>
        ),
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '6.48vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '7.89vw'
            : // 4k
            windowWidth >= 3840
            ? '9.56vw'
            : ''
      },
      {
        name: 'Acciones',
        allowOverflow: true,
        center: true,
        width: '130px',
        hide: this.hide(370),
        cell: row => {
          return (
           <div className="flex justify-center align-center rounded-full gap-2">
              <ButtonIcon onClick={() => this.onEdit(row.id)} icon="view_show" buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm" className="w-5" />
              <PrivateButton control="delete">
                <ButtonIcon onClick={() => this.onRemove(row.id)} icon="trash" buttonClassName="btn-link text-error bg-gray-100 btn-sm" />
              </PrivateButton>
            </div>
          );
        }
      }
    ];

    // ** Main and secondary actions of out layout
    const actions = {
      main: {
        onClick: this.onNew,
        title: this.t('New'),
        checkPermissions: 'insert',
        visible: this.props.auth.user.roles.includes(config.ROLES.INITIATIVES)
      },
      secondaries: [],
      search: {
        onSearch: this.onSearch,
        onClearClick: this.searchClear,
        title: this.t('Search')
      }
    };

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={initiatives}
            columns={columns}
            //selectableRows={this.props.auth.user.roles.includes(config.ROLES.INITIATIVES) ? windowWidth > 600 : null}
            query={this.props.initiatives.query}
            getDataFunction={this.getInitiatives}
            multipleDeleteFunction={
              this.props.auth.user.roles.includes(config.ROLES.INITIATIVES) ? this.onRemove : null
            }
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            expandableRows={windowWidth < 600}
            expandableRowsComponent={expandableComponent}
            onRowExpand={() => {}}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    initiatives: state.initiatives.list,
    businesses: state.businesses.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAll: params => dispatch(initiativeActions.getAll(params)),
    onGetBusinesses: params => dispatch(businessesActions.getAll(params)),
    onRemove: id => dispatch(initiativeActions.del(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Initiatives));
