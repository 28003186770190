import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import IconPoints from '../../../components/customs/IconPoints';
import IconCoins from '../../../components/customs/IconCoins';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { capitalize, validateIsfilled } from '../../../libraries/utils';

import categoriesActions from '../../../context/categories/actions'
import userActions from '../../../context/users/actions'
import productActions from '../../../context/products/actions';

import FormLayout from '../../../components/forms/FormLayout';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;

    this.state = {
      editing: false,
      editPage: false,
      loading: true,

      product: {},
      remove: false,
      submitting: false,
      titles: '',
      view: false
    };

    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Products'), this.t('New')];
    this.titles = this.t('User details');
  }

  // ** Life cycle
  componentDidMount() {
    const params = this.props.match.params;

    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        titles: this.t('Edit Product'),
        editPage: true
      });
      this.breadcrumbs = [this.t('Products'), capitalize(this.t('details'))];

      this.getProduct(params.id);
    } else {
      this.setState({ titles: this.t('New Product') });
    }
  }

  getRelatedCategories = async category => {
    let relatedCategories = { son: category };
    // ** If parent is root, function ends
    if (category?.parent === 'root') {
      this.setState({ related_categories: relatedCategories });
      return;
    }

    // ** If parent is not root, we bring the parent from the backend
    await this.props.onGetCategory(category.parent);
    category = this.props.category.item;
    // ** We set it as a property of our object, named as root
    relatedCategories.root = category;

    // ** In case this category has its parent displayed as root, function ends
    if (category?.parent === 'root') {
      this.setState({ related_categories: relatedCategories });
      return;
    }
    // ** If not, we change the name from root to father
    relatedCategories.father = relatedCategories.root;

    // ** We get our root category
    await this.props.onGetCategory(category.parent);
    category = this.props.category.item;
    // ** Set the property and set a state with our final object
    relatedCategories.root = category;
    this.setState({ related_categories: relatedCategories });
  };
  getUser = async () => {
    const owner = this.props.product.item.owner;
    if (owner) {
      await this.props.onGetUser({ id: owner });
      const error = this.props.user.error;

      if (error) {
        notify(this.t(error.message));
      } else {
        this.setState({
          user: this.props.user.item,
          loading: this.props.user.loading
        });
      }
    } else {
      this.setState({ loading: false });
    }
  };
  getProduct = async id => {
    await this.props.onGetProduct(id);

    const error = this.props.product.error;

    if (error) {
      notify(this.t(error.message));
    } else {
      await this.setState({
        product: this.props.product.item
      });
      await this.getRelatedCategories(this.state.product.json_data.category);
      await this.getUser();
    }

    if (this.state.product.measure) this.state.product.measure = this.state.product.measure.id;
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  goBack = () => {
    history.push(config.ROUTES.PRODUCTS);
  };

  onSubmit = async () => {
    this.setState({ submitting: true });
    const _product = this.state.product;
    delete _product.variants;
    delete _product.options;
    this.setState({ loading: true });
    await this.props.onSaveOrUpdate({
      ..._product,
      enabled: _product.enabled ? 0 : 1
    });
    const product = this.props.product;
    if (product.error) {
      notify(this.t(product.error.message));
      this.setState({ submitting: false });
    } else {
      this.setState({ submitting: false });
      const params = this.props.match.params;
      await this.getProduct(params.id);
      this.setState({ loading: false });
    }
  };

  render() {
    // **Destructuring objects for direct use of values
    const { view, editing, submitting, product, user, loading, related_categories } = this.state;

    const category = () => {
      // ** If related_categories is not defined, function ends
      if (!related_categories) return;
      // ** If not, we destructure our object
      const { son, father, root } = related_categories;
      // ** If the object has not a root property, we return a string with son.name (In reality is just a root category that doesnt have fathers)
      if (!root) return capitalize(son.name);
      // ** If it has a root property, we ask if it has a father property, if is not the case, we return a string with the root name, and son name (Root category, and his son)
      if (!father) return `${capitalize(root.name)} > ${capitalize(son.name)}`;
      // ** In case it has a father property, we return a string with root name, father name, and son name (Our three levels of categories)
      else return `${capitalize(root.name)} > ${capitalize(father.name)} > ${capitalize(son.name)}`;
    };
    // Layout actions
    const actions = {
      main: {
        onClick: e => this.onSubmit(e),
        title: this.t(product.enabled ? 'Disable' : 'Enable'),
        icon: !product.enabled ? 'view_show' : 'view_hide',
        disabled: submitting || this.props.product.loading || this.state.loading,
        checkPermissions: 'insert'
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.PRODUCTS),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        }
      ]
    };

    // ** Form validation functions
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');

    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      errors.type = required(values.type);
      return errors;
    };

    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={loading}>
          <div>
            <div className="flex flex-wrap">
              <div className="w-full sm:w-6/12 lg:w-6/12 px-2">
                <img className="rounded-box" src={product.json_data?.img} alt="" />
              </div>
              <div className="w-full sm:w-6/12 lg:w-6/12 p-5 border rounded-box">
                <div className="flex items-center">
                  <h1 className="p-3">{product.name}</h1>
                  <p className={`${product.enabled ? 'bg-green-100' : 'bg-red-100'} rounded-box px-2 py-1 h-7`}>
                    {product.enabled ? this.t('Active') : this.t('Inactive')}
                  </p>
                </div>
                <div className="flex text-center justify-start ">
                  <IconCoins
                    className="bg-white bg-opacity-50 rounded-xl text-primary p-2"
                    coins={product.json_data?.coins}
                  />
                  <IconPoints
                    className="bg-white bg-opacity-50 rounded-xl text-primary mr-4 p-2"
                    points={product.json_data?.points}
                  />
                </div>
                <div>
                  <div className="py-4">
                    <h4 className="pb-1">Descripcion del producto:</h4>
                    <p>{product.details}</p>
                  </div>
                </div>
                <h4>Informacion del dueño:</h4>
                <div className="flex p-3 items-center">
                  {!user && <h3>Sistema</h3>}
                  <img className="rounded-full w-20 mr-3" src={user?.profile_image} alt="" />
                  <h3>{capitalize(user?.name)}</h3>
                </div>
              </div>
              <hr />
              <div className="w-full sm:w-6/12 lg:w-10/12 p-5">
                <h2 className="mb-3">Caracteristicas principales</h2>
                <div className="border rounded">
                  <div className="grid grid-cols-10">
                    <h5 className="p-4 bg-gray-200 rounded-tl col-span-3 font-semibold">{this.t('Estado')}</h5>
                    <h5 className="p-4 bg-gray-100 rounded-tr col-span-7">{this.t(product.status)}</h5>
                  </div>
                  <div className="grid grid-cols-10">
                    <h5 className="p-4 bg-gray-100 col-span-3 font-semibold">{this.t('Marca')}</h5>
                    <h5 className="p-4 bg-gray-50 col-span-7">
                      {' '}
                      {capitalize(product.json_data?.brand) || 'No especificado'}
                    </h5>
                  </div>
                  <div className="grid grid-cols-10">
                    <h5 className="p-4 bg-gray-200 col-span-3 font-semibold">{this.t('Condicion')}</h5>
                    <h5 className="p-4 bg-gray-100 col-span-7">
                      {capitalize(product.json_data?.state) || 'No especificado'}
                    </h5>
                  </div>
                  <div className="grid grid-cols-10">
                    <h5 className="p-4 bg-gray-100 col-span-3 font-semibold">{this.t('Categoria')}</h5>
                    <h5 className="p-4 bg-gray-50 col-span-7">{category() || 'No especificado'}</h5>
                  </div>
                  <div className="grid grid-cols-10">
                    <h5 className="p-4 bg-gray-200 col-span-3 font-semibold">{this.t('Valor minimo')}</h5>
                    <h5 className="p-4 bg-gray-100 col-span-7">
                      {product.json_data?.category?.json_data?.min_value || 'No especificado'}
                    </h5>
                  </div>
                  <div className="grid grid-cols-10">
                    <h5 className="p-4 bg-gray-100 col-span-3 font-semibold">{this.t('Valor maximo')}</h5>
                    <h5 className="p-4 bg-gray-50 col-span-7">
                      {product.json_data?.category?.json_data?.max_value || 'No especificado'}
                    </h5>
                  </div>
                </div>
                <div className="grid grid-cols-10">
                  <h5 className="p-4 bg-gray-200 col-span-3 font-semibold">{capitalize(this.t('condition'))}</h5>
                  <h5 className="p-4 bg-gray-100 col-span-7">
                    {capitalize(product.json_data?.state) || 'No especificado'}
                  </h5>
                </div>
                <div className="grid grid-cols-10">
                  <h5 className="p-4 bg-gray-100 col-span-3 font-semibold">{this.t('Categoria')}</h5>
                  <h5 className="p-4 bg-gray-50 col-span-7">
                    {capitalize(product.json_data?.category.name) || 'No especificado'}
                  </h5>
                </div>
                <div className="grid grid-cols-10">
                  <h5 className="p-4 bg-gray-200 col-span-3 font-semibold">{capitalize(this.t('min value'))}</h5>
                  <h5 className="p-4 bg-gray-100 col-span-7">
                    {product.json_data?.category?.json_data?.min_value || 'No especificado'}
                  </h5>
                </div>
                <div className="grid grid-cols-10">
                  <h5 className="p-4 bg-gray-100 col-span-3 font-semibold">{capitalize(this.t('max value'))}</h5>
                  <h5 className="p-4 bg-gray-50 col-span-7">
                    {product.json_data?.category?.json_data?.max_value || 'No especificado'}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <Form initialValues={product || {}} onSubmit={this.onSubmit} validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return <FormLayout form={form} onSubmit={this.onSubmit} values={values}></FormLayout>;
            }}
          </Form>
          {/* {editPage && product.id ? (
            <ProductVariants productID={product.id} />
          ) : (
            ''
          )} */}
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    
    product: state.products.current,
    user: state.users.current,
    category: state.categories.current
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveOrUpdate: (params) => dispatch(productActions.saveOrUpdate(params)),
    onGetCategory: id => dispatch(categoriesActions.get(id)),
    onGetProduct: (id) => dispatch(productActions.get(id)),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onRemove: (id) => dispatch(productActions.del(id)),
    onClearCurrent: () => dispatch(productActions.clearCurrent()),
    
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Product));
