import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PanelLayout from "../../components/PanelLayout";
import StyledDataTable from "../../components/commons/StyledDataTable";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import config from "../../config";
import trxActions from "../../context/transactions/actions";
import usersActions from "../../context/users/actions";
import { notify, ToastContainer } from "../../libraries/notifications";
import { capitalizePhrase, getCreditType } from "../../libraries/utils";
import { history } from "../../routes";

class OneUserHistory extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      loading: true,
      user: null,
      pageUserTrx: null,
      breadcrumbs: [],
    };
    this.userId = undefined;
  }

  async componentDidMount() {
    const params = this.props.match.params;
    this.userId = params.id;

    await this.getUser();
    await this.getUserTrx();

    this.setState({ loading: false });
  }

  async getUser() {
    await this.props.onGetUser({ id: this.userId });

    if (this.props.user.error) {
      notify(this.props.user.error.message);
      return;
    }

    this.setState({
      breadcrumbs: [`Transac. de ${capitalizePhrase(this.props.user.item?.name)}`],
    });
  }

  async getUserTrx(currentPage) {
    const baseParams = {
      page_num: currentPage,
      page_size: this.props.trx?.query?.page_size || 8,
      type: "credits",
      order_by: "transactions__created_at",
      order_direction: "DESC",
    };
    
    // TODO

    // await this.props.onGetTrx({ ...baseParams, target: this.userId });

    // if (this.props.trx.error) {
    //   notify(this.props.trx.error.message);
    //   return;
    // }

    // const targetTrx = this.props.trx.items;

    // await this.props.onGetTrx({ ...baseParams, source: this.userId });

    // if (this.props.trx.error) {
    //   notify(this.props.trx.error.message);
    //   return;
    // }

    await this.props.onGetTrx({ ...baseParams, where: { custom: {target: this.userId, source: this.userId , comparison: "OR",}} });
    if (this.props.trx.error) {
      return notify(this.props.trx.error.message);
    }
    const sourceTrx = this.props.trx.items;


    this.setState({ pageUserTrx: sourceTrx, query: { ...this.props.trx.query, records: sourceTrx.length}});
  }

  render() {
    const { loading, pageUserTrx, query } = this.state;
    console.log("pageUserTrx", pageUserTrx);

    const columns = [
      {
        name: "Fecha",
        sortable: true,
        maxWidth: "15vw",
        selector: (row) => row.created_at.toLocaleString("es-AR"),
        sortSelector: "created_at",
      },
      {
        name: "Tipo",
        sortable: true,
        selector: (row) => (
          <p
            className={
              getCreditType(row, this.userId) === "egress"
                ? "text-red-700 text-center bg-red-100 py-1 px-2 w-full rounded-md"
                : "text-green-700 text-center bg-green-100 py-1 px-2 w-full rounded-md"
            }
          >
            {getCreditType(row, this.userId) === "egress"
              ? "Egreso"
              : "Ingreso"}
          </p>
        ),
        sortSelector: "target",
        maxWidth: "8vw",
      },
      {
        name: "Razón",
        sortable: true,
        selector: (row) => row.json_data?.reason || "Sin razón",
        sortSelector: "reason",
      },
      {
        name: "Cantidad",
        sortable: true,
        selector: (row) => (
          <p
            className={
              getCreditType(row, this.userId) === "egress"
                ? "text-red-700"
                : "text-green-700"
            }
          >
            {row.total}
          </p>
        ),
        sortSelector: "total",
        maxWidth: "10vw",
      },
    ];

    const actions = {
      main: {
        onClick: (e) => history.push(config.ROUTES.CREDITS_COLLABORATORS),
        title: this.t("Go Back"),
        icon: "cheveron_left",
        // disabled: submitting,
        visible: true,
        className: "btn-accent",
      },
    };

    return (
      <LayoutWithSidebar
        main={{ className: "text-content-400" }}
        header={{
          breadcrumbs: this.state.breadcrumbs,
        }}
        container={{ className: "px-8 py-12" }}
        actions={actions}
        loading={loading}
      >
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={pageUserTrx}
            columns={columns}
            query={query}
            getDataFunction={this.getUserTrx}
            pagination
            loading={loading}
            customSort={this.customSort}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    trx: state.transactions.list,
    user: state.users.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: (params) => dispatch(usersActions.get(params)),
    onGetTrx: (params) => dispatch(trxActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(OneUserHistory));
