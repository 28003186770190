import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import config from "../../config"
import companyActions from "../../context/companies/actions"
import userActions from "../../context/users/actions"
import { ToastContainer, notify } from "../../libraries/notifications"
import { capitalizePhrase } from "../../libraries/utils"
import { history } from "../../routes"
import StyledDataTable from "../commons/StyledDataTable"
import LayoutWithSidebar from "../layout/LayoutWithSidebar"
import trxActions from "../../context/transactions/actions"
import { exportToExcel } from "../../libraries/exports/exportToExcel"
import Swal from "sweetalert2"
import ButtonIcon from "../../components/commons/ButtonIcon"

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary mx-1",
    cancelButton: "btn btn-outline btn-primary mx-1",
    title: "swal2-title",
    htmlContainer: "swal-text",
  },
  buttonsStyling: false,
  background: "#fff",
})

class UsersHistory extends React.Component {
  constructor(props) {
    super(props)
    this.t = this.props.t
    this.state = {
      disableEverything: false,
      users: [],
      companyId: this.props.companyID,
      windowWidth: window.innerWidth,
      loading: true,
      balances: [],
    }
    // this.breadcrumbs = [this.t("Créditos de Colaboradores")];
    this.breadcrumbs = [this.t("Créditos")]
  }

  // ** Life cycle
  async componentDidMount() {
    await this.getCompanyId()
    await this.getBalance()
    // ** Get window width
    window.addEventListener("resize", this.handleResize)
  }
  componentWillUnmount() {
    // ** Get window width on resize
    window.removeEventListener("resize", this.handleResize)
  }
  // ** Function to handle resize
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  getCompanyId = async () => {
    if (this.state.companyId) return

    await this.props.onGetCompaniesByOwner(this.props.auth.user.accounts[0].id)
    const { companies } = this.props
    if (companies.error) {
      notify(this.t(companies.error))
    } else {
      if (companies.item) {
        this.setState({ companyId: companies.item[0].id })
      }
    }
  }

  getBalance = async (page_num = 1) => {
    let params = { page_num, page_size: 2, unique_id: this.state.companyId, output: "users_balances" }

    // const { search } = this.state
    // ** If search exist, filter the data
    // if (search && search !== "") {
    //   params.where = {
    //     custom: {
    //       // keys can be in any order
    //       name: `%${search.toLowerCase()}`,
    //       email: `%${search.toLowerCase()}`,
    //       comparison: "OR",
    //     },
    //   }
    // }
    // // ** If sort exist, sort the data. Else, sort it by creation_date by default.
    // if (this.state.sort) {
    //   params.order_by = this.state.sort.order_by
    //   params.order_direction = this.state.sort.order_direction
    // } else {
    //   params.order_by = "users__created_at"
    //   params.order_direction = "desc"
    // }

    await this.props.onGetBalances(params)
    const { balance } = this.props.user

    if (balance.error) {
      notify(this.t(balance.error.message))
      return
    }
    
    const newBalances = balance.map(b => ({
      ...b,
      credits: b.credits.balance,
    }))
    this.setState({ balances: newBalances, loading: false })
    
  }

  // ** Custom sort function
  customSort = async (field, order, currentPage) => {
    this.setState(
      { sort: { order_by: `users__${field}`, order_direction: order } },
      () => this.getBalance(currentPage)
    )
  }

  exportToNubis = async () => {
    const { value: accepted } = await customSwal.fire({
      title: this.t("Queres enviar créditos a nubis?"),
      text: this.t("Esta acción debitará todos los saldos de colaboradores."),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.t("Yes"),
      cancelButtonText: this.t("Cancel"),
    })
    if (!accepted) return
    const { balances } = this.state
    const result = []
    for (const balance of balances) {
      if (parseInt(balance.credits.balance) <= 0) continue
      let data = {
        type: "credits",
        sub_type: "credits",
        owner: "system",
        total: balance.credits.balance,
        source: balance.id,
        target: "NUBIS",
        currency: "ARS",
        status: "paid",
      }
      await this.props.onSaveTrx(data)
      const trx = this.props.transaction
      result.push(trx)
    }
    // TODO: no se descuenta el debit cuando se hace la transacción
    let montoTotal = 0
    const proccessedBalances = balances
      .filter(bal => parseInt(bal.credits.balance) > 0)
      .map(balance => {
        montoTotal += parseInt(balance.credits.balance)
        return {
          CUIL: balance.labor_idetification_code,
          MAIL: balance.email,
          DNI: balance.labor_idetification_code?.slice(2, -1), // verificar
          MONTO: balance.credits.balance,
        }
      })
    const header = [
      ["Nombre", "Empresa"],
      ["monto total", montoTotal],
    ]
    exportToExcel(proccessedBalances, "balances", {
      data: header,
      position: "top",
    })
  }

  // ** Function to look for specific data in the table
  onSearch = data => {
    if (this.state.search !== data.search)
      this.setState({ search: data.search || "" }, () => this.getBalance())
  }

  searchClear = form => {
    form.change("search", undefined)
    this.onSearch({ search: "" })
  }
  // ** Use this function when configuring columns, its for hiding columns when the window width goes below the number specified
  hide = number => {
    if (this.state.windowWidth < number) {
      return "lg"
    }
    return null
  }

  onViewOne(id) {
    history.push(`${config.ROUTES.CREDITS_COLLABORATORS}/${id}`)
  }

  render() {
    // **Destructuring objects for direct use of values
    const { windowWidth, balances, disableEverything, loading } = this.state
    const sumaTotalBalance = balances.reduce((acc, balance) => acc + balance.credits, 0);
    // balances.forEach( b => {
    //   console.log(b.credits.balance)
    //   b.credits = b.credits.balance
    // })
    // ** Columns configuration for our data table
    const columns = [
      {
        name: this.t("First name"),
        sortable: true,
        className: "w-1/5",
        selector: row => capitalizePhrase(row["name"]?.toUpperCase()),
        sortSelector: "name",
      },
      {
        name: this.t("Email"),
        sortable: true,
        // hide: "md",
        selector: row => row["email"],
        sortSelector: "email",
        className: "w-1/5",
      },
      {
        name: this.t("CUIL"),
        // hide: "md",
        selector: row => row["labor_idetification_code"],
        className: "w-1/5",
        sortable: true,
        sortSelector: "labor_idetification_code",
      },
      {
        name: this.t("Saldo"),
        sortable: true,
        center: true,
        sortSelector: "credits",
        // hide: "md",
        selector: row => row.credits,
        // className: "w-1/5",
      },
      {
        name: "Acciones",
        center: true,
        omit: !!this.props.companyID,
        width: "130px",
        cell: row => {
          return (
            <div className="flex gap-2 justify-center align-center rounded-full">
              <ButtonIcon
                onClick={() => this.onViewOne(row.id)}
                icon="view_show"
                buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm"
                className="w-5"
              />
            </div>
          )
        },
      },
    ]

    // ** Main and secondary actions of out layout
    const actions = {
      secondaries: [
        // {
        //   onClick: this.exportToNubis,
        //   title: this.t("Enviar créditos a Nubiz"),
        //   // checkPermissions: "update",
        //   visible: !!this.props.companyID,
        //   disabled: disableEverything,
        // },
      ],
      // search: {
      //   onSearch: this.onSearch,
      //   onClearClick: this.searchClear,
      //   title: this.t("Search"),
      // },
    }

    return (
      // <LayoutWithSidebar
      //   main={{ className: "text-content-400" }}
      //   header={{
      //     breadcrumbs: this.breadcrumbs,
      //   }}
      //   container={{ className: "px-8 !ml-0 mt-8 w-[80vw]" }}
      //   actions={actions}
      //   loading={loading || this.state.loading}
      // >
      //   <ToastContainer />
        <div className="flex flex-col gap-5">
          <p className="mt-12 font-bold text-lg self-start"> Total de créditos: <small>{sumaTotalBalance}</small></p>
          <StyledDataTable
            data={balances}
            columns={columns}
            selectableRows={windowWidth > 600}
            query={this.props.users.query}
            getDataFunction={this.getBalance}
            // pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            multipleFunctionLabel={this.t("Acción múltiple")}
            multipleFunctionIcon={"view_show"}
            onRowExpand={() => {}}
          />
        </div>
      // </LayoutWithSidebar>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.current,
    users: state.users.list,
    user: state.users.current,
    transaction: state.transactions.current,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAll: params => dispatch(userActions.getAll(params)),
    onSaveUser: params => dispatch(userActions.saveOrUpdate(params)),
    onGetCompanies: params => dispatch(companyActions.getAll(params)),
    onRemove: id => dispatch(userActions.del(id)),
    onSendColaboradoresMail: ids =>
      dispatch(userActions.sendInvatationMails(ids)),
    onGetBalances: params => dispatch(userActions.getBalance(params)),
    onGetCompaniesByOwner: params =>
      dispatch(companyActions.getByOwner(params)),
    onSaveTrx: params => dispatch(trxActions.saveOrUpdate(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UsersHistory))
