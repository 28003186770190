const types = {
  CLEAR_CURRENT: 'CHALLENGES_CLEAR_CURRENT',
  GET_REQUEST: 'CHALLENGES_GET_REQUEST',
  GET_SUCCESS: 'CHALLENGES_GET_SUCCESS',
  GET_FAILURE: 'CHALLENGES_GET_FAILURE',

  SAVE_REQUEST: 'CHALLENGES_SAVER_REQUEST',
  SAVE_SUCCESS: 'CHALLENGES_SAVE_SUCCESS',
  SAVE_FAILURE: 'CHALLENGES_SAVE_FAILURE',

  GETALL_REQUEST: 'CHALLENGES_GETALL_REQUEST',
  GETALL_SUCCESS: 'CHALLENGES_GETALL_SUCCESS',
  GETALL_FAILURE: 'CHALLENGES_GETALL_FAILURE',

  DELETE_REQUEST: 'CHALLENGES_DELETE_REQUEST',
  DELETE_SUCCESS: 'CHALLENGES_DELETE_SUCCESS',
  DELETE_FAILURE: 'CHALLENGES_DELETE_FAILURE',    

  UPDATE_REQUEST: 'CHALLENGES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CHALLENGES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CHALLENGES_UPDATE_FAILURE', 


  ///challenges/:id/activity
  GET_ACTIVITY_REQUEST: 'CHALLENGES_GET_ACTIVITY_REQUEST',
  GET_ACTIVITY_SUCCESS: 'CHALLENGES_GET_ACTIVITY_SUCCESS',
  GET_ACTIVITY_FAILURE: 'CHALLENGES_GET_ACTIVITY_FAILURE', 

 ///challenges/:id/leaderboard
  GET_LEADERBOARD_REQUEST: 'CHALLENGES_GET_LEADERBOARD_REQUEST',
  GET_LEADERBOARD_SUCCESS: 'CHALLENGES_GET_LEADERBOARD_SUCCESS',
  GET_LEADERBOARD_FAILURE: 'CHALLENGES_GET_LEADERBOARD_FAILURE', 


    ///challenges/:id/awards
  GET_AWARDS_REQUEST: 'CHALLENGES_GET_AWARDS_REQUEST',
  GET_AWARDS_SUCCESS: 'CHALLENGES_GET_AWARDS_SUCCESS',
  GET_AWARDS_FAILURE: 'CHALLENGES_GET_AWARDS_FAILURE', 

  POST_AWARDS_REQUEST: 'CHALLENGES_POST_AWARDS_REQUEST',
  POST_AWARDS_SUCCESS: 'CHALLENGES_POST_AWARDS_SUCCESS',
  POST_AWARDS_FAILURE: 'CHALLENGES_POST_AWARDS_FAILURE',
  
  ///challenges/:id/impact
  GET_IMPACT_REQUEST: 'CHALLENGES_GET_IMPACT_REQUEST',
  GET_IMPACT_SUCCESS: 'CHALLENGES_GET_IMPACT_SUCCESS',
  GET_IMPACT_FAILURE: 'CHALLENGES_GET_IMPACT_FAILURE',


  ///challenges/:id/participants
  JOIN_REQUEST: 'CHALLENGES_JOIN_REQUEST',
  JOIN_SUCCESS: 'CHALLENGES_JOIN_SUCCESS',
  JOIN_FAILURE: 'CHALLENGES_JOIN_FAILURE', 
  
  GET_PARTICIPANTS_REQUEST: 'CHALLENGES_GET_PARTICIPANTS_REQUEST',
  GET_PARTICIPANTS_SUCCESS: 'CHALLENGES_GET_PARTICIPANTS_SUCCESS',
  GET_PARTICIPANTS_FAILURE: 'CHALLENGES_GET_PARTICIPANTS_FAILURE', 

  POST_ACTIVITIES_REQUEST: 'CHALLENGES_POST_ACTIVITIES_REQUEST',
  POST_ACTIVITIES_SUCCESS: 'CHALLENGES_POST_ACTIVITIES_SUCCESS',
  POST_ACTIVITIES_FAILURE: 'CHALLENGES_POST_ACTIVITIES_FAILURE',

  GET_ACTIVITIES_REQUEST: 'CHALLENGES_GET_ACTIVITIES_REQUEST',
  GET_ACTIVITIES_SUCCESS: 'CHALLENGES_GET_ACTIVITIES_SUCCESS',
  GET_ACTIVITIES_FAILURE: 'CHALLENGES_GET_ACTIVITIES_FAILURE', 

  CLOSE_REQUEST: 'CHALLENGES_CLOSE_REQUEST',
  CLOSE_SUCCESS: 'CHALLENGES_CLOSE_SUCCESS',
  CLOSE_FAILURE: 'CHALLENGES_CLOSE_FAILURE', 

  START_REQUEST: 'CHALLENGES_START_REQUEST',
  START_SUCCESS: 'CHALLENGES_START_SUCCESS',
  START_FAILURE: 'CHALLENGES_START_FAILURE', 

  CHALLENGE_BENEFIT_REQUEST: 'CHALLENGES_BENEFIT_REQUEST',
  CHALLENGE_BENEFIT_SUCCESS: 'CHALLENGES_BENEFIT_SUCCESS',
  CHALLENGE_BENEFIT_FAILURE: 'CHALLENGES_BENEFIT_FAILURE',
};

export default types