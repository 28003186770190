const types = {
  CLEAR_CURRENT: 'ACCOUNTS_CLEAR_CURRENT',
  GET_REQUEST: 'ACCOUNTS_GET_REQUEST',
  GET_SUCCESS: 'ACCOUNTS_GET_SUCCESS',
  GET_FAILURE: 'ACCOUNTS_GET_FAILURE',

  SAVE_REQUEST: 'ACCOUNTS_SAVE_REQUEST',
  SAVE_SUCCESS: 'ACCOUNTS_SAVE_SUCCESS',
  SAVE_FAILURE: 'ACCOUNTS_SAVE_FAILURE',

  GETALL_REQUEST: 'ACCOUNTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'ACCOUNTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'ACCOUNTS_GETALL_FAILURE',

  DELETE_REQUEST: 'ACCOUNTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'ACCOUNTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'ACCOUNTS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'ACCOUNTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ACCOUNTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ACCOUNTS_UPDATE_FAILURE'    

};

export default types;