import React from "react";
import Chart from "react-apexcharts";

export default function ApexBarChart(props) {
	let series = props.series//[{data: [21, 22, 10, 28, 16, 21, 13, 30]}]
	let options = {
    chart: {
      height: 350,
      type: 'bar',
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      }
    },
    colors: ['#297f87', '#ffc93f', '#0fb9b1', '#759699'],
    //colors: colors,
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: props.categories,
      labels: {
        style: {
          //colors: colors,
          fontSize: '12px'
        }
      }
    }
  }

	return (
		<div id="chart" >
      <h2>{props.title}</h2>
			<Chart options={options} series={series} type="bar" height={350}  />
		</div>
    )
}


          
            
