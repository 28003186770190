import React from "react";
import {connect} from 'react-redux';
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { ToastContainer } from "../../libraries/notifications";
import { history } from "../../routes";
import config from "../../config";

import trxActions from "../../context/transactions/actions";
import usersActions from "../../context/users/actions";

import CompanyHistory from "../../components/customs/CompanyHistory"
import UsersHistory from "../../components/customs/UsersHistory"

function Credits({clearTrx}) {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    clearTrx();
    setMounted(true);
  }, [clearTrx])

  const tab = history.location.pathname.split('/')[2];

  return (
    <LayoutWithSidebar
      main={{ className: "text-content-400" }}
      container={{ className: "px-12 pb-12 " }}
    >
      <ToastContainer />
      {mounted && <section className="flex flex-col items-center w-full">
        <h3 className="self-start text-xl">Balance e Historial de Créditos</h3>
        <div className='mt-4 flex gap-4 mb-5'>
          <button onClick={() => history.push(config.ROUTES.CREDITS_COMPANY)} className={tab === 'company' ? 'btn-primary rounded-md px-4 py-2' : ''}>Empresa</button>
          <button onClick={() => history.push(config.ROUTES.CREDITS_COLLABORATORS)} className={tab === 'collaborators' ? 'btn-primary rounded-md px-4 py-2' : ''}>Colaboradores</button>
        </div>

        {tab === 'company' && <CompanyHistory />}
        {tab === 'collaborators' && <UsersHistory  />}
      </section>}
    </LayoutWithSidebar>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    clearTrx: () => dispatch(trxActions.clearCurrentList()),
    clearUsers: () => dispatch(usersActions.clearCurrentList()),
  }
}

export default connect(null, mapDispatchToProps)(Credits);
