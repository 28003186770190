import React from 'react';
import { Field, Form } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PanelWindow from '../../../components/PanelWindow';
import FormLayout from '../../../components/forms/FormLayout';
import SelectInput from '../../../components/forms/SelectInput';
import TextInput from '../../../components/forms/TextInput';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import config from '../../../config';

import businessesActions from '../../../context/businesses/actions';
import companiesActions from '../../../context/companies/actions';
import filesActions from '../../../context/files/actions';
import rolesActions from '../../../context/roles/actions';
import usersActions from '../../../context/users/actions';
import transactionActions from '../../../context/transactions/actions';

import { ToastContainer, notify } from '../../../libraries/notifications';
import { capitalize, composeValidators, formatOnlyNumberInput, getOwner, validateEmail, validateIsfilled, } from '../../../libraries/utils';
import { history } from '../../../routes';


class Administradores extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      creatingNew: false,
      submitting: false,
      view: false,
      editing: false,
      user: {},
      roles: [],
      company_id: '',
      companyOptionsInState: [],
      transactions: [],
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Administrador'), this.t('New')];
    this.titles = this.t('User details');  
  }

  componentDidMount() {
    const params = this.props.match.params;
    this.getData();
    if (!params.id) {
      this.setState({ creatingNew: true });
    }
    
    this.getDataUser(params)

  }

  getDataUser = async (params) => {
    await this.getCompaniesAreasSucursales()

    if (params.id) {
      this.breadcrumbs[1] = this.t('Edit');
      this.setState({ view: true });
      this.getUser(params.id);
    }

  }

  getCompaniesAreasSucursales = async () => {
    await this.getCompany()
    await this.getAreas()
    await this.getSucursales()
  }

  getCompany = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      if(this.props.companies.items.length){
        this.setState({company_id: this.props.companies.items[0].id});
      }
    }
  }

  getAreas = async () => {
    await this.props.onGetRoles({ type: 'group', owner: this.state.company_id });
    const { roles } = this.props;
    if (roles.error) {
      notify(this.t(roles.error.message));
    } else {
      this.setState({areas: roles.items});
    }
  }

  getSucursales = async () => {
    await this.props.onGetBusinesses({ type: 'subsidiaries', owner: getOwner(this.props.auth.user) });
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      this.setState({sucursales: businesses.items});
    }
  }

  getData = async () => {
    const { user } = this.props.auth;
    await this.props.onGetRoles();
    const { roles } = this.props;
    if (roles.error) {
      notify(this.t(roles.error.message));
    } else {
      this.setState({ roles: [...roles.items] });
      if (user.type.includes(config.ROLES.COMPANIES)) {
        //await this.props.onGetCompanies({ owner: user.id });
        await this.props.onGetCompanies({ owner: getOwner(user) });
        const { companies } = this.props;
        if (companies.error) {
          notify(this.t(companies.error.message));
        } else {
          this.setState({
            company_id: this.props.companies.items[0].id
          });
        }
      }
    }
  };

  getUser = async id => {
    await this.props.onGetUser({ id });
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      const roles = this.props.user.item?.role;
      let role;
      if (roles && roles.id && roles.name) {
        role = roles.id.split('|')[0];
      }
      
      this.setState({
        user: { ...this.props.user.item, role }
      });
    }
  };

  componentWillUnmount() {
    this.props.onClearCurrentUser();
  }

  onDelete = id => {
    this.props.onDelUser(id);
  };

  gotoBack = user => {
    if (!this.props.location.state) {
      history.push(config.ROUTES.ADMINISTRATORS);
    } else {
      const { location, from } = this.props.location.state;
      if (from === config.ROUTES.BOOK.USER) {
        history.push({
          pathname: user ? config.ROUTES.BOOK.SERVICE : config.ROUTES.BOOK.USER,
          state: {
            location,
            user: user || undefined
          }
        });
      } else {
        history.push(config.ROUTES.ADMINISTRATORS);
      }
    }
  };

  onSubmit = async values => {
    const { creatingNew } = this.state
    this.setState({ submitting: true });

    let data = {
      phone: values.phone,
      email: values.email,
      password: values.password,
      user_type: "companies",
    };
    if(this.state.user?.id){
      data.id = this.state.user.id
    } else {
      data.role = '846f1650-2275-4394-b75e-a16605ec8922';
    }
    if (creatingNew) {
      data.first_name = values.first_name;
      data.last_name = values.last_name;
      data.status = "active"
    } else {
      data.json_data = {
        first_name: values.first_name,
        last_name: values.last_name,
      }
    }
    
    if(!this.state.user?.id){
      data.welcome_email = false
      data.verify_email = true
      data.invitation_email = true
      data.invitation_email_data = {
        domain_name: window.location.origin,
      }
    }

    await this.props.onSaveUser(data);
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    } else {
      this.gotoBack(this.props.user.item);
    }
  };


  disableEnableUser = async () => {
    const { user } = this.state;
    const data = {
      id: user.id,
      enabled: user.enabled,
    };
    await this.props.onSaveUser(data);
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    }
  };

  getTransactions = async () => {
    let params = {
      owner: this.props.auth.user.unique_id,
      
    }//owner: this.props.auth.user.unique_id
    await this.props.onGetAllTransactions(params);
    const { transactions } = this.props;
    if (transactions.error) {
      notify(this.t(transactions.error.message));
    } else {
      this.setState({ transactions: transactions.items, });
    }
  }

  
  createTransaction = async () => {
    this.onSaveTransaction({
      
    })
  }

  render() {
    const { view, creatingNew, editing, submitting, user } = this.state;
    const { auth } = this.props;

    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert',
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.ADMINISTRATORS),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing
        },
        {
          onClick: e => {
            if (user.enabled === 1) this.setState({ user: { ...user, enabled: 0 } }, () => this.disableEnableUser());
            if (user.enabled === 0) this.setState({ user: { ...user, enabled: 1 } }, () => this.disableEnableUser());
          },
          title: this.t(user.enabled ? 'Disable' : 'Enable'),
          icon: !user.enabled ? 'view_show' : 'view_hide',
          disabled: submitting || this.props.user.loading,
          checkPermissions: 'insert',
          visible: !creatingNew
        },
      ]
    };

    const sucursalesOptions = this.state.sucursales ?  this.state.sucursales.map(s => {return {label: s.name, value: s.id}}) : []
    const areasOptions = this.state.areas ?  this.state.areas.map(s => {return {label: s.name, value: s.id}}) : [];

    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');
    // CHECK EMAIL
    const checkEmail = value => (validateEmail(value) || (view && !editing) ? undefined : this.t('Not a valid email'));

    const validateForm = values => {
      const errors = {};
      errors.first_name = required(values.first_name);
      errors.last_name = required(values.last_name);
      errors.email = checkEmail(values.email);
      //errors.phone = required(values.phone);
      // errors.user_type = required(values.user_type);
      return errors;
    };

    if (user.json_data) {
      user.first_name = user.json_data.first_name;
      user.last_name = user.json_data.last_name;
    }

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outterTitle={this.titles} loading={this.props.user.loading}>
          <Form
            initialValues={Object.assign(user || {}, {
            })}
            onSubmit={this.onSubmit}
            validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={handleSubmit} values={values}>
                  <div className="flex md:flex-row flex-col flex-wrap -mx-2">
                    <div className="md:w-1/2 sm:w-3/3 px-2">
                      <Field
                        name="first_name"
                        component={TextInput}
                        label={this.t('First name')}
                        readOnly={view}
                      />
                    </div>
                    <div className="md:w-1/2 sm:w-3/3 px-2">
                      <Field
                        name="last_name"
                        component={TextInput}
                        label={this.t('Last name')}
                        readOnly={view }
                      />
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col -mx-2">
                    <div className="w-full md:w-1/3 px-2">
                      <Field
                        name="phone"
                        component={TextInput}
                        label={this.t('Phone')}
                        readOnly={view}
                        parse={v => formatOnlyNumberInput(v)}
                      />
                    </div>
                    <div className="w-full md:w-1/3 px-2">
                      <Field
                        name="email"
                        component={TextInput}
                        label={this.t('Email')}
                        validate={composeValidators(required, checkEmail)}
                        parse={ v => v.trim() }
                        readOnly={view}
                      />
                    </div>
                    <div className="w-full md:w-1/3 px-2">
                      <Field
                        type='password'
                        name="password"
                        inputClassName="grow"
                        component={TextInput}
                        label={this.t('Password')}
                        validate={required}
                        readOnly={view}
                      />
                    </div>
                  </div>
                  {auth.user.roles.includes(config.ROLES.COMPANIES) && <div className="flex">
                    <div className="w-full sm:w-4/6 lg:w-5/12 px-2">
                      <Field name="Área" component={SelectInput} label={capitalize(this.t('area'))} options={areasOptions} empty={this.t('Select a value')} readOnly={view && !editing}/>
                    </div>
                    <div className="w-full sm:w-4/6 lg:w-5/12 px-2">
                      <Field name="sucursal" component={SelectInput} label={capitalize(this.t('sucursal'))} options={sucursalesOptions} empty={this.t('Select a value')} readOnly={view && !editing}/>
                    </div>
                  </div>}

                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
        <div className='h-4'/>
        {/* <PanelWindow outterTitle={"TEST"} loading={false}>
          <button onClick={() => this.getTransactions()} >TEST</button>
          <CreditTable data={transactions} />
        </PanelWindow> */}
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    user: state.users.current,
    roles: state.roles.list,
    files: state.files,
    businesses: state.businesses.list,
    transaction: state.transactions.current,
    transactions: state.transactions.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUser: id => dispatch(usersActions.get(id)),
    onSaveUser: params => dispatch(usersActions.saveOrUpdate(params)),
    onDelUser: id => dispatch(usersActions.del(id)),
    onClearCurrentUser: () => dispatch(usersActions.clearCurrent()),
    onGetRoles: params => dispatch(rolesActions.getAll(params)),
    onGetCompanies: params => dispatch(companiesActions.getAll(params)),
    onGetBusinesses: params => dispatch(businessesActions.getAll(params)),
    onPictureUpload: params => dispatch(filesActions.upload(params)),
    onGetAllTransactions: params => dispatch(transactionActions.getAll(params)),
    onSaveTransaction: params => dispatch(transactionActions.saveOrUpdate(params))

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Administradores));
