import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { capitalize, capitalizePhrase, optionSelectGenerator, validateIsfilled, formatOnlyNumberInput, getOwner, replaceNewLines,  validateURL, parseUrl, } from '../../../libraries/utils';
import FormLayout from '../../../components/forms/FormLayout';
import initiativeActions from '../../../context/initiatives/actions';
import businessesActions from '../../../context/businesses/actions';
import filesActions from '../../../context/files/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import ImageUploadFileInput from '../../../components/forms/ImageUploadFileInput';
import TextInput from '../../../components/forms/TextInput';
import IntegerInput from '../../../components/forms/IntegerInput';
import TextareaInput from '../../../components/forms/TextareaInput';
import SelectInput from '../../../components/forms/SelectInput';
import InitiativeAssistants from './InitiativeAssistants';
import settingsActions from '../../../context/settings/actions';
import { WhenFieldChangesDo } from '../../../libraries/forms';

class Initiative extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      activeTab: 0,
      creatingNew: false,
      editPage: false,
      editing: false,
      files: {},
      imageUpdated: false,
      initiative: {},
      loading: false,
      remove: false,
      rewards: {},
      submitting: false,
      titles: '',
      settings: [],
      view: false
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Iniciativas de Servicio Comunitario'), this.t('New')];
    this.titles = this.t('User details');
  }

  componentDidMount() {
    const params = this.props.match.params;
    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        titles: this.t('Edit Initiative'),
        editPage: true,
        editing: this.props.auth.user.roles.includes(config.ROLES.ADMIN) ? true : false
      });
      this.breadcrumbs = [this.t('Iniciativas de Servicio Comunitario'), this.t('Edit')];
      this.getInitiative(params.id);
    } else {
      this.setState({ titles: this.t('New Initiative'), creatingNew: true });
    }
    this.getSettings();
  }

  getSettings = async () => {
    await this.props.onGetSettings({ type: config.TYPES.SETTINGS.SHARYCO_ADMIN_SETTINGS, owner: config.OWNER.SYSTEM });
    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      this.setState({ settings: settings.items });
    }
  };

  getInitiative = async id => {
    this.setState({ loading: true });
    await this.props.onGetInitiative(id);
    const { initiative } = this.props;
    if (initiative.error) {
      notify(this.t(initiative.error.message));
    } else {
      this.setState({
        initiative: initiative.item,
        files: { img: initiative.item?.json_data?.img }
      });
    }
    this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  goBack = () => {
    history.push(config.ROUTES.INITIATIVES);
  };

  onFileChangeImageInput = async ({ file, data, name }) => {
    const { creatingNew, initiative} = this.state;
    this.setState({ submitting: true });
    let imageIdentifier;
    if (creatingNew) {
      imageIdentifier = `${parseInt(Math.random() * 10 ** 9)}`;
      data.append('identifier', imageIdentifier);
    } else {
      data.append('identifier', `${initiative.id}_${name}`);
    }
    await this.props.onPictureUpload(data);
    const error = this.props.files.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({ imageUpdated: true });
      this.setState({
        files: {
          ...this.state.files,
          [name]: this.props.files.files.fileInfo.location
        }
      });
      this.setState({ submitting: false });
      return this.props.files.files.fileInfo.location;
    }
  };

  onClearImageField = ({ name }) => {
    if (name) this.setState({ files: { ...this.state.files, [name]: null } });
  };

  onSubmit = async values => {
    const { user } = this.props.auth;
    let initiativeOwner = '';
    const data = {
      type: values.type,
      name: values.name,
      details: replaceNewLines(values.details),
      json_data: {
        img: this.state.files && this.state.files.img,
        coins: values.coins,
        points: values.points,
        max_assistants: values.max_assistants,
        place: replaceNewLines(values.place),
        time: replaceNewLines(values.time),
        website: values.website
      }
    };

    if (values.id) data.id = values.id;

    if (user.roles.includes(config.ROLES.INITIATIVES)) {
      //await this.props.onGetBusinesses({ owner: user.id });
      await this.props.onGetBusinesses({ owner: getOwner(user) });
      const { businesses } = this.props;
      if (businesses.error) {
        notify(this.t(businesses.error.message));
        return;
      } else {
        // temporary logic, modify later
        initiativeOwner = businesses.items[0].id;
      }
    }

    await this.props.onSaveOrUpdate({
      ...data,
      owner: initiativeOwner ? initiativeOwner : user.id
    });
    const { initiative } = this.props;
    if (initiative.error) {
      notify(this.t(initiative.error.message));
    } else {
      this.goBack();
    }
  };

  disableEnableInitiative = async () => {
    this.setState({loading: true})
    const { initiative } = this.state;
    const data = { id: initiative.id, enabled: initiative.enabled };
    await this.props.onSaveOrUpdate(data);
    const error = this.props.initiative.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    }
    this.setState({loading: false})
  };

  render() {
    const { activeTab, creatingNew, editPage, editing, loading, files, initiative, submitting, view, settings } = this.state;
    const { type: userType, roles } = this.props.auth.user;

    const pointsAndCoins = {};

    settings.forEach(setting => {
      pointsAndCoins[setting.code] = setting.value;
    });

    const initiativeTypes = optionSelectGenerator(config.OPTIONS.INITIATIVES.TYPES.map(t => this.t(t)));

    // Layout actions
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert',
        visible: !this.props.auth.user.roles.includes('admin')
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.INITIATIVES),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: !this.props.auth.user.roles.includes(config.ROLES.ADMIN) && view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: !this.props.auth.user.roles.includes(config.ROLES.ADMIN) && editing
        },
        {
          onClick: e => {
            if (initiative.enabled === 1)
              this.setState({ initiative: { ...initiative, enabled: 0 } }, () => this.disableEnableInitiative());
            if (initiative.enabled === 0)
              this.setState({ initiative: { ...initiative, enabled: 1 } }, () => this.disableEnableInitiative());
          },
          title: this.t(initiative?.enabled ? 'Disable' : 'Enable'),
          icon: !initiative?.enabled ? 'view_show' : 'view_hide',
          disabled: submitting || this.props.initiative.loading,
          checkPermissions: 'enable',
          visible: !creatingNew
        }
      ]
    };

    // ** Form validation functions
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');
    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      errors.details = required(values.details);
      errors.type = required(values.type);
      errors.website = required(values.website);
      if(values.type ===  'volunteering') errors.form = required(values.form);
      return errors;
    };

    if (initiative.json_data) {
      initiative.max_assistants = initiative.json_data.max_assistants;
      initiative.place = initiative.json_data.place;
      initiative.time = initiative.json_data.time;
      initiative.website = initiative.json_data.website;
      initiative.coins = initiative.json_data.coins;
      initiative.points = initiative.json_data.points;
    }
    const isUrl = value => validateURL(value) || (view && !editing) || !value ? undefined : this.t('Ingresar URL válido');

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8 mb-3 rounded-lg' }}
        actions={actions}
        loading={loading}>
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={loading}>
          <Form initialValues={initiative || {}} onSubmit={this.onSubmit} validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="xl:grid mb-3 md:mb-6">
                    {(!editing || roles.includes(config.ROLES.ADMIN)) &&
                    !creatingNew &&
                    values.json_data &&
                    values.json_data.img ? (
                      <img src={files.img} alt="" className="w-full h-40 object-cover rounded-lg" />
                    ) : (
                      // {this is shown on edit}
                      <Field
                        name="img"
                        img={files.img}
                        component={ImageUploadFileInput}
                        label={capitalize(this.t('image'))}
                        placeholder={this.t('URL de imagen')}
                        inputOnChange={this.onFileChangeImageInput}
                        readOnly={(view && !editing) || !userType.includes(config.ROLES.INITIATIVES)}
                        clickButtonClear={this.onClearImageField}
                        inputClassName="input-bordered shadow-none"
                        noError={true}
                        disabled={view && !editing}
                      />
                    )}
                  </div>
                  <div className="xl:grid xl:grid-cols-2 xl:grid-rows-1 xl:w-1/2">
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <Field
                        name="type"
                        component={SelectInput}
                        label={capitalize(this.t('type'))}
                        placeholder={capitalize(this.t('type'))}
                        options={initiativeTypes}
                        empty={this.t('Seleccionar un tipo')}
                        readOnly={(view && !editing) || !userType.includes(config.ROLES.INITIATIVES)}
                      />
                      <WhenFieldChangesDo
                        field="type"
                        set="coins"
                        action={({ onChange, field, value, becomes, set }) => {
                          let coins = 0;
                          coins = pointsAndCoins[`${value}_coins`];
                          onChange(coins);
                        }}
                      />
                      <WhenFieldChangesDo
                        field="type"
                        set="points"
                        action={({ onChange, field, value, becomes, set }) => {
                          let points = 0;
                          points = pointsAndCoins[`${value}_points`];
                          onChange(points);
                        }}
                      />
                    </div>
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <Field
                        name="name"
                        component={TextInput}
                        placeholder={this.t('First name')}
                        label={this.t('First name')}
                        readOnly={(view && !editing) || !userType.includes(config.ROLES.INITIATIVES)}
                      />
                    </div>
                  </div>
                  <div className="xl:grid xl:grid-cols-3 xl:grid-rows-1 my-3 xl:w-3/4">
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <Field
                        name="coins"
                        component={IntegerInput}
                        placeholder="0"
                        label={capitalizePhrase(this.t('sharycoins'))}
                        // parse={v => formatOnlyNumberInput(v)}
                        readOnly
                      />
                    </div>
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <Field
                        name="points"
                        component={IntegerInput}
                        placeholder="0"
                        label={capitalizePhrase(this.t('sharypoints'))}
                        // parse={v => formatOnlyNumberInput(v)}
                        readOnly
                      />
                    </div>
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <Field
                        name="max_assistants"
                        component={IntegerInput}
                        placeholder={this.t('Máximo de asistentes')}
                        label={this.t('Máxima cantidad de asistentes (Solo sí aplica)')}
                        parse={v => formatOnlyNumberInput(v)}
                        readOnly={(view && !editing) || !userType.includes(config.ROLES.INITIATIVES)}
                      />
                    </div>
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="details"
                      component={TextareaInput}
                      placeholder={capitalize(this.t('details'))}
                      label={capitalize(this.t('details'))}
                      readOnly={(view && !editing) || !userType.includes(config.ROLES.INITIATIVES)}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="place"
                      component={TextareaInput}
                      placeholder="Lugar"
                      label={capitalize(this.t('place'))}
                      readOnly={(view && !editing) || !userType.includes(config.ROLES.INITIATIVES)}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="time"
                      component={TextareaInput}
                      placeholder={capitalize(this.t('time'))}
                      label={capitalize(this.t('time'))}
                      readOnly={(view && !editing) || !userType.includes(config.ROLES.INITIATIVES)}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="website"
                      component={TextInput}
                      placeholder={capitalize(this.t('link a detalles de iniciativa'))}
                      label={capitalize(this.t('link a detalles de iniciativa'))}
                      readOnly={(view && !editing) || !userType.includes(config.ROLES.INITIATIVES)}
                      parse={parseUrl} validate={isUrl}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="form"
                      component={TextInput}
                      placeholder={capitalize(this.t('Link a formulario de inscripción (sólo aplica para voluntariados)'))}
                      label={capitalize(this.t('Link a formulario de inscripción (sólo aplica para voluntariados)'))}
                      readOnly={(view && !editing) || !userType.includes(config.ROLES.INITIATIVES)}
                      parse={parseUrl} validate={isUrl}
                    />
                  </div>
                </FormLayout>
              );
            }}
          </Form>
          {/* {initiative assistants & assistances table} */}
          {editPage && initiative.id && initiative.json_data ? (
            <React.Fragment>
              <div className="tabs">
                <div
                  className={`tab tab-bordered ${activeTab === 0 ? 'tab-active' : ''}`}
                  onClick={() => this.setState({ activeTab: 0 })}>
                  Asistentes
                </div>
              </div>
              <div className={`${activeTab === 0 ? '' : 'hidden'}`}>
                <InitiativeAssistants
                  initiativeID={initiative.id}
                  maxAssistants={initiative.json_data.max_assistants}
                />
              </div>
            </React.Fragment>
          ) : (
            ''
          )}
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    initiative: state.initiatives.current,
    businesses: state.businesses.list,
    files: state.files,
    settings: state.settings.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(initiativeActions.saveOrUpdate(params)),
    onGetInitiative: id => dispatch(initiativeActions.get(id)),
    onGetBusinesses: params => dispatch(businessesActions.getAll(params)),
    onRemove: id => dispatch(initiativeActions.del(id)),
    onClearCurrent: () => dispatch(initiativeActions.clearCurrent()),
    onPictureUpload: params => dispatch(filesActions.upload(params)),
    onGetSettings: params => dispatch(settingsActions.getAll(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Initiative));
