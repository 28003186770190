const api = {
  LOCALSTORAGE_APP_TOKEN: 'LOCALSTORAGE_APP_TOKEN',
  LOCALSTORAGE_USER_TOKEN: 'REACT_USER_TOKEN_AUTH',
  
  ROLE_CUSTOMER: 1,
  ROLE_PROVIDER: 2,
  USER_ROLE_ID: 1, // User
  QUERIES_PAGE_SIZE: 8,
  API: {
    OAUTH: '/oauth/token',
    INTERNAL: {
      INFO: {
        IP: '/internal/ip/{ip}',
      },
    },    
    FILES: '/files/upload',
    TOKEN: '/users/token',
    LOGIN_SSO: '/connectors/ad/sso',
    LOGIN: '/users/login',
    REGISTER: '/users/register',
    FORGOT: '/users/forgot',
    VERIFY: '/users/verify',
    VERIFY_RETRY: '/users/verify/retry',
    HASH: '/users/hash/{hash}',
    POINTS: '/users/{id}/rewards',
    
    // context
    ACCOUNTS: '/accounts/',
    ACCOUNT: '/accounts/{id}',

    ACTION: '/actions/{id}',
    ACTIONS: '/actions',

    BUSINESSES: '/businesses',
    BUSINESS: '/businesses/{id}',
    
    CATEGORIES: '/categories/',
    CATEGORY: '/categories/{id}',
    
    COMPANIES: '/companies/',
    COMPANY: '/companies/{id}',
    
    INITIATIVE: '/initiatives/{id}',
    INITIATIVES: '/initiatives',
    INITIATIVE_ASSISTANTS: '/initiatives/{id}/assistants',
    INITIATIVE_ASSISTANT: '/initiatives/{initiativeId}/assistants/{id}',
    INITIATIVE_ASSISTANCES: '/initiatives/{id}/assistances',
    INITIATIVE_ASSISTANCE: '/initiatives/{initiativeId}/assistances/{id}',
    
    LOCATION: '/locations/{id}',
    LOCATIONS: '/locations',
    
    MESSAGES: '/messages/',
    MESSAGE: '/messages/{id}',
    
    PERMISSION: '/permissions/{id}',
    PERMISSIONS: '/permissions',
    PERMISSION_ROLES: '/permissions/{id}/roles',
    PERMISSION_ROLE: '/permissions/{permissionId}/roles/{id}',
    
    PRODUCTS: '/products',
    PRODUCT: '/products/{id}',
    
    ROLES: '/roles',
    ROLE: '/roles/{id}',
    ROLE_USERS: '/roles/{id}/users',
    ROLE_USER: '/roles/{roleId}/users/{id}',

    SERVIVE: '/products/{id}',
    SERVICES: '/products',
    
    SETTING: '/settings/{id}',
    SETTINGS: '/settings',
    
    TRANSACTION: '/transactions/{id}',
    TRANSACTIONS: '/transactions',
    
    USER: '/users/{id}',
    USERS: '/users',
    USERS_RANKING: '/users/ranking',
    USERS_BALANCE: '/users/balance',

    BENEFIT: '/benefits/{id}',
    BENEFITS: '/benefits',
    SEND_MAIL: '/internal/mailer/send',

    CHALLENGE: '/challenges/{id}',
    CHALLENGES: '/challenges',
    CHALLENGE_PARTICIPANTS: '/challenges/{id}/participants',
    CHALLENGE_ACTIVITY: '/challenges/{id}/activity',
    CHALLENGE_AWARDS: '/challenges/{id}/benefits',
    CHALLENGE_LEADERBOARD: '/challenges/{id}/leaderboard',
    CHALLENGE_ACTIVITIES: '/challenges/{id}/activities',
    CHALLENGE_CLOSE: '/challenges/{id}/close',
    CHALLENGE_START: '/challenges/{id}/start',
    CHALLENGE_BENEFITS: '/challenges/benefits/{id}',

    JDE_EXCHANGE_RATE: '/connectors/db2/exchange_rate',
    JDE_DOCUMENT_TYPES: {
      INVOICE: '/connectors/db2/f0411la',
      RECEIPT: '/connectors/db2/f0413jb', 
    },
    JDE_INVOICES: '/connectors/db2/invoices',
    JDE_RECEIPTS: '/connectors/db2/receipts',

    INVITES: '/users/{id}/invites',

    SEND_BATCH_INVITATION_MAILS: '/users/send_batch_invitation',
    PAYMENTS_USD: 'https://dolarapi.com/v1/dolares/contadoconliqui'
  },
};
export default api;