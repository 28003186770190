import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import challengesActions from "../../../context/challenges/actions"

import config from '../../../config';
import Icon from "../../../libraries/icons";

import { withRouter } from "react-router-dom"

class impact extends React.Component {
  constructor(props) {
    super(props)
    this.t = this.props.t
    this.state = {
      loading: true,
      leaderboard: [],
      activity: null,
      participants: null,
      points: null,
      challengeODS: []
    }
  }
  
  async componentDidMount() {
    const { id } = this.props.match.params
    await this.props.getLeaderboard(id)
    await this.props.getActivity(id)

    const activity = this.props.activity.filter(act => !act.archived)

    const challenge = this.props.challenge.current
    const leaderboard = challenge?.leaderboard?.leaderboard

    const points = leaderboard.length
      ? leaderboard.map(p => parseInt(p.impact_points))
      : [0]


    let challengeODSActions = {};
    for(let activityItem of activity){
      const itemODS = activityItem.json_data?.action?.ods?.split(",");
      if(itemODS)
        for(let odsCode of itemODS)
          challengeODSActions[odsCode.trim()] = (challengeODSActions[odsCode.trim()] + 1 || 1); 
    }     

    const challengeODS = Object.keys(challengeODSActions).length > 0 
      ? Object.keys(challengeODSActions).map(odsCode => {
          const ods = config.ODS.find(configODS => odsCode.trim() === configODS.code);
          if(ods) return {...ods, actionsAmount: challengeODSActions[odsCode]}
          else return undefined;
        }) 
      : []
    this.setState({
      challengeODS,
      leaderboard,
      activity: activity.length,
      participants: leaderboard?.length,
      points: points.reduce((acc, o) => acc + parseInt(o), 0),
      loading: false,
    })

    
  }


  render() {
    const { participants, points, activity, challengeODS } = this.state

    const t = this.t
    return (
      <div className="w-full h-full rounded-lg">
        <div className="flex flex-col gap-3">
          <div className="w-full p-2 flex justify-between items-center border-2 rounded-lg bg-[#CEF7ED] border-white">
            <div className="leading-3	">
              <h4 className="text-[#43C7A0] pb-2">
                {t("Puntos de impacto totales")}
              </h4>
              <span className="text-xs text-[#759699]">
                {points || 0} Puntos
              </span>
            </div>
            <div className="w-10">
              <span />
            </div>
          </div>

          <div className="w-full p-2 flex justify-between items-center border-2 rounded-lg bg-[#D7EEFE] border-white">
            <div className="leading-3	">
              <h4 className="text-[#57ACC1] pb-2">
                {t("Cantidad de acciones")}
              </h4>
              <span className="text-xs text-[#759699]">
                {activity || 0} Acciones
              </span>
            </div>
            <div className="w-10">
              <span />
            </div>
          </div>

          <div className="w-full p-2 flex justify-between items-center border-2 rounded-lg bg-[#FFFCEB] border-white">
            <div className="leading-3	">
              <h4 className="text-[#E3C662] pb-2">
                {t("Cantidad de usuarios que realizaron acciones")}
              </h4>
              <span className="text-xs text-[#759699]">
                {participants || 0} Usuarios
              </span>
            </div>
            <div className="w-10">
              <span />
            </div>
          </div>

          {challengeODS.length > 0 && <div className='mt-8 card shadow-[0_3px_5px_3px_rgba(0,0,0,0.2)] p-2 bg-success bg-opacity-50 mb-4'>
            <h4 className='text-base mb-2 mx-auto'>Este desafío contribuye a estos ODS:</h4>
            <div className='flex mx-auto items-center mb-2'>
              {challengeODS && challengeODS.map((ods,i) => (
                ods && <div key={`${ods?.code}+${i}`}>
                  <img key={ods?.code} src={ods?.squareIcon} alt='ods' className='p-1 mx-2 w-16 h-16' />
                  <p className="text-center text-gray-700">{ods?.actionsAmount} {ods?.actionsAmount > 1 ? "acciones" : "acción"}</p>
                </div>
              ))}
            </div>
          </div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.auth.user,
    challenge: state.challenges,
    activity: state.challenges.current.activity,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLeaderboard: id => dispatch(challengesActions.getLeaderboard(id)),
    getActivity: id => dispatch(challengesActions.getActivity(id)),
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(impact))
)
