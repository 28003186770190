import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { capitalize, isEmptyObject, validateIsfilled, selectGeneratorWObjChild, getOwner, formatOnlyNumberInput, replaceNewLines, validateURL, parseUrl, removeDuplicates, } from '../../../libraries/utils';
import FormLayout from '../../../components/forms/FormLayout';
import prizesActions from '../../../context/benefits/actions';
import filesActions from '../../../context/files/actions';
import usersActions from '../../../context/users/actions';
import companiesActions from '../../../context/companies/actions';
import categoriesActions from '../../../context/categories/actions';
import settingsActions from '../../../context/settings/actions';
import challengesActions from '../../../context/challenges/actions';
import transactionsActions from '../../../context/transactions/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import SelectInput from '../../../components/forms/SelectInput';
import ImageUploadFileInput from '../../../components/forms/ImageUploadFileInput';
import TextareaInput from '../../../components/forms/TextareaInput';
import DatepickerInput from '../../../components/forms/DatepickerInput';
import arrayMutators from 'final-form-arrays';

import CheckboxInput from '../../../components/forms/CheckboxInput';
import IntegerInput from '../../../components/forms/IntegerInput';
import { format } from 'date-fns';
import { WhenFieldChangesSetAnotherField } from '../../../libraries/forms';
import DownshiftInput from '../../../components/forms/DownshiftInput';
import ChallengePrizeTable from './ChallengePrizeTable';

class Prize extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      prize: {},
      creatingNew: false,
      editing: false,
      files: {},
      imageUpdated: false,
      loading: false,
      remove: false,
      setting: null,
      submitting: false,
      titles: '',
      view: false,
      prizeUses: []
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [capitalize(this.t('prizes')), this.t('New')];
    this.titles = this.t('Prizes details');
  }
  async componentDidMount() {
    const params = this.props.match.params;
    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        titles: this.t('Edit benefit')
      });
      this.breadcrumbs = [capitalize(this.t('prizes')), this.t('Edit')];
      this.getPrize(params.id);
      this.getPrizeUses(params.id);
    } else {
      this.setState({ titles: this.t('New Benefit'), creatingNew: true });
    }
    await this.getSettings();
    await this.getChallenges();
  }

  getSettings = async () => {
    await this.props.onGetSettings({ code: config.TYPES.SETTINGS.CODES.SHARYCO_LEVELS });
    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      this.setState({ setting: settings.items[0] });
    }
  };

  getPrize = async id => {
    this.setState({ loading: true });
    await this.props.ongetPrize(id);
    const { prize } = this.props;
    if (prize.error) {
      notify(this.t(prize.error.message));
    } else {
      this.setState({
        prize: prize.item,
        files: { picture: prize.item.json_data?.picture },
        loading: prize.loading
      });
    }
    this.setState({ loading: false });
  };

  getPrizeUses = async id => {
    this.setState({ loading: true });
    let params = {
      target: id,
      type: config.TRANSACTIONS.BENEFIT.TYPE,
      owner: this.props.auth.user.unique_id,
    };
    await this.props.onGetTransactions(params);
    const { items, error } = this.props.transactions;
    if (error) {
      notify(error.message);
    } else {
      const transactionConsumed = items.filter( t => t.status === 'consumed') 
      console.log("TRX PRIZE", items)
      this.setState({ prizeUses: [...transactionConsumed]})
    }
    this.setState({ loading: false });
  }


  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  goBack = () => {
    history.push(config.ROUTES.PRIZES);
  };

  onFileChangeImageInput = async ({ file, data, name }) => {
    const { creatingNew, files, prize } = this.state;
    this.setState({ submitting: true });
    let imageIdentifier;
    if (creatingNew) {
      imageIdentifier = `${parseInt(Math.random() * 10 ** 9)}`;
      data.append('identifier', imageIdentifier);
    } else {
      data.append('identifier', `${prize.id}_${name}`);
    }
    await this.props.onPictureUpload(data);
    const error = this.props.files.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({ imageUpdated: true });
      this.setState({
        files: {
          ...files,
          [name]: this.props.files.files.fileInfo.location
        }
      });
      this.setState({ submitting: false });
      return this.props.files.files.fileInfo.location;
    }
  };

  onClearImageField = ({ name }) => {
    if (name) this.setState({ files: { ...this.state.files, [name]: null } });
  };


  onSubmit = async values => {
    let code = parseInt(Math.random() * 10 ** 9)
      .toString()
      .padStart(9, '0');
    
    const owner = await this.getCompanies()
    const data = {
      type: config.TYPES.BENEFITS.PRIZE,
      name: values.name,
      pack: values.pack ?? null,
      detail: replaceNewLines(values.detail),
      owner: owner[0].id,
      code,
      enabled: this.state.prize?.enabled || true,
      json_data: {
        url: values.url,
        extra_info: replaceNewLines(values.extra_info),
        picture: this.state.files.picture,
        required_level: values.required_level,
        instructions: replaceNewLines(values.instructions),
        rules: {
          start_date: values.start_date,
          end_date: values.end_date,
          group_uses: parseInt(values.group_uses), // maximo de usos por negocio
          group_uses_interval: parseInt(values.group_uses_interval), // intervalo de tiempo entre cada uso de negocio
          user_uses: parseInt(values.user_uses) || 1, // maximo de usos por usuario
          user_uses_interval: 0,//parseInt(values.user_uses_interval), // intervalo de tiempo entre cada uso de usuario
        }
      }
    };

    // agregar id para que se edite
    if (values.id) data.id = values.id;

    this.setState({ loading: true });
    await this.props.onSaveOrUpdate({ ...data });
    const { prize } = this.props;
    if (prize.error) {
      notify(this.t(prize.error.message));
    } else {
      this.goBack();
    }
  };

  disableEnablePrize = async () => {
    const { id, enabled } = this.state.prize;
    await this.props.onSaveOrUpdate({id, enabled});
    if (this.props.prize.error) {
      notify(this.t(this.props.prize.error.message));
      this.setState({ submitting: false });
    }
  };

  getCompanies = async () => {
    //await this.props.onGetCompanies({ owner: this.props.auth.user.id });
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });
    
    if (this.props.companies.error) {
      notify(this.t(this.props.companies.error.messsage));
    } else {
      return this.props.companies.items
    }
  };


  getChallenges = async ( params = {} ) => {
    await this.props.onGetChallengeBenefit(this.state.prize.id);
    const { error, items = [] } = this.props.challengeBenefit;
    if (error) {
      notify(this.t(error.message));
    } else {
      if(items.length === 0 ) return
      params.id = items.map( c => { return c.challenge })

      await this.props.onGetChallenges(params);
      const { challenges } = this.props;
      if (challenges.error) {
        notify(this.t(challenges.error.message));
      } else {
        this.setState({ challenges: challenges.items });
      }
    }
  };

  render() {
    const { prize, creatingNew, prizeUses, editing, files, loading, setting, submitting, view, groups, challenges } = this.state;

    // generar objeto json sin anidado
    const flatJSON = { ...prize?.json_data };
    // generar objeto rules sin anidado tras parsear el json
    const flatRules = { ...prize?.json_data?.rules };
    // crear el objeto privado (dif del estado)
    const _prize = {};

    // mapear prize al objeto _prize
    if (!isEmptyObject(prize) && prize !== undefined && prize !== null) {
      // console.log("__prize",_prize)
      for (let key of Object.keys(prize)) {
        // se agregan las propiedades que no estaban nesteadas primero
        if (key !== 'json_data' && key !== 'rules') {
          _prize[key] = prize[key];
        }
      }

      // luego se cargan las propiedades desanidadas
      for (let jsonKey of Object.keys(flatJSON)) {
        _prize[jsonKey] = flatJSON[jsonKey];
      }
      for (let rulesKey of Object.keys(flatRules)) {
        _prize[rulesKey] = flatRules[rulesKey];
      }
    }

    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert'
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.PRIZES),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },

        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: this.props.auth.user.roles.includes(config.ROLES.COMPANIES) && view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing
        },
        {
          onClick: e => {
            if (prize.enabled === 1)
              this.setState({ prize: { ...prize, enabled: 0 } }, () => this.disableEnablePrize());
            if (prize.enabled === 0)
              this.setState({ prize: { ...prize, enabled: 1 } }, () => this.disableEnablePrize());
          },
          title: this.t(prize?.enabled ? 'Disable' : 'Enable'),
          icon: !prize?.enabled ? 'view_show' : 'view_hide',
          disabled: submitting || this.props.prize.loading,
          checkPermissions: 'enable',
          visible: !creatingNew
        }
      ]
    };

    const levels = [];

    for (let i = 0; i <= setting?.value; i++) {
      levels.push(i.toString());
    }

    
    const levelOptions = selectGeneratorWObjChild(levels, null, (o) => `${capitalize(this.t('level'))} ${o}`);
    const possibleIntervals = selectGeneratorWObjChild(config.BENEFITS.INTERVALS, 'value', o => capitalize(this.t(o.label)));
     
    const groupsOptions =  this.state.groups ? this.state.groups.filter((value, index, self) => self.indexOf(value) === index).map(p => {return {label: p, value: p}}) : []

    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');

    const mayorQueCero= (value, isUnlimited)=> (value > 0)  || isUnlimited? undefined : this.t("This field must be greater than 0");

    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      errors.detail = required(values.detail);
      errors.required_level = required(values.required_level);
      errors.group_uses = mayorQueCero(values.group_uses,values.unlimited_group_uses);
      return errors;
    };

    const isUrl = value => validateURL(value) || (view && !editing) || !value ? undefined : this.t('Ingresar URL válido');

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={loading}>
          <Form
            initialValues={_prize || {}}
            onSubmit={this.onSubmit}
            mutators={{
              ...arrayMutators
            }}
            validate={validateForm}>
            {({handleSubmit,form,submitting,pristine,values,form: {mutators: { push, pop }}}) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="w-full mb-3 md:mb-6">
                    {!editing && !creatingNew && files ? (
                      <div className='flex justify-center items-center'>
                        <img src={files.picture} alt="" className="h-48 md:h-96 object-cover rounded-lg" />
                      </div>
                    ) : (
                      <Field
                        name="picture"
                        img={files.picture}
                        component={ImageUploadFileInput}
                        label={capitalize(this.t('image'))}
                        placeholder={capitalize(this.t('image URL'))}
                        inputOnChange={this.onFileChangeImageInput}
                        disabled={view && !editing}
                        clickButtonClear={this.onClearImageField}
                        inputClassName="input-bordered shadow-none cursor-pointer"
                        noError={true}
                      />
                    )}
                  </div>
                  <div className="xl:flex">
                    <Field
                      name="name"
                      component={TextInput}
                      placeholder={capitalize(this.t('name of prize'))}
                      label={capitalize(this.t('name of prize'))}
                      readOnly={view && !editing}
                      className="px-3 mb-3 md:mb-0 xl:w-1/3"
                    />
                    <Field
                      name="required_level"
                      component={SelectInput}
                      label={capitalize(this.t('required level (0 means not required)'))}
                      placeholder={capitalize(this.t('required level'))}
                      options={levelOptions}
                      empty={this.t('Select a required level')}
                      readOnly={view && !editing}
                      className="px-3 mb-3 md:mb-0 xl:col-span-2"
                    />
                  </div>
                  <Field
                    name="detail"
                    component={TextareaInput}
                    placeholder={capitalize(this.t('details'))}
                    label={capitalize(this.t('details'))}
                    readOnly={view && !editing}
                    className="px-3 mb-3 md:mb-0"
                  />
                  <Field
                    name="instructions"
                    component={TextareaInput}
                    placeholder={capitalize(this.t('instructions'))}
                    label={this.t('Instrucciones para el uso del descuento')}
                    readOnly={view && !editing}
                    className="px-3 mb-3 md:mb-0"
                  />
                  <Field
                    name="extra_info"
                    component={TextareaInput}
                    placeholder={capitalize(this.t('extra info'))}
                    label={capitalize(this.t('extra info'))}
                    readOnly={view && !editing}
                    className="px-3 mb-3 md:mb-0"
                  />
                  <Field
                    name="url"
                    component={TextInput}
                    placeholder={capitalize(this.t('website'))}
                    label={capitalize(this.t('website'))}
                    readOnly={view && !editing}
                    className="px-3 mb-3 md:mb-0 xl:w-1/3"
                    parse={parseUrl} validate={isUrl}
                  />

                  <Field
                    name="pack"
                    component={DownshiftInput}
                    placeholder={capitalize(this.t('Grupo'))}
                    label={capitalize(this.t('Grupo'))}
                    items={groups ? removeDuplicates(groups).map(g => {return {value: g, label: g}}).filter(x => x.label !== undefined) : []}
                    readOnly={view && !editing}
                    className="px-3 mb-3 md:mb-0 xl:w-1/3"
                  />
                  <div className=''>
                    <hr />
                      <h2 className="text-xl px-3 mb-3">{capitalize(this.t('Reglas de uso del beneficio'))}</h2>
                      <div className='flex flex-col md:flex-row md:justify-between'>
                        <div>
                        <h3 className="px-3 mb-3 text-lg ml-3">{capitalize(this.t('company'))}:</h3>
                        <div className="flex ml-8">
                          <Field
                            name="unlimited_group_uses"
                            component={CheckboxInput}
                            placeholder={capitalize(this.t('unlimited'))}
                            label={capitalize(this.t('unlimited'))}
                            readOnly={view && !editing}
                            type="checkbox"
                            labelClassName="mr-8"
                          />
                          <Field
                            name="group_uses"
                            component={IntegerInput}
                            placeholder={capitalize(this.t('cantidad'))}
                            label={capitalize(this.t('cantidad inicial'))}
                            parse={v => formatOnlyNumberInput(v)}
                            readOnly={view && !editing}
                            disabled={values.unlimited_group_uses === true}
                            className="mr-4"
                          />
                          {/* <Field
                            name="group_uses_interval"
                            component={SelectInput}
                            label={capitalize(this.t('interval between uses'))}
                            placeholder={capitalize(this.t('interval'))}
                            options={possibleIntervals}
                            empty={capitalize(this.t('without interval'))}
                            readOnly={view && !editing}
                            disabled={values.unlimited_group_uses === true}
                          /> */}
                          {/* <WhenFieldChangesSetAnotherField
                            set="group_uses_interval"
                            field="unlimited_group_uses"
                            becomes="1"
                            to="0"
                          /> */}
                          <WhenFieldChangesSetAnotherField
                            set="group_uses"
                            field="unlimited_group_uses"
                            becomes="1"
                            to="0"
                          />
                      </div>
                        </div>
                        
                      <div>
                        <h3 className="px-3 mb-3 text-lg ml-3">{capitalize(this.t('Colaboradores'))}:</h3>
                        <div className="flex ml-8">
                          <Field
                            name="unlimited_user_uses"
                            component={CheckboxInput}
                            placeholder={capitalize(this.t('unlimited'))}
                            label={capitalize(this.t('unlimited'))}
                            readOnly={view && !editing}
                            type="checkbox"
                            labelClassName="mr-8"
                          />
                        <Field
                            name="user_uses"
                            component={IntegerInput}
                            placeholder={capitalize(this.t('uses'))}
                            label={capitalize(this.t('usos por colaborador'))}
                            parse={v => formatOnlyNumberInput(v)}
                            readOnly={view && !editing}
                            disabled={values.unlimited_user_uses === true}
                            className="mr-4"
                            defaultValue={0}
                          />
                          <Field
                            name="user_uses_interval"
                            component={SelectInput}
                            placeholder={capitalize(this.t('interval'))}
                            label={capitalize(this.t('interval between uses'))}
                            options={possibleIntervals}
                            empty={capitalize(this.t('without interval'))}
                            readOnly={view && !editing}
                            disabled={values.user_uses <= 1}
                          />
                          <WhenFieldChangesSetAnotherField set="user_uses_interval" field="user_uses" becomes="1" to="0" />
                          {/* <WhenFieldChangesSetAnotherField set="user_uses_interval" field="unlimited_user_uses" becomes="1" to="0"/> */}
                        </div>
                      </div>
                    </div>

                    <div className="flex px-3 gap-5">
                      <Field
                        name="start_date"
                        component={DatepickerInput}
                        placeholder={capitalize(this.t('start date'))}
                        label={capitalize(this.t('start date'))}
                        readOnly={view && !editing}
                        className="pr-3 z-20"
                      />
                      <Field
                        name="end_date"
                        component={DatepickerInput}
                        placeholder={capitalize(this.t('end date'))}
                        label={capitalize(this.t('end date'))}
                        readOnly={view && !editing}
                        className="z-20"
                      />
                    </div>
                    <div className='p-4 flex flex-col md:flex-row md:justify-between'>
                      <div className='p-4 w-full'>
                        <h4 className='pl-4'>Desafíos con este premio</h4>
                        { challenges && challenges.length > 0 &&
                          <div className='w-full max-h-80 overflow-y-scroll'>
                            <table className='table card white w-full'>
                              <thead>
                                <tr>
                                  <th className='px-4 py-2 bg-accent text-white'>{capitalize(this.t('name'))}</th>
                                  <th className='px-4 py-2 bg-accent text-white'>{capitalize(this.t('img'))}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {challenges.map((c,i) => (
                                  <tr className='cursor-pointer' key={i} onClick={()=> history.push(config.ROUTES.CHALLENGES_EDIT.replace(':id', c.id))}>
                                    <td className='px-4 text-base py-2'><p>{c.name}</p></td>
                                    <td className='px-4 py-2'>
                                      {c.image && 
                                        <div className='flex justify-center items-center'>
                                          <img src={c.image} className='h-20 rounded-lg aspect-video' />
                                        </div>
                                      }
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        }
                      </div>
                      <div className='p-4 w-full'>
                        <h4 className='pl-4'>Prize consumptions{values.group_uses > 0 && ` (${values.group_uses - prizeUses.length} usos disponibles)`}</h4>
                        {prizeUses.length > 0 && <div className='max-h-60 overflow-y-scroll'>
                          <table className='table card white w-full'>
                            <thead>
                              <tr>
                                <th className='px-4 py-2 bg-accent text-white'>{capitalize(this.t('date'))}</th>
                                <th className='px-4 py-2 bg-accent text-white'>{capitalize(this.t('user email'))}</th>
                                <th className='px-4 py-2 bg-accent text-white'>{capitalize(this.t('status'))}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {prizeUses.map((t,i) => (
                                <tr key={i}>
                                  <td className='px-4 text-base py-2'><p>{t.date}</p></td>
                                  <td className='px-4 py-2'><p>{t.json_data.owner.email}</p></td>
                                  <td className='px-4 py-2'><p>{t.status}</p></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>}
                        {prizeUses.length === 0 && <p>No hay consumiciones de este premio</p>}
                      </div>
                    </div>
                  </div>



                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}





const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    prize: state.benefits.current,
    // prizes: state.benefits.list,
    companies: state.companies.list,
    categories: state.categories.list,
    category: state.categories.current,
    files: state.files,
    user: state.users.current,
    settings: state.settings.list,
    challengeBenefit: state.challenges.challengeBenefit,
    challenges: state.challenges.list,
    transactions: state.transactions.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(prizesActions.saveOrUpdate(params)),
    ongetPrize: id => dispatch(prizesActions.get(id)),
    // onGetAllPrizes: params => dispatch(prizesActions.getAll(params)),
    onGetCompanies: params => dispatch(companiesActions.getAll(params)),
    onGetSettings: params => dispatch(settingsActions.getAll(params)),
    onGetTypeName: id => dispatch(categoriesActions.get(id)),
    onGetUser: params => dispatch(usersActions.get(params)),
    onClearCurrent: () => dispatch(prizesActions.clearCurrent()),
    onPictureUpload: params => dispatch(filesActions.upload(params)),
    onGetChallengeBenefit: id => dispatch(challengesActions.getChallengeBenefit(id)),
    onGetChallenges: params => dispatch(challengesActions.getAll(params)),
    onGetTransactions: params => dispatch(transactionsActions.getAll(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Prize));
