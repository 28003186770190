import React from 'react';
import Icon from '../../libraries/icons';

export default function ButtonIcon({ ...rest }) {
  return (
    <button
      type="button"  //bg-white text-gray-600 hover:text-gray-900
      className={"btn " + (rest.buttonClassName ? rest.buttonClassName : "") + ((rest.submitted || rest.disabled) ? " disabled" : "")}
      onClick={() => rest.onClick && rest.onClick(rest.value)}
      disabled={rest.disabled}
    >
      <Icon className={rest.className ? rest.className : "h-4 w-4"} name={rest.icon} />
    </button>
  )
}