import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { capitalize, validateIsfilled, selectGeneratorWObjChild, capitalizePhrase, getObjectWithJsonDataToFormValues, getOwner, validateURL, parseUrl, } from '../../../libraries/utils';
import FormLayout from '../../../components/forms/FormLayout';
import filesActions from '../../../context/files/actions';
import businessActions from '../../../context/businesses/actions';
import companiesActions from '../../../context/companies/actions';
import usersActions from '../../../context/users/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import SelectInput from '../../../components/forms/SelectInput';
import ImageUploadFileInput from '../../../components/forms/ImageUploadFileInput';

class Business extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      business: {},
      loading: true,
      creatingNew: false,
      files: {},
      submitting: false,
      imageUpdated: false,
      roles: [],
      location: '',
      view: false,
      editing: false,
      titles: '',
      userOptions: [],
      remove: false
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [capitalize(this.t('businesses')), this.t('New')];
    this.titles = this.t('User details');
  }

  componentDidMount() {
    const location = this.props.location.pathname.split('/')[1];
    this.setBreadcrumbs(location);
    const params = this.props.match.params;
    if (params.id) {
      this.setState({
        users: [],
        remove: true,
        view: true,
        titles: capitalize(this.t('edit business')),
        editing: this.props.auth.user.roles.includes(config.ROLES.ADMIN) ? true : false,
        location,
        roles: this.props.auth.user.roles
      });
      this.breadcrumbs[1] = this.t('Edit');
      this.getBusiness(params.id);
    } else {
      this.setState({
        titles: capitalize(this.t(`new business`)),
        creatingNew: true,
        loading: false,
        roles: this.props.auth.user.roles,
        location
      });
      this.getUsers();
    }
  }

  setBreadcrumbs = location => {
    if (location === 'businesses') return this.breadcrumbs = [capitalize(this.t('brands')), this.t('New')]
    this.breadcrumbs = [capitalize(this.t(location)), this.t('New')];
  };

  getBusiness = async id => {
    await this.getUsers();
    await this.props.onGet(id);
    const business = this.props.business;
    if (business.error) {
      notify(this.t(business.error.message));
    } else {
      this.setState({
        business: business.item,
        files: { image: business.item?.json_data?.image },
        loading: business.loading,
        userOptions: this.setUserOptions(business.type)
      });
    }
  };

  getUsers = async () => {
    await this.props.onGetUsers();
    const users = this.props.users;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      this.setState({ users: users.items });
    }
  };

  getCompanies = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });
    if (this.props.companies.error) {
      notify(this.t(this.props.companies.error.message));
    } else {
      return this.props.companies.items;
    }
  };

  onClearImageField = ({ name }) => {
    if (name) this.setState({ files: { ...this.state.files, [name]: null } });
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  goBack = () => {
    if (this.state.location === 'businesses') history.push(config.ROUTES.BUSINESSES);
    if (this.state.location === 'entities') history.push(config.ROUTES.ENTITIES);
    if (this.state.location === 'subsidiaries') history.push(config.ROUTES.SUBSIDIARIES);
    if (!this.state.location) history.push(config.ROUTES.HOME);
  };

  onFileChangeImageInput = async ({ file, data, name }) => {
    const { business } = this.state;
    this.setState({ submitting: true });
    data.append('identifier', `${business.id}_${name}`);
    await this.props.onPictureUpload(data);
    const { files } = this.props;
    if (files.error) {
      notify(this.t(files.error.message));
    } else {
      this.setState({ imageUpdated: true });
      this.setState({
        files: {
          ...this.state.files,
          [name]: files.files.fileInfo.location
        }
      });
    }
    this.setState({ submitting: false });
    return files.files.fileInfo.location;
  };

  getUser = async (id) => {
    await this.props.onGetUser({ id });
    let { user } = this.props;
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      user = user.item
      //let account = user.accounts.filter(acc => acc.ownership === config.USER_ACCOUNTS.TYPES.OWNER);
      //let owner = account.length ? account[0].id : user.id;
      //console.log(user, account, owner)
      let owner = getOwner(user)
      this.setState({userOwnerAccountId: owner}) 
    }
  };

  onSubmit = async values => {
    const { files } = this.state;
    const { roles } = this.props.auth.user;
   
    const data = {
      name: values.name,
      country: values.country,
      state: values.state,
      //owner: isOnSubsidiaryRoute ? null : values.owner,
      owner: null,
      json_data: {
        url: values.url,
        image: files ? files.image : '',
        address: values.address
      }
    };

    if (this.state.business.id) data.id = this.state.business.id;

    if (this.state.location === 'businesses') data.type = config.ROLES.BENEFITS;
    if (this.state.location === 'entities') data.type = config.ROLES.INITIATIVES;
    if (this.state.location === 'subsidiaries') {
      data.type = 'subsidiaries';
      data.owner = getOwner(this.props.auth.user)
      //const owner = await this.getCompanies();
      //data.owner = owner[0].id
    } else {
      await this.getUser(values.owner)
      data.owner = this.state.userOwnerAccountId
    }

    //if (isOnSubsidiaryRoute) {
    //  data.type = 'subsidiaries';
    //  const owner = roles.includes(config.ROLES.ADMIN) ? this.goBack() : await this.getCompanies();
    //  data.owner = owner[0].id;
    //}

    await this.props.onSaveOrUpdate({ ...data });
    const business = this.props.business;
    if (business.error) {
      notify(this.t(business.error.message));
    } else {
      this.goBack();
    }
  };

  disableEnableBusiness = async () => {
    this.setState({ loading: true });
    const { business } = this.state;
    const data = { id: business.id, enabled: business.enabled };
    await this.props.onSaveOrUpdate(data);
    const error = this.props.business.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    }
    this.setState({ loading: false });
  };

  setUserOptions = type => {
    const { users } = this.state;
    return selectGeneratorWObjChild(
      users?.filter(user => user.role?.name === type),
      'id',
      o => capitalizePhrase(o.name)
    );
  };

  render() {
    const { business, view, editing, creatingNew, files, submitting, loading, location, roles } = this.state;
    // las opciones: generar un array de propietarios de tipo benefit y de tipo initiatives
    // y asi en base a eso, hacer el when field changes do y cambiar el array de propietarios
    // ** Options for selects
    let userOptions = [];
    const countries = selectGeneratorWObjChild(config.OPTIONS.COUNTRIES, '', o => capitalizePhrase(o));
    const argentinaStates = selectGeneratorWObjChild(config.OPTIONS.STATES.ARGENTINA, '', o => capitalizePhrase(o));

    let _business;
    _business = getObjectWithJsonDataToFormValues(business, [
      'image',
      'name',
      'url',
      'country',
      'state',
      'address',
      'owner'
    ]);

    // Layout actions
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        visible: creatingNew || roles.includes(config.ROLES.BENEFITS) || roles.includes(config.ROLES.INITIATIVES),
        checkPermissions: 'update'
      },
      secondaries: [
        {
          onClick: e => this.goBack(),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting || loading,
          visible: true,
          className: 'btn-accent'
        },
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible:
            (roles.includes(config.ROLES.BENEFITS) || roles.includes(config.ROLES.INITIATIVES)) && view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: (roles.includes(config.ROLES.BENEFITS) || roles.includes(config.ROLES.INITIATIVES)) && editing
        },
        {
          onClick: e => {
            if (business.enabled === 1)
              this.setState({ business: { ...business, enabled: 0 } }, () => this.disableEnableBusiness());
            if (business.enabled === 0)
              this.setState({ business: { ...business, enabled: 1 } }, () => this.disableEnableBusiness());
          },
          title: this.t(business?.enabled ? 'Disable' : 'Enable'),
          icon: !business?.enabled ? 'view_show' : 'view_hide',
          disabled:
            (roles.includes(config.ROLES.ADMIN) && (submitting || (view && !editing) || loading)) ||
            (roles.includes(config.ROLES.COMPANIES) && (submitting || loading)),
          checkPermissions: 'insert',
          visible: !creatingNew
        }
      ]
    };

    // ** Form validation functions
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');
    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      errors.country = required(values.country);
      return errors;
    };

    if (location === 'businesses') {
      userOptions = this.setUserOptions(config.ROLES.BENEFITS);
    }
    if (location === 'entities') {
      userOptions = this.setUserOptions(config.ROLES.INITIATIVES);
    }

    const isUrl = value => validateURL(value) || (view && !editing) || !value ? undefined : this.t('Ingresar URL válido');

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outterTitle={this.titles} loading={loading}>
          <Form initialValues={_business || {}} onSubmit={this.onSubmit} validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="flex justify-center mb-3 md:mb-6">
                    {roles.includes(config.ROLES.ADMIN) &&
                      (!creatingNew && values.image ? (
                        <img src={files.image} alt="" className="w-1/2" />
                      ) : (
                        <Field
                          name="image"
                          img={files.image}
                          component={ImageUploadFileInput}
                          label={capitalize(this.t('image'))}
                          placeholder={capitalize(this.t('image URL'))}
                          inputOnChange={this.onFileChangeImageInput}
                          disabled={view && !editing}
                          clickButtonClear={this.onClearImageField}
                          inputClassName="input-bordered shadow-none"
                          labelClassName="justify-center"
                        />
                      ))}

                    {[config.ROLES.BENEFITS, config.ROLES.INITIATIVES].includes(roles[0]) &&
                      (editing ? (
                        <Field
                          name="image"
                          img={files.image}
                          component={ImageUploadFileInput}
                          label={capitalize(this.t('image'))}
                          placeholder={capitalize(this.t('image URL'))}
                          inputOnChange={this.onFileChangeImageInput}
                          disabled={view && !editing}
                          clickButtonClear={this.onClearImageField}
                          inputClassName="input-bordered shadow-none"
                          labelClassName="justify-center"
                        />
                      ) : (
                        <img src={files.image} alt="" className="w-1/2" />
                      ))}
                  </div>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-full md:w-2/4 px-2">
                      <Field
                        name="name"
                        component={TextInput}
                        placeholder={capitalize(this.t('name'))}
                        label={capitalize(this.t('name'))}
                        readOnly={!creatingNew}
                      />
                    </div>
                    <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                      {location !== 'subsidiaries' && (
                        <Field
                          name="url"
                          component={TextInput}
                          placeholder={this.t('Página web')}
                          label={this.t('Página web')}
                          readOnly={!creatingNew}
                          parse={parseUrl} validate={isUrl}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                      <Field
                        name="country"
                        component={SelectInput}
                        placeholder={this.t('Country')}
                        options={countries}
                        empty={this.t('Select a country')}
                        label={this.t('Country')}
                        readOnly={!creatingNew}
                      />
                    </div>
                    <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                      <Field
                        name="state"
                        component={SelectInput}
                        placeholder={this.t('State')}
                        options={argentinaStates}
                        empty={this.t('Select a state')}
                        label={this.t('State')}
                        readOnly={!creatingNew}
                      />
                    </div>
                    <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                      <Field
                        name="address"
                        component={TextInput}
                        placeholder={this.t('Address')}
                        label={this.t('Address')}
                        readOnly={!creatingNew}
                      />
                    </div>
                  </div>
                  <div className="w-full sm:w-2/6 lg:w-4/12 px-2">
                    {roles.includes(config.ROLES.ADMIN) && location !== 'subsidiaries' ? (
                      <Field
                        name="owner"
                        component={SelectInput}
                        placeholder={this.t('Propietario')}
                        options={userOptions}
                        empty={this.t('Seleccionar el propietario')}
                        label={this.t('Propietario')}
                        readOnly={!creatingNew}
                        validate={roles.includes(config.ROLES.ADMIN) ? required : null}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    files: state.files,
    business: state.businesses.current,
    users: state.users.list,
    user: state.users.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(businessActions.saveOrUpdate(params)),
    onGet: id => dispatch(businessActions.get(id)),
    onGetUsers: params => dispatch(usersActions.getAll(params)),
    onGetUser: id => dispatch(usersActions.get(id)),
    onGetCompanies: params => dispatch(companiesActions.getAll(params)),
    onRemove: id => dispatch(businessActions.del(id)),
    onClearCurrent: () => dispatch(businessActions.clearCurrent()),
    onPictureUpload: params => dispatch(filesActions.upload(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Business));
