import general from './general';
import api from './api';
import customs from './customs';
import routes from './routes';

const config = {
  ...general,
  ...api,
  ...customs,
  ...routes
};

export default config;