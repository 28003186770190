import React from 'react';
import { Switch } from '@headlessui/react';

const SwitchboxInput = ({ input, meta, label, ...rest }) => {
  return (
    <div className={rest.className ? rest.className : ''}>
      <Switch.Group>
        <div className={rest.labelClassName ? rest.labelClassName : ''}>
          {label && <Switch.Label>{label}</Switch.Label>}
        </div>
        <Switch
          {...input}
          checked={input.value || false}
          // onChange={input.onChange}
          className={`${
            input.value === true ? 'bg-primary' : 'bg-gray-200'
          } relative inline-flex flex-shrink-0 h-6 w-11 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:shadow-outline`}
          // value={input.value}
          disabled={rest.readOnly || rest.disabled}>
          {({ checked }) => (
            <span
              className={`${
                checked === true ? 'translate-x-5' : 'translate-x-0'
              } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
            />
          )}
        </Switch>
      </Switch.Group>
      {/* {!rest.noerror && <p className="invalid-feedback">{meta.error && meta.touched && meta.error}&nbsp;</p> } */}
    </div>
  );
};

export default SwitchboxInput;
