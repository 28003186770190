import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import {
  composeValidators,
  validateEmail,
  validateIsfilled,
  getTimeZone,
  dateComponents,
  validateOnlyNumber,
  capitalize,
  getObjectWithJsonDataToFormValues,
} from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import CheckboxInput from '../../components/forms/CheckboxInput';

import SelectInput from '../../components/forms/SelectInput';
import BirthDateInput from '../../components/forms/BirthDateInput';
// import DatepickerInput from '../../components/forms/DatepickerInput';

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      loading: true,
      user: {}
    };
  }

  async componentDidMount() {
    console.log('SIGNUP PAGE');
    const params = this.props.match.params;
    if (params.hash) {
      await this.checkHash(params);
    }
    this.setState({ loading: false });
  }

  checkHash = async (params) => {
    await this.props.onGetFromHash(params);
    const user = this.props.user;
    if (user.error) {
      history.push(config.ROUTES.LOGIN);
    } else {
      this.setState({ user: getObjectWithJsonDataToFormValues(user.item, ['id', 'first_name', 'last_name', 'phone', 'email', 'birth_day', 'identification', 'gender']) });
    }
  };

  finalizeSignUp = async (data) => {
    data.id = this.state.user.id 

    await this.props.onSaveOrUpdate({
      id: this.state.user.id,
      phone: data.phone,
      birth_date: data.birth_date,
      json_data: {
        first_name: data.first_name,
        last_name: data.last_name,
        gender: data.gender,
      }
    });
    const user = this.props.user;
    this.setState({ submitting: false });
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      history.push({ pathname: config.ROUTES.VERIFY.replace(':hash', this.props.user.item.hash) });
    }
  }

  onSubmit = async values => {
    this.setState({ submitting: true, signupData: { ...values } });
    const data = { ...values, time_zone: getTimeZone(), user_type: config.USER_TYPE.REGULAR };

    data.birth_date = new Date (dateComponents(values.birth_date)[3].dateOnly);

    if(!data.terms) return
    delete data.terms;
    this.setState({ submitting: false });

    if(!this.state.user){
      await this.props.onRegister(data);
      const user = this.props.user;
      if (user.error) {
        this.setState({ submitting: false });
        notify(this.t(user.error.message));
      } else {
        //history.push(config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash));
        history.push(config.ROUTES.VERIFY.replace(':hash', this.props.user.item.hash));
      }
    }
    else{
      this.finalizeSignUp(data);
    }
  };

  onTerms = () => {
    history.push(config.ROUTES.TERMS);
  };

  onLogin = () => {
    history.push(config.ROUTES.LOGIN);
  };

  render() {
    let { loading, user, } = this.state;
    const required = value => (validateIsfilled(value) ? undefined : this.t('This field is required'));
    const email = value => (validateEmail(value) ? undefined : this.t('Not a valid email'));
    const onlynumber = value =>
      validateOnlyNumber(value) ? undefined : capitalize(this.t('ID must only contain numbers'));
    // const dnilength = value =>
    //   value.length >= 5 && value.length <= 9 ? undefined : capitalize(this.t('ID must have 7 or 8 numbers'));

    // const dni_types = config.OPTIONS.SIGNUP.IDENTIFICATION.TYPES.map(o => {
    //   return { value: o, label: this.t(o) };
    // });
    // const gender_types = config.OPTIONS.SIGNUP.GENDER.TYPES.map(o => {
    //   return { value: o, label: capitalize(o) };
    // });
    const gender_types = [
      { value: "man", label: capitalize(this.t("man")) },
      { value: "woman", label: capitalize(this.t("woman")) },
      { value: "other", label: capitalize(this.t("Other"))},
      { value: "not_say", label: capitalize(this.t("Prefiero no decir"))},
    ];

    return (
      <LayoutSmall
        main={{ className: 'bg-base-200 text-primary bg-pattern' }}
        header={{
          title: this.t('Register'),
          className: 'bg-transparent text-primary'
        }}
        container={{
          className: 'mt-10'
        }}
        loading={loading}
      >

        <ToastContainer />
        <Form
          initialValues={user || {}}
          onSubmit={this.onSubmit}
        >
          {({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto px-4">
              <div className="w-full">
                <Field
                  name="first_name"
                  component={TextInput}
                  placeholder={this.t('Ej. Juan')}
                  label={this.t('First name')}
                  validate={required}
                  readOnly={user.first_name}
                />
              </div>
              <div className="w-full">
                <Field
                  name="last_name"
                  component={TextInput}
                  placeholder={this.t('Ej. Sosa')}
                  label={this.t('Last name')}
                  validate={required}
                  readOnly={user.last_name}
                />
              </div>
              <div className="w-full">
                <Field
                  name="email"
                  component={TextInput}
                  placeholder={this.t('Ej. mail@mail.com')}
                  label={this.t("Email")}
                  validate={composeValidators(required, email)}
                  parse={v => v.trim()}
                  readOnly={user.email}
                />
              </div>
              <div className="w-full">
                <Field
                  name="phone"
                  component={TextInput}
                  placeholder={capitalize(this.t('area code without 15'))}
                  label="Nro de Teléfono"
                  validate={required}
                />
              </div>
              <div className="w-full">
                <Field
                  name="identification"
                  component={TextInput}
                  placeholder={this.t("Número de Identificación Personal (DNI)")}
                  label={this.t("Número de Identificación Personal (DNI)")}
                  validate={composeValidators(required, onlynumber)}
                  inputClassName='rounded-xl outline outline-1 outline-[#2A818933]'
                  readOnly={user.identification}
                />
              </div>
              <div className="w-full flex gap-4 items-center mb-3 md:mb-0">
                {/* <Field
                    name="birth_date"
                    component={DatepickerInput}
                    placeholder={this.t('dd/mm/yyyy')}
                    label="Fecha de Nacimiento"
                    validate={required}
                  /> */}
                <Field
                  name="birth_date"
                  component={BirthDateInput}
                  label={this.t("Fecha de nacimiento")}
                  className='w-full'
                // initialValue={today}
                />
              </div>

              <div className="w-full">
                <Field
                  name="gender"
                  component={SelectInput}
                  placeholder={capitalize(this.t('gender'))}
                  options={gender_types}
                  label="Género"
                  empty={capitalize(this.t('select'))}
                  validate={required}
                />
              </div>

              <div className="w-full mb-3 md:mb-0">
                <Field
                  name="terms"
                  component={CheckboxInput}
                  label={this.t('I accept terms and conditions')}
                  validate={required}
                  labelClassName='justify-start items-center gap-2'
                  checkboxClassName="checkbox-secondary rounded-full border-none bg-white"
                  type="checkbox"
                />
              </div>

              <div className="w-full">
                <Button
                  className="btn-primary btn-block"
                  title={this.t('Next')}
                  onClick={handleSubmit}
                  disabled={this.state.submitting || pristine}
                />
              </div>
            </form>
          )}
        </Form>
        <div className="w-full px-3 mt-5">
          <Button
            className="btn-link btn-block underline"
            title={this.t('Terms and conditions')}
            onClick={this.onTerms}
          />
        </div>
        <div className="mt-2 p-2 flex justify-center items-center">
          <p className="text-center mr-1">{this.t('Has an account?')}</p>
          <Button className="btn-link mb-0 underline" title={this.t('Login with your user')} onClick={this.onLogin} />
        </div>
      </LayoutSmall>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.current
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegister: params => dispatch(userActions.saveOrUpdate(params, 'register')),
    onSaveOrUpdate: (params) => dispatch(userActions.saveOrUpdate(params, 'register')),
    onGetFromHash: (params) => dispatch(userActions.getFromHash(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Signup));
