import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled, getClientIp } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import TextWithIconInput from '../../components/forms/TextWithIconInput';

import Splash from '../../components/commons/Splash';

import QrReader from 'react-qr-reader';



class ScanQR extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.from =  (this.props.location && this.props.location.state && this.props.location.state.from) || { pathname: '/' };
    this.state = {
      submitting: false,
      result: null,
    }
  }
  
  componentDidMount() {
    const { fromPath } = this.props.location.state;
    this.setState({fromPath})
    console.log('QRSCAN PAGE', this.from)
  }

  gotoBack = () => {
    history.push(this.state.fromPath)
  }

  handleScan = data => {
    if (data) {
      //this.setState({
      //  result: data
      //})
      history.push({
        pathname: this.state.fromPath,
        state: {
          qr:data.replaceAll("/",""),
          error: null,
        }
      })
    }
    
  }
  
  handleError = err => {
    console.error(err)
  }
  
   render() {
    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          className: "bg-transparent fixed text-white",
          left: { icon: 'arrow_left', action: this.gotoBack },
          title:this.t("Escaneá el QR"),
        }}
        container={{ className: "px-0"}}
      > 
        <div className="bg-primary h-full px-4 pt-20">
          <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%', border: '3px solid #569399', borderRadius: '10px', overflow: 'hidden' }}
          />
       </div>
      </LayoutSmall>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    user: state.users.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ScanQR));
