import React from 'react';

//Cloned from CheckboxInput to change styles.
const CheckboxInputRow = ({ input, meta, label, ...rest }) => {
  return (
    <div className="form-control">
      <label
        className={`cursor-pointer label ${
          rest.labelClassName && rest.labelClassName
        }`}
      >
        <span className="label-text">{label}</span>
        <div>
          <input
            {...input}
            type="checkbox"
            className={'checkbox'}
            disabled={rest.readOnly || rest.disabled}
            id={input.name}
          />
          <span className="checkbox-mark"></span>
        </div>
      </label>
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">
            {meta?.error && meta?.touched && meta?.error}
          </span>
        </label>
      )}
    </div>
  );
};
export default CheckboxInputRow;
