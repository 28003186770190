import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { notify } from '../../../libraries/notifications';
import { selectGeneratorWObjChild, validateIsfilled, capitalizePhrase } from '../../../libraries/utils';
import FormLayout from '../../../components/forms/FormLayout';
import roleUsersActions from '../../../context/roles_users/actions';
import usersActions from '../../../context/users/actions';
import companiesActions from '../../../context/companies/actions';
import SelectInput from '../../../components/forms/SelectInput';
import TextInput from '../../../components/forms/TextInput';
import Button from '../../../components/commons/Button';
import Icon from '../../../libraries/icons';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import Swal from 'sweetalert2';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class RoleUsers extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      creating_new: null,
      currSelectedUser: {},
      showEditForm: false,
      companies: [],
      roleID: '',
      roleUsers: [],
      loading: true,
      users: [],
      windowWidth: window.innerWidth
    };
    this.submit = null;
    this.breadcrumbs = [this.t('Role Users')];
  }

  componentDidMount() {
    this.getData();
    if (this.props.roleID) {
      this.getRoleUsers(this.props.roleID);
    }
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getData = async () => {
    await this.props.onGetUsers();
    await this.props.onGetCompanies({ owner: this.props.auth.user.id });
    if (this.props.users.error || this.props.companies.error) {
      notify(this.t('Error loading data'));
    } else {
      this.setState({
        users: [...this.props.users.items],
        loading: this.props.users.loading,
        companies: this.props.companies.items.map(company => company.id)
      });
    }
  };

  getRoleUsers = async (roleID, page_num = 1) => {
    const params = { roleID, page_num };
    const { search } = this.state;
    if (search && search !== '') {
      params.where = {
        custom: {
          title: `%${search.toLowerCase()}`
        }
      };
    }
    // ** If sort exist, sort the data
    if (this.state.sort) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }
    await this.props.onGetRoleUsers(params);
    const { roles_users: users } = this.props;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      this.setState({
        // placeholder until endpoint works
        roleUsers: users.items || [],
        roleID,
        loading: this.props.roles_users.loading
      });
    }
  };

  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getRoleUsers(currentPage));
  };

  onRemove = (userID, resetRows) => {
    const userToRemove = this.state.roleUsers.find(user => user.id === userID);
    const params = { roleID: this.state.roleID, id: userToRemove.user_id };
    const { roleUsers } = this.state;
    customSwal
      .fire({
        title: this.t(Array.isArray(userID) ? 'Multiple remove' : '¿Are you sure?'),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          // ** Loading
          this.state.loading = true;
          // ** If the parameter recieved is an array, execute multiple delete function
          if (Array.isArray(userID)) {
            for (let index = 0; index < userID.length; index++) {
              const element = userID[index];
              // ** Wait until the api removes selected items
              await this.props.onRemoveRoleUser({ roleID: params.roleID, id: element.id });
            }
            resetRows();
            if (roleUsers.error) {
              notify(this.t(roleUsers.error.message));
            } else {
              // ** Get roles
              this.getRoleUsers(params.roleID, this.state.currentPage);
            }
            // ** If the parameter is a number execute unique delete
          } else {
            // ** wait until the api removes selected items
            await this.props.onRemoveRoleUser(params);
            if (roleUsers.error) {
              notify(this.t(roleUsers.error.message));
            } else {
              // ** Get users
              this.getRoleUsers(params.roleID, this.state.currentPage);
            }
          }
        }
      });
  };

  findUser = id => this.state.users.find(elem => elem.id === id);

  // ** Redirection to edit company route
  onView = async id => {
    const foundUser = this.state.roleUsers.find(user => user.id === id);
    await this.props.onGetUser({ id: foundUser.user_id });
    const { user } = this.props;
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      user.item.name = capitalizePhrase(user.item.name);
      const _currSelectedUser = {
        ...user.item
      };
      this.setState({
        currSelectedUser: _currSelectedUser,
        showEditForm: true
      });
    }
  };

  // ** Redirection to new company route
  onNew = () => {
    alert('NOT MADE');
  };

  // ** Function to look for specific data in the table
  onSearch = data => {
    if (this.state.search !== data.search) this.setState({ search: data.search || '' }, () => this.getRoles());
  };

  onSubmit = async values => {
    try {
      const { roleID } = this.state;
      await this.props.onSaveOrUpdateUser(
        {
          ...values
        },
        roleID
      );
      this.setState({ showEditForm: false, creating_new: false });
      this.getRoleUsers(roleID);
    } catch (e) {
      console.error(e);
    }
  };

  // ** Use this function when configuring columns, its for hiding columns when the window width goes below the number specified
  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  render() {
    // **Destructuring objects for direct use of values
    const { roleUsers, currSelectedUser, loading, companies, showEditForm, users, windowWidth } = this.state;

    let userIDs;
    if (roleUsers) {
      userIDs = roleUsers.map(user => user.user_id);
    }


    let userOptions = [];
    if (companies.length && users.length) {
      userOptions = selectGeneratorWObjChild(
        users.filter(user => !userIDs.includes(user.id) && companies.includes(user.unique_id)),
        'id',
        o => capitalizePhrase(o.name)
      );
    }

    const query = {
      records: 8,
      page_size: 8
    };

    // ** Columns configuration for our data table
    const columns = [
      {
        name: this.t('User'),
        sortable: true,
        selector: row => capitalizePhrase(row['user']['name']),
        hide: this.hide(455),
        sortSelector: 'user'
      },
      {
        name: 'Actions',
        allowOverflow: true,
        center: true,
        width: '150px',
        hide: this.hide(370),
        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full">
              <span
                onClick={() => this.onView(row.id)}
                className="p-3 rounded-l-full cursor-pointer bg-gray-700 hover:bg-gray-800">
                <Icon className="h-3 w-3 text-white" name="view_show" />
              </span>
              <span
                onClick={() => this.onRemove(row.id)}
                className="p-3 rounded-r-full cursor-pointer bg-gray-700 hover:bg-gray-800">
                <Icon className="h-3 w-3 text-white" name="trash" />
              </span>
            </div>
          );
        }
      }
    ];

    const required = value => (validateIsfilled(value) ? undefined : this.t('This field is required'));

    // ** Actual render
    return (
      <div className="flex">
        <div className="w-1/2">
          <StyledDataTable
            data={roleUsers}
            noHeadCells
            columns={columns}
            selectableRows={windowWidth > 450}
            query={query}
            getDataFunction={this.getRoleUsers}
            multipleDeleteFunction={this.onRemove}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            expandableRows={windowWidth < 450}
          />
          <div className="flex justify-evenly my-5">
            <Button
              onClick={() => {
                this.setState({ showEditForm: true, creating_new: true });
                this.getData();
              }}
              disabled={this.state.showEditForm}
              title={this.t('Add user')}
            />
            <Button
              onClick={() => {
                this.setState({
                  showEditForm: false,
                  currSelectedUser: ''
                });
              }}
              className="danger"
              disabled={!this.state.showEditForm}
              title={this.t('Close')}
            />
          </div>
        </div>
        <div className="w-1/2">
          {showEditForm ? (
            <Form initialValues={currSelectedUser || {}} onSubmit={this.onSubmit}>
              {({ handleSubmit, form, submitting, pristine, values }) => {
                this.submit = handleSubmit;
                this.reset = form.reset;
                return (
                  <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                    {this.state.creating_new ? (
                      <React.Fragment>
                        <div className="xl:grid xl:grid-rows-1 xl:grid-cols-2 xl:w-full 2xl:w-3/4">
                          <div className="w-full px-3 md:mb-0">
                            <Field
                              component={SelectInput}
                              empty={this.t('Select an user')}
                              label={this.t('User')}
                              name="user_id"
                              options={userOptions}
                              placeholder={this.t('User')}
                              validate={required}
                            />
                          </div>
                        </div>
                        <div className="flex justify-center">
                          <Button onClick={e => this.submit(e)} title={this.t('Submit')} />
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="xl:grid xl:grid-rows-1 xl:w-full 2xl:w-3/4">
                          <div className="w-full px-3 md:mb-0">
                            <Field name="name" component={TextInput} readOnly={!this.state.creating_new} />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </FormLayout>
                );
              }}
            </Form>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    roles_users: state.roles_users.list,
    role_user: state.roles_users.current,
    users: state.users.list,
    user: state.users.current,
    query: state.roles_users.list.query
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetRoleUsers: params => dispatch(roleUsersActions.getAll(params)),
    onGetRoleUser: params => dispatch(roleUsersActions.get(params)),
    onGetCompanies: params => dispatch(companiesActions.getAll(params)),
    onSaveOrUpdateUser: (params, roleID) => dispatch(roleUsersActions.saveOrUpdate(params, roleID)),
    onRemoveRoleUser: (roleID, userID) => dispatch(roleUsersActions.del(roleID, userID)),
    onGetUsers: params => dispatch(usersActions.getAll(params)),
    onGetUser: id => dispatch(usersActions.get(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RoleUsers));
