import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import usersActions from '../../context/users/actions';
import { useTranslation } from 'react-i18next';

import Button from '../commons/Button';
import UserProfileImage from '../commons/UserProfileImage';
import MenuItem from '../commons/MenuItem';
import { history } from '../../routes';
import config from '../../config';

export default function NavbarSmall(props) {
  const { t } = useTranslation();

  const user = useSelector(state => state.users.auth.user);
  const dispatch = useDispatch();

  const doLogout = e => {
    e.preventDefault();
    dispatch(usersActions.logout());
  };

  return (
    <nav className="w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <div className="flex-shrink-0">
          </div>
        </div>
      </div>
    </nav>
  );
}
