import React from "react";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import PrivateButton from "../../components/commons/PrivateButton";
import ButtonIcon from "../../components/commons/ButtonIcon";
import { capitalize, getOwner, isEmptyObject, strToDateWithoutTZ } from "../../libraries/utils";
import { ToastContainer } from "react-toastify";
import PanelLayout from "../../components/PanelLayout";
import StyledDataTable from "../../components/commons/StyledDataTable";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import challengeActions from "../../context/challenges/actions";
import { notify } from "../../libraries/notifications";
import config from "../../config";
import { format } from "date-fns";
import clsx from "clsx";
import companiesActions from "../../context/companies/actions";

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class ChallengesList extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      challenges: [],
      categories: [],
      loading: true,
      sort: {},
      company_id: null,
      windowWidth: window.innerWidth,
    };
    this.breadcrumbs = [this.t('Desafios')];
  }
  async componentDidMount() {
    await this.getCompany()
    await this.getChallenges();
    window.addEventListener("resize", this.onRezise);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onRezise);
  }

  getCompany = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      if (this.props.companies.items.length) {
        this.setState({ company_id: this.props.companies.items[0].id });
        console.log("company", this.props.companies.items[0].id);
      }
    }
  };
  getChallenges = async (page_num = 1) => {

    const params = {
      page_num,
      owner:  this.state.company_id
    };
    const { search } = this.state;

    if (search && search !== '') {
      params.where = {
        custom: {
          name: `%${search.toLowerCase()}`,
          details: `%${search.toLowerCase()}`,
          comparison: 'OR'
        }
      };
    }

    if (!isEmptyObject(this.state.sort)) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }
    await this.props.onGetAllChallenges(params);
    const { challenges } = this.props;
    if (challenges.error) {
      notify(this.t(challenges.error.message));
    } else {
      let withFormattedDates = challenges.items.map(challenge => ({
        ...challenge,
        start_date: strToDateWithoutTZ(challenge.start_date),
        end_date: strToDateWithoutTZ(challenge.end_date),
      }))
      this.setState({ challenges: withFormattedDates, loading: false });
      this.getCategories();
      this.mapChallengeToSpanish()
    }
  };

  mapChallengeToSpanish() {
    const { challenges } = this.state;
    const mappedChallenges = challenges.map(challenge => {
      if(challenge.type === "activities") {
        challenge.type = "Actividades"
      }
      if(challenge.participants_type === "company") {
        challenge.participants_type = "Empresa"
      }
      return challenge
    })
    this.setState({ challenges: mappedChallenges })
  
  }

  onRemove = (id, resetRows) => {
    const { currentPage } = this.state;
    const { challenges } = this.props;
    customSwal
      .fire({
        title: this.t(resetRows ? capitalize('delete multiple elements') : '¿Are you sure?'),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          // If the parameter received is an array, execute multiple delete function
          this.setState({ loading: true })
          if (resetRows) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (challenges.error) {
              notify(this.t(challenges.error.message));
            } else {
              this.getChallenges(currentPage);
            }
          } else {
            await this.props.onRemove(id);
            if (challenges.error) {
              notify(this.t(challenges.error.message));
            } else {
              this.getChallenges(currentPage);
            }
          }
        }
      });

  }
  getCategories = async params => {
    // await this.props.onGetCategories(params);
    // const { categories } = this.props;
    // if (categories.error) {
    //   notify(this.t(categories.error.message));
    // } else {
    //   this.setState({ categories: categories });
    // }

    // const { challenges } = this.state;
    // for (let challenge of challenges) {
    //   const foundCategory = categories.items.find(elem => elem.id === challenge.type);
    //   if (foundCategory?.name) challenge.type = foundCategory?.name;
    // }
  };
 
  onRezise = () => {
    this.setState({ windowWidth: window.innerWidth });
  }
  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getChallenges(currentPage));
  };
  
  onEdit = (id) => {
    this.props.history.push(`${config.ROUTES.CHALLENGES}/${id}`);
  }
  onNew = () => {
    this.props.history.push(`/challenges/new`);
  }
  onSearch = data => {
    if (this.state.search !== data.search) this.setState({ search: data.search || '' }, () => this.getChallenges());
  }
  searchClear = form => {
    form.change('search', undefined);
    this.onSearch({ search: '' });  }

  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  checkStatus = (challenge) => {
    switch(challenge.status){
      case "not_started":
        return "No empezado"
      case "started":
        return "Empezado"
      case "finished":
        return "Finalizado"
      default:
        return ""
    }
  }



  render() {
    const { challenges, loading, windowWidth } = this.state;
    const roles = this.props.auth.user.roles

    const columns = [
      {
        name: this.t('Nombre'),
        sortable: true,
        selector: row => row['name'],
        sortSelector: 'name',
        maxWidth: '200px'
      },

      {
        name: capitalize(this.t('Participantes')),
        sortable: true,
        selector: row => row.participants_type,
        sortSelector: 'participants_type',
        hide: this.hide(1280)

      },
      {
        name: capitalize(this.t('Fecha de inicio')),
        sortable: true,
        selector: row => format(new Date(row.start_date), 'dd/MM/yyyy'),
        sortSelector: 'start_date',
        hide: this.hide(1280)

      },
      {
        name: capitalize(this.t('Fecha de finalización')),
        sortable: true,
        selector: row => format(new Date(row.end_date), 'dd/MM/yyyy'),
        sortSelector: 'end_date',
        hide: this.hide(1280)

      },
      {
        name: capitalize(this.t('Status')),
        sortable: true,
        selector: row => row.enabled,
        sortSelector: 'enabled',
        hide: this.hide(370),
        cell: row => {
          const status = this.checkStatus(row)
          return (
            <span className={clsx("rounded-box px-2 py-1 capitalize", status === "not_started" ? "bg-red-100 ": "bg-green-100 ")}>
              {status}
            </span>
          )
        }
      },
      {
        name: capitalize(this.t('Enabled')),
        sortable: true,
        selector: row => row.enabled,
        sortSelector: 'enabled',
        hide: this.hide(370),
        cell: row => {
          const status = row.enabled ? "habilitado" : "deshabilitado"
          return (
            <span className={clsx("rounded-box px-2 py-1 capitalize", !row.enabled ? "bg-red-100 ": "bg-green-100 ")}>
              {status}
            </span>
          )
        }
      },
      {
        name: capitalize(this.t('Ganadores')),
        // sortable: true,
        // sortFunction
        selector: row => row.winner_number,
        hide: this.hide(370),
        cell: row => {
          return (
            <span className="rounded-box px-2 py-1 capitalize bg-slate-200 text-center">
              {row.winner === "first_n" ? row.winner_number : "Todos"}
            </span>
          )
        }
        
      },      
      {
        name: capitalize(this.t('Visibilidad')),
        selector: row => row.visibility === 'referents' ? 'Sólo referentes' : 'Todos',
        hide: this.hide(1280),
      },
      {
        name: 'Acciones',
        allowOverflow: true,
        center: true,
        width: '130px',
        hide: this.hide(370),
        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full gap-2">
              <ButtonIcon onClick={() => this.onEdit(row.id)} icon="view_show" buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm" className="w-5" />
              <PrivateButton control="delete">
                <ButtonIcon onClick={() => this.onRemove(row.id)} icon="trash" buttonClassName="btn-link text-error bg-gray-100 btn-sm" />
              </PrivateButton>
            </div>
          );
        }
      },
      
    ];
    const actions = {
      main: {
        onClick: this.onNew,
        title: this.t('New'),
        // checkPermissions: 'insert'
      },
      secondaries: [],
      search: {
        onSearch: this.onSearch,
        onClearClick: this.searchClear,
        title: this.t('Search')
      }
    };
    return (
      <LayoutWithSidebar main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={loading}
      >
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={challenges}
            columns={columns}
            screenWidth={windowWidth}
            selectableRows={roles.includes(config.ROLES.ADMIN) ? windowWidth > 600 : null}
            query={this.props.challenges.query}
            getDataFunction={this.getChallenges}
            pagination
            loading={loading}
            customSort={this.customSort}
            multipleDeleteFunction={roles.includes(config.ROLES.ADMIN) ? this.onRemove : null}
            onRowExpand={() => {}}

          />
        </PanelLayout>


      </LayoutWithSidebar>
    
    )
  }
}




const mapStateToProps = state => {
  return {
    challenges: state.challenges.list,
    auth: state.users.auth,
    companies: state.companies.list,
    activities: state.challenges.current.activities,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllChallenges: params => dispatch(challengeActions.getAll(params)),
    onRemove: id => dispatch(challengeActions.del(id)),
    onGetCompanies: (params) => dispatch(companiesActions.getAll(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChallengesList));

