import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import { history } from '../../../routes';
import { capitalize } from '../../../libraries/utils';
import productActions from '../../../context/products/actions';
import categoriesActions from '../../../context/categories/actions'
import PanelLayout from '../../../components/PanelLayout';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import Icon from '../../../libraries/icons';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    denyButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      products: [],
      loading: false,
      windowWidth: window.innerWidth
    };
    this.breadcrumbs = [this.t('Products')];
  }

  // ** Life cycle
  componentDidMount() {
    this.getProducts();

  }

  // ** Function to handle resize
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  // ** Function to get data from our api
  getProducts = async (page_num = 1) => {
    // ** By default, page_num equal to 1, it will change if you send it as a parameter
    const params = { page_num };
    const { search } = this.state;
    // ** If search exist, filter the data
    if (search && search !== '') {
      params.where = {
        custom: {
          name: `%${search.toLowerCase()}`,
          type: `%${search.toLowerCase()}`,
          /* 
          No funciona buscar por status
          el campo esta en ingles
          status: `%${search.toLowerCase()}`, 
          
          */
          comparison: 'OR',
        }
      };
    }
    // ** If sort exist, sort the data
    if (this.state.sort) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }
    // ** Execute get request with params
    await this.props.onGetAll(params);
    const { products } = this.props;
    if (products.error) {
      notify(this.t(products.error.message));
    } else {
      // ** Set state based on response data
      this.setState({ products: products.items, loading: products.loading });
    }
  };
  // ** Custom sort function
  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () =>
      this.getProducts(currentPage)
    );
  };
  //  ** Delete function
  onRemove = (id, resetRows) => {
    const { products } = this.props;
    customSwal
      .fire({
        title: this.t(
          Array.isArray(id) ? capitalize('delete multiple elements') : '¿Are you sure?'
        ),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          // ** Loading
          products.loading = true;
          // ** If the parameter recieved is an array, execute multiple delete function
          if (Array.isArray(id)) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              // ** Wait until the api removes selected items
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (products.error) {
              notify(this.t(products.error.message));
            } else {
              // ** Get products
              this.getProducts(this.state.currentPage);
            }
            // ** If the parameter is a number execute unique delete
          } else {
            // ** wait until the api removes selected items
            await this.props.onRemove(id);
            if (products.error) {
              notify(this.t(products.error.message));
            } else {
              // ** Get products
              this.getProducts(this.state.currentPage);
            }
          }
        }
      });
  };
  //  ** Actions function
  multipleAction = (array, resetRows) => {
    const { products } = this.props;
    customSwal
      .fire({
        title: capitalize(this.t('multiple action')),
        text: capitalize(this.t('do you want to enable or disable selected elements?')),
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        denyButtonText: this.t('Disable'),
        confirmButtonText: this.t('Enable'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        let changed = false;
        // ** Loading
        if (!result.isDismissed) {
          products.loading = true;
          for (let index = 0; index < array.length; index++) {
            const element = array[index];

            let data = { ...element, enabled: !result.isDenied };
            delete data.user;

            if (
              (element.enabled === 1 && result.isDenied) ||
              (element.enabled === 0 && result.isConfirmed)
            ) {
              this.setState({ loading: true });
              await this.props.onSaveProduct(data);
              changed = true;
            }
          }
          resetRows();
          changed && this.setState({ loading: false });
          if (products.error) {
            notify(this.t(products.error.message));
            products.loading = false;
          } else {
            // ** Get products
            changed && (await this.getProducts(this.state.currentPage));
            products.loading = false;
          }
        }
      });
  };
  // ** Redirection to edit product route
  onEdit = id => {
    history.push(config.ROUTES.PRODUCTS_EDIT.replace(':id', id));
  };

  // ** Redirection to new product route
  onNew = () => {
    history.push(config.ROUTES.PRODUCTS_NEW);
  };

  // ** Function to look for specific data in the table
  onSearch = data => {
    if (this.state.search !== data.search)
      this.setState({ search: data.search || '' }, () => this.getProducts());
  };

  searchClear = form => {
    form.change('search', undefined);
    this.onSearch({ search: '' });
  };

  // ** Use this function when configuring columns, its for hiding columns when the window width goes below the number specified
  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  render() {
    const { products, windowWidth, loading } = this.state;
    // ** This is the component that will show when expanding the table
    const expandableComponent = ({ data }) => (
      <Link to={`${config.ROUTES.PRODUCTS}/${data.id}`}>
        <div className="flex flex-wrap py-3 px-5 align-center justify-around bg-gray-50">
          <div className="py-2">
            <h4>{capitalize(this.t('type'))}</h4>
            <p className="text-gray-500">{this.t(data.type)}</p>
          </div>
          <div className="py-2">
            <p
              className={`${
                data.status === 'available' ? 'bg-green-100' : 'bg-red-100'
              } rounded-box px-2 py-1`}>
              {data.status === 'available' ? this.t('available') : capitalize(this.t('not available'))}
            </p>
          </div>
          <div className="py-2">
            <p
              className={`${
                data.enabled ? 'bg-green-100' : 'bg-red-100'
              } rounded-box px-2 py-1`}>
              {data.enabled ? this.t('Active') : this.t('Inactive')}
            </p>
          </div>
        </div>
      </Link>
    );

    /*
    FALTA: 1366, 1440

    CALCULO PARA 720P
    Porcentaje de la pantalla ocupado por la tabla: 86,11%
    Selector checkbox izquierdo: 3,74%
    Acciones: 10,15%
    Padding: 7,5%
    Vw disponible: 64,72
    50% para nombre: 32,36
    50% para el resto / 3 columnas: 10,7

    CALCULO PARA 1080P
    Porcentaje de la pantalla ocupado por la tabla: 94,44%
    Selector checkbox izquierdo: 3,74%
    Acciones: 6,76%
    Padding: 5%
    Vw disponible: 78,94
    60% para nombre: 47,09
    40% para el resto / 3 columnas: 10,46

    CALCULO PARA 4K
    Porcentaje de la pantalla ocupado por la tabla: 102,77%
    Selector checkbox izquierdo: 1,24%
    Acciones: 3,38%
    Padding: 2,5%
    Vw disponible: 95,65
    70% para nombre: 66,95
    30% para el resto / 3 columnas: 9,56
     */
    const columns = [
      {
        name: this.t('First name'),
        sortable: true,
        selector: row => row['name'],
        sortSelector: 'name',
        maxWidth:
          // non-desktop
          windowWidth < 455
            ? // windowWidth - (table padding + cell padding) - (actions cell width + actions cell padding)
              `${windowWidth - 64 - 162}px`
            : // 720p
            windowWidth > 959 && windowWidth < 1920
            ? '32.36vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '47.09vw'
            : // 4k
            windowWidth >= 3840
            ? '66.95vw'
            : ''
      },
      {
        name: capitalize(this.t('type')),
        sortable: true,
        selector: row => capitalize(row['type']),
        // hide: this.hide(455),
        sortSelector: 'type',
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '10.7vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '10.46vw'
            : // 4k
            windowWidth >= 3840
            ? '9.56vw'
            : ''
      },
      {
        name: this.t('Status'),
        // hide: this.hide(800),
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '10.7vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '10.46vw'
            : // 4k
            windowWidth >= 3840
            ? '9.56vw'
            : '',
        selector: row => (
          <p
            className="rounded-box px-2 py-1 bg-gray-100">
            {this.t(row.status)}
          </p>
        )
      },
      {
        name: this.t('Active'),
        maxWidth:
          windowWidth < 1920
            ? '10.7vw'
            : windowWidth >= 1920 && windowWidth < 3840
            ? '10.46vw'
            : windowWidth >= 3840
            ? '9.56vw'
            : '',
        // hide: 'md',
        selector: row => (
          <p
            className={`${
              row.enabled ? 'bg-green-100' : 'bg-red-100'
            } rounded-box px-2 py-1`}>
            {row.enabled ? this.t('Active') : this.t('Inactive')}
          </p>
        )
      },
      {
        name: capitalize(this.t('UI actions')),
        allowOverflow: true,
        center: true,
        // width: '130px',
        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full">
              <span
                onClick={() => this.onEdit(row.id)}
                className="p-3 rounded-full cursor-pointer bg-gray-700 hover:bg-gray-800">
                <Icon className="h-3 w-3 text-white" name="view_show" />
              </span>
            </div>
          );
        }
      }
    ];

    // ** Main and secondary actions of out layout
    const actions = {
      main: {},
      secondaries: [],
      search: {
        onSearch: this.onSearch,
        onClearClick: this.searchClear,
        title: this.t('Search')
      }
    };
    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={products}
            noHeadCells
            columns={columns}
            selectableRows={windowWidth > 450}
            query={this.props.products.query}
            getDataFunction={this.getProducts}
            multipleFunction={this.multipleAction}
            // multipleDeleteFunction={onRemove}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            // expandableRows={windowWidth < 450}
            // expandableRowsComponent={expandableComponent}
            multipleFunctionLabel={this.t('Acción múltiple')}
            multipleFunctionIcon={'view_show'}
            onRowExpand={() => {}}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    products: state.products.list,
    query: state.products.list.query,
    category: state.categories.current
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAll: (params) => dispatch(productActions.getAll(params)),
    onRemove: (id) => dispatch(productActions.del(id)),
    onSaveProduct: (params) => dispatch(productActions.saveOrUpdate(params)),
    onGetCategory: id => dispatch(categoriesActions.get(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Products));
