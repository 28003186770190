import React from 'react';
import { capitalizePhrase } from '../../libraries/utils';
import { useTranslation } from 'react-i18next';
import IconPoints from '../../components/customs/IconPoints';
import IconCoins from '../../components/customs/IconCoins';
import Icon from '../../libraries/icons';
import Loader from '../../components/commons/Loader';

export default function UserCard({ user, ...rest }) {
  const { t } = useTranslation();
  if (rest.loading) {
    return <Loader />;
  } else if (user) {
    return (
      <div
        className={`flex flex-col p-3 items-center border-2 rounded-lg ${
          rest.cardClassName || ''
        }`}>
        {user.profile_image ? (
          <div>
            <img
              className="rounded-full w-20 h-20"
              src={user.profile_image}
              alt="User profile"
            />
          </div>
        ) : (
          <div>
            <Icon name="user" size="5rem" className="rounded-full border-2" />
          </div>
        )}
        <div>
          {user.name ? (
            <h3 className="text-center">{capitalizePhrase(user.name)}</h3>
          ) : (
            ''
          )}
          {rest.rewards && rest.rewards.total ? (
            <div className="flex text-center items-center">
              <IconCoins
                className="bg-white bg-opacity-50 rounded-xl text-primary p-2"
                coins={rest.rewards.total.coins}
              />
              <IconPoints
                className="bg-white bg-opacity-50 rounded-xl text-primary p-2"
                points={rest.rewards.total.points}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <p>{user.email ? user.email : ''}</p>
        {rest.showPhone ? (
          <p className="flex content-center justify-evenly mt-1 w-full">
            <Icon name="phone" size="1rem" />
            {user.phone}
          </p>
        ) : (
          ''
        )}
        {user.enabled && user.enabled === 1 ? (
          <div className="bg-green-100 rounded-box mt-2 px-2 py-1">
            {t('Activo')}
          </div>
        ) : user.enabled === 0 ? (
          <div className="bg-red-100 rounded-box mt-2 px-2 py-1">
            {t('Disabled')}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}
