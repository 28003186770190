import { addMinutes, format, formatDistance, isAfter, isBefore, parse, subDays, subHours, subMinutes, } from 'date-fns';
import publicIp from 'public-ip';
import { useEffect, useState } from 'react';
import config from '../config';

export const selectIconSize = (ctrClass, defaultClass = 'h-4 w-4') => {
  if (!ctrClass) return defaultClass;
  const iconclass = ctrClass.split(' ');
  if (iconclass.includes('btn-lg')) return 'h-5 w-5';
  if (iconclass.includes('btn-sm')) return 'h-3 w-3';
  if (iconclass.includes('btn-xs')) return 'h-2 w-2';
  return defaultClass;
};

export const id = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

//generates random id;
export const guid = () => {
  return id() + id() + '-' + id() + '-' + id() + '-' + id() + '-' + id() + id() + id();
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : r & (0x3 | 0x8);
    return v.toString(16);
  });
};

export const validateURL = (URL = null) => {
  if (URL === null) return false;
  // regex source: https://regexr.com/39nr7
  const URLRegex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;
  return URLRegex.test(URL);
};

export const validateEmail = (email = null) => {
  if (email === null) return false;
  // const re    = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const validatePassword = (pass = null) => {
  if (pass === null) return false;
  return pass.length > 3;
};

export const validateStringNotEmpty = (str = null) => {
  if (str === null) return false;
  return str.length > 0;
};

export const validateOnlyNumber = (num = null, allowNegative = false) => {
  // const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;  // For phonenumber //TODO: test it
  // allowNegative /^-?
  const regex = /^(?:\d+)(?:(\.|,)\d+)?$/;
  return regex.test(num);
  // if (num === null) return false;
  // return !isNaN(num);
};

export const formatOnlyNumberInput = (value, maxNumberAllow) => {
  if (!value) return value;

  // Remove all non-numeric characters
  const numericValue = value.replace(/\D+/g, '');

  // Convert the numeric value to a number
  const numberValue = Number(numericValue);

  // Check if the number exceeds the maximum allowed value
  if (!isNaN(maxNumberAllow) && numberValue > maxNumberAllow) {
    return String(maxNumberAllow);
  }

  return String(numberValue);
};

export const parseOnlyLetterAndSpace = str => str.replace(/[^A-Za-z ]/g, '');

export const parseLength = (str, length) => str.substring(0, length);

export const validateAtLeastLength = (str, length) => str && str.trim().length >= length;

export const validateIsfilled = expression => expression && (expression.length > 0 || expression > 0);

export const validateIsTrue = expression => expression;

export const composeValidators =
  (...validators) =>
  value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

// export const optionSelectGenerator = (array = [], type = 'vector') => {
//   let options = [];
//   array.forEach(e => {
//     if (type === 'vector') {
//       options.push({label: e, value: capitalize(e)});
//     }
//   });
//   return options;
// }

export const optionSelectGenerator = (array = []) => array?.map(o => ({ value: o, label: capitalize(o) })) || [];

// For arrays of objects coming from redux state
// def properties if necessary
// -JC
export const selectGeneratorWObjChild = (array = [], optProp1 = '', optProp2 = '') => {
  return (
    array?.map(o => ({
      value: optProp1 ? o[optProp1] : o,
      label: isFunction(optProp2) ? optProp2(o) : optProp2 ? capitalize(o[optProp2]) : o
    })) || []
  );
};

export const getOwner = user => {
  //return id to save in owner field
  let owner;
  if (user) {
    if (config.USER_ACCOUNTS_INTEGRATED && user.accounts) {
      let account = user.accounts.filter(acc => acc.ownership === config.USER_ACCOUNTS.TYPES.OWNER);
      owner = config.USER_ACCOUNTS_INTEGRATED && account.length ? account[0].id : user.id;
    } else {
      owner = user.id;
    }
  }
  return owner;
};

/** OBJECT & DATA CHECK & MANIPULATION */

export const searchJSON = (json, root, search) => {
  // console.log('json', json, search);
  if (!isObject(search)) return false;
  if (isObject(json)) {
    let json_root = '';
    if (!root.startsWith('$')) return false;
    if (root.startsWith('$.')) {
      json_root = root.substring(2, root.length);
    }
    const json_root_array = json_root !== '' ? json_root.split('.') : [];
    const search_names = Object.keys(search);
    const search_values = Object.values(search);
    // console.log('JSON', json_root, json_root_array, search_names, search_values);
    if (json_root_array.length) {
      let level = 0;
      for (let i = 0; i < json_root_array.length; i++) {
        const el = json_root_array[i];
        if (json.hasOwnProperty(el)) {
          // console.log('JSON HAS', el, level);
          if (level === json_root_array.length - 1) {
            // console.log('LAST LEVEL');
            for (let j = 0; j < search_names.length; j++) {
              const name = search_names[j];
              const value = search_values[j];
              // console.log('SEARCH', name, value, json[el]);
              if (!Array.isArray(json[el])) {
                if (json[el].hasOwnProperty(name) && json[el][name] === value) {
                  // console.log('FOUND', level, name, value);
                  return true;
                }
              } else {
                const result = json[el].filter(el => {
                  console.log('EL', el);
                  return el.hasOwnProperty(name) && el[name] === value;
                });
                // console.log('RESULT', result, result.length);
                if (result.length) {
                  return result;
                } else {
                  return false;
                }
              }
            }
            level++;
          }
        }
      }
    }
    return true;
  }
  return false;
};

export const complexAPIObjectToFormValues = obj => {
  let response = { ...obj };
  Object.keys(obj).forEach(k => {
    // console.log('object key', k, obj[k], isObject(obj[k]) );
    if (isObject(obj[k])) {
      response[k] = obj[k].id;
    }
  });
  return response;
};
export const deleteDuplicatedEntries = array => {
  const result = array.reduce((acc, item) => {
    if (!acc.includes(item)) {
      acc.push(item);
    }
    return acc;
  }, []);
  return result;
};

export const getObjectWithJsonDataToFromValues = (obj, fields = []) => {
  let response = { ...obj };
  if (!obj) return response;
  if (obj && obj.hasOwnProperty('json_data')) {
    const json_data = obj.json_data;
    delete response.json_data;
    response = { ...response, ...json_data };
  }
  console.log('RESPONSE', response);
  if (fields.length) {
    response = Object.assign(
      ...Object.keys(response)
        .filter(key => fields.includes(key) === true)
        .map(key => ({ [key]: response[key] }))
    );
  }
  return response;
};
export const chunkArray = (array, chunkSize) => {
  const numberOfChunks = Math.ceil(array.length / chunkSize);

  return [...Array(numberOfChunks)].map((value, index) => {
    return array.slice(index * chunkSize, (index + 1) * chunkSize);
  });
};

export const sortArrayByAttribute = (array, attr) => {
  let array_sorted = array.sort(
    (a1, a2) => (a1[attr] > a2[attr]) ? 1 : (a1[attr] < a2[attr]) ? -1 : 0);  
  return array_sorted;
};

/*
  Return a object to use in a form returning only the id for child objects
*/
export const getObjectWithChildObjectsToFormValues = obj => {
  let response = { ...obj };
  Object.keys(obj).forEach(k => {
    // console.log('object key', k, obj[k], isObject(obj[k]) );
    if (isObject(obj[k])) {
      response[k] = obj[k].id;
    }
  });
  return response;
};

//extrapolateProp(obj,"prop") transforms {a:1,prop:{b:2,c:3}} into {a:1,b:2,c:3}
export const extrapolateProp = (obj, prop) => {
  if (Object.keys(obj).includes(prop)) {
    const new_obj = { ...obj };
    delete new_obj[prop];
    return { ...new_obj, ...obj[prop] };
  } else {
    return obj;
  }
};

/*
  Return object with first level json_data at root level and filtered by fields
*/
export const getObjectWithJsonDataToFormValues = (obj, fields = []) => {
  if (isEmptyObject(obj)) return {};
  let response = extrapolateProp(obj, 'json_data');
  if (fields.length) {
    response = Object.assign(
      ...Object.keys(response)
        .filter(key => fields.includes(key) === true)
        .map(key => ({ [key]: response[key] }))
    );
  }
  return response;
};

const getObjectWithJsonDataFilter = (obj, fields = []) => {
  let response = { ...obj };
  if (!obj) return response;
  if (obj && obj.hasOwnProperty('json_data')) {
    response.json_data = getObjectWithJsonDataFilter(response.json_data, fields);
  }
  //if any of the fields or a json_data field is in the object, return an empty object
  if (!fields.some(x => Object.getOwnPropertyNames(response).includes(x))) {
    if (!response.json_data) {
      return {};
    } else {
      if (!fields.some(x => Object.getOwnPropertyNames(response.json_data).includes(x))) {
        return {};
      }
    }
  }

  if (fields.length) {
    response = Object.assign(
      ...Object.keys(response)
        .filter(key => fields.includes(key) === true || key === 'json_data') //json_data is not given in fields
        .map(key => {
          return { [key]: response[key] };
        })
    );
  }
  return response;
};

//pass an object to specifies how to filter json data
//{"main": ["field1", "field2", "fieldN"], "object1": ["field1", "field2", "fieldN"], "objectN": ["field1", "field2", "fieldN"], }
//main: fields outside any object or inside json_data
const getObjectWithJsonObjectFilter = (obj, fields) => {
  const new_obj = { json_data: {} };
  Object.keys(fields).forEach(key => {
    if (key === 'main') {
      new_obj[key] = getObjectWithJsonDataFilter(obj, fields[key]);
      new_obj.json_data = { ...new_obj.json_data, ...new_obj[key].json_data };
      delete new_obj[key].json_data;
    } else {
      new_obj.json_data = { ...new_obj.json_data, [key]: getObjectWithJsonDataFilter(obj.json_data[key], fields[key]) };
      new_obj.json_data[key] = extrapolateProp(new_obj.json_data[key], 'json_data');
    }
  });

  return extrapolateProp(new_obj, 'main');
};

export const getObjectWithJsonArrayFilter = (obj, fields) => {
  const object_fields = {
    ...Object.assign({}, ...fields.filter(f => isObject(f))),
    main: fields.filter(f => !isObject(f))
  };
  return getObjectWithJsonObjectFilter(obj, object_fields);
};

export const isEmptyObject = obj => {
  if (typeof obj === 'undefined') {
    return true;
  } else if (isObject(obj)) {
    return Object.entries(obj).length === 0;
  }
  return false;
};

export const isObject = obj => {
  if (Object.prototype.toString.call(obj) === '[object Object]') return true;
  // ref: https://medium.com/javascript-in-plain-english/javascript-check-if-a-variable-is-an-object-and-nothing-else-not-an-array-a-set-etc-a3987ea08fd7
  // alternatives: if (obj && obj.constructor.name === 'Object') return true;
  return false;
};

export const isEmptyOrUndefined = str => {
  if (typeof str === 'undefined' || !str || str.length === 0) {
    return true;
  }
  return false;
};

export const isNotSetOrTrue = str => {
  if (typeof str === 'undefined' || str === null || (typeof str === 'boolean' && str)) {
    return true;
  } else if (typeof str === 'boolean' && str === false) {
    return false;
  }
  return false;
};

export const isJSON = str => {
  try {
    const json = JSON.parse(str);
    if (json && isObject(json)) return true;
  } catch (e) {
    return false;
  }
  return false;
};

export const isFunction = val => {
  //If our variable is an instance of "Function"
  if (val instanceof Function) {
    return true;
  }
  return false;
};

/** DATE MANIPULATION */

/* enumerateTimeSlots v0.0.4 */
export const enumerateTimeSlots = ({
  slot = null,
  wait = 0,
  start = null,
  end = null,
  timeOffset = 0,
  ampm = null
}) => {
  let timeSlots = [];
  if (slot === null) slot = 15;
  if (wait !== 0) slot = slot + wait;
  if (ampm === null) ampm = true;
  if (start === null) start = '00:00';
  if (end === null) end = '23:59';
  if (timeOffset === null) timeOffset = 0;
  const date = new Date();

  let startTime = parse(start, 'HH:mm', date);
  let endTime = parse(end, 'HH:mm', date);

  // console.log('BEFORE ', startTime, endTime);
  // console.log('TIMEOFFSET', timeOffset);

  if (timeOffset > 0) {
    // console.log('MAS');
    startTime = addMinutes(startTime, timeOffset);
    endTime = addMinutes(endTime, timeOffset);
  } else if (timeOffset < 0) {
    // console.log('MENOS');
    startTime = subMinutes(startTime, timeOffset);
    endTime = subMinutes(endTime, timeOffset);
  }
  console.log('AFTER', startTime, endTime);

  let loop = 0;
  while (startTime <= endTime && loop <= 24) {
    // console.log('startTime', startTime);
    // console.log('endTime', endTime);
    let timeSlot = format(startTime, 'HH:mm');
    // console.log('SLOT', timeSlot);
    // if (ampm) timeSlot = moment(timeSlot, 'HH:mm').format('hh:mm A');
    timeSlots.push(timeSlot);
    startTime = addMinutes(startTime, slot);
    loop++;
  }
  return timeSlots;
};

export const convertUTCDateToLocalDate = (ISOdate) => {
  // TODO: temp fix
  if(process.env.NODE_ENV !== 'development') return new Date(ISOdate)
  const date = new Date(ISOdate)
  const newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);
  return newDate;   
}

export const toDate = (dateStr, separator = '/') => {
  const parts = dateStr.split(separator);
  return new Date(parts[2], parts[1] - 1, parts[0]);
};

export const fromISO = (dateISO, separator = '/') => {
  const parts = dateISO.substring(0, 10).split('-');
  return `${parts[2]}${separator}${parts[1]}${separator}${parts[0]}`;
};

export const fromISOtoDate = (dateISO, tz = true) => {
  let date = new Date(dateISO);
  let userTimezoneOffset = 0;
  if (tz === false) userTimezoneOffset = new Date(date).getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const isDate = val => {
  return val && Object.prototype.toString.call(val) === '[object Date]' && !isNaN(val);
};

export const capitalize = string => {
  if (typeof string !== 'string') return '';
  return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizePhrase = string => {
  if (typeof string !== 'string') return '';
  return string
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');
};

export const dateComponents = date => {
  if (!isDate(date)) return null;
  let response = [];
  response.push({ short: format(date, 'd') });
  response.push({ short: format(date, 'cccc') });
  response.push({ short: format(date, 'MMM'), long: format(date, 'MMMM') });
  response.push({ dateOnly: format(date, 'yyyy-MM-d') });
  return response;
};

export const timeDistance = (from, to, locale) => {
  console.log('>>>> timeDistance', from, to);
  return formatDistance(to, from, { addSuffix: true, includeSeconds: true, locale });
};

export const changeTimeInDate = (time, day) => {
  return parse(time, 'HH:mm', new Date(day));
};

export const now = (separator = '-', type = 'date') => {
  const now = new Date();
  // const offsetMs = now.getTimezoneOffset() * 60 * 1000;
  // const dateLocal = new Date(now.getTime() - offsetMs);
  const dateLocal = new Date(now.setHours(0, 0, 0, 0));
  if (type === 'string') {
    return dateLocal.toISOString().slice(0, 19).replace(/-/g, separator).replace('T', ' ');
  } else if (type === 'date') {
    return dateLocal;
  }
};

// export const setTimeZeroDateWithTZ = (date, tz = false) => {
//   const baseDate = new Date(date).setUTCHours(0,0,0,0);
//   const dateZero = new Date(baseDate);
//   const offsetMs = new Date(baseDate).getTimezoneOffset() * 60 * 1000;
//   // console.log('setTimeZeroDateWithTZ',date);
//   // console.log('setTimeZeroDateWithTZ',dateZero);
//   // console.log('setTimeZeroDateWithTZ',offsetMs);
//   if (!tz) {
//     return dateZero;
//   } else {
//     return new Date(dateZero.getTime() + offsetMs);
//   }
// }
export const setTimeZeroDateWithTZ = (date, tz = true) => {
  const baseDate = new Date(date).setHours(0, 0, 0, 0);
  const dateZero = new Date(baseDate);
  return dateZero;
};

export const getDateOnly = date => {
  const date1 = new Date(date);
  return new Date(format(date1, 'yyy-MM-dd')).toISOString().slice(0, 10);
};

export const setDateTZ = date => {
  const date1 = new Date(date);
  const dateTZ = new Date(date1.getTime() + date1.getTimezoneOffset() * 60 * 1000);
  return dateTZ;
};

export const months = (m = null) => {
  const month = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'setiembre',
    'octubre',
    'noviembre',
    'diciembre'
  ];
  return m !== null ? month[m] : month;
};

export const numToTime = number => {
  // Check sign of given number
  const sign = number >= 0 ? 1 : -1;
  number = number * sign;

  let hour = Math.floor(number);
  let decpart = number - hour;

  let min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);
  let minute = Math.floor(decpart * 60);
  // Add padding if need
  return (sign === 1 ? '' : '-') + formatCompleteWith(hour, 2) + ':' + formatCompleteWith(minute, 2);
};

/** FORMAT */

export const formatCompleteWith = (str, len = 0, char = '0') => {
  const length = len === 0 ? str.toString().length : len;
  return char.repeat(length - str.toString().length) + str.toString();
};

export const formatAddressFromGoogle = address => {
  return `${address.components[1].short_name} ${address.components[0].short_name}`;
};

export const formatMoney = (amount, decimalCount = 2, decimal = ',', thousands = '.') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? '-' : '';
    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log('FORMATMONEY error:', e);
  }
};

export const formatPhoneNumber = phoneNumberString => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const formatTax = str => {
  if (isEmptyOrUndefined(str)) return null;
  let match = str.match(/^(\d{2})(\d{8})(\d{1})$/);
  if (match) {
    return [match[1], match[2], match[3]].join('-');
  }
  return null;
};

export const currencyTranslate = (current, translation) => {
  return translation && translation[current] ? translation[current] : current;
};

/** OTHERS */

export const paginate = (totalItems, currentPage = 1, pageSize = 10, maxPages = 0) => {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize);

  maxPages = maxPages === 0 ? totalPages : maxPages;
  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage, endPage;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  let pages = 0;
  if (totalPages) {
    pages = Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i);
  }

  const disablePrevious = currentPage === 1 ? true : false;
  const disableNext = currentPage === endPage ? true : false;

  // return object with all pager properties required by the view
  return {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    disablePrevious,
    disableNext,
    pages
  };
};

export const permissionsCheck = (path, userType, permissions, action = 'route') => {
  // If permissions is not defined, can't access anything
  const excludeSuperAdmin = [
    "credits",
    "mycompany",
    "mybusiness",
    "myorganization",
    "ranking",
    "departments",
    "prizes"
  ]
  const pathArr = path.split('/');
  if(userType === "superadmin" && !excludeSuperAdmin.includes(pathArr[1])) return true;

  if (permissions === undefined || permissions.length === 0) return false;
  // cut the path into a string after the /, and split that string with / separator

  if(path === "/benefits/collaborators"){
    if(userType === 'companies'){
      return true
    }
  }
  if(path.includes("administrators")){
    return userType !== "companies"
  }
  if(path.includes("moderador")) {
    if(userType === 'companies'){
      return true
    }
  }
  if(path.includes("collaborators")) {
    return true
  }
  if(path.includes('ranking')){
    return true
  }
  if(userType === 'companies' && path.includes('credits')){
    return true;
  }

  if(path === "/benefits" || path === "/benefits/new" || path === "/benefits/:id"){
    if(userType === 'companies'){
      return false
    }
  }

  if(path === "/benefits/consumptions" || path === "/benefits/consumptions/:id"){
    // if(userType === 'companies'){
      return true
    // }
  }

  if(path === "/referents" || path === "/referents/new" || path === "/referents/:id"|| path === "/referents/:id/excel"){ 
    return true  
  }

  if(path === "/challenges" || path === "/challenges/new" || path === "/challenges/:id"){
    return true
  }
  if(path ==="/actions/order") return true

  let route = null;
  if (!path.includes('user/')) {
    route = path.substr(1, path.length).split('/');
  } else if (path.includes('user/')) {
    // in this special case we'll just make an array with one element, we will not split
    route = [path.substr(1, path.length)];
  }
  // if the first position of the route includes these options, or there is nothing there
  // true, everyone can access these paths
  if (['login', 'logout', 'icons'].includes(route[0]) || route[0] === '')
    return true;
  // function to check permissions (invoked below)
  function actionType(perm) {
    // if route action and route is one
    // return true if we either are allowed to read or write

    if (action === 'route' && route.length === 1) {
      return perm.action_type === 'read' || perm.action_type === 'insert' || perm.action_type === 'attendance' || perm.action_type === 'consume' || perm.action_type === 'companybenefits' || perm.action_type === 'excel';
    }
    // if 2nd pos is new, we must have write permissions
    if (action === 'route' && route.length > 1 && (route[1].includes('new') || route[1].includes('profile'))) {
      return perm.action_type === 'insert';
    }
    // if it's id, we must have permission to either read or write
    if (action === 'route' && route.length > 1 && route[1].includes(':id')) {
      return perm.action_type === 'read' || perm.action_type === 'insert';
    }
    // if it's id, we must have permission to either read or write
    if (action === 'route' && route.length > 1 && route[1].includes(':volunteering_id')) {
      return perm.action_type === 'attendance';
    }
    // if it's id, we must have permission to either read or write
    if (action === 'route' && route.length > 1 && route[1].includes(':benefit_id')) {
      return perm.action_type === 'consume';
    }
    if (action === 'route' && route.length > 1 && route[1].includes(':companybenefit_id')) {
      return perm.action_type === 'companybenefits';
    }
    // if we want to delete update insert we must be allowed to write
    // refactor target?
    // if (action !== 'route' && ['delete'].includes(action)) {return perm.action_type === 'delete'}
    if (action !== 'route' && ['insert'].includes(action)) {
      return perm.action_type === 'insert';
    }
    if (action !== 'route' && ['update'].includes(action)) {
      return perm.action_type === 'update';
    }
    if (action !== 'route' && ['delete'].includes(action)) {
      return perm.action_type === 'delete';
    }
    if (action !== 'route' && ['enable'].includes(action)) {
      return perm.action_type === 'enable';
    }
    if (action !== 'route' && ['consume'].includes(action)) {
      return perm.action_type === 'consume';
    }
    if (action !== 'route' && ['attendance'].includes(action)) {
      return perm.action_type === 'attendance';
    }
    if (action !== 'route' && ['companybenefits'].includes(action)) {
      return perm.action_type === 'companybenefits';
    }
    if (action !== 'route' && ['excel'].includes(action)) {
      return perm.action_type === 'excel';
    }
  }
  // if permissions is not an array or there are no permissions, and we're trying to do a route
  // true
  if ((!Array.isArray(permissions) || !permissions) && action === 'route') {
    return true;
  } else if ( // if the action is delete update or insert without an array or permissions, no permissions
    (!Array.isArray(permissions) || !permissions) && ['delete', 'update', 'insert'].includes(action)
  ) {
    return false;
  }
  // generates array based on what permissions we have
  
  // object types that fit first position of route array call actionType func with the permission
  const found = permissions.filter(
    p => {
      if(["attendance", "scanqr"].includes(route[0]) && p.action_type === "attendance") return actionType(p);
      if(["consume", "scanqr"].includes(route[0]) && p.action_type === "consume") return actionType(p);
      if(["companybenefits",].includes(route[0]) && p.action_type === "companybenefits") return actionType(p);
      if(["excel",].includes(route[0]) && p.action_type === "excel") return actionType(p);
      return p.object_type === route[0] && actionType(p);
    }
    // p.action_type === action
  );

  // returns true if we have found permissions
  return found.length > 0;
};

export const getTimeZone = () => {
  if (Intl) return Intl.DateTimeFormat().resolvedOptions().timeZone;
  return null;
};

export const getClientIp = async () => {}

export const getLatLng = callback => {
  // Try HTML5 geolocation.
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        if (callback) callback({ lat, lng });
      },
      err => {
        console.log('ERROR WITH GEOLOCATION', err);
        if (callback) callback(null);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  } else {
    console.log('NO HTML GEOLOCATION');
    if (callback) callback(null);
  }
};

export const vehicleString = ({ brand, model, color = '', patent = '' }) => {
  return `${capitalize(brand)} ${capitalize(model)} ${color} ${patent}`;
};


export const replaceNewLines = (str) => {
  if(!str) return str
  if (typeof(str) != 'string') return str

  return str.replaceAll("\n", "<br>") 
}

export const parseUrl = value => {
  if (!value) return value;
  //reemplazar http por https
  if(value.includes("http:")){
    value = value.replace("http:", "https:")
  } 
  //reemplazar para que se pueda borrar el campo
  if(value == 'https:/') return ''
  //si empieza a escribir que agregue un url https
  if(value.length < 8) return `https://${value}`
  //si ya tiene el https que quede como está
  if(value.slice(0,8) == 'https://') return value;
  //y si no que lo agregue
  return `https://${value}`
};


export const validateBenefitsTransactions = (all_transactions, my_transactions, rules, set_error=()=>{}) => {
  let {group_uses, group_uses_interval, user_uses, user_uses_interval, days_of_week} = rules
  console.log("rules", rules)
  if(days_of_week){
    //FIXME: que el panel guarde domingo como 0 y no lunes
    let today_day = (new Date()).getDay()-1
    if(today_day === -1) today_day = 0
      console.log("bncheck", days_of_week)
    let available_days_of_week = Object.keys(days_of_week).map((k, index) => (days_of_week[k] === true) ? index : null).filter(x => x !== null)
   
    if(available_days_of_week.length !== 0){
      console.log("bncheck", available_days_of_week, today_day, available_days_of_week.indexOf(today_day))
      if(available_days_of_week.indexOf(today_day) === -1) {
        console.log("bncheck", "_________INVALIDATED FOR day of week", set_error)
        set_error("No disponible este día de la semana")
        return false
      }
    }
  }

  if(group_uses){
    group_uses = parseInt(group_uses)
    group_uses_interval = parseInt(group_uses_interval)/24
    console.log("max_group_uses", group_uses, group_uses_interval)
    console.log("group_uses", all_transactions.length)

    if(group_uses){
      if(all_transactions.length >= group_uses){
        console.log("bncheck", "_________INVALIDATED FOR group_uses")
        return false
      }
    }
    if(group_uses_interval){
      //FIXME: updated_at
      let interval_transactions = all_transactions.filter(t => t.updated_at ? new Date(t.updated_at) > subDays(new Date(), group_uses_interval) : false).length
      if(interval_transactions){
        console.log("bncheck", "_________INVALIDATED FOR group_uses_interval")
        return false
      }
    }
  }

  if(user_uses){
    user_uses = parseInt(user_uses)
    user_uses_interval = parseInt(user_uses_interval)/24
    console.log("max_user_uses", user_uses, user_uses_interval)
    console.log("user_uses", my_transactions.length)

    if(user_uses){
      if(my_transactions.length > user_uses){
        console.log("bncheck", "_________INVALIDATED FOR user_uses")
        return false
      }
    }

    if(user_uses_interval){
      let interval_transactions = my_transactions.filter(t => t.updated_at ? new Date(t.created_at) > subDays(new Date(), user_uses_interval) : false).length
      if(interval_transactions){
        console.log("bncheck", "_________INVALIDATED FOR interval_transactions")
        return false
      }
    }
  }

  return true
}

export const validateBenefitsTransactionsArray = (all_transactions, my_transactions, rules, pack=null) => {
  let {group_uses, group_uses_interval, user_uses, user_uses_interval, days_of_week} = rules
  console.log("rules", rules)

  let reasons = []

  if(days_of_week){
    //FIXME: que el panel guarde domingo como 0 y no lunes
    let today_day = (new Date()).getDay()-1
    if(today_day === -1) today_day = 0
      console.log("benefit_check", days_of_week)
    let available_days_of_week = Object.keys(days_of_week).map((k, index) => (days_of_week[k] === true) ? index : null).filter(x => x !== null)
   
    if(available_days_of_week.length !== 0){
      console.log("benefit_check", available_days_of_week, today_day, available_days_of_week.indexOf(today_day))
      if(available_days_of_week.indexOf(today_day) === -1) {
        console.log("benefit_check", "INVALIDATED FOR  day of week")
        reasons = [...reasons, "No disponible este día de la semana"]
      }
    }
  }

  if(group_uses){
    group_uses = parseInt(group_uses)
    group_uses_interval = parseInt(group_uses_interval)

    console.log("max_group_uses", group_uses, group_uses_interval)
    console.log("group_uses", all_transactions.length)

    if(group_uses){
      if(all_transactions.length >= group_uses){
        console.log("benefit_check", "INVALIDATED FOR group_uses")
        reasons = [...reasons, "La empresa ya agotó los usos disponibles"]
      }
    }
    if(group_uses_interval){
      let interval_transactions = all_transactions.filter(t => t.updated_at ? new Date(t.updated_at) > subHours(new Date(), group_uses_interval) : false).length
      if(interval_transactions){
        console.log("benefit_check", "INVALIDATED FOR group_uses_interval")
        reasons = [...reasons, `Disponible para empresa cada ${group_uses_interval} horas`]
      }
    }
  }

  if(user_uses){
    user_uses = parseInt(user_uses)
    user_uses_interval = parseInt(user_uses_interval)
    console.log("max_user_uses", user_uses, user_uses_interval)
    console.log("user_uses", my_transactions.length)
    console.log("my_transactions", my_transactions)

    if(user_uses){
      if(my_transactions.length >= user_uses){
        console.log("benefit_check", "INVALIDATED FOR user_uses")
        if(pack){
          reasons = [...reasons, "Ya agotaste los usos disponibles de este premio o de este grupo de premios"]
        } else {
          reasons = [...reasons, "El usuario ya agotó los usos disponibles"]
        }

      }
    }

    if(user_uses_interval){
      let interval_transactions = my_transactions.filter(t => t.updated_at ? new Date(t.created_at) > subHours(new Date(), user_uses_interval) : false).length
      if(interval_transactions){
        console.log("benefit_check", "INVALIDATED FOR interval_transactions")
        reasons = [...reasons, `Disponible para usuarios particulares cada ${group_uses_interval}`]
      }
    }
  }

  return reasons
}


export const validateBenefitRules = (benefit) => {
  let reasons = []
  let start = new Date(benefit.json_data.rules.start_date)
  let end = new Date(benefit.json_data.rules.end_date)
  end.setDate(end.getDate() + 1); // add one day to the end date to include the last day
  let today = new Date()
  if(!(isAfter(today, start) && isBefore(today, end))){
    if(benefit.json_data.rules.start_date && benefit.json_data.rules.end_date){
      console.log("bncheck", "_______________datecheck check failed")
      let reason = ""
      if(benefit.json_data.rules.start_date){
        reason += `desde ${getDateFirstPart(start)}`
      }  
      if(benefit.json_data.rules.end_date){
        reason += ` hasta ${getDateFirstPart(end)}`
      }

      console.log("benefit_check", "INVALIDATED FOR start-end date")
      reasons = [...reasons, `disponible ${reason}`]
    }
  }

  return reasons

}

export const daysOfWeekText = (daysOfWeek) => {
  if(typeof(daysOfWeek) === 'object') {
    return Object.keys(daysOfWeek).map(k => {
      if(daysOfWeek[k] && k === 'l') return "Lunes"
      if(daysOfWeek[k] && k === 'm') return "Martes"
      if(daysOfWeek[k] && k === 'x') return "Miércoles"
      if(daysOfWeek[k] && k === 'j') return "Jueves"
      if(daysOfWeek[k] && k === 'v') return "Viernes"
      if(daysOfWeek[k] && k === 's') return "Sábados"
      if(daysOfWeek[k] && k === 'd') return "Domingos"
      return null
    }).filter(d => d)
  }
  if(daysOfWeek.length === 0) return daysOfWeek
  return daysOfWeek.split("-").map(day => {
    if(day === 'l') return "Lunes"
    if(day === 'm') return "Martes"
    if(day === 'x') return "Miércoles"
    if(day === 'j') return "Jueves"
    if(day === 'v') return "Viernes"
    if(day === 's') return "Sábados"
    if(day === 'd') return "Domingos"
  })
}

export const daysOfWeekToString = (daysOfWeek) => {
  let s = ""
  let available_days = daysOfWeekText(daysOfWeek)
  if(available_days.length === 7){
    s = "Disponible todos los días"
  } else if(available_days.length === 1){
    s = `Disponible los ${available_days[0]}` 
  } else {
    s = `Disponible los ${available_days.slice(0,available_days.length-1).join(", ")} y ${available_days[available_days.length-1]}` 
  }
  return s  
}

export const getDateFirstPart = (d) => {
  return new Date(d).toLocaleDateString()
}

export const getRewardFromLevel = (userLevel, benefit) => {
  //console.log('USER LEVEL', userLevel, benefit);
  let user_current_level_benefit = false;
  if (userLevel > 0) {
    try {
      const benefit_user_value = benefit.values[(userLevel - 1).toString()]
      user_current_level_benefit = benefit_user_value.value
    } catch (e) {
      //console.log('No reward level in benefit', e, benefit)
    }
  }
  return user_current_level_benefit
}


export const removeDuplicates = (arr) => {
    return arr.filter((value, index, self) => self.indexOf(value) === index)
}


export const getTrxData = (trx) => {

}

export const edgeCaseTranslation = (t, phrase, n=0) => {
  //n === 
  if(phrase === "Beneficios"){

  }

  return t(phrase)
}

export const hideColumns = (number, windowWidth = 0) => {
  if (windowWidth < number) {
    return 'lg';
  }
  return null;
};

//reemplaza los "-" de las fechas como las guardamos en la db por "/"
//eso hace que cuando le hagas new Date() la string de la fecha
//anule el timezone, entonces las fechas que en en la db se guarda en una fecha a las 00
//el new date no le resta 3 horas
//https://twitter.com/Syb3rspace/status/799558118904594432
export const strToDateWithoutTZ = (date_str) => {
  if(!date_str) return null
  return new Date(date_str.replaceAll("-", "/").substring(0,10))
}

export function getCreditType(trx, id) {
  if(trx.json_data && trx.json_data.winner) return "award"
  if(trx.target !== id && trx.source !== id) return "other"
  if(trx.status === 'archived') return 'archived';
  if (trx.target === "RESERVED") return "reserved"
  if (trx.source === id) return "egress"
  if (trx.target === id) return "income"
  return null
}


export function calculateBalanceFromTrx(trxArray, id) {
  let balance = 0
  let reservedBalance = 0

  trxArray.forEach(trx => {
    const trxAmount = +trx.total
    if (
      trx.source === "RESERVED"
      || trx.target === "RESERVED" 
      // || trx.source === "ARCHIVED"
      // || trx.target === "ARCHIVED"
    ) {
      reservedBalance += trx.source === "RESERVED" ? -trxAmount : trxAmount
    }
    if (trx.source === id || trx.target === id) {
      balance += trx.source === id ? -trxAmount : trxAmount
    }
  })
  return { balance, reservedBalance }
}


export function getAwardingAndRemainingCredits(awards, winners, reservedCredits){
  let creditsWinners = [];
  let totalCreditsAwarded = 0;

  awards.forEach((award, index) => {
    if(award.benefit === 'credits'){
      creditsWinners.push({winner: winners[index], award});
      totalCreditsAwarded += award.quantity;
    }
  });

  const remainingCredits = reservedCredits - totalCreditsAwarded;

  return { creditsWinners, remainingCredits };
}

export function getCreditsFromMoney(money, maslow_factor, usd){
  return (+money * ((100 - +maslow_factor) / 100)).toFixed(2)
}

export function getMoneyFromCredits(credits, maslow_factor, usd){
  const result = (credits * ((100 - +maslow_factor)/100));
  return result.toFixed(2);
}

export const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
} 
export const removeAccentsWithOutN = (str) => {
  return str.normalize("NFD").replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/g, "");
} 