import types from './types';
import actions from './actions';
import reducers from './reducers';
// import services from './services';

const localization = {
  types,
  actions,
  reducers,
  // services,
}

export default localization;