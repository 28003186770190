import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { notify } from '../../../libraries/notifications';
import { getOwner, capitalizePhrase, capitalize } from '../../../libraries/utils';
import initiativeAssistantsActions from '../../../context/initiative_assistants/actions';
import usersActions from '../../../context/users/actions';
import Button from '../../../components/commons/Button';
import Icon from '../../../libraries/icons';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import UserCard from '../../../components/customs/UserCard';

class InitiativeAssistants extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      assistants: [],
      assistant: {},
      creatingNew: null,
      currSelectedAssistant: {},
      currSelectedUser: {},
      initiativeID: '',
      loading: false,
      maxAssistants: '',
      rewards: {},
      showEditForm: false,
      users: [],
      user: {},
      windowWidth: window.innerWidth
    };
    this.submit = null;
    this.breadcrumbs = [capitalize(this.t('Participantes'))];
  }

  componentDidMount() {
    // The data are the users
    this.getData();
    if (this.props.initiativeID) {
      this.getAssistants(this.props.initiativeID);
    }
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getData = async () => {
    await this.props.onGetUsers();
    this.setState({
      users: [...this.props.users.items]
    });
    if (this.props.maxAssistants) {
      this.setState({
        maxAssistants: this.props.maxAssistants
      });
    }
  };

  getAssistants = async (initiativeID, page_num = 1) => {
    const params = { initiativeID, page_num };
    const { search } = this.state;
    if (search && search !== '') {
      params.where = {
        custom: {
          title: `%${search.toLowerCase()}`
        }
      };
    }
    if (this.state.sort) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }
    await this.props.onGetAssistants(params);
    const { initiative_assistants: assistants } = this.props;
    if (assistants.error) {
      notify(this.t(assistants.error.message));
    } else {
      this.setState({
        assistants: assistants.items,
        initiativeID,
        loading: this.props.initiative_assistants.loading
      });
    }
  };

  getUser = async id => {
    await this.props.onGetUser({ id });
    const { user } = this.props;
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      this.setState({
        user: user.item
      });
      await this.props.onGetRewards(id);
      const { rewards } = this.props;
      if (!rewards.total) {
        notify(capitalize(this.t('error fetching rewards')));
      } else {
        this.setState({ rewards });
      }
    }
  };

  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () =>
      this.getAssistants(currentPage)
    );
  };

  /*   onRemove = (assistantID, resetRows) => {
    const params = { initiativeID: this.state.initiativeID, id: assistantID };
    // initiativeID: param required by redux context
    const { assistants } = this.state;
    customSwal
      .fire({
        title: this.t(
          Array.isArray(params.assistantID)
            ? 'Multiple remove'
            : '¿Are you sure?'
        ),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          // ** Loading
          this.state.loading = true;
          // ** If the parameter recieved is an array, execute multiple delete function
          if (Array.isArray(params.assistantID)) {
            for (let index = 0; index < params.assistantID.length; index++) {
              const element = params.assistantID[index];
              // ** Wait until the api removes selected items
              await this.props.onRemoveAssistant({
                initiativeID: params.initiativeID,
                id: element.id
              });
            }
            resetRows();
            if (assistants.error) {
              notify(this.t(assistants.error.message));
            } else {
              // ** Get initiatives
              this.getAssistants(
                this.state.initiativeID,
                this.state.currentPage
              );
            }
            // ** If the parameter is a number execute unique delete
          } else {
            // ** wait until the api removes selected items
            await this.props.onRemoveAssistant(params);
            if (assistants.error) {
              notify(this.t(assistants.error.message));
            } else {
              // ** Get assistants
              this.getAssistants(
                this.state.initiativeID,
                this.state.currentPage
              );
            }
          }
        }
      });
  }; */

  findIndividualUser = id => this.getUser(id);
  findUser = id => this.state.users.find(elem => elem.id === id);

  onView = async id => {
    const params = {
      id: id,
      initiativeID: this.state.initiativeID
    };

    await this.props.onGetAssistant(params);
    const { initiative_assistant: initiativeAssistant } = this.props;
    if (initiativeAssistant.error) {
      notify(this.t(initiativeAssistant.error.message));
    } else {
      // show the form to view assistant data
      const _currSelectedAssistant = {
        ...initiativeAssistant.item,
        name: capitalizePhrase(
          this.getUser(initiativeAssistant.item.assistant).name
        )
      };
      this.setState({
        currSelectedAssistant: _currSelectedAssistant,
        showEditForm: true
      });
    }
  };

  // ** Redirection to new initiative assistant route, disabled
  /* onNew = () => {
    
  }; */

  // ** Function to look for specific data in the table
  onSearch = data => {
    if (this.state.search !== data.search)
      this.setState({ search: data.search || '' }, () => this.getInitiatives());
  };

  onSubmit = async values => {
    try {
      const { initiativeID } = this.state;
      await this.props.onSaveOrUpdateAssistant(
        {
          ...values,
          owner: getOwner(this.props.auth.user)
        },
        initiativeID
      );
      this.setState({ showEditForm: false, creating_new: false });
      this.getAssistants(initiativeID);
    } catch (e) {
      console.error(e);
    }
  };

  // ** Hide functions when below certain widths
  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  render() {
    const {
      assistants,
      loading,
      showEditForm,
      rewards,
      user,
      windowWidth
    } = this.state;

    const query = {
      records: 8,
      page_size: 8
    };

    // ** This is the component that will show when expanding the table
    const expandableComponent = ({ data }) => (
      <div className="flex flex-wrap py-3 px-5 align-center justify-around bg-gray-50">
        <div className="py-2">
          <p className="text-gray-500">Desarrollar componente expandible</p>
        </div>
        <div className="py-2">
          <p>{this.t('Active')}</p>
          <p
            className={`${
              data.enabled ? 'bg-green-100' : 'bg-red-100'
            } rounded-box px-2 py-1`}>
            {data.enabled ? 'Activo' : 'Inactivo'}
          </p>
        </div>
      </div>
    );

    // ** Columns configuration for our data table
    const columns = [
      {
        name: capitalize(this.t('assistant')),
        sortable: true,
        selector: row => {
          const user = this.findUser(row['assistant']);
          if (user) {
            return capitalizePhrase(user.name);
          }
        },
        sortSelector: 'assistant'
      },
      {
        name: 'Acciones',
        allowOverflow: true,
        center: true,
        width: '150px',

        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full">
              <span
                onClick={() => this.onView(row.id)}
                className="p-3 rounded-full cursor-pointer bg-gray-700 hover:bg-gray-800">
                <Icon className="h-3 w-3 text-white" name="view_show" />
              </span>
              {/* <span
                onClick={() => this.onRemove(row.id)}
                className="p-3 rounded-r-full cursor-pointer bg-gray-700 hover:bg-gray-800"
              >
                <Icon className="h-3 w-3 text-white" name="trash" />
              </span> */}
            </div>
          );
        }
      }
    ];

    /* const required = (value) =>
      validateIsfilled(value) ? undefined : this.t('This field is required'); */

    return (
      <div className="xl:flex">
        <div className="w-full xl:w-1/2">
          <StyledDataTable
            data={assistants}
            noHeadCells
            columns={columns}
            selectableRows={windowWidth > 450}
            query={query}
            getDataFunction={this.getAssistants}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            expandableRows={windowWidth < 450}
            expandableRowsComponent={expandableComponent}
          />
          <div className="flex justify-evenly my-5">
            {/* <Button
              onClick={() => {
                this.setState({ showEditForm: true, creatingNew: true });
                this.getData();
              }}
              disabled={
                this.state.showEditForm || assistants.length >= maxAssistants
              }
              title={capitalize(this.t('add assistant'))}
            /> */}
            <Button
              onClick={() => {
                this.setState({
                  showEditForm: false,
                  currSelectedAssistant: ''
                });
              }}
              className="danger"
              disabled={!this.state.showEditForm}
              title={capitalize(this.t('close'))}
            />
          </div>
        </div>
        <div className="w-full xl:w-1/2">
          <div className="xl:flex xl:flex-col xl:items-center">
            {showEditForm ? (
              <div className="w-full xl:w-1/2 px-3 md:mb-0">
                <UserCard
                  user={user}
                  rewards={rewards}
                  loading={this.props.user.loading}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          {/* <Form
              initialValues={currSelectedAssistant || {}}
              onSubmit={this.onSubmit}
            >
              {({ handleSubmit, form, submitting, pristine, values }) => {
                this.submit = handleSubmit;
                this.reset = form.reset;
                return (
                  <FormLayout
                    form={form}
                    onSubmit={this.onSubmit}
                    values={values}
                  >
                    {this.state.creatingNew ? (
                      <React.Fragment>
                        <div className="xl:grid xl:grid-rows-1 xl:grid-cols-2 xl:w-full 2xl:w-3/4">
                          <div className="w-full px-3 md:mb-0">
                            <Field
                              component={SelectInput}
                              empty={capitalize(this.t('select assistant'))}
                              label={capitalize(this.t('assistant'))}
                              name="assistant"
                              options={assistantOptions}
                              placeholder={capitalize(this.t('assistant'))}
                              validate={required}
                            />
                          </div>
                        </div>
                        <div className="flex justify-center">
                          <Button
                            onClick={(e) => this.submit(e)}
                            title={this.t('Save')}
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="xl:grid xl:grid-rows-1 xl:w-full 2xl:w-3/4">
                          <div className="w-full px-3 md:mb-0">
                            <Field
                              name="name"
                              component={TextInput}
                              readOnly={!this.state.creatingNew}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </FormLayout>
                );
              }}
            </Form> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    initiative_assistants: state.initiative_assistants.list,
    initiative_assistant: state.initiative_assistants.current,
    users: state.users.list,
    user: state.users.current,
    rewards: state.users.auth.user.rewards,
    query: state.initiative_assistants.list.query
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAssistants: params =>
      dispatch(initiativeAssistantsActions.getAll(params)),
    onGetAssistant: params => dispatch(initiativeAssistantsActions.get(params)),
    onSaveOrUpdateAssistant: (params, initiativeID) =>
      dispatch(initiativeAssistantsActions.saveOrUpdate(params, initiativeID)),
    onRemoveAssistant: (initiativeID, assistantID) =>
      dispatch(initiativeAssistantsActions.del(initiativeID, assistantID)),
    onGetUsers: params => dispatch(usersActions.getAll(params)),
    onGetRewards: id => dispatch(usersActions.getRewards(id)),
    onGetUser: params => dispatch(usersActions.get(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(InitiativeAssistants));
