import arrayMutators from "final-form-arrays";
import React from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import FormLayout from "../../components/forms/FormLayout";
import config from "../../config";
import { ToastContainer, notify } from "../../libraries/notifications";
import {
  capitalize,
  formatOnlyNumberInput,
  getOwner,
  replaceNewLines,
  sortArrayByAttribute,
  strToDateWithoutTZ,
  validateIsfilled,
} from "../../libraries/utils";
import { history } from "../../routes";
// import actionActions from '../../context/actions/actions';
import benefitsActions from "../../context/benefits/actions";
import challegeActions from "../../context/challenges/actions";
import filesActions from "../../context/files/actions";
import usersActions from "../../context/users/actions";
// import businessesActions from '../../context/businesses/actions';
import actionsActions from "../../context/actions/actions";
import categoriesActions from "../../context/categories/actions";
import companiesActions from "../../context/companies/actions";
import trxActions from "../../context/transactions/actions";

import PanelWindow from "../../components/PanelWindow";
import CheckboxInputRow from "../../components/forms/CheckboxInputRow";
import DatepickerInput from "../../components/forms/DatepickerInput";
import ImageUploadFileInputEdit from "../../components/forms/ImageUploadFileInputEdit";
import IntegerInput from "../../components/forms/IntegerInput";
import SelectInput from "../../components/forms/SelectInput";
import TextInput from "../../components/forms/TextInput";

import { endOfDay, format, subDays } from "date-fns";
import Swal from "sweetalert2";
import Button from "../../components/commons/Button";
import WinnersList from "../../components/customs/WinnersList";
import ComboboxInput from "../../components/forms/ComboboxInput";
import TextareaInput from "../../components/forms/TextareaInput";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import Icon from "../../libraries/icons";
import FinishedChallengeBottom from "./finished/FinishedChallengeBottom";
import WebApp from "./webapp/WebApp";


//async function sleep(ms) {
//  return new Promise(resolve => setTimeout(resolve, ms));
//}

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary mx-1",
    cancelButton: "btn btn-outline btn-primary mx-1",
    title: "swal2-title",
    htmlContainer: "swal-text",
  },
  buttonsStyling: false,
  background: "#fff",
});

const ModalWinners = ({ open, closeChallenge, close, ...rest }) => {
  return (
    <>
      <input
        type="checkbox"
        id="my_modal_6"
        className="modal-toggle"
        checked={open}
      />
      <div className="modal">
        <div className="modal-box">
          <WinnersList {...rest} />
          <div className="flex w-full items-center justify-between py-4 mt-4">
            <Button title={"Confirmar cierre"} onClick={closeChallenge} />
            <Button title={"Cancelar"} onClick={close} />
          </div>
        </div>
      </div>
    </>
  );
};

class Challenge extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      challenge: {},
      benefits: [],
      awards: [],
      creatingNew: true,
      editing: false,
      customEditing: false,
      canBeDisabled: false,
      files: {},
      imageUpdated: false,
      loading: true,
      submitting: false,
      actionType: "action",
      challenge_id: null,
      activities: [],
      initialValues: { type: "action" },
      participants: [],
      leaderboard: [],
      categories: [],
      actions: [],
      initialCategories: [],
      initialActions: [],
      condition_type: "points",
      windowWidth: window.innerWidth,
      remove: false,
      titles: "",
      user: {},
      view: false,
      prizeUses: {},
      credits: 0, 
      reservedBalance: 0, 
      available: 0
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t("Desafíos"), this.t("New")];
    this.titles = this.t("User details");
  }

  async load() {
    const params = this.props.match.params;

    await this.getCompanyId()

    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        titles: this.t("Edit Challenge"),
        challenge_id: params.id,
        creatingNew: false,
      });
      this.breadcrumbs = [this.t("Desafíos"), this.t("Edit")];
      await this.getChallenge(params.id);
      await this.getParticipants(params.id);
      await this.getCreditsData(this.props.challenge.item.visibility);
    } else {
      this.setState({ titles: this.t("Nuevo desafío"), creatingNew: true });
      await this.getCreditsData('all');
    }
    await this.getBenefits();

    await this.loadAwards(params.id);
    await this.getActivities(params.id);
    await this.getCategories();
    await this.getActions();

    await this.setInitialValues(!params.id);

    //Winners

    const { challenge, benefits } = this.props;
    let { item, leaderboard, awards } = challenge;
    // const conditionType = challenge.item?.condition_type;
    if(leaderboard){
      leaderboard = [...leaderboard.leaderboard]
    }

    let winners = [];
    let winners_awards = [];
    if (item && item.winner === "umbral") {
      leaderboard.forEach((p) => {
        if (p.points >= item.winner_number) {
          winners.push(p);
          winners_awards.push(awards[0]);
        }
      });
    }

    if (item && item.winner === "first_n") {
      leaderboard.forEach((p, index) => {
        console.log(
          "index+1 >= item.winner_number",
          index + 1,
          item.winner_number
        );
        if (index + 1 <= item.winner_number) {
          winners.push(p);
          winners_awards.push(awards.find((a) => a.order_number === index + 1));
        }
      });
    }

    for (let i = 0; i < winners_awards.length; i++) {
      if (
        winners_awards[i].benefit !== "coins" &&
        winners_awards[i].benefit !== "credits"
      ) {
        let benefit = benefits.items.find(
          (b) => b.id === winners_awards[i].benefit
        );
        winners_awards[i].benefit = benefit ? benefit.name : "Premio";
      }
    }

    this.setState({
      modalWinners: winners,
      modalAwards: winners_awards,
      loadWebAppView: false,
      loading: false,
    });

    setTimeout(() => this.setState({ loadWebAppView: true }), 500);
    ;
  }

  async componentDidMount() {
    await this.load();
  }

  getCompanyId = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      if (this.props.companies.items.length) {
        this.setState({ company_id: this.props.companies.items[0].id });
        console.warn("company", this.props.companies.items[0].id);
      }
    }
  };

  async previewWinners() {
    this.setState({
      modalWinnersOpen: true,
    });
  }

  getCategories = async () => {
    await this.props.onGetCategories({ type: "actions" });
    const { categories } = this.props;
    if (categories.error) {
      notify(this.t(categories.error.message));
    } else {
      const orderedCategories = categories.items.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      //orderedCategories.unshift({id: "ALL_CATEGORIES", name:"Todas las categorías"})
      this.setState({
        categories: orderedCategories,
        initialCategories: orderedCategories,
      });
    }
  };

  getActions = async () => {
    await this.props.onGetActions();
    const { actions } = this.props;
    if (actions.error) {
      notify(this.t(actions.error.message));
    } else {
      const orderedActions = [...actions?.items].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      // orderedActions.unshift({id: "ALL_ACTIONS", name:"Todas las acciones"})
      this.setState({ actions: orderedActions });
    }
  };

  getChallenge = async (id) => {
    await this.props.onGetChallenge(id);
    await this.props.onGetLeaderboard(id);
    const { challenge } = this.props;
    if (challenge.error) {
      notify(this.t(challenge.error.message));
    } else {
      this.setState({
        challenge: {
          ...challenge.item,
          start_date: strToDateWithoutTZ(challenge.item?.start_date),
          end_date: strToDateWithoutTZ(challenge.item?.end_date),
          image: "",
        },
        condition_type: challenge.item?.condition_type,
        files: { img: challenge.item?.image },
        // loading: challenge.loading
      });
      if (
        this.state.challenge.owner &&
        this.state.challenge.owner !== "system"
      ) {
        //this.getUser(this.state.challenge.owner);
      }
    }

    await this.props.onGetTrx({
      type: 'credits',
      sub_type: 'credits',
      status: "paid",
      target: "RESERVED",
      reference: id,
    });

    const reservedTrx = this.props.trx.items.reduce((acc, trx) => acc + trx.total, 0);

    this.setState({ reservedTrx });
  };

  getParticipants = async (id) => {
    await this.props.onGetParticipants(id);

    const { participants } = this.props;
    this.setState({
      participants: participants?.participants,
    });
  };

  loadAwards = async (id) => {
    if (id) {
      await this.props.onGetAwards(id);
      const { awards } = this.props;
      if (awards?.error) {
        notify(this.t(awards.error.message));
      }
      this.setState({ awards: awards });
    }
  };

  getActivities = async (id) => {
    if (id) {
      await this.props.onGetActivities(id);
      const { activities } = this.props;
      if (activities?.error) {
        notify(this.t(activities.error.message));
      }
      this.setState({ activities: activities });
    }
  };

  closeChallenge = async () => {
    const { value: swalResponse } = await customSwal.fire({
      title: this.t("¿Are you sure?"),
      text: this.t(
        "Una vez cerrado se dará como finalizado el desafío y se enviará un correo a todos los participantes y ganadores."
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.t("Aceptar"),
      cancelButtonText: this.t("Cancel"),
    });

    if (swalResponse) {
      this.setState({ loading: true, submitting: true });

      await this.props.onCloseChallenge({ id: this.state.challenge.id });

      this.setState({
        challengeClosed: true,
        loading: false,
        submitting: false,
      });
    }
  };

  startChallenge = async () => {
    const { value: swalResponse } = await customSwal.fire({
      title: this.t("¿Are you sure?"),
      text: this.t(
        "Se enviará un correo a todos los participantes del desafío."
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.t("Aceptar"),
      cancelButtonText: this.t("Cancel"),
    });

    if (swalResponse) {
      this.setState({ loading: true });
      this.setState(
        { challenge: { ...this.state.challenge, status: "started" } },
        async () => {
          let creditsTotal = 0;
          if (this.state.challenge.winner === 'umbral' && this.state.awards[0].benefit === 'credits')
            creditsTotal = this.state.colaborators * this.state.awards[0].quantity;
          else this.state.awards.forEach(aw => {
            if (aw.benefit === 'credits') creditsTotal += aw.quantity;
          })

          if (creditsTotal > this.state.credits) customSwal.fire({
            title: 'No hay suficientes créditos.',
            text: `Este desafío tiene ${creditsTotal} créditos en premios, pero sólo hay ${this.state.credits} disponibles.`,
            icon: 'error',
            confirmButtonText: 'Aceptar',
          })
          else {

            await this.props.onStartChallenge({ id: this.state.challenge.id });

            customSwal.fire({
              title: 'Desafío comenzado',
              text: `Desafío correctamente iniciado. Los ${this.state.colaborators} colabadores que pueden verlo podrán participar en él.`,
              icon: 'success',
              confirmButtonText: 'Aceptar',
            })
          }

        }
      );
      this.setState({ loading: false });
    }
  };

  getCompanies = async () => {
    //await this.props.onGetCompanies({ owner: this.props.auth.user.id });
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });

    if (this.props.companies.error) {
      notify(this.t(this.props.companies.error.messsage));
    } else {
      return this.props.companies.items;
    }
  };

  getBenefits = async () => {
    const { user } = this.props.auth;
    const owner = await this.getCompanies();

    const params = { enabled: 1, type: "prize" }; //

    if (user.roles.includes(config.ROLES.COMPANIES)) {
      params.owner = owner[0].id;
    }
    await this.props.onGetBenefits(params);

    const { benefits } = this.props;


    if (benefits.error) {
      notify(this.t(benefits.error.message));
    } else {
      benefits.items.forEach(async (benefit) => {
        await this.getPrizeUses(benefit.id);
      });
      this.setState({ benefits: benefits.items });
    }
  };


  getPrizeUses = async id => {
    let params = {
      target: id,
      type: config.TRANSACTIONS.BENEFIT.TYPE,
      owner: this.props.auth.user.unique_id,
    };
    await this.props.onGetTrx(params);
    const { items, error } = this.props.trx;
    if (error) {
      notify(error.message);
    } else {
      this.setState({
        prizeUses: {
          ...this.state.prizeUses,
          [id]: items.filter( t => t.status === 'consumed')
        }
      })
    }
  }
  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  goBack = () => {
    history.push(config.ROUTES.CHALLENGES);
  };

  getUser = async (id) => {
    await this.props.onGetUser({ id });
    const { user } = this.props;
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      this.setState({
        user: user.item,
      });
    }
  };

  onFileChangeImageInput = async ({ file, data, name }) => {
    // const { challenge } = this.state;
    this.setState({ submitting: true });
    data.append(
      "identifier",
      `challenges_${parseInt(Math.random() * 1000000)}`
    );
    // if(!challenge.id) data.append('identifier', `${name}`);

    await this.props.onPictureUpload(data);
    const { files } = this.props;
    if (files.error) {
      notify(this.t(files.error.message));
    } else {
      this.setState({ imageUpdated: true });
      this.setState({
        files: {
          ...this.state.files,
          [name]: files.files.fileInfo.location,
        },
      });
    }
    this.setState({ submitting: false });
    return files.files.fileInfo.location;
  };

  onClearImageField = ({ name }) => {
    if (name) this.setState({ files: { ...this.state.files, [name]: null } });
  };

  onSubmit = async (values, form) => {
    //notify(this.t("Guardando..."), "info");
    const { creatingNew } = this.state;
    form.pauseValidation();

    const data = {
      name: values.name,
      type: "activities",
      details: replaceNewLines(values.details),
      participants_type: values.participants_type,
      condition_type: values.condition_type,
      winner: values.winner,
      winner_number: values.winner_number,
      start_date: format(new Date(values.start_date), "yyyy/MM/dd"),
      end_date: format(new Date(values.end_date), "yyyy/MM/dd"),
      image: this.state.files && this.state.files.image,
      visibility: values.visibility,
      owner: this.state.company_id ? this.state.company_id : "system",
      json_data: {},
    };

    if (creatingNew) {
      data.enabled = 0;
    }

    if (this.state.challenge_id) {
      data.id = this.state.challenge_id;
    }

    await this.props.onSaveOrUpdate(data);
    const { challenge } = this.props;

    if (challenge.error) {
      notify(this.t(challenge.error.message));
      return;
    }

    if (values.array_prize) {
      if (values.array_prize.length > 0) {
        const challenges_awards = values.array_prize.map((ap, index) => {
          return {
            challenge_id: challenge.item.id,
            benefit: ap.prize_type === "prize" ? ap.prize_id : ap.prize_type,
            order_number: index + 1,
            quantity: parseInt(ap.prize_number),
            delete: this.state.challenge_id && index === 0 ? true : false,
          };
        });

        await this.props.onPostAwards(challenges_awards[0]);
        let promises = [];
        for (let i = 1; i < challenges_awards.length; i++) {
          promises.push(this.props.onPostAwards(challenges_awards[i]));
        }
        await Promise.all(promises);
      }
    }

    if (values.all_activities) {
      await this.props.onPostActivities({
        type: values.type,
        sub_type: "n_actions",
        challenge_id: challenge.item.id,
        activity: "ALL",
        delete: this.state.challenge_id ? true : false,
      });
    } else if (values.activities && values.activities.length > 0) {
      const activities = values.activities.map((v, index) => {
        return {
          ...v,
          type: values.type,
          challenge_id: challenge.item.id,
          delete: this.state.challenge_id && index === 0 ? true : false,
        };
      });

      await this.props.onPostActivities(activities[0]);
      let promises = [];
      for (let i = 1; i < activities.length; i++) {
        promises.push(this.props.onPostActivities(activities[i]));
      }
      await Promise.all(promises);
    }

    if (challenge.error) {
      notify(this.t(challenge.error.message));
    } else {
      //await sleep(1000);
      this.goBack();
    }
  };

  disableEnableChallenge = async () => {
    this.setState({ loading: true });
    const { challenge } = this.state;
    const data = { id: challenge.id, enabled: challenge.enabled };
    await this.props.onSaveOrUpdate(data);
    const error = this.props.challenge.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    }
    this.setState({ loading: false });
  };

  didChallengeStarted = () => {
    const { challenge } = this.state;
    const today = new Date();
    const startDate = new Date(challenge.start_date);
    return startDate <= today;
  };

  checkStatus = () => {
    const { challenge } = this.state;
    const today = new Date();
    const endDate = new Date(challenge.end_date);

    if (challenge.enabled) {
      if (challenge.status === "finished" || endDate < today) {
        return "finished";
      }
      return "enabled";
    }
    return "disabled";
  };

  subCategoryTypes = (type) => {
    if (type === "action") {
      // subtypes puede ser categoría y seleccionas qué categoría
      // o n_actions para elegir entre todas las acciones
      return [
        { value: "category", label: this.t("Categoría") },
        { value: "n_actions", label: this.t("Acción") },
      ];
    }
    if (type === "borrow") {
      // borrow/lend
      return [
        { value: "borrow", label: this.t("Borrow") },
        { value: "lend", label: this.t("Lend") },
      ];
    }
    // type products?
    return [{ value: "category", label: this.t("Categoría") }];
  };

  setFieldLength(args, state, { changeValue }) {
    const { value, arrayField } = args[0];
    let numElements = value;
    if (typeof value === "string") {
      numElements = parseInt(value, 10);
    }

    const currentValues = state.formState.values[arrayField] || [];

    if (currentValues.length < numElements) {
      const diff = numElements - currentValues.length;
      const newValues = Array.from({ length: diff }, () => ({}));
      changeValue(state, arrayField, () => [...currentValues, ...newValues]);
    }

    if (currentValues.length > numElements) {
      const newValues = currentValues.slice(0, numElements);
      changeValue(state, arrayField, () => newValues);
    }
  }

  categoryTypes = [
    { value: "action", label: this.props.t("Acciones") },
    // { value: 'borrow', label: this.t('Borrow') },
    // { value: 'products', label: this.t('Products') },
  ];

  conditionTypes = [
    { value: "points", label: this.props.t("Puntos") },
    { value: "actions", label: this.props.t("Numero de acciones") },
  ];

  participants_types = [
    // { value: 'individuals', label: this.t('Individuals') },
    // { value: 'groups', label: this.t('Groups') },
    // { value: 'role', label: this.t('Role') },
    // { value: 'sucursal', label: this.t('Sucursal') },
    { value: "company", label: this.props.t("Empresa") },
  ];

  prize = [
    { value: "coins", label: this.props.t("Puntos Shary") },
    { value: "prize", label: this.props.t("Premio") },
    { value: "credits", label: this.props.t("Crédito") },
  ];

  winnersOptions = [
    {
      value: "umbral",
      label: this.props.t("Usuarios que realicen al menos x cantidad de puntos/acciones"),
    },
    { value: "first_n", label: this.props.t("Número de ganadores") },
    // { value: "TODO", label: this.t('Usuarios que superen x cantidad de acciones')},
  ];

  visibility = [
    { value: "all", label: this.props.t("Todos") },
    { value: "referents", label: this.props.t("Sólo Referentes") },
  ];

  setInitialValues = async (isNew) => {
    let values = {};

    if (!isNew) {
      const { challenge, awards: awards_list, activities } = this.state;

      const awards = sortArrayByAttribute(awards_list, "order_number");
      // let awards = awards_list.sort(
      //   (a1, a2) => (a1.order_number > a2.order_number) ? 1 : (a1.order_number < a2.order_number) ? -1 : 0);
      values = {
        ...challenge,
        array_prize: awards
          .sort((a, b) => a.order_number - b.order_number)
          .map((award) => {
            const isCoinOrCredit =
              award.benefit === "coins" || award.benefit === "credits";
            if (isCoinOrCredit) {
              return {
                prize_type: award.benefit,
                prize_number: award.quantity,
              };
            } else {
              return {
                prize_type: "prize",
                prize_id: award.benefit,
                prize_number: award.quantity || 0,
              };
            }
          }),
        type: activities?.length > 0 ? activities[0].type : undefined,
        all_activities: activities[0]?.activity === "ALL",
        activities: activities?.map((activity) => {
          return {
            activity: activity.activity,
            sub_type: activity.sub_type,
          };
        }),
        winner_number: challenge.winner_number.toString(),
        visibility: challenge.visibility,
      };

      delete values.enabled;
    } else {
      values.condition_type = this.conditionTypes[0].value;
      values.participants_type = this.participants_types[0].value;
      values.winner = this.winnersOptions[0].value;
      values.type = this.categoryTypes[0].value;
      values.visibility = this.visibility[0].value;
    }

    this.setState({ initialValues: values });
  };

  async getCreditsData(visibility) {
    this.setState({ colaborators: undefined, credits: undefined })
    // await this.props.onGetTrx({ type: 'credits', status: "paid", source: this.state.company_id });
    await this.props.onGetBalances({ unique_id: this.state.company_id, output: "company_balance" });
    const balance = this.props.user?.balance[0]?.credits?.balance ?? 0;
    const reservedBalance = this.props.user?.balance[0]?.credits?.reservedBalance ?? 0;
    const available = this.props.user?.balance[0]?.credits?.available ?? 0;
    
    let params = { unique_id: this.state.company_id };
    if (visibility === 'referents') params.invited_by = 'referent';

    await this.props.onGetAllUsers(params)
    const { users } = this.props
    if (users.error) {
      notify(this.t(users.error.message))
    } else {
      // ** Set state based on response data
      this.setState({
        colaborators: users.items.filter(u => u.enabled).map(u => u.id).length,
        credits: balance, 
        reservedBalance, 
        available
      })
    }
  }

  render() {
    const {
      creatingNew,
      view,
      editing,
      customEditing,
      submitting,
      challenge,
      loading,
      files,
      benefits,
      initialValues,
      participants,
      canBeDisabled,
      credits,
      colaborators,
      reservedTrx,
      prizeUses,
      available,
      condition_type,
    } = this.state;

    let array_credits_prizes = (initialValues.array_prize || []).filter(prize => prize.prize_type === "credits")
    
    // create options for benefits
    const prizeOptions = benefits
      .map((benefit) => {
        if (benefit.json_data.rules.group_uses < 0) return null
        return {
          value: benefit.id,
          // label: benefit.name,
          label: benefit.json_data.rules.group_uses > 0 ? benefit.name + ` (${benefit.json_data.rules.group_uses - prizeUses[benefit.id]?.length} disponibles)` : benefit.name,
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    // Layout actions
    const actions = {
      main: {
        onClick: (e) => this.submit(e),
        title: this.t("Save"),
        icon: "checkmark",
        disabled: submitting || (view && !editing && !customEditing),
        // checkPermissions: 'insert'
      },
      secondaries: [
        {
          onClick: (e) => history.push(config.ROUTES.CHALLENGES),
          title: this.t("Go Back"),
          icon: "cheveron_left",
          disabled: submitting,
          visible: true,
          className: "btn-accent",
        },

        {
          onClick: () => {
            //Se requiere poder editar el challenge aunque ya haya sido iniciado si no tiene actividad
            // if (challenge.status === "not_started")
            //   return this.setState({ editing: true });
            // if (this.didChallengeStarted()) {
            //   if (challenge.enabled && participants.length === 1) {
            //     notify(
            //       this.t("El desafío ya empezó, pero aún puedes deshabilitarlo")
            //     );
            //     this.setState({ canBeDisabled: true });
            //     return;
            //   }
            //   if (challenge.enabled === 0) {
            //     notify(
            //       this.t(
            //         "El desafío empezó pero no está habilitado\n Por favor cambie la fecha de inicio antes de habilitar el desafío"
            //       )
            //     );
            //     this.setState({ editing: true });
            //   } else {
            //     notify(
            //       this.t(
            //         "El desafío ya empezó, pero aún puedes modificar detalles y nombre"
            //       )
            //     );
            //     this.setState({ customEditing: true });
            //   }
            //   return;
            // }
            if (this.state.activities?.length > 0) {
              notify(
                this.t(
                  "El desafío ya tiene actividades, pero aún puedes modificar detalles y nombre"
                )
              );
              this.setState({ customEditing: true });
            }else{
              alert('Editing true')
            }
            this.setState({ editing: true });
          },
          title: this.t("Edit"),
          icon: "edit_pencil",
          disabled: submitting,
          visible: view && !editing && !customEditing,
          // checkPermissions: 'update'
        },
        {
          onClick: (e) => {
            this.reset();
            this.setState({ editing: false, customEditing: false });
          },
          title: this.t("Cancel"),
          icon: "edit_pencil",
          // disabled: submitting || !editing || !customEditing,
          disabled: submitting || (!editing && !customEditing),
          visible: editing || customEditing,
        },
        {
          onClick: (e) => {
            this.startChallenge();
          },
          title: this.t("Start"),
          icon: "",
          disabled:
            (array_credits_prizes && array_credits_prizes.length > 0 && array_credits_prizes.reduce((total, prize) => total + prize.prize_number, 0) >= credits)
            || !colaborators
            || challenge.status === "started"
            || challenge.status === "finished",
          visible: !creatingNew,
        },
        //fixme: ¿condicional si ya pasó la fecha de cierre?
        {
          onClick: (e) => {
            this.previewWinners();
          },
          title: this.t("Cerrar"),
          icon: "close",
          disabled:
            !this.state.modalWinners || !this.state.modalAwards ||
            !this.props.challenge?.awards ||
            this.state.challengeClosed ||
            submitting ||
            this.checkStatus() !== "finished",
          visible: !creatingNew,
        },
        {
          onClick: (e) => {
            if (challenge.enabled && participants.length === 1) {
              this.disableEnableChallenge();
              this.setState({ canBeDisabled: false });
              return;
            }
            if (this.didChallengeStarted() && participants.length > 0) {
              notify(this.t("El desafío ya empezó"));
              return;
            }
            if (challenge.enabled === 1)
              this.setState({ challenge: { ...challenge, enabled: 0 } }, () => {
                this.disableEnableChallenge();
                this.setState({ editing: false, customEditing: false });
              });
            if (challenge.enabled === 0)
              this.setState({ challenge: { ...challenge, enabled: 1 } }, () => {
                this.disableEnableChallenge();
                this.setState({ editing: false, customEditing: false });
              });
          },
          title: this.t(challenge?.enabled ? "Disable" : "Enable"),
          icon: !challenge?.enabled ? "view_show" : "view_hide",
          disabled:
            challenge.status !== "not_started" ||
            challenge.status !== "finished" ||
            submitting ||
            (view && !editing && !canBeDisabled),
          // checkPermissions: 'enable',
          visible: !creatingNew,
        },
      ],
    };

    const required = (value) =>
      validateIsfilled(value) || (view && !editing)
        ? undefined
        : this.t("This field is required");

    const validateForm = (values) => {
      const errors = {};
      errors.name = required(values.name);
      errors.type = required(values.type);
      errors.details = required(values.details);

      errors.participants_type = required(values.participants_type);
      errors.start_date = required(values.start_date);
      errors.end_date = required(values.end_date);
      errors.condition_type = required(values.condition_type);
      errors.winner = required(values.winner);
      errors.winner_number = required(values.winner_number);

      if (values.start_date && values.end_date && !customEditing) {
        if (values.start_date < subDays(endOfDay(new Date()), 2)) {
          errors.start_date = this.t("La fecha de inicio debe ser desde ayer");
        }
        if (values.start_date.toISOString() === values.end_date.toISOString()) {
          errors.start_date = this.t(
            "El desafio no puede inicar y terminar el mismo día"
          );
          errors.end_date = this.t(
            "El desafio no puede inicar y terminar el mismo día"
          );
        }
      }
      if (values.activities && values.activities.length > 0) {
        values.activities.forEach((activity, index) => {
          if (!activity.sub_type) {
            errors.activities = errors.activities || [];
            errors.activities[index] = errors.activities[index] || {};
            errors.activities[index].sub_type = this.t(
              "This field is required"
            );
          }
          if (!activity.activity) {
            errors.activities = errors.activities || [];
            errors.activities[index] = errors.activities[index] || {};
            errors.activities[index].activity = this.t(
              "This field is required"
            );
          }
        });
      }
      if (values.array_prize && values.array_prize.length > 0) {
        const maxCreditsPerColab = Math.floor(available / colaborators);
        let remainingCredits = available;

        values.array_prize.forEach((prize, index) => {
          if (!prize.prize_type) {
            errors.array_prize = errors.array_prize || [];
            errors.array_prize[index] = errors.array_prize[index] || {};
            errors.array_prize[index].prize_type = this.t(
              "This field is required"
            );
          }
          if (prize.prize_type === "prize" && !prize.prize_id) {
            errors.array_prize = errors.array_prize || [];
            errors.array_prize[index] = errors.array_prize[index] || {};
            errors.array_prize[index].prize_id = this.t(
              "This field is required"
            );
          }
          if (
            (prize.prize_type === "coins" || prize.prize_type === "credits") &&
            !prize.prize_number
          ) {
            errors.array_prize = errors.array_prize || [];
            errors.array_prize[index] = errors.array_prize[index] || {};
            errors.array_prize[index].prize_number = this.t(
              "This field is required"
            );
          }

          if (values.winner === 'umbral'
            && prize.prize_type === 'credits'
            && prize.prize_number
            && prize.prize_number > maxCreditsPerColab) {

            errors.array_prize = errors.array_prize || [];
            errors.array_prize[index] = errors.array_prize[index] || {};
            errors.array_prize[index].prize_number = `Máximo ${maxCreditsPerColab} créditos por colaborador.`
          }

          else if (values.winner === 'first_n'
            && prize.prize_type === 'credits'
            && prize.prize_number) {

            if (prize.prize_number > remainingCredits) {
              errors.array_prize = errors.array_prize || [];
              errors.array_prize[index] = errors.array_prize[index] || {};
              errors.array_prize[index].prize_number = `No hay suficientes créditos.`
            }
            else remainingCredits -= prize.prize_number;
          }
        });
      }

      return errors;
    };

    return (
      <LayoutWithSidebar
        main={{ className: "text-content-400" }}
        header={{ breadcrumbs: this.breadcrumbs }}
        container={{ className: "px-8 mb-3 rounded-lg" }}
        actions={actions}
      >
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={loading}>
          <Form
            initialValues={initialValues || { type: "action" }}
            onSubmit={this.onSubmit}
            subscription={{ values: true }}
            validate={validateForm}
            mutators={{ ...arrayMutators, setFieldLength: this.setFieldLength }}
          >
            {({
              handleSubmit,
              form,
              values,
              form: {
                mutators: { setFieldLength, push, pop },
              },
            }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              values.details = values.details || "";

              return (
                <FormLayout
                  form={form}
                  onSubmit={this.onSubmit}
                  values={values}
                >
                  <div className="w-full mb-3 md:mb-6">
                    <Field
                      name="image"
                      img={files.img}
                      component={ImageUploadFileInputEdit}
                      label={capitalize(this.t("image"))}
                      placeholder={capitalize(this.t("image URL"))}
                      inputOnChange={this.onFileChangeImageInput}
                      readOnly={view && !editing && !customEditing}
                      clickButtonClear={this.onClearImageField}
                      inputClassName="input-bordered shadow-none cursor-pointer"
                      noError={true}
                    />
                  </div>
                  <div className="flex">
                    <Field
                      name="type"
                      component={SelectInput}
                      label={capitalize(this.t("type"))}
                      placeholder={capitalize(this.t("type"))}
                      options={this.categoryTypes}
                      empty={capitalize(
                        this.t("Selecciona un tipo de desafío")
                      )}
                      readOnly={view && !editing}
                      className="form-control px-3 mb-3 md:mb-0 xl:col-span-4"
                    />
                    <Field
                      name="name"
                      component={TextInput}
                      placeholder={this.t("First name")}
                      label={this.t("First name")}
                      readOnly={view && !editing && !customEditing}
                      className="px-3 mb-3 md:mb-0 xl:col-span-10 w-full"
                    />
                  </div>
                  <div className="flex flex-col lg:flex-row w-full">
                    <Field
                      name="details"
                      component={TextareaInput}
                      placeholder={"Una descripción completa del desafío"}
                      label={capitalize(this.t("details"))}
                      readOnly={view && !editing && !customEditing}
                      className="px-3 mb-3 md:mb-0 flex-1"
                      inputClassName="resize-none h-28"
                    />
                  </div>

                  <div className="flex flex-col lg:flex-row gap-6">
                    <div className="flex gap-3 flex-col sm:flex-row justify-start">
                      <Field
                        name="visibility"
                        component={SelectInput}
                        placeholder={this.t("Visibilidad")}
                        info="El nivel de visibilidad de este desafío; es decir, qué colaboradores podrán verlo y participar en él."
                        readOnly={view && !editing}
                        label={capitalize(this.t("Visibilidad"))}
                        options={this.visibility}
                        empty={capitalize(this.t("Selecciona la visibilidad"))}
                        className="w-full md:w-40"
                        selectClassName="w-40"
                        onChangeSelect={(v) => this.getCreditsData(v)}
                      />
                      <Field
                        name="condition_type"
                        component={SelectInput}
                        placeholder={this.t("Tipo de condición")}
                        info="Que condición debe cumplir para participar del desafío si haciendo una cierta cantidad de acciones o sumando cierta cantidad de puntos"
                        readOnly={view && !editing}
                        label={capitalize(this.t("Tipo de condición"))}
                        options={this.conditionTypes}
                        empty={capitalize(this.t("Selecciona una condición"))}
                        className="w-full md:w-40"
                        selectClassName="w-40"
                      />
                      <Field
                        name="participants_type"
                        component={SelectInput}
                        placeholder={this.t("Participantes")}
                        info="Quienes pueden participar, toda la empresa, ciertas personas, areas, sucursales, etc"
                        readOnly={view && !editing}
                        label={capitalize(this.t("Participantes"))}
                        options={this.participants_types}
                        empty={this.t("Seleccionar participantes")}
                        className="w-full md:w-40"
                        selectClassName="w-40"
                      />
                    </div>
                    <div className="flex flex-col sm:flex-row gap-3 justify-start lg:justify-end">
                      <Field
                        name="start_date"
                        info="Una vez comenzado no se podrá modificar nada de la información"
                        component={DatepickerInput}
                        placeholder={this.t("dd/mm/yyyy")}
                        label={this.t("Fecha de inicio")}
                        readOnly={view && !editing}
                        className="w-full md:w-40"
                      />
                      <Field
                        name="end_date"
                        component={DatepickerInput}
                        placeholder={this.t("dd/mm/yyyy")}
                        readOnly={view && !editing}
                        label={this.t("Fecha de fin")}
                        className="w-full md:w-40"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col lg:flex-row gap-3">
                    <div className="card white flex flex-col lg:w-1/2 border p-3 pb-48">
                      <h3 className="mb-2 flex">
                        Actividades
                        <span
                          className="ml-1 font-normal pop-up  after:content-[attr(after)]"
                          after={"Qué hay que  hacer para poder ganar puntos. "}
                        >
                          <Icon name="information_solid" className="h-4 w-4" />
                        </span>
                      </h3>

                      <div className="w-36">
                        <Field
                          name="all_activities"
                          component={CheckboxInputRow}
                          placeholder={capitalize(this.t("all"))}
                          label={capitalize(this.t("Cualquier Acción"))}
                          readOnly={view && !editing}
                          type="checkbox"
                        />
                      </div>

                      {!values.all_activities && (
                        <>
                          <div>
                            <button
                              className="btn btn-primary mb-2"
                              type="button"
                              disabled={view && !editing}
                              onClick={() => push("activities", {})}
                            >
                              {this.t("+ Agregar actividades")}
                            </button>
                          </div>
                          <FieldArray name="activities">
                            {({ fields }) =>
                              fields.map((name, index) => {
                                const type_value = values?.type;
                                const sub_type_value =
                                  fields.value[index]?.sub_type;
                                const show_actions_options =
                                  type_value === "action" &&
                                  sub_type_value === "n_actions";
                                const show_categories_options =
                                  type_value === "action" &&
                                  sub_type_value === "category";

                                let options = [];
                                if (
                                  show_actions_options &&
                                  Array.isArray(this.state.actions)
                                ) {
                                  options = [
                                    ...this.state.actions.map((a) => {
                                      return { label: a.name, value: a.id };
                                    }),
                                  ];
                                }

                                if (
                                  show_categories_options &&
                                  Array.isArray(this.state.categories)
                                ) {
                                  options = [
                                    ...this.state.categories.map((a) => {
                                      return { label: a.name, value: a.id };
                                    }),
                                  ];
                                }

                                return (
                                  <div key={name + index}>
                                    <div className="xl:grid xl:grid-cols-12 xl:grid-rows-1 pt-2 pl-2 mb-2 border">
                                      <Field
                                        name={`${name}.sub_type`}
                                        component={SelectInput}
                                        // label={capitalize(this.t('sub_type'))}
                                        placeholder={capitalize(
                                          this.t("subcategory")
                                        )}
                                        options={this.subCategoryTypes(
                                          type_value
                                        )}
                                        empty={capitalize(
                                          this.t("Selecciona una subcategoría")
                                        )}
                                        readOnly={view && !editing}
                                        className="form-control pr-3 mb-1 md:mb-0 xl:col-span-4"
                                      />
                                      {sub_type_value !== "borrow" &&
                                        sub_type_value !== "lend" && (
                                          <Field
                                            name={`${name}.activity`}
                                            component={ComboboxInput}
                                            // label={sub_type_value === "n_actions" ? capitalize(this.t('actions')) : capitalize(this.t('category'))}
                                            placeholder={
                                              sub_type_value === "n_actions"
                                                ? capitalize(this.t("actions"))
                                                : capitalize(this.t("category"))
                                            }
                                            showInfo={sub_type_value === "n_actions"}
                                            options={options}
                                            empty={capitalize(
                                              this.t("Selecciona una acción")
                                            )}
                                            readOnly={view && !editing}
                                            className="form-control pr-3 mb-1 md:mb-0 xl:col-span-6"
                                            disabled={type_value !== "action"}
                                            onChangeSelect={(v) => {
                                              if (!show_categories_options)
                                                return;
                                              if (v === "ALL_CATEGORIES") {
                                                this.setState({
                                                  categories: [],
                                                });
                                              } else {
                                                // this.setState((prevState) =>  ({categories: prevState.categories.filter(c => c.id !== v)}))
                                                // TODO: checkear esto
                                              }
                                            }}
                                          />
                                        )}

                                      <button
                                        className="btn btn-primary self-center w-14"
                                        type="button"
                                        disabled={view && !editing}
                                        onClick={() => {
                                          fields.remove(index);
                                          if (!show_categories_options) return;
                                          const removedItem =
                                            this.state.initialCategories.find(
                                              (c) =>
                                                c.id ===
                                                values?.activities[index]
                                                  ?.activity
                                            );
                                          if (
                                            removedItem?.id === "ALL_CATEGORIES"
                                          ) {
                                            this.setState({
                                              categories:
                                                this.state.initialCategories,
                                            });
                                          } else {
                                            this.setState((prevState) => ({
                                              categories:
                                                prevState.categories.filter(
                                                  (c) => c.id !== removedItem.id
                                                ),
                                            }));
                                          }
                                        }}
                                      >
                                        <Icon
                                          name="trash"
                                          className="h-4 w-4"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </FieldArray>
                        </>
                      )}
                    </div>

                    <div className="card white border p-3 lg:w-1/2">
                      {this.state.creatingNew && <p className="px-4 py-2 mb-4 bg-gray-100 rounded-md">Créditos disponibles: {available ? <><span className='text-green-700'>{available}</span> (para {colaborators} colaboradores)</> : 'Cargando...'}. </p>}
                      {!!reservedTrx && <p className="px-4 py-2 mb-4 bg-gray-100 rounded-md">Créditos reservados: <span className="text-yellow-700">{reservedTrx}</span></p>}
                      <div className="flex flex-col md:flex-row sm:gap-2 w-full">
                        <Field
                          name="winner"
                          component={SelectInput}
                          label={capitalize(this.t("Tipo de ganador"))}
                          options={this.winnersOptions}
                          // empty={capitalize(this.t('Tipo de ganador'))}
                          readOnly={view && !editing}
                          labelClassName="mr-8"
                          className="w-48"
                          selectClassName="w-48"
                        />
                        <ShowOnCondition when="winner" is="umbral">
                          <Field
                            name="winner_number"
                            component={IntegerInput}
                            label={capitalize(this.t("Cantidad"))}
                            placeholder={capitalize(this.t("Desde cuanto"))}
                            parse={(v) => formatOnlyNumberInput(v)}
                            readOnly={view && !editing}
                            disabled={values.winner !== "umbral"}
                            className="mr-4"
                            onChangeSelect={() => {
                              setFieldLength({
                                value: "1",
                                arrayField: "array_prize",
                              });
                            }}
                          />
                        </ShowOnCondition>
                        <ShowOnCondition when="winner" is="first_n">
                          <Field
                            name="winner_number"
                            component={ComboboxInput}
                            customValues={true}
                            placeholder="N° Ganadores"
                            readOnly={view && !editing}
                            label={capitalize(this.t("Número de ganadores"))}
                            options={Array(50)
                              .fill(false)
                              .map((_, i) => ({
                                value: (i + 1).toString(),
                                label: (i + 1).toString(),
                              }))}
                            empty={capitalize(
                              this.t("Selecciona el numero de ganadores")
                            )}
                            customParse={(v) => formatOnlyNumberInput(v, 80)}
                            onChangeSelect={(value) => {
                              setFieldLength({
                                value,
                                arrayField: "array_prize",
                              });
                            }}
                          />
                        </ShowOnCondition>
                      </div>

                      <FieldArray name="array_prize">
                        {({ fields }) => {
                          //
                          return fields.map((name, i) => {
                            return (
                              <div
                                className="flex flex-col md:flex-row sm:gap-2 w-full"
                                key={name}
                              >
                                <Field
                                  name={`${name}.prize_type`}
                                  component={SelectInput}
                                  placeholder={this.t("premio")}
                                  readOnly={view && !editing}
                                  label={
                                    fields.length > 1
                                      ? `${this.t("Puesto")} ${i + 1}`
                                      : this.t("Premio")
                                  }
                                  options={this.prize}
                                  empty={capitalize(
                                    this.t("Selecciona el premio")
                                  )}
                                />
                                <ShowOnCondition
                                  when={`${name}.prize_type`}
                                  is={["coins", "credits"]}
                                >
                                  <Field
                                    name={`${name}.prize_number`}
                                    component={IntegerInput}
                                    placeholder={this.t("Cantidad")}
                                    parse={(v) => formatOnlyNumberInput(v)}
                                    readOnly={view && !editing}
                                    label={capitalize(this.t("Cantidad"))}
                                  />
                                </ShowOnCondition>
                                <ShowOnCondition
                                  when={`${name}.prize_type`}
                                  is="prize"
                                >
                                  <Field
                                    name={`${name}.prize_id`}
                                    component={ComboboxInput}
                                    options={prizeOptions}
                                    empty={capitalize(
                                      this.t("Selecciona el premio")
                                    )}
                                    placeholder={this.t(
                                      "Selecciona el premio del reto"
                                    )}
                                    readOnly={view && !editing}
                                    label={capitalize(
                                      this.t("Premio del reto")
                                    )}
                                  />
                                </ShowOnCondition>
                              </div>
                            );
                          });
                        }}
                      </FieldArray>
                    </div>
                  </div>

                  {/* <div className="my-32" /> */}
                </FormLayout>
              );
            }}
          </Form>
          <div className="pt-8">
            {this.state.challenge?.status !== "finished" &&
              !creatingNew &&
              this.state.loadWebAppView && <WebApp onReload={() => this.load()} />}
            {!creatingNew && this.state.challenge?.status === "finished" && (
              <FinishedChallengeBottom
                open={this.state.modalWinnersOpen}
                close={() => {
                  this.setState({ modalWinnersOpen: false });
                }}
                closeChallenge={() => {
                  this.setState({ modalWinnersOpen: false }, () =>
                    this.closeChallenge()
                  );
                }}
                winners={this.state.modalWinners}
                awards={this.state.modalAwards}
                condition_type={condition_type}
              />
            )}
          </div>
        </PanelWindow>


        <ModalWinners
          open={this.state.modalWinnersOpen}
          close={() => {
            this.setState({ modalWinnersOpen: false });
          }}
          closeChallenge={() => {
            this.setState({ modalWinnersOpen: false }, () =>
              this.closeChallenge()
            );
          }}
          winners={this.state.modalWinners}
          awards={this.state.modalAwards}
          reservedCredits={reservedTrx}
          condition_type={condition_type}
        />
      </LayoutWithSidebar>
    );
  }
}
function ShowOnCondition({ when, is, children }) {
  const values = Array.isArray(is) ? is : [is];

  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (values.includes(value) ? children : null)}
    </Field>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    challenge: state.challenges.current,
    awards: state.challenges.current.awards,
    activities: state.challenges.current.activities,
    participants: state.challenges.current.participants,
    leaderboard: state.challenges.current.leaderboard,
    files: state.files,
    user: state.users.current,
    benefits: state.benefits.list,
    actions: state.actions.list,
    categories: state.categories.list,
    companies: state.companies.list,
    users: state.users.list,
    trx: state.transactions.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetChallenge: (id) => dispatch(challegeActions.get(id)),
    onSaveOrUpdate: (params) => dispatch(challegeActions.saveOrUpdate(params)),
    onClearCurrent: () => dispatch(challegeActions.clearCurrent()),
    onGetAwards: (params) => dispatch(challegeActions.getAwards(params)),
    onPostAwards: (params) => dispatch(challegeActions.postAwards(params)),

    onGetBenefits: (params) => dispatch(benefitsActions.getAll(params)),

    onGetUser: (params) => dispatch(usersActions.get(params)),
    onGetAllUsers: params => dispatch(usersActions.getAll(params)),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),

    // onGetBusinesses: params => dispatch(businessesActions.getAll(params)),

    // onClearCurrent: () => dispatch(actionActions.clearCurrent()),

    onGetCategories: (params) => dispatch(categoriesActions.getAll(params)),
    onGetActions: (params) => dispatch(actionsActions.getAll(params)),

    onPostActivities: (params) =>
      dispatch(challegeActions.postActivities(params)),
    onGetActivities: (id) => dispatch(challegeActions.getActivities(id)),

    onGetParticipants: (id) => dispatch(challegeActions.getParticipants(id)),
    onGetLeaderboard: (id) => dispatch(challegeActions.getLeaderboard(id)),

    onCloseChallenge: (params) => dispatch(challegeActions.close(params)),
    onStartChallenge: (params) => dispatch(challegeActions.start(params)),

    onGetCompanies: (params) => dispatch(companiesActions.getAll(params)),

    onGetTrx: params => dispatch(trxActions.getAll(params)),
    onGetBalances: params => dispatch(usersActions.getBalance(params)),

    // onPostTrx: params => dispatch(trxActions.saveOrUpdate(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Challenge));
