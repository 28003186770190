import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled, getClientIp, isEmptyObject } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import TextWithIconInput from '../../components/forms/TextWithIconInput';
import Icon from '../../libraries/icons';

import Splash from '../../components/commons/Splash';
import Swal from 'sweetalert2';

class BenefitConsume extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.from =  (this.props.location && this.props.location.state && this.props.location.state.from) || { pathname: '/' };
    this.state = {
      submitting: false,
      initialValues: {},
    }
  }
  
  componentDidMount() {
    if (!isEmptyObject(this.props.location.state)) { 
      console.log(this.props.location.state?.reason)
      if(this.props.location.state.error){
        this.fireErrorSwal(this.props.location.state.reason)
      }
      if(this.props.location.state.success){
        this.fireSuccessSwal()
      } else {
        this.fireErrorSwal("Premio ya reclamado.")
      }
      const { qr } = this.props.location.state;
      if(qr){
        this.setState({ 
          initialValues: {
            codigo: qr
          }
        });
        this.onSubmit({codigo: qr})
      }
    }
  }

  fireErrorSwal(reason=null) {
      const swalWithStyle = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-primary mr-2',
          cancelButton: 'btn btn-ghost',
        },
        buttonsStyling: false
      })
      swalWithStyle.fire({ 
        title:this.t("Error"),
        text:  reason ? this.t(reason) : this.t("Código inexistente"),
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: this.t("Ok"),
      })
  }

  fireSuccessSwal() {
    const swalWithStyle = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-ghost',
      },
      buttonsStyling: false
    })
    swalWithStyle.fire({ 
      title: this.t("Success"),
      text: this.t("Beneficio consumido exitosamente"),
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: this.t("Ok"),
    })
  }

  onSubmit = (values) => {
    
    history.push({
      pathname: config.ROUTES.BENEFITS_CONSUME_CONFIRMATION.replace(':benefit_id', values.codigo),
      state: {
        transactionCode: values.codigo,
        error: null,
      }
    });
  }
  
  render() {
    //if (!this.state.doLogin || (!this.props.auth.logged && this.props.auth.loading && !this.state.submitting)) return (
    //  <Splash />
    //);
    let title = this.props.auth.user.roles.includes(config.ROLES.COMPANIES) ? 'Consumir premio' : 'Consumir beneficio'

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: [this.props.auth.user.roles.includes(config.ROLES.COMPANIES) ? 'Lectura de QR de premios' : 'Lectura de QR de beneficios']
        }}
        container={{ className: 'px-8' }}
        actions={null}
        loading={null}>

        <ToastContainer/>
         <div className="w-full max-w-lg mt-10">
          <div className="flex items-center space-between w-full">
            <h2 className="text-primary p-2">{this.t(title)}</h2>
            <div className="absolute bottom-10 left-0 right-0 md:static px-4 text-center">
              <div className="flex flex-col w-full opacity-70 md:hidden">
                <p className="divider">{this.t("o escanéa el código")}</p>
              </div>
              <div className="btn btn-outline btn-lg inline-flex md:btn-sm tooltip tooltip-right" data-tip={this.t("Escanea el código")}
                
                onClick={
                  ()=>{
                    history.push(
                      {
                        pathname: config.ROUTES.BENEFITS_SCANQR,
                        state: {
                          fromPath: config.ROUTES.BENEFITS_CONSUME,
                        }
                      })
                  }
                }>
                  <Icon className="h-6 w-6" name="qrcode" />
              </div>
            </div>
          </div>
          <Form
            initialValues={this.state.initialValues || {}}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="mt-3">
                <div className="w-full mb-3 md:mb-0">
                  <Field name="codigo" component={TextInput} placeholder={this.t("Código")} />
                </div>
                <div className="flex flex-wrap mb-3">
                  <Button
                    className="btn-secondary btn-block "
                    title={this.t("Siguiente")}
                    onClick={handleSubmit}
                    disabled={this.state.submitting}
                  />
                </div>
              </form>
            )}
          </Form>
        </div>
        
        {/*<button onClick={()=>{history.push(config.ROUTES.SCANQR)}}>QR</button>*/}
      

        {/* <div className="text-primary-content text-center mt-10">Logueado como: {this.props.auth.user.email}</div> */}
      </LayoutWithSidebar>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    user: state.users.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
     onLogout: () => dispatch(userActions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(BenefitConsume));
