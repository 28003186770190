import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import config from '../../config';
import logo from '../../assets/img/logo.png';
import Icon from '../../libraries/icons';
import Button from '../commons/Button';
import ButtonIcon from '../commons/ButtonIcon';
import MenuItem from '../commons/MenuItem';
import Avatar from '../commons/Avatar';
import usersActions from '../../context/users/actions';
import { history } from '../../routes';
import { useTranslation } from 'react-i18next';
import PrivateMenuItem from '../commons/PrivateMenuItem';

export default function SidebarDesktop({ header }) {
  const location = useLocation();
  const [collapseShow, setCollapseShow] = React.useState('close');
  const user = useSelector(state => state.users.auth.user);
  const { t } = useTranslation();
  const mainMenu = config.SIDEBAR.MENU.MAIN;
  const secondaryMenu = config.SIDEBAR.MENU.SECONDARY;
  const adminMenu = config.SIDEBAR.MENU.ADMIN;

  const dispatch = useDispatch();
  const doLogout = e => {
    //e.preventDefault();
    dispatch(usersActions.logout());
  };

  return (
    <nav className="bg-gray-200">
      <div className="relative z-10 py-2 flex flex-wrap items-center justify-between md:w-64 md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden border-l-0 shadow-md bg-white">
        <ButtonIcon
          buttonClassName="btn-ghost btn-sm"
          className="w-6 h-6"
          onClick={() => setCollapseShow('open')}
          icon="menu"
        />
        {header?.title ? (
          <h2 className="h2 md:hidden">{header.title}</h2>
        ) : (
          header?.logo && (
            <div className="mx-auto -pl-4">
              <img src={logo} alt="" width="100px" />
            </div>
          )
        )}
        {header?.breadcrumbs && (
          <ol className="list-none p-0 inline-flex md:hidden">
            {header.breadcrumbs.map((item, index) => (
              <li key={`bc${index}`} className="flex items-center">
                <span className="pr-2">{item}</span>
                {header.breadcrumbs.length > index + 1 ? (
                  <Icon className="w-8 h-8 pr-2" name="cheveron_right" />
                ) : null}
              </li>
            ))}
          </ol>
        )}
        <div className="w-12">
          {header && header.right && (
            <ButtonIcon
              buttonClassName="btn-ghost btn-sm"
              className={
                header.right.className ? header.right.className : 'h-6 w-6'
              }
              onClick={header.right.action}
              icon={header.right.icon}
            />
          )}
        </div>

        <div
          className={
            'bg-white text-base-content z-50 transform top-0 left-0 bottom-0 w-64 fixed h-full ease-in-out transition-all duration-300 flex justify-between flex-col md:rounded-none md:translate-x-0 md:shadow-none shadow-2xl bg-pattern ' +
            (collapseShow === 'close' ? '-translate-x-full' : 'translate-x-0')
          }>
          <div className="flex flex-wrap overflow-y-auto">
            <div className="border-b w-full">
              <img className="mx-auto mt-4" width="150px" src={logo} alt="" />

              <div className="flex flex-row gap-3 p-4 mt-5 pb-4">
                <div className="flex items-center">
                  <Avatar label={user?.username} className="w-12 h-12" data={user} image={user?.profile_image} />
                </div>
                <div className="">
                <h4 className=''>{user?.first_name} {user?.last_name}</h4>
                  <div className="flex gap-2 mt-1">
                    <Button
                      className="btn-primary btn-outline btn-xs "
                      title={t('Profile')}
                      onClick={() => history.push(config.ROUTES.USER.PROFILE)}
                    />
                    <ButtonIcon
                      buttonClassName="btn-error btn-outline btn-xs"
                      title={t('Profile')}
                      onClick={doLogout}
                      icon='logout'
                    />
                  </div>
                </div>
                {/* <ul
                  className="menu pt-4"
                  style={{ cursor: 'pointer' }}>
                  {[{ icon: 'logout', label: 'Logout', action: doLogout }].map(
                    (item, index) => (
                      <MenuItem key={index} item={item} />
                    )
                  )}
                </ul> */}
              </div>
            </div>
            <ButtonIcon
              buttonClassName={"btn-ghost text-white absolute  md:hidden "  +
              (collapseShow === 'close' ? '-right-0' : '-right-14')}
              className="w-8 h-8"
              onClick={() => setCollapseShow('close')}
              icon="close"
            />

            {mainMenu && mainMenu.length > 0 && <ul className="menu pt-5 p-3">
              {mainMenu.map((item, index) => (
                  <MenuItem key={index} item={item} location={location} />
                ))}
            </ul>}

            {secondaryMenu && secondaryMenu.length > 0 && <ul className="menu p-3">
              {secondaryMenu.map((item, index) => (
                <MenuItem key={index} item={item} location={location} />
              ))}
            </ul>}

            {adminMenu && adminMenu.length > 0 && <ul className="menu overflow-y-auto p-3">
              {adminMenu.map((item, index) => (
                  <PrivateMenuItem location={item.route} key={index}>
                    <MenuItem item={item} location={location} />
                  </PrivateMenuItem>
                ))}
            </ul>}

            {/* <ul className="menu rounded-box pt-10">
              { extraMenu && extraMenu.map((item, index) => <MenuItem key={index} item={item} location={location} />)}
            </ul>   */}
            
          </div>
        </div>
        <div
          className={
            'fixed inset-0 z-10 w-screen h-screen bg-black bg-opacity-25 md:hidden ' +
            (collapseShow === 'open' ? '' : 'hidden')
          }></div>
      </div>
    </nav>
    
  );
}
