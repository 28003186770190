import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom';

import challengesActions from '../../../context/challenges/actions'


import Leaderboard from './Leaderboard';
import Activity from './Activity';
import Impact from './Impact';

class WebApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      joined: false,
      challenge: {},
      participants: [],
      loading: true,
      user: {},
      finished: false
    };

  }

  async componentDidMount() { 
    const { id } = this.props.match.params
    const { challenge } = this.props

    if(!challenge?.item || challenge?.item?.id !== id) {
      await this.props.onGetChallenge(id)
      this.setState({ challenge: this.props.challenge?.item,  })
    }
    if(!this.props.participants) {
      await this.props.onGetAllParticipants(id)
    }
    this.setState({ participants: this.props.participants,})

    this.setState({ loading: false })

  }



  render() {
    return (
      <div className='my-4 w-full flex flex-col gap-4 rounded-lg'>
        {/* <h2 className='text-2xl font-bold'></h2>  */}
        <div className='flex flex-col gap-2 justify-between xl:flex-row'>
          <div className='card p-4 bg-white border shadow-xl min-h-16  xl:w-1/2 '>
            <h3 className='p-2'>Leaderboard</h3>
            <div className='overflow-y-auto custom-scrollbar'>
              <Leaderboard />
            </div>
          </div>

          <div className='card p-4 bg-white border shadow-xl w-full max-h-[500px] min-h-16  xl:w-1/2'>
            <h3 className='p-2'>Impacto</h3>
            <div className='overflow-y-auto'>
              <Impact />
            </div>
          </div>
        </div>
        <div className='card bg-white shadow-xl p-4'>
          <h3 className='pb-2 px-4'>Actividades</h3>
          <Activity onReload={() => this.props.onReload()} />
        </div>
      </div>
    )
  }
}







const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    challenge: state.challenges.current,
    participants: state.challenges.current.participants?.participants,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetChallenge: (params) => dispatch(challengesActions.get(params)),
    onGetAllParticipants: (params) => dispatch(challengesActions.getParticipants(params)),
    clearChallenge: () => dispatch(challengesActions.clearCurrent()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WebApp)))
