import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import { history } from '../../../routes';
import { deleteDuplicatedEntries, selectGeneratorWObjChild, capitalize } from '../../../libraries/utils';
import categoryActions from '../../../context/categories/actions';
import SelectInput from '../../../components/forms/SelectInput';
import PanelLayout from '../../../components/PanelLayout';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import CategoriesTree from '../../../components/customs/CategoriesTree';
import Swal from 'sweetalert2';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      categories_0: [],
      windowWidth: window.innerWidth,
      type: config.OPTIONS.CATEGORIES.TYPES[0]
    };
    this.breadcrumbs = [capitalize(this.t('categories'))];
  }

  // Life cycle
  componentDidMount() {
    this.setState({ main_loading: true });
    this.getRelatedCategories(true);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getCategories = async (parent, parents, removing) => {
    // Page num default is 0
    const params = {};
    const { search } = this.state;
    // If there are parents, we look for categories that match their id with an id in the array
    params.type = this.state.type;
    if (parents?.length > 0) {
      params.id = parents;
    } else {
      // ** If not, we set the parent as parent.id or root
      params.parent = parent ? (removing ? parent.parent : parent.id) : 'root';
      if (search && search !== '' && !parent) {
        delete params.parent;
        params.where = {
          custom: {
            name: `%${search.toLowerCase()}`,
            comparison: 'OR',
            type: `%${search.toLowerCase()}`
          }
        };
      }
    }
    if (this.state.sort) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }
    await this.props.onGetAll(params);
    const { categories } = this.props;
    if (categories.error) notify(this.t(categories.error.message));
  };

  customSort = async (field, order) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getCategories());
  };

  getRelatedCategories = async (expanded, row) => {
    // ** If a row level property exists
    if (row?.level || row?.level === 0) {
      // ** We close all expanded categories from the same level, then rerender the array and show the categories contracted
      const selectedLevelCategories = this.state[`categories_${row.level}`]?.map(item =>
        item.id !== row.id ? { ...item, expanded: false } : item
      );
      await this.setState({
        [`categories_${row.level}`]: selectedLevelCategories
      });
      const level = this.state.level;
      // ** We delete from the state all the unnecessary data that is not being shown on screen (memory optimization)
      for (let index = row.level + 1; index <= level; index++) {
        index !== 0 && delete this.state[`categories_${index}`];
      }
      // ** We set the level to be the same level that the row we selected has
      await this.setState({ level: row.level });
    }
    // If expanded is not true, we end the function here
    if (!expanded) return;
    await this.getCategories(row);
    const { categories } = this.props;
    // ** If there is no row selected, we set our fist level, as categories_0
    if (!row) {
      await this.setState({
        level: 0,
        categories_0: categories.items.map(item => ({ ...item, level: 0 }))
      });
    }
    // ** If there is a row selected, we set the next level. Based on the actual level + 1.
    // ** If level is 0, next level would be 1. So the array will be saved as categories_1
    else {
      const level = row.level + 1;
      this.setState({
        level,
        [`categories_${level}`]: categories.items.map(item => ({
          ...item,
          level
        }))
      });
    }
    await this.setState({ main_loading: false });
  };

  searchByType = async type => {
    await this.setState({ main_loading: true });
    await this.setState({ type });
    for (let index = 0; index < this.state.level; index++) {
      delete this.state[`categories_${index + 1}`];
    }
    await this.getRelatedCategories(true);
  };

  searchClear = form => {
    form.change('search', undefined);
    this.onSearch({ search: '' });
  };

  // Go to edit view
  onEdit = id => {
    history.push(config.ROUTES.CATEGORIES_EDIT.replace(':id', id));
  };

  onRemove = async row => {
    customSwal
      .fire({
        title: this.t('¿Are you sure?'),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          await this.setState({ main_loading: true });
          await this.getCategories(row);
          const { categories } = this.props;
          if (categories.items.length) {
            // If the array has elements, we WON'T delete the category
            notify(capitalize(this.t('cannot delete categories with children')));
            await this.setState({ main_loading: false });
            return;
          }
          await this.props.onRemove(row.id);

          if (categories.error) {
            notify(this.t(categories.error.message));
          } else {
            await this.getCategories(row, undefined, true);
            const { categories } = this.props;
            await this.setState({
              [`categories_${row.level}`]: categories.items.map(item => ({
                ...item,
                level: row.level
              }))
            });
            await this.setState({ main_loading: false });
          }
        }
      });
    // }
  };

  // Go to new view (similar to edit)
  onNew = () => {
    history.push(config.ROUTES.CATEGORIES_NEW);
  };

  onSearch = async data => {
    if (this.state.search === data.search) return;
    await this.setState({ main_loading: true });
    // ** We close all the expanded categories
    this.setState({
      categories_0: this.state.categories_0.map(e => ({
        ...e,
        expanded: false
      }))
    });
    // ** This little function filters the categories array getting only the categories that don't have root as a parent
    // ** Then it maps the array to just be strings with the property parent of each object
    // ** It also deletes duplicated entries, so there is no unnecessary data kept on memory
    const getParentsIds = array => deleteDuplicatedEntries(array.filter(e => e.parent !== 'root').map(e => e.parent));

    // ** If not we set our state and get the categories
    await this.setState({ search: data.search || '' });
    await this.getCategories();
    const { categories } = this.props;

    // ** We now map all the objects to be just the id of each and save it into a variable
    let ids = categories.items.map(e => e.id);

    // ** We also use our function to get the parent id of each category
    let parents = getParentsIds(categories.items);

    // ** While our parents array length is longer than 0 we execute this porcion of the code
    while (parents.length > 0) {
      // ** Get categories, we send our parents ids for filtering
      await this.getCategories(undefined, parents);
      const { categories } = this.props;

      // ** We add the ids of our result to the array of ids (now we have the parents ids and the children ids in the same variable)
      ids = [...ids, ...categories.items.map(e => e.id)];

      // ** And also get the parent's id, this eventualy will lead to have 0 length because at some point all of the categories will have root as a parent
      parents = getParentsIds(categories.items);
    }
    // ** Set the state so our tree component could have access to the ids for filtering
    this.setState({ ids, main_loading: false });
  };

  // ** Function for hiding columns when the window width goes below the number specified
  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  render() {
    const { windowWidth } = this.state;
    const {
      categories: { loading }
    } = this.props;

    const categoryTypes = selectGeneratorWObjChild(config.OPTIONS.CATEGORIES.TYPES, 'value', 'label');

    // Layout actions
    const actions = {
      main: {
        onClick: this.onNew,
        title: this.t('New'),
        checkPermissions: 'insert'
      },
      secondaries: [],
      form: {
        onSubmit: () => console.log('A'),
        fields: [
          // { onChange: this.onChangeRefresh, title: this.t("Refresh"), component: SwitchboxInput, className: 'w-14', name: 'refresh', checkPermissions: 'insert' },
          {
            onChange: this.searchByType,
            title: capitalize(this.t('type')),
            component: SelectInput,
            className: 'w-48',
            name: 'type',
            data: categoryTypes,
            checkPermissions: 'insert'
          }
        ]
      },
      search: {
        onSearch: this.onSearch,
        onClearClick: this.searchClear,
        title: this.t('Search')
      }
    };

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <CategoriesTree
            screenWidth={windowWidth}
            onExpand={this.getRelatedCategories}
            loading={loading}
            state={this.state}
            onRemove={this.onRemove}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    categories: state.categories.list,
    category: state.categories.current,
    query: state.categories.list.query
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAll: params => dispatch(categoryActions.getAll(params)),
    onGetCategory: id => dispatch(categoryActions.get(id)),
    onRemove: id => dispatch(categoryActions.del(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Categories));
