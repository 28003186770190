import React from "react"
import { Field, Form } from "react-final-form"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import Swal from "sweetalert2"
import PanelWindow from "../../../components/PanelWindow"
import FormLayout from "../../../components/forms/FormLayout"
import SelectInput from "../../../components/forms/SelectInput"
import TextInput from "../../../components/forms/TextInput"
import LayoutWithSidebar from "../../../components/layout/LayoutWithSidebar"
import config from "../../../config"
import businessesActions from "../../../context/businesses/actions"
import companiesActions from "../../../context/companies/actions"
import filesActions from "../../../context/files/actions"
import rolesActions from "../../../context/roles/actions"
import usersActions from "../../../context/users/actions"
import { notify } from "../../../libraries/notifications"
import {
  capitalize,
  composeValidators,
  formatOnlyNumberInput,
  getOwner,
  validateEmail,
  validateIsfilled,
  validateOnlyNumber,
} from "../../../libraries/utils"
import { history } from "../../../routes"
import CheckboxInputRow from "../../../components/forms/CheckboxInputRow"

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary mx-1",
    cancelButton: "btn btn-outline btn-primary mx-1",
    denyButton: "btn btn-outline btn-primary mx-1",
    title: "swal2-title",
    htmlContainer: "swal-text",
  },
  buttonsStyling: false,
  background: "#fff",
})

class Colaborador extends React.Component {
  constructor(props) {
    super(props)
    this.t = this.props.t
    this.state = {
      creatingNew: false,
      submitting: false,
      view: false,
      editing: false,
      user: {},
      referentUsers: [],
      roles: [],
      company_id: "",
      loading: true
    }
    this.submit = null
    this.reset = null
    this.breadcrumbs = [this.t("Colaborador"), this.t("New")]
    this.titles = this.t("User details")
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const params = this.props.match.params
    await this.getData()
    if (!params.id) {
      this.setState({ creatingNew: true })
    }
    
    await this.getDataUser(params)
    this.setState({ loading: false })
    
  }

  getDataUser = async params => {
    await this.getCompaniesAreasSucursales()

    if (params.id) {
      this.breadcrumbs[1] = this.t("Edit")
      this.setState({ view: true })
      this.getUser(params.id)
    }
  }

  getCompaniesAreasSucursales = async () => {
    await this.getCompany()
    await this.getAreas()
    await this.getSucursales()
  }

  getCompany = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) })
    const { companies } = this.props
    if (companies.error) {
      notify(this.t(companies.error.message))
    } else {
      if (this.props.companies.items.length) {
        this.setState({ company_id: this.props.companies.items[0].id })
      }
    }
  }

  getAreas = async () => {
    await this.props.onGetRoles({ type: "group", owner: this.state.company_id })
    const { roles } = this.props
    if (roles.error) {
      notify(this.t(roles.error.message))
    } else {
      this.setState({ areas: roles.items })
    }
  }

  getSucursales = async () => {
    await this.props.onGetBusinesses({
      type: "subsidiaries",
      owner: getOwner(this.props.auth.user),
    })
    const { businesses } = this.props
    if (businesses.error) {
      notify(this.t(businesses.error.message))
    } else {
      this.setState({ sucursales: businesses.items })
    }
  }

  getData = async () => {
    const { user } = this.props.auth
    await this.props.onGetRoles()
    const { roles } = this.props
    if (roles.error) {
      notify(this.t(roles.error.message))
    } else {
      this.setState({ roles: [...roles.items] })
      if (user.type.includes(config.ROLES.COMPANIES)) {
        //await this.props.onGetCompanies({ owner: user.id });
        await this.props.onGetCompanies({ owner: getOwner(user) })
        const { companies } = this.props
        if (companies.error) {
          notify(this.t(companies.error.message))
        } else {
          this.setState({
            company_id: this.props.companies.items[0].id,
          })
        }
      }
    }
  }

  getUser = async id => {
    await this.props.onGetUser({ id })
    const error = this.props.user.error
    if (error) {
      notify(this.t(error.message))
    } else {
      const roles = this.props.user.item?.role
      let role
      if (roles && roles.id && roles.name) {
        role = roles.id.split("|")[0]
      }
 
      this.setState({
        user: {
          ...this.props.user.item,
          country: (this.props.user.item?.location?.toLowerCase()),
          role,
          area: this.props.user.item.meta_description,
          sucursal: this.props.user.item.meta_title,
          identification: this.props.user.item.json_data?.identification,
          labor_identification_code: this.props.user.item.json_data?.labor_identification_code,
        },
      })
    }
  }

  componentWillUnmount() {
    this.props.onClearCurrentUser()
  }

  onDelete = id => {
    this.props.onDelUser(id)
  }

  gotoBack = user => {
    if (!this.props.location.state) {
      history.push(config.ROUTES.COLLABORATORS)
    } else {
      const { location, from } = this.props.location.state
      if (from === config.ROUTES.BOOK.USER) {
        history.push({
          pathname: user ? config.ROUTES.BOOK.SERVICE : config.ROUTES.BOOK.USER,
          state: {
            location,
            user: user || undefined,
          },
        })
      } else {
        history.push(config.ROUTES.COLLABORATORS)
      }
    }
  }

  onSubmit = async values => {
    // const { user } = this.props.auth
    const { creatingNew } = this.state
    this.setState({ submitting: true })

    let data = {
      phone: values.phone,
      email: values.email,
      subsidiary_id: values.sucursal,
      group_id: values.area,
      unique_id: this.state.company_id,
      first_name : values.first_name,
      last_name : values.last_name,
      labor_identification_code: values.labor_identification_code || "",
      location: values.country,
      identification: values.identification,
    }

    // if (Array.isArray(this.props.auth.user.accounts)){
    //  data.unique_id= this.props.auth.user.accounts[0].id
    // }

    if (this.state.user?.id) {
      data.id = this.state.user.id
    } else {
      data.role = ""
    }
    if (creatingNew) {
      data.status = "new"
    }

    data.user_type = "regular"

    if (!this.state.user.id) {
      data.welcome_email = false
      data.verify_email = false
      data.invitation_email = true
    }
    
    await this.props.onSaveUser(data)
    const error = this.props.user.error
    if (error) {
      notify(this.t(error.message))
      this.setState({ submitting: false })
    } else {
      notify("Operación exitosa", "SUCCESS")
      if(values.send_email) await this.sendEmail([this.props.user?.item])
      this.gotoBack(this.props.user.item)
    }
  }


  disableEnableUser = async () => {
    const { user } = this.state
    const data = {
      id: user.id,
      enabled: user.enabled,
      //role: isObject(user.role) ? user.role.id : user.role,
      user_type: config.USER_TYPE.REGULAR,
    }
    await this.props.onSaveUser(data)
    const error = this.props.user.error
    if (error) {
      notify(this.t(error.message))
      this.setState({ submitting: false })
    }
  }

  getReferentUsers = async referentID => {
    await this.props.onGetAllUsers({ invited_by: referentID })
    this.setState({
      referentUsers: [...this.props.users.items],
    })
  }
  // TODO: faltan detalles
  makeUserReferent = async () => {
    const { user } = this.state

    await this.getReferentUsers(this.state.user.id)

    const { referentUsers } = this.state
    if (referentUsers.length > 0) {
      notify(this.t("Este usuario tiene referentes asociados, no se puede cambiar el tipo de usuario"))
      return
    }
    customSwal
      .fire({
        title: this.t("¿Está seguro?"),
        text: user.invited_by ==="system" ? this.t("El usuario dejará de ser referente") : this.t("¿Está seguro que quiere hacer este usuario un referente?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.t("Yes"),
        cancelButtonText: this.t("Cancel"),
      })
      .then(async result => {
        if (result.isConfirmed) {
          const data = {
            id: user.id,
            invited_by: user.invited_by,
            user_type: config.USER_TYPE.REGULAR,
          }
          await this.props.onSaveUser(data)
          const error = this.props.user.error
          if (error) {
            notify(this.t(error.message))
            this.setState({ submitting: false })
          }
        }
      })
  }

  sendEmail = async (users) => {
    let payload = {
      ids: users.map(u => u.id).join(","),
      referents: false,
    }
    this.props.onSendColaboradoresMail(payload)
  }

  render() {
    const { view, creatingNew, editing, submitting, user, loading } = this.state
    const { auth } = this.props
    // const countries = selectGeneratorWObjChild(config.OPTIONS.COUNTRIES, '', o => capitalizePhrase(o));


    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t("Save"),
        icon: "checkmark",
        disabled: submitting || (view && !editing),
        checkPermissions: "insert",
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.COLLABORATORS),
          title: this.t("Go Back"),
          icon: "cheveron_left",
          disabled: submitting,
          visible: true,
          className: "btn-accent",
        },
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t("Edit"),
          icon: "edit_pencil",
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: "update",
        },
        {
          onClick: e => {
            this.reset()
            this.setState({ editing: false })
          },
          title: this.t("Cancel"),
          icon: "edit_pencil",
          disabled: submitting || !editing,
          visible: editing,
        },
        {
          onClick: e => {
            if (user.enabled === 1)
              this.setState({ user: { ...user, enabled: 0 } }, () =>
                this.disableEnableUser()
              )
            if (user.enabled === 0)
              this.setState({ user: { ...user, enabled: 1 } }, () =>
                this.disableEnableUser()
              )
          },
          title: this.t(user.enabled ? "Disable" : "Enable"),
          icon: !user.enabled ? "view_show" : "view_hide",
          disabled: submitting || this.props.user.loading,
          checkPermissions: "insert",
          visible: !creatingNew,
        },
        {
          onClick: e => {
            if (user.invited_by === "referent")
              this.setState({ user: { ...user, invited_by: "system" } }, () =>
                this.makeUserReferent()
              )
            if (user.invited_by !== "referent")
              this.setState({ user: { ...user, invited_by: "referent" } }, () =>
                this.makeUserReferent()
              )
          },
          title: this.t(
            user.invited_by === "referent" ? "Referente" : "Referente"
          ),
          // icon: user.invited_by === 'referent' ? 'view_hide' : 'view_show',
          className:
            user.invited_by === "referent" ? "btn-error" : "btn-primary",
          disabled: submitting || this.props.user.loading,
          checkPermissions: "insert",
          visible: !creatingNew,
        },
      ],
    }

    const sucursalesOptions = this.state.sucursales
      ? this.state.sucursales.map(s => {
          return { label: s.name, value: s.id }
        }).sort((a, b) => a.label.localeCompare(b.label))
      : []
    const areasOptions = this.state.areas
      ? this.state.areas.map(s => {
          return { label: s.name, value: s.id }
        }).sort((a, b) => a.label.localeCompare(b.label))
      : []
    const countriesOptions = config.OPTIONS.COUNTRIES
      ? config.OPTIONS.COUNTRIES.map(s => {
          return { label: capitalize(s), value: s }
        }).sort((a, b) => a.label.localeCompare(b.label))
      : []
//    const countries = selectGeneratorWObjChild(config.OPTIONS.COUNTRIES, '', o => capitalizePhrase(o));

    const required = value =>
      validateIsfilled(value) || (view && !editing)
        ? undefined
        : this.t("This field is required")
    // CHECK EMAIL
    const checkEmail = value =>
      validateEmail(value) || (view && !editing)
        ? undefined
        : this.t("Not a valid email")

    const onlynumber = value =>
      validateOnlyNumber(value) ? undefined : capitalize(this.t('ID must only contain numbers'));
    
    const dnilength = value =>
      value.length >= 7 && value.length <= 8 ? undefined : capitalize(this.t('ID must have 7 or 8 numbers'));

    const validateForm = values => {
      const errors = {}
      errors.first_name = required(values.first_name)
      errors.country = required(values.country)
      errors.last_name = required(values.last_name)
      errors.email = checkEmail(values.email)
      errors.identification = required(values.identification)

      return errors
    }

    if (user.json_data) {
      user.first_name = user.json_data.first_name
      user.last_name = user.json_data.last_name
      user.labor_identification_code = user.json_data?.labor_identification_code
      user.identification = user.json_data?.identification
      user.dni= user.json_data?.dni
      user.sucursal = user.subsidiary_id
      user.area = user.group_id
    }

    return (
      <LayoutWithSidebar
        main={{ className: "text-content-400" }}
        header={{
          breadcrumbs: this.breadcrumbs,
        }}
        container={{ className: "px-8 pb-5" }}
        actions={actions}
      >
        <PanelWindow
          outterTitle={this.titles}
          loading={loading}
        >
          <Form
            initialValues={{
              ...user,
              send_email: creatingNew
            }}
            onSubmit={this.onSubmit}
            validate={validateForm}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit
              this.reset = form.reset
              return (
                <FormLayout form={form} onSubmit={handleSubmit} values={values}>
                  <div className="flex flex-col md:flex-row flex-wrap -mx-2">
                    <div className="w-full md:w-1/2 px-2">
                      <Field
                        name="first_name"
                        component={TextInput}
                        label={this.t("First name")}
                        readOnly={view}
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-2">
                      <Field
                        name="last_name"
                        component={TextInput}
                        label={this.t("Last name")}
                        readOnly={view}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row -mx-2">
                    <div className="w-full md:w-1/2 px-2">
                      <Field
                        name="phone"
                        component={TextInput}
                        label={this.t("Phone")}
                        readOnly={!creatingNew && !editing}
                        parse={v => formatOnlyNumberInput(v)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-2">
                      <Field
                        name="email"
                        component={TextInput}
                        label={this.t("Email")}
                        validate={composeValidators(required, checkEmail)}
                        readOnly={!creatingNew &&!editing}
                        parse={ v => v.trim() }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row -mx-2">
                    <div className="tooltip w-full md:w-1/3 px-2 " data-tip="Documento de identidad (DI), también conocido como cédula de identidad (CI), tarjeta de identidad (TI), cédula de ciudadanía (CC)">
                      <Field
                        name="identification"
                        component={TextInput}
                        label={capitalize(this.t("Número de Identificación Personal"))}
                        readOnly={view&&(!editing||!!user.identification)}
                        validate={composeValidators(required, onlynumber, dnilength)}

                      />
                    </div>
                    <div className="w-full md:w-1/3 px-2">
                      <Field
                        name="labor_identification_code"
                        component={TextInput}
                        label={capitalize(this.t("CUIL"))}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="w-full md:w-1/3 px-2">
                      <Field
                        name="country"
                        component={SelectInput}
                        placeholder={this.t('Country')}
                        options={countriesOptions}
                        empty={this.t('Select a country')}
                        label={this.t('Country')}
                        readOnly={view && !editing}
                      />
                    </div>
                  </div>
                  {auth.user.roles.includes(config.ROLES.COMPANIES) && (
                    <div className="flex">
                      <div className="w-full md:w-4/6 px-2">
                        <Field
                          name="area"
                          component={SelectInput}
                          label={capitalize(this.t("Área"))}
                          options={areasOptions}
                          empty={this.t("Select a value")}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full md:w-4/6 px-2">
                        <Field
                          name="sucursal"
                          component={SelectInput}
                          label={capitalize(this.t("sucursal"))}
                          options={sucursalesOptions}
                          empty={this.t("Select a value")}
                          readOnly={view && !editing}
                        />
                      </div>
                    </div>
                  )}
                  <div className="w-full sm:w-4/6 lg:w-80 px-2">
                    {creatingNew && 
                      <Field
                        name="send_email"
                        component={CheckboxInputRow}
                        type="checkbox"
                        label={capitalize(this.t("Enviar mail automático"))}
                        readOnly={view && !editing}
                      />
                    }
                  </div>

                  {view && (
                    <h2>
                      Referente: {user.invited_by === "referent" ? "SÍ" : "NO"}
                    </h2>
                  )}
                </FormLayout>
              )
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    user: state.users.current,
    users: state.users.list,
    roles: state.roles.list,
    files: state.files,
    businesses: state.businesses.list,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetUser: id => dispatch(usersActions.get(id)),
    onGetAllUsers: params => dispatch(usersActions.getAll(params)),
    onSaveUser: params => dispatch(usersActions.saveOrUpdate(params)),
    onDelUser: id => dispatch(usersActions.del(id)),
    onClearCurrentUser: () => dispatch(usersActions.clearCurrent()),
    onGetRoles: params => dispatch(rolesActions.getAll(params)),
    onGetCompanies: params => dispatch(companiesActions.getAll(params)),
    onGetBusinesses: params => dispatch(businessesActions.getAll(params)),
    onPictureUpload: params => dispatch(filesActions.upload(params)),
    onSendColaboradoresMail: ids => dispatch(usersActions.sendInvatationMails(ids)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Colaborador))
