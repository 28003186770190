import React, { useEffect, useReducer, useState } from 'react';
import useUpdateEffect from '../../../libraries/hooks/useUpdateEffect';
import { Form, Field } from 'react-final-form';
import FormLayout from '../../../components/forms/FormLayout';
import TextInput from '../../../components/forms/TextInput';
import TextareaInput from '../../../components/forms/TextareaInput';
import Icon from '../../../libraries/icons';
import AttachImage from '../../../components/forms/AttachImage';
import SelectInput from '../../../components/forms/SelectInput';
import Button from '../../../components/commons/Button';
import Loader from '../../../components/commons/Loader';
import { validateIsfilled, selectGeneratorWObjChild, getOwner } from '../../../libraries/utils';
import messageActions from '../../../context/messages/actions';
import filesActions from '../../../context/files/actions'
import userActions from '../../../context/users/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, notify } from '../../../libraries/notifications';
const initialState = { loading: false, preview: null, attachedImage: null }
const Composer = ({ t, afterComposeMessage }) => {
  const reduxDispatch = useDispatch()
  const getAllUsers = (params) => reduxDispatch(userActions.getAll(params))
  const saveMessage = (params) => reduxDispatch(messageActions.saveOrUpdate(params))
  const users = useSelector(state => state.users.list.items) || []
  const owner = useSelector(state => getOwner(state.users.auth.user))

  const pictureUpload = params => reduxDispatch(filesActions.upload(params))
  const pictureClean = () => reduxDispatch(filesActions.clean())
  const files = useSelector(state => state.files)


  const getUsers = (params = {}) => {
    params.where = {
      id: {
        comparison: '!=',
        value: owner,
      }
    }
    getAllUsers(params)
  }

  const reducer = (state, action) => {
    let newState = { ...state }
    const setState = (data) => {
      newState = { ...newState, ...data }
    }
    switch (action.type) {
      case 'getUsers':
        getUsers()
        break;
      case 'compose':
        setState({ loading: !state.loading })
        break
      case 'previewImage':
        setState({ preview: action.data })
        break;
      case 'cleanPreview':
        setState({ preview: null })
        break;
      case 'attachImage':
        setState({ attachedImage: action.data })
        console.log(newState)
        break;
      case 'cleanAttach':
        setState({ attachedImage: null })
        break;
      default:
        throw new Error();
    }
    return newState
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const [message, setMessage] = useState()
  const { loading, preview, attachedImage } = state

  const composeMessage = (params) => {
    dispatch({ type: 'compose' })

    saveMessage(params).then(() => {
      dispatch({ type: 'compose' })
      afterComposeMessage()
    })

  }
  // This function handles the submit event of our form
  const onSubmit = (values, form) => {
    const message = {
      ...values,
      source: owner,
      parent: 'root',
      type: 'message',
      json_data: {
        deleted_chat_for_source: 0,
        deleted_for_source: 0,
        deleted_chat_for_target: 0,
        unread_messages_for_target: 1,
        unread_messages_for_source: 0
      }
    }
    if (attachedImage) {
      uploadImage(message)
    } else {
      composeMessage(message)
    }
    form.reset()
  }
  const uploadImage = (message) => {
    const { data } = attachedImage
    let image_identifier = `message_input_${parseInt(Math.random() * 10 ** 9)}`
    data.append('identifier', image_identifier);
    setMessage(message)
    pictureUpload(data)
  }
  const previewImage = ({ data, e }) => {
    dispatch({ type: 'previewImage', data: { data, src: URL.createObjectURL(e) } })
  }
  const attachImage = (image) => {
    dispatch({ type: 'attachImage', data: image })
    dispatch({ type: 'cleanPreview' })
  }
  const required = (value) =>
    validateIsfilled(value)
      ? undefined
      : t('This field is required');

  const validateForm = (values) => {
    const errors = {};
    errors.target = required(values.target);
    errors.subject = required(values.subject);
    errors.body = required(values.body);
    return errors;
  };
  // Generates user optios for the select
  const userOptions = selectGeneratorWObjChild(users, 'id', 'name')
  // Life cycle
  useEffect(() => {
    dispatch({ type: 'getUsers' })
  }, [])

  useUpdateEffect(() => {
    if (files.files) {
      if (!files.uploading) {
        if (files.error) {
          notify(t(files.error.message))
        } else {
          const sendImage = () => {
            const obj = {
              ...message,
              json_data: { ...message.json_data, picture: files.files.fileInfo.location }
            }
            composeMessage(obj)
            pictureClean()
          }
          sendImage()
        }
      }
    }
  }, [files, message])
  return (
    <div className='h-full relative flex-shrink'>
      <ToastContainer />
      {(!users.length || loading || files.uploading) ? <div className='h-full flex'><Loader /></div> :
        <Form
          initialValues={{}}
          onSubmit={onSubmit}
          validate={validateForm}
        >
          {({ handleSubmit, form }) => {
            return (
              <FormLayout form={form} onSubmit={handleSubmit}>
                {preview ?
                  <div className='flex h-full absolute bg-white justify-center items-center w-full'>
                    <p className='absolute top-4 left-12'>Preview</p>
                    <button onClick={() => dispatch({ type: 'cleanPreview' })}><Icon className="w-5 h-5 absolute top-4 left-6" name='close' /></button>
                    <div className='w-36'><img src={preview.src} alt="" /></div>
                    <div className='absolute bottom-12 right-4'><Button onClick={() => attachImage(preview)} invert icon='paper_clip' title={t('Attach')} /></div>
                  </div> :
                  <div>
                    <div className='tabs tabs-boxed p-3'>
                      <h2 className='px-2'>{t('Redacta un mensaje')}</h2>
                    </div>
                    <div className="w-full px-4">
                      <Field
                        name="target"
                        component={SelectInput}
                        options={userOptions}
                        defaultValue={userOptions.length && userOptions[0].value}
                        placeholder={t('To')}
                        label={t('To')}
                        readOnly={loading}
                      />
                    </div>
                    <div className="w-full px-4">
                      <Field
                        name="subject"
                        component={TextInput}
                        placeholder={t('Subject')}
                        label={t('Subject')}
                        readOnly={loading}
                      />
                    </div>
                    <div className="w-full px-4 relative">
                      <Field
                        name="body"
                        component={TextareaInput}
                        placeholder={t('Body')}
                        label={t('Body')}
                        readOnly={loading}
                      />
                    </div>
                    <div className='flex justify-between items-center'>
                      <div className='px-4 relative w-14 h-14'>
                        {
                          attachedImage && <div className='w-14 h-14'>
                            <img className='w-14 h-14 object-cover border' src={attachedImage.src} alt="" />
                            <button onClick={() => dispatch({ type: 'cleanAttach' })} className='w-14 h-14 bg-black opacity-0 hover:opacity-60 absolute top-0 flex items-center justify-center transition'><Icon className="w-5 h-5 text-white" name='close' /></button>
                          </div>
                        }
                      </div>
                      <div className='flex justify-end items-center pr-4'>
                        <div className='border-r pr-2 mr-2'>
                          <Field
                            name="picture"
                            component={AttachImage}
                            placeholder={t('Send an image')}
                            inputOnChange={previewImage}
                            readOnly={loading}
                            noError={true}
                          />
                        </div>
                        <Button type='submit' invert icon='arrow_right' title={t('Enviar')} />
                      </div>
                    </div>
                  </div>
                }

              </FormLayout>
            );
          }}
        </Form>
      }

    </div>
  );
};

export default Composer;