import { Combobox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

function ComboboxInput({ input, meta, label, options, empty, onChangeSelect, customValues, customParse, ...rest }) {
  const [selected, setSelected] = useState(options.find((option) => option.value === input.value));
  const [query, setQuery] = useState("");
  const [hoveredOption, setHoveredOption] = useState(null);

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === input.value);
    setSelected(selectedOption);
  }, [input.value, options]);

  const btn_ref = useRef(null);
  const input_ref = useRef();

  const filteredOptions =
    query === ""
      ? options
      : options.filter((item) =>
          item.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleOptionChange = (value) => {
    if(onChangeSelect){
      onChangeSelect(value.value)
    }
    input_ref.current.blur();
    setSelected(value);
    input.onChange(value.value);
  };

  const parseValue = (value) => {
    if (customParse) return customParse(value)
    return value;
  }

  const emptyOption = filteredOptions.length === 0 && query !== ""

  return (
    <div className={clsx("form flex-col w-full relative", rest.className)} {...input}>
      {label && (
        <label htmlFor={input.name} className={clsx("label", rest.labelClassName)}>
          <span className="label-text">{label}</span>
        </label>
      )}
      {rest?.showInfo && <div className={clsx(
          "absolute bg-gray-600 w-full text-center max-w-full text-white py-2 px-4 bottom-24 rounded-lg pointer-events-none z-10 transform opacity-0 transition-opacity",
          hoveredOption && 'opacity-100'
        )}
      >{hoveredOption}</div>}
      <Combobox
        {...input}
        
        value={selected}
        onChange={handleOptionChange}
        disabled={rest.readOnly || rest.disabled}
        // displayValue={(item) => item.label}
      >
        <div className="relative mt-1 w-full border rounded-md ">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className={clsx(
                "select w-full outline-none border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0",
                {
                  "input-error invalid-shadow": meta && meta.error && meta.touched,
                  "px-1": rest.narrow,
                  "bg-gray-100 cursor-not-allowed text-slate-600": rest.disabled || rest.readOnly,
                }
              )}
              displayValue={(item) => item.label}
              onChange={(event) => {
                setQuery(event.target.value)
              }}
              onClick={() => {
                btn_ref.current.click();
              }}
              ref={input_ref}
              name={input.name}
              placeholder={rest.placeholder}
              
            />
            <Combobox.Button
              ref={btn_ref}
              className="absolute inset-y-0 right-0 flex items-center pr-2"
            />
          </div>
          <Transition
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options
              static
              className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50"
            >
              {
                (emptyOption && customValues) && (
                  <Combobox.Option value={{ label: query.toString(), value: parseValue(query) }}>
                    Agregar "{query}"
                  </Combobox.Option>
                )
              }
              {
                (emptyOption && !customValues) && (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    No hay resultados
                  </div>
                )
              }
              {
                !emptyOption && (
                  filteredOptions.map((item) => (
                    <Combobox.Option
                    key={item.value}
                    className={({ active }) =>
                      clsx(
                        "relative cursor-default select-none py-2 pl-10 pr-4",
                        active ? "bg-blue-300 text-white" : "text-gray-900"
                      )
                    }
                    value={item}
                    onMouseEnter={() => setHoveredOption(item.label)}
                    onMouseLeave={() => setHoveredOption(null)}
                    onMouseDown={() => setHoveredOption(null)}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {item.label}
                        </span>
                      </>
                    )}
                  </Combobox.Option>

                  )
                )
                )
              }

            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">{(meta?.error && meta?.touched) && meta.error}</span>
        </label>
      )}
    </div>
  );
}

export default ComboboxInput;