import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min"
import PanelLayout from "../../../components/PanelLayout"
import OrderComponent from "../../../components/customs/OrderComponent"
import SelectInput from "../../../components/forms/SelectInput"
import LayoutWithSidebar from "../../../components/layout/LayoutWithSidebar"
import config from "../../../config"
import actionActions from "../../../context/actions/actions"
import categoryActions from "../../../context/categories/actions"
import { notify } from "../../../libraries/notifications"
import { capitalize, getOwner } from "../../../libraries/utils"
import { history } from "../../../routes"

class ActionsOrder extends React.Component {
  constructor(props) {
    super(props)
    this.t = this.props.t
    this.state = {
      actions: [],
      categories: [],
      loading: true,
      initialPage: 1,
      currentPage: 1,
      currentType: null,
    }
    this.breadcrumbs = [this.t("Actions Order")]
  }

  async componentDidMount() {
    await this.getCategories()
    await this.getActions()
  }

  getCategories = async params => {
    await this.props.onGetCategories({ ...params, type: "actions" })
    const categories = this.props.categories?.items
    if (categories.error) {
      notify(this.t(categories.error.message))
    } else {
      this.setState({ categories: categories, currentType: categories[0].id })
    }
  }

  getActions = async (type = this.state.currentType) => {
    const categories = this.state.categories

    await this.props.onGetAllActions({ type })
    const { actions } = this.props
    if (actions.error) {
      notify(this.t(actions.error.message))
    } else {
      for (let action of actions.items) {
        const foundCategory = categories.find(elem => elem.id === action.type)
        if (foundCategory?.name) action.type = foundCategory?.name
      }

      this.setState({ actions: actions.items, loading: actions.loading })
    }
  }

  filterByType = async type => {
    this.setState({ currentType: type }, () => this.getActions())
  }

  onNew = () => {
    history.push(config.ROUTES.ACTIONS_NEW)
  }
  goBack = () => {
    history.push(config.ROUTES.ACTIONS)
  }

  onSubmit = async values => {
    const updatedActions = []
    values.forEach(action => {
      updatedActions.push(
        this.props.onSaveOrUpdate({
          id: action.id,
          order_index: action.order_index,
          // name: action.name,
          // type: action.type,

          owner: getOwner(this.props.auth.user),
        })
      )
    })
    await Promise.all(updatedActions)

    const { action } = this.props
    if (action.error) {
      notify(this.t(action.error.message))
    }
  }

  render() {
    const { actions, loading, categories } = this.state

    const actionsTypes = categories.map(cat => ({
      label: cat.name,
      value: cat.id,
    }))

    const _actions = {
      main: {
        onClick: this.onNew,
        title: this.t("New"),
        checkPermissions: "insert",
      },
      secondaries: [
        {
          onClick: () => history.push(config.ROUTES.ACTIONS),
          title: "Actions",
          checkPermissions: "insert",
        },
      ],
      form: {
        onSubmit: () => null,
        fields: [
          {
            onChange: this.filterByType,
            title: capitalize(this.t("type")),
            component: SelectInput,
            inputClassName: "mt-3",
            className: "w-48 mt-3 mr-2",
            name: "type",
            data: actionsTypes,
            checkPermissions: "insert",
          },
        ],
      },
    }

    return (
      <LayoutWithSidebar
        main={{ className: "text-content-400" }}
        header={{
          breadcrumbs: this.breadcrumbs,
        }}
        container={{ className: "px-8" }}
        actions={_actions}
        loading={loading}
      >
        <PanelLayout>
          <OrderComponent
            dataList={actions}
            onSubmit={data => this.onSubmit(data)}
            loading={loading}
            saveOnMove={true}
            // resetData={true}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    )
  }
}
// TODO: TEST


const mapStateToProps = state => {
  return {
    actions: state.actions.list,
    action: state.actions.current,
    categories: state.categories.list,
    auth: state.users.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAllActions: params => dispatch(actionActions.getAll(params)),
    onGetCategories: params => dispatch(categoryActions.getAll(params)),
    onRemove: id => dispatch(actionActions.del(id)),
    onSaveOrUpdate: params => dispatch(actionActions.saveOrUpdate(params)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActionsOrder))
)
