import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import Icon from '../../../libraries/icons';
import {
  capitalize,
  capitalizePhrase,
  getOwner,
  selectGeneratorWObjChild,
  validateIsfilled
} from '../../../libraries/utils';
import Button from '../../../components/commons/Button';
import FormLayout from '../../../components/forms/FormLayout';
import categoryActions from '../../../context/categories/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import SelectInput from '../../../components/forms/SelectInput';
import IntegerInput from '../../../components/forms/IntegerInput';
import { WhenFieldChangesDo } from '../../../libraries/forms';
import AddOrModifyCategory from './AddOrModifyCategory';
import { OnChange } from 'react-final-form-listeners';
import ImageUploadFileInputEdit from '../../../components/forms/ImageUploadFileInputEdit';
import filesActions from '../../../context/files/actions';
// import ImageUploadFileInput from '../../../components/forms/ImageUploadFileInput';


class Category extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      disableAddCategoryButton: false,
      displaySelectComponent: false,
      creatingNew: false,
      category: {},
      loading: false,
      fullSelectedCategoryData: {},
      categories: [],
      submitting: false,
      view: false,
      editing: false,
      titles: '',
      selectedType: '',
      remove: false,
      originalID: '',

    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [capitalize(this.t('category')), this.t('New')];
    this.titles = this.t('User details');
  }

  componentDidMount() {
    const params = this.props.match.params;
    this.getData();
    if (params.id) {
      this.setState({
        creatingNew: false,
        remove: true,
        view: true,
        titles: this.t('Edit Category'),
        originalID: params.id
      });
      this.setState({breadcrumbs: [capitalize(this.t('category')), this.t('Edit')]})
      this.getCategory(params.id);
    } else {
      this.setState({ titles: this.t('New Category'), creatingNew: true });
    }
  }

  getData = async () => {
    await this.props.onGetCategories();
    this.setState({
      categories: [...this.props.categories.items]
    });
  };

  getCategory = async id => {
    await this.props.onGetCategory(id);
    const { category } = this.props;
    this.setState({
      category: {
        ...category.item,
        image: ""
      },
      files: { img: category.item?.image },
      loading: category.loading,
      fullSelectedCategoryData: category.item
    });
    if (category.error) notify(this.t(category.error.message));
  };

  componentWillUnmount() {
    this.props.onClearCurrentCategory();
  }

  goBack = () => {
      history.push(config.ROUTES.CATEGORIES)
  };

  findCategory = id => this.state.categories.find(elem => elem.id === id);

  disableEnableCategory = async () => {
    const { id, enabled } = this.state.category;
    const data = { id, enabled };
    await this.props.onSaveOrUpdateCategory(data);
    const error = this.props.category.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    }
  };



 
  onSubmit = async values => {
    delete values.parent_name;
    await this.props.onSaveOrUpdateCategory({
      ...values,
      owner: getOwner(this.props.auth.user),
      order_index: 0,
    });
    const category = this.props.category;
    if (category.error) {
      notify(this.t(category.error.message));
    } else {
      this.goBack();
    }
  };

  render() {
    const {
      disableAddCategoryButton,
      view,
      editing,
      submitting,
      category,
      creatingNew,
      fullSelectedCategoryData,
      displaySelectComponent,
      selectedType,
    } = this.state;

    // Options for select
    const categoryTypes = selectGeneratorWObjChild(config.OPTIONS.CATEGORIES.TYPES, 'value', 'label');

    // Layout actions
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert'
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.CATEGORIES),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.getCategory(this.state.originalID);
            this.setState({ editing: false });
            if (displaySelectComponent) {
              this.setState({ displaySelectComponent: false });
            }
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing
        },
        {
          onClick: e => {
            if (category.enabled === 1)
              this.setState({ category: { ...category, enabled: 0 } }, () => this.disableEnableCategory());
            if (category.enabled === 0)
              this.setState({ category: { ...category, enabled: 1 } }, () => this.disableEnableCategory());
          },
          title: this.t(category?.enabled ? 'Disable' : 'Enable'),
          icon: !category?.enabled ? 'view_show' : 'view_hide',
          disabled: submitting || (view && !editing) || this.props.category.loading,
          checkPermissions: 'insert',
          visible: !creatingNew
        }
      ]
    };
    // ** Form validation functions
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');
    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      errors.type = required(values.type);
      return errors;
    };

    const closeModal = () => {
      this.setState({ displaySelectComponent: false });
    };

    const setParentCategory = cat => {
      /* 
      By doing things this way we avoid saving the 
      entire category with ID's included, thus overwriting 
      already saved categories
      -----
      The fullSelectedCategoryData property will hold the
      data of the currently selected item so that we can
      keep the history once we have positioned ourselves
      somewhere in the tree
      -----
      It will be overwritten every time we select something else
      */
      if (creatingNew) {
        this.setState({
          displaySelectComponent: false,
          category: { parent: cat.id, type: cat.type },
          fullSelectedCategoryData: { ...cat }
        });
      }

      if (!creatingNew) {
        this.setState({
          displaySelectComponent: false,
          category: { ...category, parent: cat.id, type: cat.type },
          fullSelectedCategoryData: { ...cat }
        });
      }
    };

    // Finding the parent in order to get the name
    let tempParent;
    if (category && category.parent !== 'root') tempParent = this.findCategory(category.parent);
    else category.parent_name = 'Root';
    if (tempParent && tempParent.name) category.parent_name = capitalizePhrase(tempParent.name);

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8 mb-3 rounded-lg' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={this.props.category.loading}>
          <Form initialValues={category || {}} onSubmit={this.onSubmit} validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="flex flex-wrap -mx-2 xl:w-1/2">
                    <div className="w-full px-2">
                      <Field
                        name="type"
                        component={SelectInput}
                        label={capitalize(this.t('type'))}
                        placeholder={capitalize(this.t('type'))}
                        options={categoryTypes}
                        empty={this.t('Select a type')}
                        readOnly={view && !editing}
                      />
                      {selectedType && values.parent !== 'root' ? (
                        <OnChange name="type">
                          {() => {
                            values.name = '';
                            values.parent = '';
                            values.parent_name = '';
                            this.setState({ fullSelectedCategoryData: {} });
                          }}
                        </OnChange>
                      ) : (
                        ''
                      )}
                      <WhenFieldChangesDo
                        field="type"
                        action={({ onChange, field, value }) => {
                          if (value !== '') {
                            /* 
                              Because of the way the child is programmed
                              If we pass category data with something inside
                              It won't search for child categories again
                              Which it should because the type has changed
                              So we delete the data inside that object
                            */
                            if (creatingNew) {
                              this.setState({
                                displaySelectComponent: false,
                                selectedType: value,
                                disableAddCategoryButton: false,
                                fullSelectedCategoryData: {}
                              });
                            }

                            if (!creatingNew) {
                              this.setState({
                                displaySelectComponent: false,
                                selectedType: value,
                                disableAddCategoryButton: false
                              });
                            }
                          }
                          if (value === '' && disableAddCategoryButton !== true) {
                            this.setState({
                              selectedType: '',
                              disableAddCategoryButton: true
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="w-full px-2">
                      <Field
                        name="name"
                        component={TextInput}
                        label={capitalize(this.t('name of category'))}
                        placeholder={capitalize(this.t('name of category'))}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="w-full px-2 xl:grid xl:grid-cols-2 xl:grid-rows-1">
                      <div className="w-11/12">
                        <Field
                          name="parent_name"
                          component={TextInput}
                          label={capitalize(this.t('subcategory of'))}
                          placeholder={capitalize(this.t('subcategory of'))}
                          readOnly
                        />
                      </div>
                      <div className="xl:flex xl:justify-self-start xl:self-center xl:mt-4">
                        <Button
                          iconSize={creatingNew ? '28' : ''}
                          onClick={() => {
                            if (selectedType) {
                              this.setState({ displaySelectComponent: true });
                            }
                          }}
                          disabled={
                            values.type === '' ||
                            !values.type ||
                            displaySelectComponent ||
                            (!creatingNew && !editing) ||
                            values.parent === 'root' ||
                            (!values.parent_name && !creatingNew)
                          }
                          icon={creatingNew ? 'plus_circle' : 'edit_pencil'}
                          title={creatingNew ? capitalize(this.t('choose')) : capitalize(this.t('modify parent'))}
                        />
                        {/* LOADER until parent name is loaded */}
                        {!values.parent_name && !creatingNew ? (
                          <span className="flex place-items-center text-red-500 ml-3">Cargando...</span>
                        ) : (
                          ''
                        )}
                        {/* Special functionality
                            ROOT child can only be made like this
                        */}
                        {creatingNew ? (
                          <Button
                            className={'ml-4'}
                            iconSize={'28'}
                            onClick={() => {
                              if (selectedType) {
                                this.setState({
                                  category: {
                                    parent: 'root',
                                    type: values.type
                                  }
                                });
                              }
                            }}
                            disabled={values.type === '' || !values.type || displaySelectComponent}
                            icon={'plus_circle'}
                            title={capitalize(this.t('create root category'))}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="xl:flex xl:justify-center xl:items-center w-full xl:w-8/12 px-2 mt-4">
                      {values.parent !== 'root' ? (
                        <>
                          <div className="xl:flex xl:justify-center xl:items-center ">
                            <Icon
                              name="sharypoints_main"
                              className="justify-self-center mr-3"
                              style={{ width: '32', height: '32' }}
                            />
                            <Field
                              name="json_data.min_value"
                              component={IntegerInput}
                              placeholder="0"
                              label={capitalize(this.t('min sharypoints'))}
                              readOnly={view && !editing}
                              className="w-6/12"
                            />
                          </div>
                          <div className="xl:flex xl:justify-center xl:items-center">
                            <Icon
                              name="sharypoints_main"
                              className="justify-self-center mr-3"
                              style={{ width: '32', height: '32' }}
                            />
                            <Field
                              name="json_data.max_value"
                              component={IntegerInput}
                              placeholder="0"
                              label={capitalize(this.t('max sharypoints'))}
                              readOnly={view && !editing}
                              className="w-6/12"
                            />
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </FormLayout>
              );
            }}
          </Form>
          {displaySelectComponent && selectedType ? (
            <div className="modal modal-open">
              {/* 
              full selected category debe mandarse
              eso en el hijo se evalua para armar o no armar el historial 
              */}
              <AddOrModifyCategory
                type={selectedType}
                selectedCategory={fullSelectedCategoryData}
                originalCategory={category}
                setParentCategory={setParentCategory}
                closeModal={closeModal}
                creatingNew={creatingNew}
              />
            </div>
          ) : (
            ''
          )}
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    category: state.categories.current,
    categories: state.categories.list,
    files: state.files,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdateCategory: params =>
      dispatch(categoryActions.saveOrUpdate(params)),
    onGetCategory: id => dispatch(categoryActions.get(id)),
    onGetCategories: id => dispatch(categoryActions.getAll(id)),
    onRemoveCategory: id => dispatch(categoryActions.del(id)),
    onClearCurrentCategory: () => dispatch(categoryActions.clearCurrent()),
    onPictureUpload: params => dispatch(filesActions.upload(params)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Category));
