import React from 'react';
import Icon from '../../libraries/icons';
// import config from '../../config';

const IntegerInput = ({ input, meta, label, prefix, options, ...rest }) => {
  return (
    <div className={'form-control ' + rest.className ? rest.className : ''}>
      {label && (
        <label htmlFor={input.name} className={"relative label " + (rest.labelClassName ? rest.labelClassName : "")}>
          <span className="label-text flex gap-2">{label}
          {rest.info &&
            <span
              className="ml-1 font-normal pop-up  after:content-[attr(after)]"
              after={rest.info}
            >
              <Icon name="information_solid" className="h-4 w-4" />
            </span>
          }
          </span>


        </label>
      )}
      <div className="mt-1 relative rounded-md shadow-sm">
        {prefix && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm sm:leading-5">
              {prefix}
            </span>
          </div>
        )}
        {rest.readOnly ? (
          <input
            {...input}
            type="numeric"
            step="any"
            className={
              'input w-full ' +
              (rest.narrow ? 'pl-2 pr-2' : prefix ? 'pl-12' : 'pl-6') +
              (meta.error && meta.touched ? 'input-error invalid-shadow' : '') +
              (rest.invalidFocusActive
                ? 'outline-none shadow-outline is-invalid invalid-shadow-focus invalid-shadow'
                : '') +
              (rest.validFocusActive ? ' outline-none shadow-outline' : '')
            }
            placeholder={rest.placeholder}
            disabled={rest.disabled}
            readOnly={rest.readOnly}
            value={input.value}
          />
        ) : (
          <input
            {...input}
            type="numeric"
            step="any"
            className={
              'input w-full ' +
              (rest.narrow ? 'pl-2 pr-2' : prefix ? 'pl-12' : 'pl-6') +
              (meta.error && meta.touched ? 'input-error invalid-shadow' : '') +
              (rest.invalidFocusActive
                ? ' outline-none shadow-outline is-invalid invalid-shadow-focus invalid-shadow'
                : '') +
              (rest.validFocusActive ? ' outline-none shadow-outline' : '')
            }
            placeholder={rest.placeholder}
            disabled={rest.disabled}
            onChange={(v) => {
              input.onChange(v)
              if(rest.onChangeSelect){
                rest.onChangeSelect(v.target.value)
              }
            }}
          />
        )}
      </div>
      {!rest.noerror && (
        <label className="label">
          <span className='label-text-alt invalid-feedback'>{meta.error && meta.touched && meta.error}&nbsp;</span>
        </label>
      )}
    </div>
  );
};

export default IntegerInput;
