import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import { Link } from 'react-router-dom';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import rolesActions from '../../../context/roles/actions';
import companiesActions from '../../../context/companies/actions';
import Swal from 'sweetalert2';
import PrivateButton from '../../../components/commons/PrivateButton';
import { capitalize, getOwner } from '../../../libraries/utils';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      roles: [],
      loading: true,
      windowWidth: window.innerWidth
    };
    // this.breadcrumbs = [capitalize(this.t('departments'))];
    this.breadcrumbs = ["Áreas"]
  }

  componentDidMount() {
    this.getRoles();
    // ** Get window width
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    // ** Get window width on resize
    window.removeEventListener('resize', this.handleResize);
  }

  // ** Function to handle resize
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getRoles = async (page_num = 1) => {
    // ** By default, page_num is equal to 1, it will change if you send it as a parameter
    const params = { page_num };
    const { search } = this.state;
    // ** If search exists, filter the data
    if (search && search !== '') {
      params.where = { json_data: [] };
      if (search && search !== '') {
        params.where = { custom: { name: `%${search.toLowerCase()}` } };
      }
    }
    // ** If sort exists, sort the data
    if (this.state.sort) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    } 

    const owner = await this.getCompanies()
    params.type = 'group';
    params.owner = owner[0].id;
    await this.props.onGetAll(params);
    const { roles } = this.props;
    if (roles.error) {
      notify(this.t(roles.error.message));
    } else {
      // ** Set state based on response data
      this.setState({ roles: roles.items, loading: roles.loading });
    }
  };

  getCompanies = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });
    if (this.props.companies.error) {
      notify(this.t(this.props.companies.error.message));
    } else {
      return this.props.companies.items;
    }
  };

  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getRoles(currentPage));
  };
  //  ** Delete function
  onRemove = (id, resetRows) => {
    const { roles } = this.props;
    customSwal
      .fire({
        title: this.t(resetRows ? 'Multiple remove' : '¿Are you sure?'),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          // ** Loading
          roles.loading = true;
          // ** If the parameter received is an array, execute multiple delete functions
          if (resetRows) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              // ** Wait until the api removes selected items
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (roles.error) {
              notify(this.t(roles.error.message));
            } else {
              // ** Get roles
              this.getRoles(this.state.currentPage);
            }
            // ** If the parameter is a number execute unique delete
          } else {
            // ** Wait until the api removes selected items
            await this.props.onRemove(id);
            if (roles.error) {
              notify(this.t(roles.error.message));
            } else {
              // ** Get roles
              this.getRoles(this.state.currentPage);
            }
          }
        }
      });
  };
  // ** Redirection to edit role route
  onEdit = id => {
    history.push(`${config.ROUTES.DEPARTMENTS}/${id}`);
  };
  // ** Redirection to new role route
  onNew = () => {
    history.push(config.ROUTES.DEPARTMENTS_NEW);
  };
  // ** Function to look for specific data in the table
  onSearch = data => {
    if (this.state.search !== data.search) this.setState({ search: data.search || '' }, () => this.getRoles());
  };
  // ** Use this function when configuring columns, its for hiding columns when the window width goes below the number specified
  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  render() {
    const { windowWidth, roles, loading } = this.state;

    const expandableComponent = ({ data }) => (
      <Link to={`${config.ROUTES.DEPARTMENTS}/${data.id}`}>
        <div className="flex flex-wrap py-3 px-5 align-center justify-around bg-gray-50">
          <div className="py-2">
            <p className="text-gray-500">Desarrollar componente expandible</p>
          </div>
          <div className="py-2">
            <p>{this.t('Enabled')}</p>
            <p className={`${data.enabled ? 'bg-green-100' : 'bg-red-100'} rounded-box px-2 py-1`}>
              {data.enabled ? 'Active' : 'Inactive'}
            </p>
          </div>
        </div>
      </Link>
    );
    // ** Columns configuration for our data table
    const columns = [
      {
        name: this.t('Name'),
        sortable: true,
        selector: row => row['name'],
        maxWidth: '100px',
        sortSelector: 'name'
      },
      {
        name: this.t('Description'),
        sortable: true,
        selector: row => row['description'],
        maxWidth: '300px',
        // hide: 'md'
      },
      {
        name: this.t('Code'),
        sortable: true,
        selector: row => row['code'],
        minWidth: '150px',
        // hide: 'md'
      },

      // >>>>>>> ACTIONS <<<<<<<
      {
        name: 'Actions',
        allowOverflow: true,
        center: true,
        width: '150px',
        hide: this.hide(370),
        cell: row => {
          return (
           <div className="flex justify-center align-center rounded-full gap-2">
              <ButtonIcon onClick={() => this.onEdit(row.id)} icon="view_show" buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm" className="w-5" />
              <PrivateButton control="delete">
                <ButtonIcon onClick={() => this.onRemove(row.id)} icon="trash" buttonClassName="btn-link text-error bg-gray-100 btn-sm" />
              </PrivateButton>
            </div>
          );
        }
      }
    ];
    // ** Main and secondary actions of out layout
    const actions = {
      main: {
        onClick: this.onNew,
        title: this.t('New'),
        checkPermissions: 'insert'
      },
      secondaries: [],
      search: { onSearch: this.onSearch, title: this.t('Search') }
    };
    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={roles}
            columns={columns}
            selectableRows={this.props.auth.user.roles.includes(config.ROLES.ADMIN) ? true : false}
            query={this.props.roles.query}
            getDataFunction={this.getRoles}
            multipleDeleteFunction={this.props.auth.user.roles.includes(config.ROLES.ADMIN) ? this.onRemove : null}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            // expandableRows={windowWidth < 600}
            // expandableRowsComponent={expandableComponent}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    roles: state.roles.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCompanies: params => dispatch(companiesActions.getAll(params)),
    onGetAll: params => dispatch(rolesActions.getAll(params)),
    onRemove: id => dispatch(rolesActions.del(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Roles));
