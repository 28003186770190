import React from 'react';
import Icon from '../../libraries/icons';

const AttachImage = ({
  input,
  meta,
  label,
  inputOnChange,
  ...rest
}) => {
  const inputProps = {
    ...input,
    onChange: async (e) => {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('myFile', file, file.name);
      (await inputOnChange) &&
        inputOnChange({ file: file.name, data: formData, name: input.name, e: file });
    },
  };

  return (
   
    <button type='button' onClick={rest.onClick} className={`${rest.className} ${rest.size || 'h-4 w-4'} relative flex`}>
       <Icon className={`${rest.size || 'h-4 w-4'} absolute`} name="paper_clip" />
          <label htmlFor="file-upload" className={`${rest.size || 'h-4 w-4'} cursor-pointer absolute`}/>
          <input
            {...inputProps}
            id='file-upload'
            type="file"
            accept="accept"
            className='hidden'
            disabled={rest.disabled}
            readOnly={rest.readOnly}
          />
    </button>
  );
};

export default AttachImage;
