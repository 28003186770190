import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { notify } from '../../../libraries/notifications';
import { history } from '../../../routes';
import config from '../../../config';
import SearchInput from '../../../components/forms/SearchInput';
import OfferOptions from '../../../components/customs/OfferOptions';
import Loader from '../../../components/commons/Loader';
import Icon from '../../../libraries/icons';
import Button from '../../../components/commons/Button';
import categoriesActions from '../../../context/categories/actions';
import { capitalize, isEmptyObject } from '../../../libraries/utils';

class AddOrModifyCategory extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      historyIndex: 0,
      search: '',
      type: null,
      parent: 'root',
      categoriesSelected: [],
      categoriesSelectedHistory: [],
      categories: [],
      categoriesHistory: [],
      loading: false,
      category: null,
      categoryHistory: [],
      save: false,
      completeCategoryList: []
    };
  }

  componentDidMount() {
    const { type, selectedCategory } = this.props;
    // If nothing is received, we run a search of the base category level
    if (isEmptyObject(selectedCategory)) {
      this.setState({ type }, () => this.getCategories(false));
    } else if (!isEmptyObject(selectedCategory)) {
      // In the other case, we must locate the position on the tree
      this.getEverySingleCategory();
      this.setState({ type });
    } else {
      this.goBack();
    }
  }

  getEverySingleCategory = async () => {
    await this.props.onGetAllCategories();
    const { categories, selectedCategory } = this.props;
    if (categories.error) notify(this.t(categories.error.message));
    this.setState({ completeCategoryList: categories.items });
    /* If we have received a category object, we must locate it's position on the tree */
    this.buildHistoryAndCurrentArrays(
      this.state.completeCategoryList,
      selectedCategory.parent
    );
  };

  makeSelectedArray = array => {
    const builtArray = [];
    for (let i = 0; i < array.length; i++) {
      if (i === 0) {
        // first index always has null
        builtArray.push([]);
      }
      if (i === 1) {
        // we create an array with a single object
        builtArray.push([array[i]]);
      }
      if (i > 1) {
        // then we create arrays with multiple objects
        builtArray.push([...builtArray[i - 1], array[i]]);
      }
    }
    return builtArray;
  };

  buildHistoryAndCurrentArrays = (
    list,
    parent,
    categoryHistory = [],
    historyIndex = 0,
    categoriesHistory = []
  ) => {
    const { selectedCategory, type } = this.props;
    // Recursion stops in this case
    if (parent === 'root') {
      // the root siblings are determined here
      categoriesHistory.unshift(
        list.filter(elem => elem.parent === parent && elem.type === type)
      );
      // the first position of this array represents the starting position (nothing selected)
      categoryHistory.unshift(null);
      // the index must be increased in final case to represent selection
      historyIndex++;
      // this array can only be built once we have all the individual categories
      const categoriesSelectedHistory = this.makeSelectedArray(categoryHistory);
      this.setState({
        categoriesHistory,
        // it's either null or built from the array
        // a root child would not have a history
        categories:
          selectedCategory.parent === 'root'
            ? []
            : categoriesHistory[historyIndex],
        categoryHistory: categoryHistory,
        categoriesSelectedHistory,
        historyIndex,
        category: categoryHistory[historyIndex],
        categoriesSelected: categoriesSelectedHistory[historyIndex]
      });
      return;
    }

    if (parent === selectedCategory.parent) {
      /*  On first run these are the same,
      in this instance we push the root level elements */
      const categoryWithoutParentName = { ...selectedCategory };
      delete categoryWithoutParentName.parent_name;
      categoryHistory.unshift(categoryWithoutParentName);
      categoriesHistory.unshift([]);
    }

    // the rest of the array is built on every step
    let foundParent = list.find(elem => elem.id === parent);
    let foundSiblings = list.filter(elem => elem.parent === parent);

    if (!isEmptyObject(foundParent)) {
      categoryHistory.unshift(foundParent);
      categoriesHistory.unshift(foundSiblings);
      parent = foundParent.parent;
    }

    historyIndex++;
    // recursion (parent is the variable that is modified to reach base case)
    this.buildHistoryAndCurrentArrays(
      list,
      parent,
      categoryHistory,
      historyIndex,
      categoriesHistory
    );
  };

  getCategories = async next => {
    // WARNING: don't destructure save, the save functionality
    // gets broken if you do
    const {
      categoriesSelected,
      categoriesSelectedHistory,
      category,
      categoryHistory,
      categoriesHistory,
      parent,
      search,
      type
    } = this.state;
    const params = { parent, type };

    if (search && search !== '') {
      // params.where = { json_data: { name: search }}
      params.name = `${search.toLowerCase()}`;
      params.parent = null;
    }

    await this.props.onGetAllCategories(params);

    const { categories } = this.props;

    if (categories.error) {
      notify(this.t(categories.error.message));
    } else {
      /* 
      If there are no categories found, it means we have no children
      We must exit the function here to stop pushing more levels into the history array
      */
      if (!categories) {
        return;
      }

      this.setState({ categories: categories.items });

      /* If we have clicked the button "save"
      We will trigger the save funcion, close the modal,
      And the data will be passed to our parent component (Category)
      Probably next param should be removed, discuss */
      if (next && this.state.save) {
        if (category === null) {
          return notify(capitalize(this.t('select a category')));
        }
        this.props.setParentCategory(category);
      }
    }

    this.setState({
      categoriesHistory: [...categoriesHistory, this.state.categories],
      categoriesSelectedHistory: [
        ...categoriesSelectedHistory,
        categoriesSelected
      ],
      categoryHistory: [...categoryHistory, category]
    });
  };

  onCategorySelect = async category => {
    const { historyIndex, categoriesSelected } = this.state;
    this.setState({ historyIndex: historyIndex + 1 });
    this.setState(
      {
        search: '',
        category,
        categoriesSelected: [...categoriesSelected, category],
        parent: category.id
      },
      () => this.getCategories(true)
    );
  };

  searchClear = form => {
    form.change('search', undefined);
    this.searchOnClick({ search: '' });
  };

  searchOnClick = async values => {
    const { search } = this.state;
    if (search !== values.search)
      this.setState({ search: values.search || '' }, () =>
        this.getCategories(false)
      );
  };

  returnToPreviousView = () => {
    const {
      categoriesHistory,
      categoriesSelectedHistory,
      categoryHistory,
      historyIndex
    } = this.state;

    // If we are at base level (0) we mustn't run this function
    if (historyIndex === 0) return;
    const tempIndex = historyIndex - 1;

    // This operation resets everything to its previous state
    this.setState({
      categories: categoriesHistory[tempIndex],
      categoriesSelected: categoriesSelectedHistory[tempIndex],
      category: { ...categoryHistory[tempIndex] },
      categoriesHistory: categoriesHistory.slice(0, historyIndex),
      categoriesSelectedHistory: categoriesSelectedHistory.slice(
        0,
        historyIndex
      ),
      categoryHistory: categoryHistory.slice(0, historyIndex),
      historyIndex: historyIndex - 1
    });
  };

  goBack = () => history.push(config.ROUTES.OFFERS.ADD);

  render() {
    const {
      categories,
      categoriesSelected,
      type,
      categoriesHistory,
      historyIndex
    } = this.state;

    const { selectedType, creatingNew } = this.props;
    const { loading } = this.props.categories;

    let title = '';

    // Hardcoded type, maybe will need to be changed
    switch (type) {
      case 'products':
        title = capitalize(this.t('select a product'));
        break;
      case 'services':
        title = capitalize(this.t('select a service'));
        break;
      case 'favors':
        title = capitalize(this.t('select a favor'));
        break;
      case 'actions':
        title = capitalize(this.t('select an action'));
        break;
      case 'benefits':
        title = capitalize(this.t('select a benefit'));
        break;
      default:
    }

    return (
      <div className="rounded-xl bg-gray-100 shadow-xl w-1/2 mx-auto h-3/4 overflow-y-scroll">
        <h1 className="flex justify-center my-5">{title}</h1>
        <div className="flex justify-center my-5">
          {/* {Return button} */}
          <Button
            onClick={() => {
              this.returnToPreviousView();
            }}
            title={this.t('Go Back')}
            className="mr-2"
          />
          {/* {Save button} */}
          <Button
            onClick={() => {
              this.setState({ save: true });
              return this.getCategories(true);
            }}
            title={capitalize(this.t('select'))}
          />
          {/* {Close modal button (calls parent component function and exits)} */}
          <Button
            onClick={() => this.props.closeModal()}
            className="btn-error ml-2"
            title={capitalize(this.t('close'))}
          />
        </div>
        {/* These are the breadcrumbs displayed underneath the buttons */}
        <div className="flex flex-wrap justify-center my-5">
          {/* The base level type is always loaded */}
          <span>{`${
            selectedType !== undefined ? selectedType : capitalize(this.t(type))
          }`}</span>
          <Icon className="w-6 h-6 " name="cheveron_right" />
          {/* Then we map the children */}
          {categoriesSelected?.map((item, index) => (
            <>
              <span>{item.name}</span>
              <Icon className="w-6 h-6 " name="cheveron_right" />
            </>
          ))}
        </div>
        <section className="px-3 pt-3">
          <Form initialValues={{}} onSubmit={this.searchOnClick}>
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="w-full  mx-auto pb-4">
                <Field
                  name="search"
                  component={SearchInput}
                  placeholder={this.t('Search')}
                  onClick={this.searchOnClick}
                  onClearClick={() => this.searchClear(form)}
                />
              </form>
            )}
          </Form>
        </section>
        <section className="overflow-y-auto mx-auto categories-vertical-wrapper">
          {loading && <Loader />}
          {/* Shown when there are no more children */}
          {!loading && categories?.length === 0 && (
            <>
              {!creatingNew ? (
                ''
              ) : (
                <div className="text-center p-10">
                  <Icon className="h-24 w-24 mx-auto mb-3" name="bell" />
                  <h4 className="mb-1">{capitalize(this.t('no daughter categories'))}</h4>
                </div>
              )}
              <div className="flex justify-center">
                <Button
                  onClick={() => {
                    this.returnToPreviousView();
                  }}
                  title={this.t('Go Back')}
                  className="mr-2"
                />
                {creatingNew ? (
                  <Button
                    onClick={() => {
                      this.setState({ save: true });
                      return this.getCategories(true);
                    }}
                    title={capitalize(this.t('select'))}
                  />
                ) : (
                  ''
                )}
              </div>
              {/* List without chevrons 
                  podria decir volver al nivel anterior:
                  y estas son las opciones del nivel anterior
              */}
              {creatingNew
                ? categoriesHistory[historyIndex - 1]?.map(
                    (category, index) => (
                      <OfferOptions
                        key={index}
                        data={category}
                        noChevron={true}
                      />
                    )
                  )
                : ''}
            </>
          )}
          {/* This is the actual table we see where we can select next category */}
          <div className="card white rounded-none">
            <div className="overflow-x-auto">
              {categories &&
                // remove same category from list
                // we mustn't be able to select the same category as parent
                categories.filter(category => category.id !== this.props.originalCategory.id).map((category, index) => {
                  return (
                    <OfferOptions
                      key={index}
                      data={category}
                      
                      onClick={this.onCategorySelect}
                    />
                  );
                })}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    categories: state.categories.list,
    category: state.categories.current
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllCategories: params => dispatch(categoriesActions.getAll(params)),
    onGetCategory: params => dispatch(categoriesActions.get(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddOrModifyCategory));
