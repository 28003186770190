import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import userActions from "../../context/users/actions";
import { ToastContainer, notify } from "../../libraries/notifications";

import { history } from "../../routes";
import config from "../../config";
import UserProfileImage from "../../components/commons/UserProfileImage";
import transactionsAction from "../../context/transactions/actions";
import companiesActions from "../../context/companies/actions";
import Loader from "../../components/commons/Loader";
import medal from "../../assets/img/medal.png";
import { capitalizePhrase } from "../../libraries/utils";
import benefitsActions from "../../context/benefits/actions";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";

class UserRanking extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      activities: [],
      userId: this.props.auth.user.id,
      areNewPrizes: false,
      prizes: [],
      dataLoading: true,
    };
  }

  async componentDidMount() {
    console.log("RANKING PAGE", this.props.auth.user);
    const params = this.props.match.params;
    let userId = this.props.auth.user.id;
    let isPublicProfile = false;
    if (params.id) {
      userId = params.id;
      isPublicProfile = true;
    }

    let fromPath = null;
    if (this.props.location?.state?.fromPath) {
      fromPath = this.props.location.state.fromPath;
    }

    await this.getCompanyId();

    this.setState({ userId, isPublicProfile, fromPath }, () =>
      this.getAllData()
    );
  }

  getCompanyId = async () => {
    await this.props.onGetCompaniesByOwner(this.props.auth.user.accounts[0].id);
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error));
    } else {
      if (companies.item) {
        this.setState({ companyId: companies.item[0].id });
      }
    }
  };

  getAllData = async () => {
    const { userId: id } = this.state;
    this.setState({ dataLoading: true });

    let promises = [];
    console.log("PROFILE PAGE ID", id);
    promises.push(this.getUser(id));
    promises.push(this.getRanking(id));
    //promises.push(this.getLevels())
    //promises.push(this.getRewards(id))
    //promises.push(this.getActivities(id))
    //promises.push(this.getOffers(id))

    let d = await Promise.all(promises);
    let state = { dataLoading: false };
    d.forEach((x) => {
      if (d.error) {
        notify(this.t(x.error.message));
      } else {
        state = { ...state, ...x };
      }
    });
    if (state.rewards?.total?.points === "0") {
      state.user_ranking = "N/A";
    }
    this.setState(state);
  };

  getUser = async () => {
    const { userId: id } = this.state;
    await this.props.onGetUser({ id });
    const { user } = this.props;
    if (user.error) {
      return { error: this.t(user.error.message) };
    } else {
      const rate_total = user.item.json_data.rate_total;
      const rate_count = user.item.json_data.rate_count;
      const rating =
        rate_total && rate_count !== 0 ? rate_total / rate_count : 0;

      return { user: user.item, rating };
    }
  };

  getRanking = async () => {
    const { userId: id } = this.state;

    await this.props.onGetUsersRanking({ user_type: config.USER_TYPE.REGULAR, unique_id: this.state.companyId });
    
    const { ranking } = this.props.user;

    if (ranking?.error) {
      return { error: this.t("Error obteniendo el ranking") };
    } else {
      let user_ranking = "N/A";
      console.log("RANKING=============", ranking);
      if (ranking) {
        ranking.forEach((r, index) => {
          if (r.id === id) {
            user_ranking = index + 1;
            ranking[index].name = ranking[index].name + " (Vos)";
          }
          this.setState({ ranking });
        });
      }
      return { user_ranking };
    }
  };

  getRewards = async () => {
    const { userId: id } = this.state;

    await this.props.onGetRewards({ id });
    const { rewards } = this.props;
    if (!rewards.total) {
      return { error: this.t("Error obteniendo las recompensas") };
    } else {
      // let user_ranking = "N/A"
      // if(rewards.total.points === "0"){
      //   user_ranking = "N/A"
      // }
      console.log("REWARDS========", rewards.level);
      return { rewards, userLevel: rewards.level };
    }
  };

  getActivities = async () => {
    // if(!id){
    //   id = this.props.auth.user.id
    // }
    const { userId: id } = this.state;

    const ownerParams = {
      type: [
        config.TRANSACTIONS.INITIATIVE.TYPE,
        config.TRANSACTIONS.ACTION.TYPE,
      ],
      status: [
        config.TRANSACTIONS.INITIATIVE.STATUS.ATTENDED,
        config.TRANSACTIONS.ACTION.STATUS.COMPLETED,
      ],
      created_by: id,
    };

    await this.props.onGetOwnerActivities(ownerParams);
    const { activities } = this.props;
    let ownerActivities = [...activities.items];

    if (activities.error) {
      return { error: this.t(activities.error.message) };
    } else {
      const sourceParams = {
        type: config.TRANSACTIONS.BORROW.TYPE,
        status: [
          config.TRANSACTIONS.BORROW.STATUS.ACCEPTED,
          config.TRANSACTIONS.BORROW.STATUS.ON_USER,
          config.TRANSACTIONS.BORROW.STATUS.ON_OWNER,
          config.TRANSACTIONS.BORROW.STATUS.REVIEW,
          config.TRANSACTIONS.BORROW.STATUS.COMPLETED,
        ],
        where: { custom: { source: id, comparison: "OR", created_by: id } },
      };
      await this.props.onGetSourceActivities(sourceParams);
      const { activities } = this.props;
      if (activities.error) {
        return { error: this.t(activities.error.message) };
      } else {
        let sourceActivities = [...activities.items];
        let opinionsReceived = sourceActivities.filter(
          (a) =>
            a.status.includes([config.TRANSACTIONS.BORROW.STATUS.COMPLETED]) &&
            a.source === id
        );
        let opinionsGiven = sourceActivities.filter(
          (a) =>
            a.status.includes([config.TRANSACTIONS.BORROW.STATUS.COMPLETED]) &&
            a.created_by === id
        );

        let totalActivities = ownerActivities.concat(
          sourceActivities.filter((a) => a.source === id)
        );
        console.log("TOTAL ACTIVITIES", totalActivities);

        return { activities: totalActivities, opinionsGiven, opinionsReceived };
      }
    }
  };

  sendMessage = (user) => {
    history.push({
      pathname: config.ROUTES.SEND_MESSAGE,
      state: {
        fromPath: config.ROUTES.USER.PUBLIC_PROFILE.replace(":id", user.id),
        user,
      },
    });
  };

  getPointsLeftForNextLevel = () => {
    if (this.state.userLevel === config.MAX_LEVEL) return null;
    console.log(this.state.userLevel);
    return (
      this.state.levels[(this.state.userLevel + 1).toString()].min -
      this.props.auth.user.rewards.total.points
    );
  };

  goProfileRating = (opinions, type, isPublicProfile) => {
    const { userId } = this.state;
    history.push({
      pathname: config.ROUTES.USER.PROFILE_RATINGS,
      state: { opinions, type, isPublicProfile, userId },
    });
  };

  goBack = () => {
    let path = this.state.fromPath ? this.state.fromPath : config.ROUTES.HOME;
    history.push(path);
  };

  render() {
    const { user, ranking, dataLoading } = this.state;
    // const company_name = this.state.company_name ? this.state.company_name : this.props.auth.user.ancestors?.company?.name

    const top3 = ranking?.slice(0, 3);
    const rest_ranking = ranking?.slice(3);

    return (
      <LayoutWithSidebar
        main={{ className: "text-content-400" }}
        header={{
          breadcrumbs: this.breadcrumbs,
        }}
        container={{ className: "md:px-32 pb-12" }}
      >
        <ToastContainer />
        <section className="">
          <div className=" px-4">
            {!dataLoading && top3 ? (
              <div className="py-5 grid grid-cols-3 gap-4 items-end justify-between">
                <div className="card w-full h-full bg-primary-focus text-center flex gap-3 flex-col items-center relative p-2">
                  <img src={medal} alt="" className="w-12 -mt-2" />
                  <p className="absolute top-[28px] left-0 right-0 text-2xl text-white font-bold">
                    1
                  </p>
                  <div
                    className="flex flex-col items-center"
                    onClick={() =>
                      history.push({
                        pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(
                          ":id",
                          top3[0].id
                        ),
                        state: { fromPath: config.ROUTES.USER.RANKING },
                      })
                    }
                  >
                    <UserProfileImage
                      picture={{ className: "h-12 w-12 !m-0 !mb-2" }}
                      data={top3[0]}
                      withoutFirstName
                    />
                    <p className="text-white leading-[14px]">
                      {capitalizePhrase(top3[0].name)}
                    </p>
                    <p className="text-yellow-400 font-semibold">
                      {top3[0].points}pts
                    </p>
                  </div>
                </div>
                <div className="card w-full mt-5 bg-[#0c6a73d2] text-center flex flex-col gap-3 items-center relative p-2">
                  <img src={medal} alt="" className="w-9 -mt-2" />
                  <p className="absolute  top-[18px] left-0 right-0 text-xl text-white font-bold">
                    2
                  </p>
                  <div
                    className="flex flex-col items-center"
                    onClick={() =>
                      history.push({
                        pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(
                          ":id",
                          top3[1].id
                        ),
                        state: { fromPath: config.ROUTES.USER.RANKING },
                      })
                    }
                  >
                    <UserProfileImage
                      picture={{ className: "h-10 w-10 !m-0 !mb-2" }}
                      data={top3[1]}
                      withoutFirstName
                    />
                    <p className="text-white leading-[14px]">
                      {capitalizePhrase(top3[1].name)}
                    </p>
                    <p className="text-yellow-400 font-semibold">
                      {top3[1].points}pts
                    </p>
                  </div>
                </div>
                <div className="card w-full mt-8 bg-[#0c6a73d2] text-center flex gap-3 flex-col items-center relative p-2">
                  <img src={medal} alt="" className="w-6 -mt-2" />
                  <p className="absolute top-[12px] left-0 right-0 text-sm text-white font-bold">
                    3
                  </p>
                  <div
                    className="flex flex-col items-center"
                    onClick={() =>
                      history.push({
                        pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(
                          ":id",
                          top3[2].id
                        ),
                        state: { fromPath: config.ROUTES.USER.RANKING },
                      })
                    }
                  >
                    <UserProfileImage
                      picture={{ className: "h-8 w-8 !m-0 !mb-2" }}
                      data={top3[2]}
                      withoutFirstName
                    />
                    <p className="text-white leading-[14px]">
                      {capitalizePhrase(top3[2].name)}
                    </p>
                    <p className="text-yellow-400 font-semibold">
                      {top3[2].points}pts
                    </p>
                  </div>
                </div>
              </div>
            ) : dataLoading ? (
              <div className="mx-auto grid place-content-center">
                <Loader spinnerClassName="h-16 w-16 text-primary" />
              </div>
            ) : (
              <p className='text-center mt-8 text-xl w-full'>
                Por ahora, el ranking está vacío.
              </p>
            )}
          </div>
          <div className="flex mt-5 flex-col px-4 gap-2">
            {rest_ranking &&
              rest_ranking.map((u, i) => (
                <div
                  key={u.id}
                  className={
                    "card bg-white py-2 px-4 flex justify-between flex-row" +
                    (u.id === user?.id ? " border-2 border-primary" : " ")
                  }
                  onClick={() =>
                    history.push({
                      pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(
                        ":id",
                        u.id
                      ),
                      state: { fromPath: config.ROUTES.USER.RANKING },
                    })
                  }
                >
                  <div className="flex items-center gap-3">
                    <p className="text-gray-300">{i + 4}</p>
                    <UserProfileImage
                      picture={{ className: "h-5 w-5 !m-0" }}
                      data={u}
                      withoutFirstName
                    />
                    <p className="text-primary">{capitalizePhrase(u.name)}</p>
                  </div>
                  <div className="text-yellow-400 font-semibold text-sm">
                    {u.points}pts
                  </div>
                </div>
              ))}
          </div>
        </section>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.current,
    auth: state.users.auth,
    activities: state.transactions.list,
    rewards: state.users.auth.user.rewards,
    ranking: state.users.ranking,
    companies: state.companies.current,

    benefits: state.benefits.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (params) => dispatch(userActions.saveOrUpdate(params)),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onGetUsersRanking: (params) => dispatch(userActions.getRanking(params)),
    onGetRewards: (params) => dispatch(userActions.getRewards(params)),
    onLogout: () => dispatch(userActions.logout()),
    onGetOwnerActivities: (ownerParams) =>
      dispatch(transactionsAction.getAll(ownerParams)),
    onGetSourceActivities: (sourceParams) =>
      dispatch(transactionsAction.getAll(sourceParams)),
    onGetCompaniesByOwner: (params) =>
      dispatch(companiesActions.getByOwner(params)),
    onGetAllBenefits: (params) => dispatch(benefitsActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserRanking));
