import React  from 'react';
import { isNotSetOrTrue } from '../../libraries/utils';

import ButtonIcon from '../commons/ButtonIcon';
import logoNegative from '../../assets/img/logo-negative.png';
import logo from '../../assets/img/logo.png';

export default function HeaderSmall({ header }) {
  /*
  {
    header: {
      logo: false / true / 'negative',
      title: '',
      className: '',
      left: {
        className: '',
        icon: '',
        action: '',
        visible: true/false
      },
      right: {
        className: '',
        icon: '',
        action: '',
        visible: true/false
      },
    },
  }
  */
  return ( 
    <header className={"h-16 items-center justify-between w-full p-3 flex " + (header && header.className ? header.className : "")}>
      <div className="w-10 text-center">
        { (header && header.left && isNotSetOrTrue(header.left.visible)) && (
          <ButtonIcon
            buttonClassName="btn-ghost btn-sm"
            className={header.left.className ? header.left.className : "h-6 w-6"}
            onClick={header.left.action || (() => true) }
            icon={header.left.icon}
          />
        )}
      </div>
      { header && header.title ? (
        <h2 className="h2 text-primary-100">{header.title}</h2>
        ) : header && header.logo && (
          <div>
            {header.logo === true && <img src={logo} alt="" width="150px" />}
            {typeof header.logo !== 'boolean' && header.logo.toLocaleLowerCase() === 'negative' && <img src={logoNegative} alt="" width="150px" />}
          </div>
        )
      }
      <div className="w-10 text-center">
        { (header && header.right && isNotSetOrTrue(header.right.visible)) && (
          <ButtonIcon
            buttonClassName="btn-ghost btn-sm"
            className={header.right.className ? header.right.className : "h-6 w-6"}
            onClick={header.right.action}
            icon={header.right.icon}
          />
        )}
      </div>
    </header>
  )
}
