import config from '../../config';
import { request } from '../../libraries/request'

const getAll = (params = {}, token) => {
  console.log('SERVICES::COMPANIES::get', params, token);
  if (params && params.page_num && config.QUERIES_PAGE_SIZE) {
    console.log('GET BY PAGE', params.page_num)
    params.page_size = config.QUERIES_PAGE_SIZE;
  }
  if (!params.order_by) { 
    params.order_by = 'created_at';
    params.order_direction = 'ASC';
  }

  console.log('PARAMS FROM SERVICE', params)
  return request({
    url: config.BASE_API_URL + config.API.COMPANIES,
    accessToken: token,
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
    }
    return response;
  })
}

const getBalance = (params = {}, token) => {
  if (params){
    params.user_type = "regular"
  } else {
    params = {user_type: "regular"}    
  }
  return request({
    url: config.BASE_API_URL + config.API.USERS_BALANCE,
    accessToken: token,
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    return response;
  })
}


const save = (data, token) => {
  console.log('SERVICES::COMPANIES::save', data, token);
  return request({
    url: config.BASE_API_URL + config.API.COMPANIES,
    accessToken: token,
    method: 'POST',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}
const update = (data, token) => {
  console.log('SERVICES::COMPANIES::update', data, token);
  return request({
    url: config.BASE_API_URL + config.API.COMPANY.replace('{id}', data.id),
    accessToken: token,
    method: 'PATCH',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const get = (id, token) => {
  console.log('SERVICES::COMPANIES::get', id, token);
  return request({
    url: config.BASE_API_URL + config.API.COMPANY.replace('{id}', id),
    accessToken: token,
    // data: {},
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })

}

const getByOwner = (owner, token) => {
  console.log('SERVICES::COMPANIES::getByOwner', owner, token);
  return request({
    url: config.BASE_API_URL + config.API.COMPANIES + `?owner=${owner}`,
    accessToken: token,
    // data: {},
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })

}

const del = (id, token) => {
  console.log('SERVICES::COMPANIES::remove', id, token);
  return request({
    url: config.BASE_API_URL + config.API.COMPANY.replace('{id}', id),
    accessToken: token,
    method: 'DELETE',
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const services = {
  getAll,
  save,
  get,
  del,
  update,
  getByOwner,
  getBalance,
};

export default services;