import React from 'react';
import Icon from '../../libraries/icons';
import ButtonIcon from '../commons/ButtonIcon';


const ImageUploadFileInput = ({ input, meta, label, inputOnChange, ...rest }) => {
  const inputProps = {
    ...input,
    onChange: async e => {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("myFile",file,file.name)
      await inputOnChange && inputOnChange({ file: file.name, data: formData, name: input.name });
    }
  };

  return (
    <div className={"form-control " + (rest.className ? rest.className : "")}>
      {label && (
        <label htmlFor={input.name} className={"label " + (rest.labelClassName ? rest.labelClassName : "")}>
          <span className="label-text">{label}</span>
        </label>
      )}
      <div className={"relative input py-10 flex justify-center items-center " + (rest.disabled ? "bg-gray-400 " : "") + (rest.inputClassName ? rest.inputClassName : "")}>
        {(rest.img && !rest.disabled) ? (
            <>
              <img className="absolute w-auto h-full" src={rest.img} alt="" />
              <ButtonIcon
                buttonClassName="btn-ghost btn-sm absolute right-2 z-1"
                className="w-8 h-8"
                onClick={() => rest.clickButtonClear && rest.clickButtonClear({ name: input.name })}
                icon="x_circle"
              />
            </>
          ) : (
            <Icon className="h-8 w-8 absolute" name="photograph" />
          )
        }
        {<input {...inputProps}
          type="file" 
          accept="accept"
          className={"opacity-0 " 
            + (meta && meta.error && meta.touched ? "input-error invalid-shadow " : "") 
          }
          placeholder={rest.placeholder}
          onClick={rest.onClick}
          disabled={rest.disabled}
          readOnly={rest.readOnly}
          value={undefined}
        />}
      </div>
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">{meta && meta.error && meta.touched}</span>
        </label>
      )}
    </div>
  )
}

export default ImageUploadFileInput;