
import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';


class InfoHelp extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }
  
  componentDidMount() {
    ;
  }

  onExit = () => {
    const back = (this.props.location && this.props.location.state &&  this.props.location.state.from) ? this.props.location.state.from : config.ROUTES.HOME;
    history.push(back);
  }
 
  render() {
    return (
      <LayoutSmall
        header={{ 
          className: "bg-primary",
          title: this.t("Help"),
          left: { icon: 'arrow_left', action: this.onExit }
        }}      
        main={{ 
          className: "light-primary-bg text-gray-700",
        }}
      >
        <p>
          Para más información ingresa a <a target="_blank" href="https://aaa.com" rel="noreferrer">aaa.com</a>
        </p>
        <Button
          className="btn-block btn-secondary my-4"
          title={this.t("Continue")}
          onClick={this.onExit}
        />
      </LayoutSmall>
    ) 
  }
}

export default withTranslation()(InfoHelp);
