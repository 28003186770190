import React, { useState } from 'react';
import Icon from '../../libraries/icons';
import Loader from '../../components/commons/Loader';
import { useTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';
import { capitalize } from '../../libraries/utils';

const onEdit = id => {
  history.push(config.ROUTES.CATEGORIES_EDIT.replace(':id', id));
};

const CategoriesTree = ({
  screenWidth,
  onExpand,
  loading,
  state,
  onRemove
}) => {
  const { t } = useTranslation();
  const [reRender, setReRender] = useState(false)
  const hide = number => screenWidth < number;
  const columns = [
    {
      name: '',
      selector: row => row['name']
      // hide: 700
    }
  ];
  const gridCols = columns.filter(column => !hide(column.hide)).length;
  // ** Function to handle Pagination

  const BaseComponent = ({ data, bg }) => {
    const dataArray = () => {
      const filteredData = data.filter(e =>
        state.ids ? state.ids?.includes(e.id) : true
      );
      if (filteredData.length === 0) {
        return data;
      } else return filteredData;
    };
    return (
      <div>
        {dataArray().map(row => (
          <div key={row.name + row.id}>
            <div
              className={`pl-${4 * row.level} bordered border-b px-4 flex justify-between items-center grid-cols-${
                gridCols + 2
              } ${row.expanded && `bg-gray-${bg}`}`}>
              <button
                onClick={() => {
                  row.expanded = !row.expanded;
                  setReRender(!reRender);
                  onExpand(row.expanded, row);
                }}
                className="justify-self-center ml-8">
                {row.expanded ? (
                  <Icon name="minus_no_outline" size="16" className="text-gray-300" />
                ) : (
                  <Icon name="plus" size="16" className="flex justify-center pl- text-primary" />
                )}
              </button>
              {columns.map(column => (
                <div
                  key={column.name + 45}
                  className={`flex-1 p-3 ${hide(column.hide) && 'hidden'}`}>
                  <p>{column.selector(row)}</p>
                </div>
              ))}
              <div className="flex justify-center align-center rounded-full my-3">
                <span
                  onClick={() => onEdit(row.id)}
                  className="p-3 cursor-pointer text-gray-400">
                  <Icon className="h-5 w-5" name="view_show" />
                </span>
                <span
                  // onClick={() => onRemove(row)}
                  onClick={() => onRemove(row)}
                  className="p-3 cursor-pointer text-error">
                  <Icon className="h-5 w-5" name="trash" />
                </span>
              </div>
            </div>
            <div className={`bg-gray-${bg}`}>
              {row.expanded && state[`categories_${row.level + 1}`]?.length? (
                <BaseComponent
                  row={row}
                  bg={100 * (row.level + 2)}
                  data={state[`categories_${row.level + 1}`]}
                />
              ) : loading && row.expanded ? (
                <Loader spinnerClassName="text-primary h-8 w-8" />
              ) : (
                row.expanded && (
                  <p className="p-6 text-center">
                    {capitalize(t('this category has no subcategories'))}
                  </p>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <>
      {!state.main_loading  ? (
        <div>
          {state.categories_0
            ?.filter(e => (state.ids ? state.ids?.includes(e.id) : true))
            .map(row => (
              <div key={row.name + row.id}>
                <div
                  className={`border-b px-4 flex justify-between items-center grid-cols-${gridCols + 2}  ${
                    row.expanded && `bg-gray-${100 * (row.level + 1)}`
                  }`}>
                  <button
                    onClick={() => {
                      row.expanded = !row.expanded;
                      setReRender(!reRender);
                      onExpand(row.expanded, row);
                    }}
                    className="">
                    {row.expanded ? (
                      <Icon name="minus_no_outline" size="16px" className="text-gray-200" />
                    ) : (
                      <Icon name="plus" size="16px" className="text-primary" />
                    )}
                  </button>
                  {columns.map(column => (
                    <div
                      key={column.name + 45}
                      className={`flex-1 p-3 ${hide(column.hide) && 'hidden'}`}>
                      <p>{column.selector(row)}</p>
                    </div>
                  ))}
                  <div className="flex justify-center align-center rounded-full my-3">
                    <span
                      onClick={() => onEdit(row.id)}
                      className="p-3 cursor-pointer text-gray-400">
                      <Icon className="h-5 w-5" name="view_show" />
                    </span>
                    <span
                      onClick={() => onRemove(row)}
                      className="p-3  cursor-pointer text-error">
                      <Icon className="h-5 w-5" name="trash" />
                    </span>
                  </div>
                </div>

                <div className={`bg-gray-${100 * (row.level + 1)}`}>
                  {row.expanded && state[`categories_${row.level + 1}`]?.length ? (
                    <BaseComponent
                      row={row}
                      bg={100 * (row.level + 2)}
                      data={state[`categories_${row.level + 1}`]}
                    />
                  ) : loading && row.expanded ? (
                    <Loader spinnerClassName="text-primary h-8 w-8" />
                  ) : row.expanded && (
                    <p className="p-6 text-center">
                      {capitalize(t('this category has no subcategories'))}
                    </p>
                  )}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <Loader spinnerClassName="text-primary h-8 w-8" className="bg-gray" />
      )}
    </>
  );
};

export default CategoriesTree;
