import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NoAccess from '../../pages/commons/NoAccess';
import config from '../../config/';
import { permissionsCheck } from '../../libraries/utils';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const logged = useSelector(state => state.users.auth.logged);
  const userType = useSelector(state => state.users.auth.user?.type[0]);
  const permissions = useSelector(state => state.users.auth.user?.permissions);

  const access = ({ path }) => {
    if (path === '/') return true;
    //path, userType, permissions, action
    console.log(path)
    return permissionsCheck(path, userType, permissions);
  }

  return (
    <Route 
      {...rest}
      render = { props => (
        logged ? ( 
          access(rest) ? (
            <Component {...props} />
          ) : (
            <NoAccess title='401' headline='No access' button='Go back' from='/' />
          )
        ) : (
          <Redirect to={{ pathname: config.ROUTES.LOGIN, state: { from: props.location } }} />
        )
      )}
    />

  )
}