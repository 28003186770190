import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import transactionActions from '../../../context/transactions/actions';
import challengesActions from '../../../context/challenges/actions';
import categoriesActions from '../../../context/categories/actions';
import companyActions from '../../../context/companies/actions';
import usersActions from '../../../context/users/actions';
import { isEmptyObject, capitalizePhrase, capitalize, getOwner } from '../../../libraries/utils';
import SelectInput from '../../../components/forms/SelectInput';
import { exportToExcel } from '../../../libraries/exports/exportToExcel';
import clsx from "clsx";

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      actionCategoryTypes: [],
      // default type
      currentType: 'all',
      currentStatus:'all',
      sort: {
        order_by: 'sub_type',
        order_direction: 'DESC'
      },
      loading: true,
      transactions: [],
      users: [],
      windowWidth: window.innerWidth,
      page_num: 1,
      query: {
        records: 0,
        page_size: 8
      },
    };
    this.breadcrumbs = [capitalize(this.t('Interacciones de usuarios'))];
  }

  componentDidMount() {
    // used to display user data in some columns
    this.getUsers();
    this.getTransactions();
    // used for the select
    this.getActionTypesArray();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getActionTypesArray = async () => {
    await this.props.onGetActionTypes({ type: 'actions' });
    const { categories } = this.props;
    if (categories.error) {
      notify(this.t(categories.error.message));
    } else {
      this.setState({ actionCategoryTypes: categories.items });
    }
  };

  getUsers = async () => {
    let user_params={}
    let unique_id = null
    if (this.props.auth.user.roles.includes(config.ROLES.COMPANIES)) {
      await this.props.onGetAllCompanies({ owner: getOwner(this.props.auth.user) })
      const { companies } = this.props;
      if (companies.error) {
        notify(this.t(companies.error.message))
      } else {
        unique_id = companies.items[0].id;
      }
      if(unique_id){
        user_params = { unique_id: unique_id }
      }
    }
    await this.props.onGetAllUsers(user_params);
    const { users } = this.props;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      this.setState({ users: users.items });
    }
  };

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getTransactions = async (page_num = 1, type = this.state.currentType,status=this.state.currentStatus) => {
    this.setState({loading: true})
    const params = {
      page_num,
      type,
      page_size: 8
    };
    status !== "all" && (params.status = status)
    if(page_num === "export") {
      delete params.page_num 
      delete params.page_size 
    }
    if(type === "prize"){
      params.type = "benefit"
      params.sub_type = ["prize"]        
    }
    if(type === "all"){
      params.type = [
        config.TRANSACTIONS.INITIATIVE.TYPE,
        config.TRANSACTIONS.ACTION.TYPE,
        config.TRANSACTIONS.BORROW.TYPE,
        config.TRANSACTIONS.BENEFIT.TYPE,
        // config.TRANSACTIONS.REQUEST.TYPE,
      ];
      delete params.sub_type 
    }

    const { search } = this.state;
    const {user} = this.props.auth

    if (search && search !== '') {
      params.where = {
        custom: {
          sub_type: Array.isArray(search)?search:`%${search.toLowerCase()}%`,
          comparison: 'OR'
        }
      };
    }

    if (!isEmptyObject(this.state.sort)) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }

    if (user.roles.includes(config.ROLES.COMPANIES)) {
      await this.props.onGetAllCompanies({ owner: getOwner(this.props.auth.user) })
      const { companies } = this.props;
      if (companies.error) {
        notify(this.t(companies.error.message))
      } else {
        params.owner = companies.items[0].id;
      }
    }

    await this.props.onGetAllTransactions(params);
    const { transactions } = this.props;
    if (transactions.error) {
      notify(this.t(transactions.error.message));
    } else {
      const translatedTransactions = transactions.items.map( t => {
        let newTrx = { ...t };
        if (t.type === "request") {
          newTrx.json_data.category.type = this.t(t.json_data.category.type);
        } else {
          newTrx.sub_type = this.t(t.sub_type);
        }
        return newTrx;
      })
      if(page_num === "export") {
        this.setState({ loading: transactions.loading });
        return translatedTransactions.filter(t => t.archived === 0)
      }
      let { query } = this.props.transactions;
      if (transactions.items.length === 0) {
        query.records = 0;
      }
      this.setState({ transactions: translatedTransactions, loading: transactions.loading, query });
    }
  };

  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getTransactions(currentPage));
  };
  
  onSearch = data => {
    if (this.state.search !== data.search){
      const foundItem = this.state.actionCategoryTypes.filter(item => String(item.name).toLocaleUpperCase().includes(String(data?.search).toLocaleUpperCase())).map(i=>i.id);
      this.setState({ search: foundItem.length>0? foundItem : data.search },  () => this.getTransactions());
    } 
  };

  searchByType = async type => {
    this.setState({ currentType: type,currentStatus: 'all' }, () => this.getTransactions());
  };
  searchByStatus= async status => {
    this.setState({ currentStatus: status }, () => this.getTransactions());
  };

  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  searchClear = form => {
    form.change('search', undefined);
    this.onSearch({ search: '' });
  };
  getStatusesByTransactionType(obj, transactionType = this.state.currentType) {
    const labelStatus = {
      NEW: 'Nuevo',
      ACCEPTED: 'Aceptado',
      CANCELED: 'Cancelado',
      ON_USER: 'Recibido',
      ON_OWNER: 'Devuelto',
      review: 'Aceptado',
      ARCHIVED: 'Invalidado',
      COMPLETED: 'Completado',
      GENERATED: 'Generado',
      CONSUMED: 'Consumido',
    }

    const statuses = [];

    // Función para extraer estados de un tipo de transacción
    function extractStatuses(subObj) {
      if (subObj.STATUS) {
        Object.entries(subObj.STATUS).forEach(([statusKey, statusValue]) => {
          const label = labelStatus[statusValue.toUpperCase()] || statusValue;
          statuses.push({ value: statusValue, label: label });
        });
      }
    }

    // Si se especifica un tipo de transacción, solo extraemos de ese tipo
    if (transactionType) {
      if (obj[transactionType]) {
        extractStatuses(obj[transactionType]);
      }
    } else {
      // delete obj.INITIATIVE; // Eliminamos INITIATIVE
      // Si no se especifica tipo, extraemos los estados de todos los tipos
      Object.values(obj).forEach(subObj => {
        if (subObj.TYPE !== "initiative") {
          extractStatuses(subObj);
        }
      });
    }

    return statuses;
  }
  removeDuplicateStatuses(statusArray) {
    const uniqueStatuses = new Map(); // Usamos un Map para evitar duplicados
  
    statusArray.forEach(status => {
      if (!uniqueStatuses.has(status.value)) {
        uniqueStatuses.set(status.value, status); // Guardamos el objeto completo basado en su 'value'
      }
    });
  
    return Array.from(uniqueStatuses.values()); // Convertimos el Map de nuevo a un array
  }
  render() {
    const { windowWidth, transactions, actionCategoryTypes, currentType, users, loading, query } = this.state;
 
    const transactionTypes = [{ value: "all", label: this.t("Todos") }, ...config.TRANSACTIONS_TYPES].filter(t => t.value !== "initiative" && t.value !== "benefit");
    const transactionStatus = [{ value: "all", label: this.t("Todos") },...this.removeDuplicateStatuses(this.getStatusesByTransactionType(config.TRANSACTIONS,this.state.currentType == 'all' ? null : this.state.currentType.toUpperCase()))];
    console.log(transactionStatus)

    const actionNameDisplay = row => {
      const foundItem = actionCategoryTypes.find(item => item.id === row['sub_type']);
      if(foundItem) return capitalizePhrase(foundItem.name);
      return capitalizePhrase(row['sub_type']);
    };

    const displaySourceName = row => {
      /* 
        Actions and initiatives do not have the source data saved in json_data
        So the user data must be found in order to display the name correctly
      */
      if (row['source'].length === 36) {
        const foundUser = users.find(user => user.id === row['source']);
        return capitalizePhrase(foundUser?.name);
      }
      return capitalizePhrase(row['source']);
    };

    /* Configure columns shown on table
    New ones can be added by creating new objects */
    const columnsWhenNotRequest = [
      {
        name: capitalize(this.t('type')),
        sortable: true,
        selector: actionNameDisplay,
        sortSelector: 'sub_type'
      },
      {
        name: this.t('Source'),
        sortable: true,
        selector:
          row => {
            if (row.type === 'action') {
              return `${row.json_data?.user?.first_name} ${row.json_data?.user?.last_name}` || '';
            }
            if (currentType === 'action' || currentType === 'initiative') {
              if (displaySourceName(row) === 'System') return 'Sistema';
              else return displaySourceName(row)
            }
            return `${row.json_data?.owner?.first_name} ${row.json_data?.owner?.last_name}` || '';
          },
        sortSelector: 'source',
        // hide: 'md'
      },
      {
        name: capitalize(this.t('Destino')),
        sortable: true,
        selector: row => {
          if(row.type === "borrow") {
            return `${capitalizePhrase(row.json_data?.borrower?.first_name)}, ${capitalizePhrase(row.json_data?.borrower?.last_name)}` 
          }
           return "-"
        },
      },
      {
        name: this.t('Nombre'),
        sortable: true,
        selector:
          row => {
            if (currentType === 'borrow') {
              return capitalizePhrase(row.json_data?.product?.name) || capitalizePhrase(row.json_data?.offer?.name);
            }
            if (currentType === 'action') {
              return capitalizePhrase(row.json_data?.action?.name);
            }
            if (currentType === 'initiative') {
              return capitalizePhrase(row.json_data?.initiative?.name);
            }
            if(currentType === "prize") return capitalizePhrase(row.json_data?.benefit?.name)
            return capitalizePhrase(row.json_data?.product?.name || row.json_data?.offer?.name || row.json_data?.action?.name || row.json_data?.initiative?.name || row.json_data?.benefit?.name);
          },
        sortSelector: 'target'
      },
      {
        name: capitalize(this.t('sharycoins')),
        sortable: true,
        selector: row =>  row.status === "archived" ? 0 : row.json_data.coins,
        // hide: this.hide(1280)
      },
      {
        name: capitalize(this.t('sharypoints')),
        sortable: true,
        selector: row =>row.status === "archived" ? 0 : row.json_data.points,
        // hide: this.hide(1280)
      },
      {
        name: capitalize(this.t('creation date')),
        sortable: true,
        selector: row => row.created_at.toLocaleDateString(),
        sortSelector: 'transactions__created_at',
        // hide: this.hide(1280)
      },
      {
        name: this.t('Status'),
        // hide: this.hide(1100),
        maxWidth:
          // 720p
          windowWidth < 1920
            ? '10.48vw'
            : // 1080p
            windowWidth >= 1920 && windowWidth < 3840
            ? '11.89vw'
            : // 4k
            windowWidth >= 3840
            ? '13.56vw'
            : '',
        selector: row => <p className={
          clsx("rounded-box px-2 py-1 capitalize", 
            row.status === "canceled" || row.status === "archived" ? "bg-red-100 ": 
              row.status === 'new' ? 'bg-yellow-100' :
                row.status == "completed" && row.json_data.coins == 0 && row.json_data.points == 0 ? "bg-red-100": "bg-green-100 "
          )}
        >
          {row.status === config.TRANSACTIONS.BORROW.STATUS.CANCELED ? "Cancelado": 
            row.status === config.TRANSACTIONS.BORROW.STATUS.NEW ? 'Nuevo' : 
              row.status === config.TRANSACTIONS.BORROW.STATUS.COMPLETED && row.json_data.coins == 0 && row.json_data.points == 0 ? "Intentos" :
                row.status === config.TRANSACTIONS.BORROW.STATUS.ARCHIVED ? "Invalidado" :
                  row.status === config.TRANSACTIONS.BORROW.STATUS.ON_USER ? "Recibido" : 
                    row.status === config.TRANSACTIONS.BORROW.STATUS.ON_OWNER ? "Devuelto" : 
                      row.status === config.TRANSACTIONS.BORROW.STATUS.COMPLETED && row.type === 'borrow' ? "Completado" :
                        row.status === config.TRANSACTIONS.BORROW.STATUS.ACCEPTED ? "Aceptado" : 
                          row.status === config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED ? "Consumido" :
                            row.status === config.TRANSACTIONS.BENEFIT.STATUS.GENERATED ? 'Generado' : 'Aceptado'
          }
        </p>
      }
    ];


    const columnsWhenRequest = [
      {
        name: capitalize(this.t('category')),
        sortable: true,
        selector: row => capitalizePhrase(row.json_data?.category?.type),
        sortSelector: 'target'
      },
      {
        name: this.t('Nombre'),
        sortable: true,
        selector: row => capitalizePhrase(row.json_data?.category?.name)
      },
      {
        name: capitalize(this.t('details')),
        sortable: true,
        selector: row => capitalizePhrase(row.json_data?.details)
      },
      {
        name: capitalize(this.t('requester')),
        sortable: true,
        selector: row =>
          capitalizePhrase(`${row.json_data?.requester?.first_name} ${row.json_data?.requester?.last_name}`),
        hide: this.hide(1000)
      },
      {
        name: capitalize(this.t('creation date')),
        sortable: true,
        selector: row => row.created_at.toLocaleDateString(),
        sortSelector: 'transactions__created_at',
        hide: this.hide(1000)
      }
    ];

    let columns = currentType !== 'request' ? columnsWhenNotRequest : columnsWhenRequest;

    // Layout actions
    const _actions = {
      secondaries: [
        {
          title: this.t('Export'),
          onClick: async () =>{
            const { users } = this.state;
            const exportedTransactions = await this.getTransactions("export")
            const proccessedTransactions = exportedTransactions.map(transaction => {
              let origen = ""
              // if (currentType === 'borrow') {
              //   origen = `${transaction.json_data?.owner?.first_name} ${transaction.json_data?.owner?.last_name}`;
              // }
              // if (currentType === 'action' || currentType === 'initiative') {
              //   origen = displaySourceName(transaction) !== "System"? displaySourceName(transaction) : "Sistema";
              // }
              if (transaction.type === "borrow") {
                origen = capitalizePhrase(users.find(u => u.id === transaction.json_data?.owner?.id)?.name) || '';
                // origen = `${transaction.json_data?.owner?.first_name} ${transaction.json_data?.owner?.last_name}`;
              }
              else {
                origen = capitalizePhrase(users.find(u => u.id === transaction.json_data?.user?.id)?.name) || '';
                // origen = `${transaction.json_data?.user?.first_name} ${transaction.json_data?.user?.last_name}` || '';
              }
              let type = actionNameDisplay(transaction)
              return {
                "Tipo": type,
                // TODO: Add source
                "Origen": origen,
                "Nombre": transaction.json_data?.product?.name || transaction.json_data?.offer?.name || transaction.json_data?.action?.name || transaction.json_data?.initiative?.name || transaction.json_data?.benefit?.name,
                "Sharycoins": transaction.json_data?.coins,
                "Sharypoints": transaction.json_data?.points,
                "Fecha": transaction.created_at.toLocaleDateString(),
                "Estado": transaction.status === "canceled" ? "Cancelado": transaction.status === 'new' ? 'Nuevo' : "Aceptado",
              }
            });
            exportToExcel(proccessedTransactions, 'transactions')
            await this.getTransactions()
          }
        }
      ],
      form: {
        onSubmit: () => null,
        fields: [
          {
            onChange: this.searchByType,
            title: capitalize(this.t('type')),
            component: SelectInput,
            inputClassName: 'mt-3',
            className: 'w-48 mt-3 mr-2',
            name: 'type',
            data: transactionTypes,
            checkPermissions: 'insert'
          },
          {
            onChange: this.searchByStatus,
            title: capitalize(this.t('Status')),
            component: SelectInput,
            inputClassName: 'mt-3',
            className: 'w-48 mt-3 mr-2',
            name: 'status',
            data: transactionStatus,
            checkPermissions: 'insert'
          },
        ]
      },
      search: {
        onSearch: this.onSearch,
        onClearClick: this.searchClear,
        title: this.t('Search')
      }
    };

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={_actions}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={transactions}
            columns={columns}
            query={query}
            getDataFunction={this.getTransactions}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            /* boolean check, request doesn't need an expandable component
            as there isn't enough data */
            /*             expandableRows={currentType !== 'request' ? true : false}
            expandableRowsComponent={expandableComponent} */
            onRowExpand={() => {}}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    transactions: state.transactions.list,
    categories: state.categories.list,
    users: state.users.list,
    
    challengeBenefit: state.challenges.challengeBenefit,
    challenges: state.challenges.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllTransactions: params => dispatch(transactionActions.getAll(params)),
    onGetAllCompanies: params => dispatch(companyActions.getAll(params)),
    onGetActionTypes: id => dispatch(categoriesActions.getAll(id)),
    onGetAllUsers: params => dispatch(usersActions.getAll(params)),

    onGetChallengeBenefit: id => dispatch(challengesActions.getChallengeBenefit(id)),
    onGetChallenges: params => dispatch(challengesActions.getAll(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Transactions));
