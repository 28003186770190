import React from 'react'
import FileSaver from "file-saver";
import {utils, write} from "xlsx";


export const exportToExcel = (tableData, fileName, customData) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const sheetData = []
  const columnKeys = Object.keys(tableData[0]);
  sheetData.push(columnKeys)
  tableData.forEach((obj) => {
    const fila = columnKeys.map((key) => obj[key]);
    sheetData.push(fila);
  });

  if(customData?.data && Array.isArray(customData.data)) {
    if(customData.position === "top") sheetData.push(...customData.data)
    else sheetData.unshift(...customData.data)
  }

  const ws = utils.aoa_to_sheet(sheetData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};