import React from 'react'
import Downshift from 'downshift'
import {matchSorter} from 'match-sorter'

import Icon from '../../libraries/icons';

const itemToString = item => (item ? item : '')

/*
* Example:
  const items = [{ label : 'A', value: 'aaa' }]

  <Field name="fieldname" component={DownshiftInput} placeholder="placeholder"
    validate={required}
    items={items}
  />

*/
const DownshiftInput = ({ input, label, meta, placeholder, items, ...rest }) => (
  <Downshift
    {...input}
    onInputValueChange={inputValue => {
      input.onChange(inputValue)
    }}
    itemToString={itemToString}
    selectedItem={input.value}
  >
    {({
      getInputProps,
      getItemProps,
      getLabelProps,
      isOpen,
      inputValue,
      highlightedIndex,
      selectedItem
    }) => {
      console.log("items", items)
      const filteredItems = items && items.filter(item => item.label.includes(inputValue))
      return (
        <div className={"form-group relative " + rest.className ? rest.className : ""}>
          <div className="relative">
            <label htmlFor={input.name} className={"label " + (rest.labelClassName && rest.labelClassName)}>{label}</label>
            <input
              {...getInputProps({
                name: input.name,
                placeholder
              })}
              className={"input w-full " + (meta.error && meta.touched ? "is-invalid invalid-shadow " : "") + (rest.inputClassName ? rest.inputClassName : "") }
            />
            {isOpen &&
              !!filteredItems.length && (
                <div
                  className="border-4"
                >
                  {filteredItems.map(({ value, label }, index) => (
                    <div
                      {...getItemProps({
                        key: value,
                        index,
                        item: value,
                       })}
                      className={"px-4 py-3 " + (highlightedIndex === index ? " downshift-options-focus" : " bg-white") + (selectedItem === value ? " downshift-options-selected" : "")}
                    >
                      {label}
                    </div>
                  ))}
                </div>
              )}
              {rest.showArrow && (
                <div className="absolute right-4 top-4">
                  <Icon className="h-5 w-5 text-gray-200 mt-10 opacity-40" name="search" />    
                </div>
              )}              
          </div>
          {!rest.noerror && <p className="invalid-feedback">{meta.error && meta.touched && meta.error}&nbsp;</p> }
        </div>
      )
    }}
  </Downshift>
)

export default DownshiftInput
