import React from 'react';
export default function Avatar({ image, className, ...rest }) {
  return (
    <div className={"avatar " + (rest.containerClassName ? rest.containerClassName : "") + (!image ? " placeholder" : "")}>
      <div className={(className ? className : "rounded-full w-20 h-20") + (!image ? " bg-neutral-focus text-neutral-content" : "")}>
        { image ? (
          <img src={image} alt="" />
        ):(
          <span>{rest.label || ""}</span>
        )}
      </div>
    </div>
  )
}