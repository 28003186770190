import { useEffect, useState } from 'react';
import { capitalize, months } from '../../libraries/utils';

function BirthDateInput({ input, meta, label, options, empty, ...rest }) {
  
  const today = new Date()
  const years = [...Array(today.getFullYear() - 1899).keys()].map(x => {
    const year = today.getFullYear() - x;
    return { label: year, value: year };
  });
  const months_arr = [...Array(13).keys()].slice(1, 13).map(x => { return { label: capitalize(months(x - 1).slice(0, 3)), value: x - 1 } })
  const days = [...Array(32).keys()].slice(1, 32).map(x => { return { label: x, value: x } })

  const [day, setDay] = useState(input.value?.getDate ? +input.value?.getDate() : today.getDate())
  const [month, setMonth] = useState(input.value?.getMonth ? +input.value?.getMonth() : today.getMonth())
  const [year, setYear] = useState(input.value?.getFullYear ? +input.value?.getFullYear() : today.getFullYear())
  
  const [selectedDate, setSelectedDate] = useState(today)
  const [invalidDate, setInvalidDate] = useState(false)

  useEffect(() => {
    try {
      let d = new Date(year, month, day)
      let valid = d.getFullYear() == year && d.getMonth() == month && d.getDate() == day
      if (valid) {
        setSelectedDate(d)
        setInvalidDate(false)
      } else {
        setSelectedDate(null)
        setInvalidDate(true)
      }
    } catch (e) {
      setSelectedDate(null)
      setInvalidDate(true)
    }
  }, [day, month, year])

  useEffect(() => {
    if (selectedDate) {
      input.onChange(selectedDate)
    }
  }, [selectedDate])

  return (
    <div className={"form-control w-full max-w-xs " + rest.className ? rest.className : ""}>
      {label && (
        <label htmlFor={input.name} className={"label " + (rest.labelClassName ? rest.labelClassName : "")}>
          <span className="label-text">{label}</span>
        </label>
      )}
      <div className='flex gap-3'>
        <input {...input} id="bdate" className="hidden" type="date" value={selectedDate ? selectedDate.toISOString().substr(0, 10) : null} onChange={(v) => {
          input.onChange(selectedDate)
        }} />
        <select
          value={day}
          onChange={(v) => {
            setDay(+v.target.value)
          }}
          className={"select flex-1 "
            + (meta && meta.error && meta.touched ? " input-error invalid-shadow " : "")
            + (rest.narrow ? " px-1 " : "")
            + (rest.inputClassName ? rest.inputClassName : "")
          }
          disabled={rest.readOnly || rest.disabled}
        >
          {empty && <option key={input.name + '0'} value="">{empty}</option>}
          {days && days.map(option =>
            <option key={option.value} value={option.value} disabled={option.disabled} selected={option.selected}>{option.label}</option>
          )}
        </select>


        <select
          value={month}
          onChange={(v) => {
            setMonth(+v.target.value)
          }}

          className={"select flex-1 "
            + (meta && meta.error && meta.touched ? " input-error invalid-shadow " : "")
            + (rest.narrow ? " px-1 " : "")
            + (rest.inputClassName ? rest.inputClassName : "")
          }
          disabled={rest.readOnly || rest.disabled}
        >
          {empty && <option key={input.name + '0'} value="">{empty}</option>}
          {months_arr && months_arr.map(option =>
            <option key={option.value} value={option.value} disabled={option.disabled} selected={option.selected}>{option.label}</option>
          )}
        </select>


        <select
          value={year}
          onChange={(v) => {
            setYear(+v.target.value)
          }}
          className={"select flex-1 "
            + (meta && meta.error && meta.touched ? " input-error invalid-shadow " : "")
            + (rest.narrow ? " px-1 " : "")
            + (rest.inputClassName ? rest.inputClassName : "")
          }
          disabled={rest.readOnly || rest.disabled}
        >
          {empty && <option key={input.name + '0'} value="">{empty}</option>}
          {years && years.map(option =>
            <option key={option.value} value={option.value} disabled={option.disabled} selected={option.selected}>{option.label}</option>
          )}
        </select>


      </div>

      {invalidDate && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">Fecha no válida</span>
        </label>
      )}


      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">{meta && meta.touched && meta.error}</span>
        </label>
      )}
    </div>
  )
}

export default BirthDateInput;