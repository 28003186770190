import types from './types';
import payments from './services';

const onCoin = () => {

  function request(payload) { return { type: types.PAYMENTS_USD_REQUEST, payload } }
  function success(payload) { return { type: types.PAYMENTS_USD_SUCCESS, payload } }
  function failure(error) { return { type:   types.PAYMENTS_USD_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request());

    let response;
    response = await payments.onCoin() //params, getState().users.auth.token
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }
  };

}

export default {
  onCoin,
};