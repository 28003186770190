import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { capitalize, formatFullname, getObjectWithJsonDataToFormValues } from "../../libraries/utils";
import { history } from '../../routes';
import config from '../../config';

import ButtonIcon from '../commons/ButtonIcon';
import Icon from '../../libraries/icons';
import { capitalizePhrase } from '../../libraries/utils';

import Avatar from '../commons/Avatar'
import logo from '../../assets/img/logo.png';
import MultiLineTextDisplay from '../commons/MultiLineTextDisplay';



export default function FeedCard({ route, data, owner: currentOwner, allowComment=true, company = {name: 'Empresa'},  ...rest }) {
  const { t, i18n } = useTranslation();
  if(!data) return null

  // console.log("DFATA", data)

   const { img, type, sub_type, owner, initiative, product, borrower, action, assistant,user, action_img, description, offer, service, likes, benefit, created_at} = getObjectWithJsonDataToFormValues(
     data,
     ['id', 'img', 'name', 'points', 'coins', 'type','sub_type', 'details','owner','initiative','source','target','status','product','borrower','action','assistant','user', 'action_img', 'description', 'offer', 'service', 'likes', 'benefit', 'created_at']
   );
    
  const fullname = (data) => {
    return data ? `${data.first_name} ${data.last_name}`: '';
  }

  const username = (data) => {
    return data ? data.first_name.charAt(0).toUpperCase() + data.last_name.charAt(0).toUpperCase() : '';
  }

  const gotoReclamo = () => {
    history.push({
      pathname: config.ROUTES.SEND_MESSAGE,
      state: {
        fromPath:config.ROUTES.HOME,
        reclamo: {
          type: "reclamo",
          related_to: data?.id,
        },
        }
      }
    );
  }

  let feedOwnerId, feedOwnerImg , feedOwnerName, feedOwnerUsername, feedType, feedImg, feedDetail, feedComment = "";
  let feedLikes = likes?.length ? likes?.split(',').length : null
  let date = new Date(created_at)
  //let date = subHours(new Date(created_at), 3)
  let iconImg = false

    switch (type){
      case config.TRANSACTIONS.INITIATIVE.TYPE:
         feedOwnerId = assistant.id
         feedOwnerImg = assistant.profile_image 
         feedOwnerName = fullname(assistant)
         feedOwnerUsername = username(assistant)
         feedType = t(sub_type) //"Servicio Comunitario"/* sub_type */;
         feedImg = initiative.img;
         feedDetail = initiative.name;
         feedComment = `${feedOwnerName} participó de ${initiative.name}`; // (Sabrina Gans) Participó de (nombre de iniciativa)
        break;

      case config.TRANSACTIONS.ACTION.TYPE:
         feedOwnerId = user.id
         feedOwnerImg = user.profile_image
         feedOwnerName = fullname(user)
        feedOwnerUsername = username(user)

         feedType = t(type) //"Acción"/* type */;
         feedImg = action_img;
         feedDetail = action.name;
         feedComment = `${feedOwnerName} realizó la acción sostenible ${action.name}` //feedDetail + (description ? ": " + description : " " ); 
         feedComment += description ? `: ${description}` : ""
        break; 
        
      case  config.TRANSACTIONS.BORROW.TYPE:
          if(sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT){
            feedOwnerId = borrower.id
            feedOwnerImg = borrower.profile_image &&  borrower.profile_image
            feedOwnerName = fullname(borrower)
            feedOwnerUsername = username(borrower)
            feedType = t(sub_type) //"Producto"/
            feedImg = product.img;
            feedDetail = product.detail;
            feedComment = `${fullname(borrower)} pidió prestado ${product.name} a ${fullname(owner)}` //a (Sabrina Sharyco) pidió prestado (cama de gato) a (Sabrina Sharyco)
          }
          if(sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.SERVICE){
            feedOwnerId = borrower.id
            feedOwnerImg = borrower.profile_image &&  borrower.profile_image
            feedOwnerName = fullname(borrower)
            feedOwnerUsername = username(borrower)
            feedType = t(sub_type) //"Producto"/*  sub_type */;
            feedImg = offer.img;
            feedDetail = offer.detail;
            feedComment = `${fullname(borrower)} solicitó el servicio ${offer.name?.toLowerCase()} a ${fullname(owner)}`; //(Sabrina Gans) Solicitó el favor (Arreglo relojes) a (Sabrina Sharyco)
            iconImg = "globe"
          }
          if(sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR){
            feedOwnerId = borrower.id
            feedOwnerImg = borrower.profile_image &&  borrower.profile_image
            feedOwnerName = fullname(borrower)
            feedOwnerUsername = username(borrower)
            feedType = t(sub_type) //"Producto"/*  sub_type */;
            feedImg = offer.img;
            feedDetail = offer.detail;
            feedComment = `${fullname(borrower)} solicitó el favor ${offer.name?.toLowerCase()} a ${fullname(owner)}`; //(Sabrina Gans) Solicitó el favor (Arreglo relojes) a (Sabrina Sharyco)
            iconImg = "support"
          }
      break;
      case "benefit":
        feedOwnerId = user.id
        feedOwnerImg = user.profile_image
        feedOwnerName = fullname(user)
        feedOwnerUsername = username(user)
        feedType = t(sub_type) //"Producto"/*  sub_type */;
        feedImg = benefit.json_data?.picture;
        feedDetail = "";
        feedComment = `${fullname(user)} ha usado el beneficio ${benefit.name}` 
      break;


      default: 
        break;
    }

  return (
    <div className={"" + (rest.className ? rest.className : "card white overflow-hidden")}>
      <div className="flex justify-between items-center p-3">
        <div className="flex content-start items-center">
          <Avatar
            image={feedOwnerImg}
            label={feedOwnerUsername}
            className="rounded-full w-10 h-10"
            containerClassName="mr-2"
            id={feedOwnerId}
          />
          {/* <div className="w-8 h-8 mr-2 overflow-hidden flex justify-center items-center rounded-full">
            <img alt="" src={feedOwnerImg} />
          </div> */}
          <div className="flex-row">
          <h5 className="">{feedOwnerName}</h5>
          <h6 className='text-gray-500'>{t("timeRemains", { date: new Date(date) })}</h6>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <span className="badge badge-primary text-xs">{feedType}</span>
        </div>
      </div>
      <div className="flex items-center justify-start p-3">
        <span className=" rounded-lg px-2 bg-secondary text-lg">{capitalizePhrase(company.name)}</span>
      </div>
      <div className="h-48 md:h-64 overflow-hidden flex justify-center items-center">
        {/* <Link to={route}> */}
        {iconImg ? <Icon className="h-28 w-28 mx-auto mb-2" name={iconImg} /> : <img alt="" className='aspect-square object-contain w-1/2 md:h-full' src={feedImg ? feedImg : logo} />}
        {/* </Link> */}
        <MultiLineTextDisplay className="p-3" text={feedComment} textClassName="text-md md:text-lg font-bold"/>
      </div>
      

    </div>
  )
}