import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import actionActions from '../../../context/actions/actions';
import categoryActions from '../../../context/categories/actions';
import { isEmptyObject, capitalize } from '../../../libraries/utils';
import PrivateButton from '../../../components/commons/PrivateButton';
import Swal from 'sweetalert2';
import Loader from '../../../components/commons/Loader';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import clsx from 'clsx';
import SelectInput from '../../../components/forms/SelectInput';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Actions extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      actions: [],
      categories: [],
      loading: true,
      sort: {},
      windowWidth: window.innerWidth,
      initialPage: 1,
      currentPage: 1,
      currentType: "all"
    };
    this.breadcrumbs = [this.t('Actions')];
  }

  componentDidMount() {
    const { location } = this.props
    const initialPage = new URLSearchParams(location.search).get("page")
    if(initialPage) {
      this.setState({initialPage})
    }

    this.getActions(initialPage || 1);
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getCategories = async params => {
    // If the length is equal to the length of the ID's stored in the database
    await this.props.onGetCategories({...params, type: "actions"});
    const categories  = this.props.categories?.items;
    if (categories.error) {
      notify(this.t(categories.error.message));
    } else {
      this.setState({ categories: categories });
    }

    const { actions } = this.state;
    for (let action of actions) {
      const foundCategory = categories.find(elem => elem.id === action.type);
      if (foundCategory?.name) action.type = foundCategory?.name;
    }
  };
  
  searchByType = async type => {
    this.setState({ currentType: type }, () => this.getActions());
  };

  getActions = async (page_num = 1, type = this.state.currentType) => {
    const params = { page_num, type};
    if(params.type === "all") delete params.type
    
    const { search } = this.state

    if (search && search !== '') {
      params.where = {
        custom: {
          // keys can be in any order
          name: `%${search.toLowerCase()}`,
          details: `%${search.toLowerCase()}`,
          comparison: 'OR'
        }
      };
    }

    if (!isEmptyObject(this.state.sort)) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }

    await this.props.onGetAllActions(params);
    const { actions } = this.props;
    if (actions.error) {
      notify(this.t(actions.error.message));
    } else {
      this.setState({ actions: actions.items, loading: actions.loading });
      this.getCategories();
    }
  };

  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getActions(currentPage));
  };

  onRemove = (id, resetRows) => {
    const { currentPage } = this.state;
    const { actions } = this.props;
    customSwal
      .fire({
        title: this.t(resetRows ? capitalize('delete multiple elements') : '¿Are you sure?'),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          // If the parameter received is an array, execute multiple delete function
          this.setState({ loading: true })
          if (resetRows) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (actions.error) {
              notify(this.t(actions.error.message));
            } else {
              // Reload actions
              this.getActions(currentPage);
            }
          } else {
            // If not an array, do:
            await this.props.onRemove(id);
            if (actions.error) {
              notify(this.t(actions.error.message));
            } else {
              // Reload actions
              this.getActions(currentPage);
            }
          }
        }
      });
  };

  // When edit button is clicked, redirect to edit view
  onEdit = id => {
    const { currentPage } = this.state
    history.push(`${config.ROUTES.ACTIONS}/${id}?page=${currentPage}`);
  };

  // Or when new button is clicked, redirect to creation view (same view as edit)
  onNew = () => {
    history.push(config.ROUTES.ACTIONS_NEW);
  };

  onSearch = data => {
    if (this.state.search !== data.search) this.setState({ search: data.search || '' }, () => this.getActions());
  };

  searchClear = form => {
    form.change('search', undefined);
    this.onSearch({ search: '' });
  };

  // Function for hiding columns when the windowWidth goes below the number specified
  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  onPageChange = (page) => {
    this.setState({currentPage: page})
  }

  render() {
    const { actions, loading, windowWidth, initialPage, categories } = this.state;

    const roles = this.props.auth.user.roles

    const actionsTypes =  [{ value: "all", label: this.t("Todos") }, ...categories.map(cat => ({label: cat.name, value: cat.id}))]

    /* Component shown when row "expand" btn is clicked
    (it's a downwards arrow, usually only appears on mobile screen sizes)
    In the case of this component it appears on every size */
    const expandableComponent = ({ data }) => {
      return (
        <>
          {loading ? (
            <div className="flex justify-center my-6">{capitalize(this.t('loading'))}...</div>
          ) : (
            <div className="flex flex-wrap py-3 px-5 items-center justify-around bg-gray-50">
              <div className="py-2">
                <h4>{capitalize(this.t('details'))}</h4>
                <p className="text-gray-500">{data.details}</p>
              </div>
              <div className="py-2">
                <h4>{capitalize(this.t('type'))}</h4>
                <p className="text-gray-500">{data.type}</p>
              </div>
              <div className="py-2">
                <h4>{capitalize(this.t('sharycoins'))}</h4>
                <p className="text-gray-500">{data.json_data?.coins}</p>
              </div>
              <div className="py-2">
                <h4>{capitalize(this.t('sharypoints'))}</h4>
                <p className="text-gray-500">{data.json_data?.points}</p>
              </div>
            </div>
          )}
        </>
      );
    };

    // Configure columns shown on table
    // New ones can be added by creating new objects
    const columns = [
      {
        name: this.t('Nombre'),
        sortable: true,
        selector: row => row['name'],
        sortSelector: 'name',
        // windowWidth - (table padding + cell padding) - (actions cell width + actions cell padding)
        // Broken because categories column was eliminated due to a bug
        maxWidth: '200px'
      },
      {
        name: capitalize(this.t('details')),
        sortable: true,
        selector: row => row['details'],
        sortSelector: 'details',
        maxWidth: '400px',
        // hide: this.hide(920)
      },
      {
        name: capitalize(this.t('type')),
        sortable: true,
        selector: row => (row['type'].length === 36 ? <Loader spinnerClassName="h-4" /> : row['type']),
        sortSelector: 'type',
        maxWidth: '200px',
        // hide: this.hide(1100)
      },
      {
        name: capitalize(this.t('sharypoints')),
        sortable: true,
        selector: row => row.json_data?.points,
        sortSelector: 'json_data.points',
        // hide: this.hide(1280)
      },
      {
        name: capitalize(this.t('sharycoins')),
        sortable: true,
        selector: row => row.json_data?.coins,
        sortSelector: 'json_data.coins',
        // hide: this.hide(1280)
      },
      {
        name: capitalize(this.t('Activo')),
        sortable: true,
        selector: row => row.enabled ? this.t('Activo') : this.t('Inactivo'),
        sortSelector: 'enabled',
        // hide: this.hide(1280),
        cell: (row) => {
          const status = row.enabled ? "habilitado" : "deshabilitado";
          return (
            <span
              className={clsx(
                "rounded-box px-2 py-1 capitalize",
                !row.enabled ? "bg-red-100 " : "bg-green-100 "
              )}
            >
              {status}
            </span>
          );
        },
      },
      {
        name: 'Acciones',
        allowOverflow: true,
        center: true,
        width: '130px',
        hide: this.hide(370),
        cell: row => {
          return (
           <div className="flex justify-center align-center rounded-full gap-2">
              <ButtonIcon onClick={() => this.onEdit(row.id)} icon="view_show" buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm" className="w-5" />
              <PrivateButton control="delete">
                <ButtonIcon onClick={() => this.onRemove(row.id)} icon="trash" buttonClassName="btn-link text-error bg-gray-100 btn-sm" />
              </PrivateButton>
            </div>
          );
        }
      }
    ];

    // Layout actions
    // Using _ to differentiate from actions in state (only in this component)
    const _actions = {
      main: {
        onClick: this.onNew,
        title: this.t('New'),
        checkPermissions: 'insert'
      },
      secondaries: [
        {
        onClick: () => history.push(config.ROUTES.ACTIONS_ORDER),
        title: "Change order",
        checkPermissions: 'insert'
      },
        {
        onClick: () => history.push(config.ROUTES.MODERADOR_ACCIONES),
        title: "Moderar Acciones",
        // checkPermissions: 'insert'
      }
    ],
      form: {
        onSubmit: () => null,
        fields: [
          {
            onChange: this.searchByType,
            title: capitalize(this.t('type')),
            component: SelectInput,
            inputClassName: 'mt-3',
            className: 'w-48 mt-3 mr-2',
            name: 'type',
            data: actionsTypes,
            checkPermissions: 'insert'
          }
        ]
      },
      search: {
        onSearch: this.onSearch,
        onClearClick: this.searchClear,
        title: this.t('Search')
      }
    };

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={_actions}
        loading={loading}>
        <PanelLayout>
          <StyledDataTable
            data={actions}
            columns={columns}
            selectableRows={roles.includes(config.ROLES.ADMIN) ? windowWidth > 600 : null}
            query={this.props.actions.query}
            getDataFunction={this.getActions}
            multipleDeleteFunction={roles.includes(config.ROLES.ADMIN) ? this.onRemove : null}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            expandableRows
            expandableRowsComponent={expandableComponent}
            onRowExpand={() => {}}
            initialPage={initialPage}
            onPageChange={this.onPageChange}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    actions: state.actions.list,
    categories: state.categories.list,
    auth: state.users.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllActions: params => dispatch(actionActions.getAll(params)),
    onGetCategories: params => dispatch(categoryActions.getAll(params)),
    onRemove: id => dispatch(actionActions.del(id))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Actions)))
